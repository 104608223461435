import React from "react";
import Box from "Components/Box";
import {Col, Row} from "antd";
import FlexRow from "Components/FlexRow";

const NotFound = () => {

    return (
        <div className={"w-100 flex column justify-center h-100"}>
            <Row justify="center">
                <Col xs={22} sm={20} md={16} lg={16} xl={12} xxl={6}>
                    <Box>
                        <FlexRow justify={"center"} direction={'column'}>
                            <h1>404</h1>
                            <p>Siden blev ikke fundet</p>
                            <img alt={"Not Found"} src={'/images/404.jpeg'} width={300} height={'auto'} style={{borderRadius: 10}}/>
                        </FlexRow>
                    </Box>
                </Col>
            </Row>
        </div>
    )

}

export default NotFound;
