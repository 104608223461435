import {queryCache, useMutation, useQuery} from "react-query";
import {CustomerModel} from "Apis/Models/CustomerModel";
import {request} from "Apis/BaseApi";

export const customerCacheKey = 'customer';
export const useCustomer = (id: number, enabled: boolean = true) => {
    return useQuery<CustomerModel>([customerCacheKey, id], async () => {
        return await request<CustomerModel>({
            url: `/customers/${id}`,
            method: 'GET'
        });
    }, {
        enabled
    })
}

export const customersCacheKey = 'customers';
export const useCustomers = (searchFilter?: string) => {
    return useQuery<CustomerModel[]>([customerCacheKey, searchFilter], async () => {
        let url = '/customers';

        if (searchFilter) {
            url += `?queryFilter=${searchFilter}`;
        }

        return await request<CustomerModel[]>({
            url,
            method: 'GET'
        });
    })
}

export const useSaveCustomer = () => {
    return useMutation(async (customer: CustomerModel): Promise<CustomerModel> => {
        if (customer.id) {
            return await request<CustomerModel>({
                url: `/customers/${customer.id}`,
                method: 'PUT',
                data: customer
            })
        } else {
            return await request<CustomerModel>({
                url: `/customers`,
                method: 'POST',
                data: customer
            })
        }
    }, {
        onSuccess: () => {
            queryCache.invalidateQueries(customerCacheKey)
            queryCache.invalidateQueries(customersCacheKey)
        }
    })
}
