import { queryCache, useMutation, useQuery } from "react-query";
import { request } from "Apis/BaseApi";
import { NewsModel } from "Apis/Models/NewsModel";

export const adminNewsCacheKey = 'adminNews';
export const useAdminNews = () => {
    return useQuery<NewsModel[]>(adminNewsCacheKey, async () => {
        return await request<NewsModel[]>({
            url: `/admin/news`,
            method: 'GET'
        })
    })
}

export const useAdminSaveNews = () => {
    return useMutation(async (entry: NewsModel): Promise<NewsModel> => {
        let response: NewsModel;

        if (entry.id) {
            response = await request<NewsModel>({
                url: `/admin/news/${entry.id}`,
                method: 'PUT',
                data: entry
            })
        } else {
            response = await request<NewsModel>({
                url: `/admin/news`,
                method: 'POST',
                data: entry
            })
        }

        return response;
    }, {
        onSuccess: (entry: NewsModel) => {
            queryCache.invalidateQueries(adminNewsCacheKey)
        },
    })
}

export const useAdminSendNews = () => {
    return useMutation(async (entry: NewsModel): Promise<NewsModel> => {
        return await request<NewsModel>({
            url: `/admin/news/${entry.id}/send`,
            method: 'PUT',
        })
    }, {
        onSuccess: (entry: NewsModel) => {
            queryCache.invalidateQueries(adminNewsCacheKey)
        },
    })
}

export const useAdminDeleteNews = () => {
    return useMutation(async (event: NewsModel): Promise<NewsModel> => {
        return await request<NewsModel>({
            url: `/admin/news/${event.id}`,
            method: 'DELETE',
        })
    }, {
        onSuccess: (entry: NewsModel) => {
            queryCache.invalidateQueries(adminNewsCacheKey)
        },
    })
}
