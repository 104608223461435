import React from "react";
import {Table} from "antd";
import Spinner from "Components/Spinner";
import StyledButton from "Components/StyledButton";
import FlexRow from "Components/FlexRow";
import { useHistory } from "react-router-dom";
import {useOrganizations} from "Apis/OrganizationAdminApi";
import {useAdminUsers, useStandardUsers} from "Apis/UserAdminApi";
import OrganizationModel from "Apis/Models/OrganizationModel";
import DefaultPageLayout from "Pages/Layouts/DefaultPageLayout";
import Building from "Assets/Icons/Building";

const OverviewPage = () => {

    const history = useHistory();

    const {data: organizations, isLoading: isLoadingOrganizations} = useOrganizations();
    const {data: adminUsers, isLoading: isLoadingUsers} = useAdminUsers();
    const {data: standardUsers, isLoading: isLoadingStandardUsers} = useStandardUsers();

    const createOrganization = () => {
        history.push('/admin/organizations/create')
    }

    const getOccupationText = (text: string) => {
        return text === 'Sanitarian' ? 'VVS' : 'Elektriker';
    }

    const getResponsibleName = (text: string, record: OrganizationModel) => {
        return adminUsers?.find(x => x.id === record.responsibleId)?.name ?? 'Ikke angivet';
    }

    const getOwnerName = (text: string, record: OrganizationModel) => {
        return standardUsers?.find(x => x.id === record.ownerId)?.name ?? 'Ikke angivet';
    }

    return (
        <DefaultPageLayout title={"Firmaer"} icon={<Building />}>
            <FlexRow justify={"end"}>
                <StyledButton onClick={createOrganization} color={"primary"}>Opret firma</StyledButton>
            </FlexRow>
            <Table rowKey={'id'} dataSource={organizations} loading={{indicator: <Spinner />, spinning: isLoadingUsers || isLoadingOrganizations || isLoadingStandardUsers}} pagination={false}
               onRow={(record) => {
                   return {
                       onClick: () => history.push(`/admin/organizations/edit/${record.id}`),
                   };}
               }
               rowClassName={"clickable"}
            >
                <Table.Column title={"Id"} dataIndex={'id'} />
                <Table.Column title={"Beskæftigelse"} dataIndex={"occupation"} render={(getOccupationText)} />
                <Table.Column title={"Navn"} dataIndex={"name"} />
                <Table.Column title={"CVR Nummer"} dataIndex={"vatNumber"}/>
                <Table.Column title={"Håndværker"} dataIndex={"ownerId"} render={getOwnerName} />
                <Table.Column title={"Ansvarlig"} dataIndex={"responsible"} render={getResponsibleName} />
            </Table>
        </DefaultPageLayout>
    )

}

export default OverviewPage;
