import React, {useCallback, useState} from "react";
import {DawaAutocompleteModel} from "Apis/Models/DawaAutocompleteModel";
import {lookupAddress} from "Apis/DawaApi";
import _ from "lodash";
import {Menu} from "antd";

export const useAddressSuggestions = () => {

    const [addressSuggestions, setAddressSuggestions] = useState<DawaAutocompleteModel[]>([]);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const queryFn = useCallback(_.debounce(async (street: string) => {
        if (street !== '') {
            let suggestions = await lookupAddress(street);

            setAddressSuggestions(suggestions)
        } else {
            setAddressSuggestions([])
        }
    }, 300), [])

    const clearSuggestions = () => {
        setAddressSuggestions([])
    }

    return [addressSuggestions, queryFn, clearSuggestions]
}

export const useAddressSuggestionMenu = (onSuggestionSelected: any) => {

    const [addressSuggestions, queryFn, clearSuggestions] = useAddressSuggestions();

    const onClickHandler = async (value: any) => {
        const clickedAddress = addressSuggestions.filter(x => x.adresse.id === value.key)[0]

        await onSuggestionSelected(clickedAddress);

        clearSuggestions();
    }

    const addressSuggestionMenu = (
        <Menu onClick={onClickHandler} style={{overflowY: 'scroll', maxHeight: '30vh', overflowX: 'hidden'}}>
            {addressSuggestions.map((address) => (
                <Menu.Item key={address.adresse.id}>
                    {address.tekst}
                </Menu.Item>
            ))}
        </Menu>
    )

    return [addressSuggestions, addressSuggestionMenu, queryFn, clearSuggestions]
}
