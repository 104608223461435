import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import moment from 'moment';
import { ConfigProvider } from "antd";
import locale from 'antd/es/locale/da_DK';
import UserProvider from './Providers/UserProvider';
import {openErrorNotification} from "Utils/NotificationUtils";
import {ReactQueryConfigProvider} from "react-query";
import _ from 'lodash';


const queryConfig = {
  queries: {
    refetchOnWindowFocus: false,
    retry: false,
    onError: (error: any) => {
      let message;
      let title = 'Der skete en fejl';

      if (error.status === 404) {
        message = 'Vi fandt ikke det du søgte efter'
      } else if (error.status === 400) {
        title = 'Der er en fejl i de indtastede data';

        const errors = _.get(error, 'data.errors');

        if (errors) {
          const keys = Object.keys(errors);

          if (keys.length > 0) {
            message = errors[keys[0]];
          }
        }

        if (!message) {
          message = _.get(error, 'data.message', error.data);
        }
      } else {
        message = error.data;
      }

      console.error(error);

      openErrorNotification(title, message)
    }
  },
  mutations: {
    throwOnError: true,
    onError: (error: any) => {
      let message;
      let title = 'Der skete en fejl';

      if (error.status === 404) {
        message = 'Vi fandt ikke det du søgte efter'
      } else if (error.status === 400) {
        title = 'Der er en fejl i de indtastede data';

        const errors = _.get(error, 'data.errors');

        if (errors) {
          const keys = Object.keys(errors);

          if (keys.length > 0) {
            message = errors[keys[0]];
          }
        }

        if (!message) {
          message = _.get(error, 'data.message', error.data);
        }

      } else {
        message = error.data;
      }

      console.error(error);

      openErrorNotification(title, message)
    }
  }
}

moment.locale("dk", {
  week: {
    dow: 1
  },
  months: ['Januar', 'Februar', 'Marts', 'April', 'Maj', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'December'],
  monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
  weekdays: ['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'],
  weekdaysShort: ['Søn', 'Man', 'Tirs', 'Ons', 'Tors', 'Fre', 'Lør'],
  weekdaysMin: ['Søn', 'Man', 'Tirs', 'Ons', 'Tors', 'Fre', 'Lør'],
});

const render = Component => {
  return ReactDOM.render(
    <React.StrictMode>
      <ReactQueryConfigProvider config={queryConfig}>
        <ConfigProvider locale={locale}>
          <BrowserRouter>
            <UserProvider>
              {process.env.REACT_APP_ASAPEN_ENV !== 'Production' && (
                <div className="test-ribbon">TEST MILJØ</div>
              )}
              <Component />
            </UserProvider>
          </BrowserRouter>
        </ConfigProvider>
      </ReactQueryConfigProvider>
    </React.StrictMode>,
    document.getElementById('root')
  )
}

render(App);

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default;
    render(NextApp);
  });
}
