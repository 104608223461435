import React from 'react';
import {Route, Switch, useRouteMatch} from "react-router-dom";
import DashboardPage from "Pages/Economy/DashboardPage";
import InvoiceOverviewPage from "Pages/Economy/InvoiceOverviewPage";
import EditInvoicePage from "Pages/Economy/EditInvoicePage";
import { useUserProvider } from 'Providers/UserProvider';
import TimeEntriesPage from "./TimeEntriesPage";

const EconomyRoutes = () => {

    const {path} = useRouteMatch();

    const { userContext } = useUserProvider();

    return (
        <Switch>

            {(userContext?.isOwner || userContext?.accessLevel === 'Admin') && (
                <Route path={`${path}`} exact>
                    <DashboardPage />
                </Route>
            )}

            {(userContext?.isOwner || userContext?.accessLevel === 'Admin') && (
                <Route path={`${path}/timeEntries`} exact>
                    <TimeEntriesPage />
                </Route>
            )}

            <Route path={`${path}/invoices`} exact>
                <InvoiceOverviewPage />
            </Route>

            <Route path={`${path}/invoices/edit/:id`}>
                <EditInvoicePage />
            </Route>
        </Switch>
    )
}

export default EconomyRoutes
