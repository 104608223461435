import React from "react";
import { Route, Switch } from "react-router-dom";
import AdminCalenderPage from "Pages/Admin/Calender/AdminCalenderPage";

const AdminCalenderRoute = () => {

    return (
        <>
            <Switch>
                <Route path={"/admin/calender"}>
                    <AdminCalenderPage/>
                </Route>
            </Switch>

        </>
    )
}

export default AdminCalenderRoute