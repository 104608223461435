import React, {useState} from 'react';
import FlexRow from "Components/FlexRow";
import StyledButton from "Components/StyledButton";
import {DatePicker, Space, Table} from "antd";
import {sumTimeEntries} from "Utils/TimeEntryUtils";
import {TimeEntryModel} from "Apis/Models/TimeEntryModel";
import moment from "moment";
import {openErrorNotification, openSuccessNotification} from "Utils/NotificationUtils";
import {getTimeEntryPdf, useDeleteTimeEntry, useSaveTimeEntry, useTimeEntries} from "Apis/TimeEntryApi";
import { OrderModel } from 'Apis/Models/OrderModel';
import Label from "Components/Label";
import {getInputClasses} from "Utils/CssUtils";
import Modal from "antd/es/modal";
import {useIsLoading} from "Hooks/UseIsLoading";
import {useUserProvider} from "../../../../Providers/UserProvider";

interface Props {
    order: OrderModel
}
const OrderTimeEntries = ({order}: Props) => {

    const { userContext } = useUserProvider();

    const [showCreateTimeEntryModal, setShowCreateTimeEntryModal] = useState<boolean>(false);
    const [selectedTimeEntry, setSelectedTimeEntry] = useState<Partial<TimeEntryModel>>();

    const {data: timeEntries} = useTimeEntries(order.id)
    const [saveTimeEntryMutation, {isLoading: isSavingTimeEntry}] = useSaveTimeEntry();
    const [deleteTimeEntryMutation, {isLoading: isDeletingTimeEntry}] = useDeleteTimeEntry();
    const [isDownloadingPdf, downloadPdf] = useIsLoading(() => getTimeEntryPdf(order?.id!));

    const createNewTimeEntry = () => {
        setShowCreateTimeEntryModal(true)

        setSelectedTimeEntry({orderId: order.id});
    }

    const selectTimeEntry = (entry: TimeEntryModel) => {
        setShowCreateTimeEntryModal(true)

        setSelectedTimeEntry(entry);
    }

    const saveTimeEntry = async (e) => {
        e.preventDefault();

        if (!selectedTimeEntry!.from || !selectedTimeEntry!.to) {
            openErrorNotification('Fejl', 'Du skal indtaste både fra og til tidspunkt');
            return;
        }

        await saveTimeEntryMutation({orderId: order.id, entry: selectedTimeEntry});

        setShowCreateTimeEntryModal(false);

        openSuccessNotification('Success', 'Timerne blev gemt')
    }

    const deleteTimeEntry = async () => {
        await deleteTimeEntryMutation({orderId: order.id, entry: selectedTimeEntry});

        setShowCreateTimeEntryModal(false)
    }

    const getTime = (time: number) => {
        return time < 10 ? '0' + time : time;
    }

    const getSecondsFromDuration = (duration: number) => {
        let unusedDuration = duration;

        const hours = Math.floor(unusedDuration / 3600);

        unusedDuration = unusedDuration % 3600;

        const minutes = Math.floor(unusedDuration / 60);

        return `${getTime(hours)}:${getTime(minutes)}`
    }

    return (
        <>
            <FlexRow justify={"end"}>
                <Space>
                    <StyledButton onClick={() => downloadPdf()} loading={isDownloadingPdf}>Download rapport</StyledButton>
                    <StyledButton color={"primary"} onClick={createNewTimeEntry}>Opret timer</StyledButton>
                </Space>
            </FlexRow>
            <Table dataSource={timeEntries}
                   pagination={false}
                   rowClassName={"clickable"}
                   onRow={(record) => {
                       return {
                           onClick: () => selectTimeEntry(record),
                       };}
                   }
                   footer={() => (
                       <div style={{display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between'}}>
                           <div>I alt</div>
                           <div>
                               <b>
                                   {getSecondsFromDuration(sumTimeEntries(timeEntries))}
                               </b>
                           </div>
                       </div>
                   )}
            >
                <Table.Column title={"Dato"} dataIndex={"from"} render={(value, record: TimeEntryModel) => moment(record.from).format('DD MMMM YYYY')} />
                <Table.Column title={"Beskrivelse"} dataIndex={"description"}  />
                <Table.Column title={"Medarbejder"} dataIndex={"userId"} render={(value) => userContext!.users.find(x => x.id === value)!.name}  />
                <Table.Column title={"Tidspunkt"} dataIndex={"from"} render={(value, record: TimeEntryModel) => (<span>{moment(record.from).format('HH:mm')} - {moment(record.to).format('HH:mm')}</span>)} />
                <Table.Column title={"Tid"} align={'right'} dataIndex={"from"} render={(value, record: TimeEntryModel) => (<b>{getSecondsFromDuration(moment(record.to).diff(moment(record.from), 'second'))}</b>)} />
            </Table>

            <Modal visible={showCreateTimeEntryModal} title={"Opret timer"}
                   onCancel={() => {
                       setShowCreateTimeEntryModal(false);
                       setSelectedTimeEntry(undefined)
                   }}
                   footer={false}
            >
                <form onSubmit={saveTimeEntry}>
                    <FlexRow direction={"column"} align={"start"}>
                        <Space direction={"vertical"} style={{width: '100%'}}>
                            <div>
                                <Label>Medarbejder</Label>
                                <select className={getInputClasses()} defaultValue={userContext?.id} value={selectedTimeEntry?.userId} onChange={e => setSelectedTimeEntry({...selectedTimeEntry, userId: parseInt(e.currentTarget.value)})} required>
                                    <option>Vælg</option>
                                    {userContext!.users!.map((user) =>
                                        <option key={user.id} value={user.id}>{user.name}</option>)}
                                </select>
                            </div>
                            <div>
                                <Label>Beskrivelse</Label>
                                <input type="text" className={getInputClasses()} value={selectedTimeEntry?.description ?? ""} name={"description"} onChange={(e => {
                                    setSelectedTimeEntry({...selectedTimeEntry, description: e.currentTarget.value})
                                })}/>
                            </div>
                            <div>
                                <Label>Fra</Label>
                                <DatePicker
                                    className={getInputClasses()}
                                    placeholder={"Fra"}
                                    showTime
                                    minuteStep={15}
                                    style={{width: '100%'}}
                                    format={"DD-MM-YYYY HH:mm"}
                                    value={selectedTimeEntry?.from ? moment(selectedTimeEntry?.from) : undefined}
                                    onChange={(e => {
                                        setSelectedTimeEntry({...selectedTimeEntry, from: e?.toDate()})
                                    })}
                                />
                            </div>
                            <div>
                                <Label>Til</Label>
                                <DatePicker
                                    className={getInputClasses()}
                                    placeholder={"Til"}
                                    showTime
                                    minuteStep={15}
                                    style={{width: '100%'}}
                                    format={"DD-MM-YYYY HH:mm"}
                                    value={selectedTimeEntry?.to ? moment(selectedTimeEntry?.to) : undefined}
                                    onChange={(e => {
                                        setSelectedTimeEntry({...selectedTimeEntry, to: e?.toDate()})
                                    })}
                                />
                            </div>

                            <FlexRow justify={"space-between"}>
                                <div>
                                    {selectedTimeEntry?.id && (
                                        <StyledButton color={'danger'} disabled={isDeletingTimeEntry}
                                                      loading={isDeletingTimeEntry}
                                                      onClick={deleteTimeEntry}>Slet</StyledButton>
                                    )}
                                </div>
                                <Space>
                                    <StyledButton
                                        onClick={() => setShowCreateTimeEntryModal(false)}>Afbryd</StyledButton>
                                    <StyledButton color={"primary"} loading={isSavingTimeEntry}
                                                  disabled={isSavingTimeEntry} type={"submit"}>Gem</StyledButton>
                                </Space>
                            </FlexRow>
                        </Space>
                    </FlexRow>
                </form>
            </Modal>
        </>
    )
}

export default OrderTimeEntries
