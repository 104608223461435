import { OrderModel } from "Apis/Models/OrderModel";
import { Controller, useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import { useCustomers } from "Apis/CustomerApi";
import { getInputClasses } from "Utils/CssUtils";
import Label from "Components/Label";
import GroupLabel from "Components/GroupLabel";
import { Col, Dropdown, Modal, Row, Select } from "antd";
import { useAddressSuggestionMenu } from "Hooks/UseAddressSuggestions";
import StyledButton from "Components/StyledButton";
import FlexRow from "Components/FlexRow";
import { useHistory } from "react-router-dom";
import { useSaveOrder } from "Apis/OrderApi";
import CustomerForm from "Pages/Projects/Customers/Forms/CustomerForm";
import {useUserProvider} from "../../../../Providers/UserProvider";

interface Props {
    order?: OrderModel
}

const OrderForm = ({ order }: Props) => {

    const history = useHistory();
    const { userContext } = useUserProvider()

    const { register, reset, errors, handleSubmit, setValue, watch, trigger, getValues, control } = useForm<OrderModel>({ mode: 'all' });

    const [showCustomerModal, setShowCustomerModal] = useState<boolean>(false);
    const [customerSearch, setCustomerSearch] = useState<string>('');

    const { data: customers } = useCustomers();
    const [saveProjectMutation, { isLoading }] = useSaveOrder();

    const [invoicingAddressSuggestions, invoicingAddressSuggestionMenu, searchForInvoicingAddress] = useAddressSuggestionMenu(async (address) => {
        setValue('invoicingAddress.street', `${address.adresse.vejnavn} ${address.adresse.husnr} ${address.adresse.etage ?? ''} ${address.adresse.dør ?? ''}`);
        setValue('invoicingAddress.city', address.adresse.postnrnavn)
        setValue('invoicingAddress.zipCode', address.adresse.postnr)
        searchForInvoicingAddress('')
    });
    const [deliveryAddressSuggestions, deliveryAddressSuggestionMenu, searchForDeliveryAddress] = useAddressSuggestionMenu(async (address) => {
        setValue('deliveryAddress.street', `${address.adresse.vejnavn} ${address.adresse.husnr} ${address.adresse.etage ?? ''} ${address.adresse.dør ?? ''}`);
        setValue('deliveryAddress.city', address.adresse.postnrnavn)
        setValue('deliveryAddress.zipCode', address.adresse.postnr)
        searchForDeliveryAddress('')
    });

    useEffect(() => {
        reset(order)
    }, [order, reset, customers])

    const onSubmitHandler = async (data: OrderModel) => {
        let newProject = await saveProjectMutation(data)

        if (newProject) {
            history.push(`/projects/orders/show/${newProject.id}`)
        }
    }

    const customerSelected = (id) => {
        setCustomerSearch('')
        const customer = customers!.find(x => x.id === parseInt(id));

        if (customer) {
            setValue('customerId', customer.id);
            setValue('invoicingAddress.customerName', customer.name);
            setValue('invoicingAddress.att', customer.att);
            setValue('invoicingAddress.street', customer.street);
            setValue('invoicingAddress.city', customer.city)
            setValue('invoicingAddress.zipCode', customer.zipCode)
            setValue('invoicingAddress.vatNumber', customer.vatNumber)
            setValue('invoicingAddress.email', customer.email)
            setValue('invoicingAddress.ean', customer.ean)
        }
    }

    const copyInvoicingAddress = () => {
        setValue('deliveryAddress.street', getValues('invoicingAddress.street'));
        setValue('deliveryAddress.city', getValues('invoicingAddress.city'))
        setValue('deliveryAddress.zipCode', getValues('invoicingAddress.zipCode'))

        trigger();
    }

    return (
        <form onSubmit={handleSubmit(onSubmitHandler)}>

            <input type="hidden" name={"id"} ref={register}/>
            <input type="hidden" name={"projectNumber"} ref={register}/>

            <GroupLabel>Stamdata</GroupLabel>
            <Row gutter={[20, 20]}>
                <Col span={24}>
                    <div>
                        <Label>Kunde</Label>
                        <Controller name={"customerId"} defaultValue={null} control={control} render={({ onChange, onBlur, value, name }) => (
                            <Select
                                className={getInputClasses(errors.customerId)}
                                bordered={false}
                                showSearch
                                value={value}
                                placeholder={'Vælg kunde'}
                                filterOption={false}
                                onSelect={customerSelected}
                                onSearch={(value) => setCustomerSearch(value.toLowerCase())}
                                dropdownRender={menu => (
                                    <div>
                                        {menu}
                                        <div onClick={() => setShowCustomerModal(true)} style={{ display: 'flex', flexWrap: 'nowrap', padding: 8, cursor: 'pointer' }}>
                                            <span>
                                                + Opret kunde
                                            </span>
                                        </div>
                                    </div>
                                )}
                            >
                                {customers?.filter(x => x.name.toLowerCase().includes(customerSearch)).map((customer) => (
                                    <Select.Option key={customer.id} value={customer.id}>{customer.name}</Select.Option>
                                ))}
                            </Select>
                        )}/>
                        <input type="hidden" ref={register} name={"invoicingAddress.customerName"}/>
                        <input type="hidden" ref={register} name={"invoicingAddress.vatNumber"}/>
                    </div>
                </Col>

                <hr/>

                <Col span={24}>
                    <GroupLabel>Fakturaadresse</GroupLabel>
                    <Row gutter={[20, 20]}>
                        <Col xs={24} md={8}>
                            <div>
                                <Label>
                                    Att
                                </Label>
                                <input className={getInputClasses(errors.invoicingAddress?.att)} name={"invoicingAddress.att"} ref={register({ required: watch('invoicingAddress.ean') })}/>
                            </div>
                        </Col>
                        <Col xs={24} md={8}>
                            <div>
                                <Label>
                                    EAN
                                </Label>
                                <input className={getInputClasses(errors.invoicingAddress?.ean)} name={"invoicingAddress.ean"} ref={register({ required: false, maxLength: 13 })}/>
                            </div>
                        </Col>
                        <Col xs={24} md={8}>
                            <div>
                                <Label>
                                    Email
                                </Label>
                                <input className={getInputClasses(errors.invoicingAddress?.ean)} name={"invoicingAddress.email"} ref={register({ required: false })}/>
                            </div>
                        </Col>

                        <Col xs={24} md={8}>
                            <div>
                                <Label>
                                    Adresse
                                </Label>
                                <Dropdown visible={invoicingAddressSuggestions.length > 0} overlay={invoicingAddressSuggestionMenu}>
                                    <input className={getInputClasses(errors.invoicingAddress?.street)} onChange={e => searchForInvoicingAddress(e.target.value)} name={"invoicingAddress.street"} ref={register({ required: true })}/>
                                </Dropdown>
                            </div>
                        </Col>
                        <Col xs={24} md={8}>
                            <div>
                                <Label>
                                    Postnummer
                                </Label>
                                <input className={getInputClasses(errors.invoicingAddress?.zipCode)} name={"invoicingAddress.zipCode"} ref={register({ required: true, minLength: 3, maxLength: 4 })}/>
                            </div>
                        </Col>
                        <Col xs={24} md={8}>
                            <div>
                                <Label>
                                    By
                                </Label>
                                <input className={getInputClasses(errors.invoicingAddress?.city)} name={"invoicingAddress.city"} ref={register({ required: true })}/>
                            </div>
                        </Col>
                    </Row>
                </Col>

                <hr/>
                <Col span={24}>
                    <GroupLabel>Installationsadresse - <span onClick={copyInvoicingAddress} style={{ fontSize: '.8em', cursor: 'pointer' }}>Kopier fakturaadresse</span></GroupLabel>
                    <Row gutter={[20, 20]}>

                        <Col xs={24} md={8}>
                            <div>
                                <Label>
                                    Adresse
                                </Label>
                                <Dropdown visible={deliveryAddressSuggestions.length > 0} overlay={deliveryAddressSuggestionMenu}>
                                    <input className={getInputClasses(errors.deliveryAddress?.street)} onChange={e => searchForDeliveryAddress(e.target.value)} name={"deliveryAddress.street"} ref={register({ required: true })}/>
                                </Dropdown>
                            </div>
                        </Col>
                        <Col xs={24} md={8}>
                            <div>
                                <Label>
                                    Postnummer
                                </Label>
                                <input className={getInputClasses(errors.deliveryAddress?.zipCode)} name={"deliveryAddress.zipCode"} ref={register({ required: true, minLength: 3, maxLength: 4 })}/>
                            </div>
                        </Col>
                        <Col xs={24} md={8}>
                            <div>
                                <Label>
                                    By
                                </Label>
                                <input className={getInputClasses(errors.deliveryAddress?.city)} name={"deliveryAddress.city"} ref={register({ required: true })}/>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <hr/>
            <GroupLabel>Ordreoplysninger</GroupLabel>

            <Row gutter={[20, 20]}>

                <Col xs={24} sm={12}>
                    <div>
                        <Label>Overskrift</Label>
                        <input className={getInputClasses(errors.title)} name={"title"} ref={register({ required: true })}/>
                    </div>
                </Col>

                <Col xs={24} sm={6}>
                    <div>
                        <Label>Ansvarlig</Label>
                        <select className={getInputClasses(errors.responsibleId)} name={'responsibleId'} ref={register({required: true})} defaultValue={userContext?.id}>
                            <option value={""}>Vælg</option>
                            {userContext!.users?.map((user) => {
                                return (
                                    <option key={user.id} value={user.id}>{user.name}</option>
                                )
                            })}
                        </select>
                    </div>
                </Col>

                <Col xs={24} sm={6}>
                    <div>
                        <Label>Rekvistionsnummer</Label>
                        <input className={getInputClasses(errors.requisitionNumber)} name={"requisitionNumber"} ref={register}/>
                    </div>
                </Col>

                <Col span={24}>
                    <div>
                        <Label>Beskrivelse</Label>
                        <textarea rows={10} className={getInputClasses(errors.description)} name={"description"} ref={register({ required: true })}/>
                    </div>
                </Col>
            </Row>

            <FlexRow justify={"end"}>
                <StyledButton type={"submit"} disabled={isLoading} loading={isLoading} color={"primary"}>Gem</StyledButton>
            </FlexRow>

            <Modal
                visible={showCustomerModal}
                onCancel={() => setShowCustomerModal(false)}
                footer={false}
                width={'70%'}
            >
                <CustomerForm onSave={() => {
                    setShowCustomerModal(false);
                }}/>

            </Modal>
        </form>
    )

}

export default OrderForm;
