export const getInputClasses = (hasError?: any) => {
    return `input full-width ${hasError ? 'error' : ''}`
};

export const getPaperInputClasses = (hasError?: any, small: boolean = false) => {
    return `paper-input full-width ${hasError ? 'error' : ''} ${small ? 'small' : ''}`
};

export const hasErrors = (errors) => {
    return Object.keys(errors).length > 0;
}
