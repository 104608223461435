import React from "react";

interface Props {
    link: string
}
const OrderImageDocumentation = (props: Props) => {

    return (
        <img height={'auto'} width={'100%'} style={{borderRadius: 10}} src={props.link} alt="Billede" />
    )
}

export default OrderImageDocumentation;
