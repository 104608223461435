import React from "react";
import {Col, Row, Space} from "antd";
import {getInputClasses} from "Utils/CssUtils";
import {UserModel} from "Apis/Models/UserModel";
import {FieldErrors} from "react-hook-form";
import FlexRow from "Components/FlexRow";
import StyledButton from "Components/StyledButton";

interface Props {
    register: any;
    errors: FieldErrors<UserModel>;
    handleSubmit: any;
    isLoading: boolean;
}
const UserForm = ({register, errors, isLoading, handleSubmit}: Props) => {

    return (
        <form onSubmit={handleSubmit}>
            <Space className={"w-100"} size={"large"} direction={"vertical"}>

                <input type="hidden" name={"id"} ref={register} />

                <Row gutter={[20, 20]}>
                    <Col xs={24}>
                        <label>E-Mail</label>
                        <input type="text" className={getInputClasses(errors.email)} name={"email"} ref={register({required: true})} />
                    </Col>
                </Row>

                <hr/>

                <Row gutter={[20, 20]}>
                    <Col xs={24} sm={12}>
                        <label>Initialer</label>
                        <input type="text" className={getInputClasses(errors.initials)} name={"initials"} ref={register({required: true})} />
                    </Col>
                    <Col xs={24} sm={12}>
                        <label>Navn</label>
                        <input type="text" className={getInputClasses(errors.name)} name={"name"} ref={register({required: true})} />
                    </Col>
                    <Col xs={24}>
                        <label>Adgangsniveau</label>
                        <select className={getInputClasses(errors.accessLevel)} name={"accessLevel"} ref={register({required: true})}>
                            <option value={''}>Vælg</option>
                            <option value={'Standard'}>Håndværker</option>
                            <option value={'Admin'}>Admin</option>
                        </select>
                    </Col>
                </Row>

                <FlexRow justify={'end'}>
                    <StyledButton loading={isLoading} color={"primary"} type={"submit"}>Gem</StyledButton>
                </FlexRow>

            </Space>
        </form>
    )

}

export default UserForm;
