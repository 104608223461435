import React from 'react';

const Link = (props) => {

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 477.389 477.389"
            {...props}
        >
            <path d="M451.209 68.647a89.462 89.462 0 00-63.312-26.226 88.987 88.987 0 00-63.266 26.197L209.056 184.194a89.615 89.615 0 00-22.869 87.518c2.559 9.072 11.988 14.352 21.06 11.793 9.072-2.559 14.352-11.988 11.793-21.06a55.481 55.481 0 0114.148-54.118L348.763 92.768c21.608-21.613 56.646-21.617 78.259-.008 21.613 21.608 21.617 56.646.009 78.259L311.456 286.594a55.16 55.16 0 01-27.682 15.002c-9.228 1.921-15.151 10.959-13.23 20.187a17.067 17.067 0 0016.762 13.588 16.88 16.88 0 003.55-.375 89.205 89.205 0 0044.732-24.269l115.576-115.558c34.95-34.926 34.97-91.571.045-126.522z" />
            <path d="M290.702 206.142c-2.559-9.072-11.988-14.352-21.06-11.793s-14.352 11.988-11.793 21.06a55.481 55.481 0 01-14.148 54.118L128.125 385.103c-21.608 21.613-56.646 21.617-78.259.008-21.613-21.608-21.617-56.646-.009-78.259l115.576-115.593a55.083 55.083 0 0127.648-15.002c9.243-1.849 15.237-10.84 13.388-20.082s-10.84-15.237-20.082-13.388a89.342 89.342 0 00-45.086 24.34L25.725 282.703c-34.676 35.211-34.242 91.865.969 126.541 34.827 34.297 90.731 34.301 125.563.008l115.575-115.593a89.612 89.612 0 0022.87-87.517z" />
        </svg>
    )
}

export default Link
