import { Col, Row } from "antd";
import FlexRow from "Components/FlexRow";
import React from "react";
import { DeliveryAddress, InvoicingAddress } from "Apis/Models/OrderModel";

interface Props {
    invoicingAddress: InvoicingAddress
    deliveryAddress?: DeliveryAddress
}
const DocumentHeader = (props: Props) => {
    
    return (
        <>
            <FlexRow justify={"space-between"}>
            
                <div style={{width: '40%', fontSize: '.9em'}}>
                    <Row gutter={[5, 5]}>
                        <Col span={24}>
                            <b>
                                {props?.invoicingAddress.customerName}
                            </b>
                        </Col>
                    </Row>
                
                    <span>{props?.invoicingAddress.street}</span>
                
                    <Row gutter={5}>
                        <Col span={24}>
                            <span>{props?.invoicingAddress.zipCode}</span> <span>{props?.invoicingAddress.city}</span>
                        </Col>
                        <Col span={24}>
                            <span>CVR: {props?.invoicingAddress.vatNumber}</span>
                        </Col>
                        <Col span={24}>
                            <span>ATT: {props?.invoicingAddress.att}</span>
                        </Col>
                        <Col span={24}>
                            <span>Email: {props?.invoicingAddress.email}</span>
                        </Col>
                        <Col span={24}>
                            <span>EAN: {props?.invoicingAddress.ean}</span>
                        </Col>
                    </Row>
                </div>
            
                <div>
                    <img src="/images/Logo.png" width={250} alt="ELi A/S Badge"/>
                </div>
            </FlexRow>
    
            {props.deliveryAddress && (
                <div style={{width: '40%', fontSize: '.9em', padding: '10px 0'}}>
                    <FlexRow justify={"space-between"}>
                        <b>Installationsadresse</b>
                    </FlexRow>
                    <span>{props?.deliveryAddress.street}</span>
                    
                    <Row gutter={5}>
                        <Col span={24}>
                            <span>{props?.deliveryAddress.zipCode}</span> <span>{props?.deliveryAddress.city}</span>
                        </Col>
                    </Row>
                </div>
            )}
        </>
    )
}

export default DocumentHeader
