import React from 'react';
import {Route, Switch, useRouteMatch} from "react-router-dom";
import NotificationsPage from "Pages/Notifications/NotificationsPage";

const NotificationsRoutes = () => {

    let {path} = useRouteMatch();

    return (
        <Switch>
            <Route path={`${path}`} exact>
                <NotificationsPage />
            </Route>
        </Switch>
    )
}

export default NotificationsRoutes
