import { queryCache, useMutation } from "react-query";
import { UploadFileModel } from "Apis/Admin/Documents/Models/UploadFileModel";
import { request } from "Apis/BaseApi";
import { CreateFolderModel } from "Apis/Admin/Documents/Models/CreateFolderModel";
import { DOCUMENT_CACHE_KEY } from "Apis/Documents/DocumentsApi";

export const useWriteFile = () => {
    return useMutation(async (model: UploadFileModel) => {
        if (model.id) {
            return await request({
                url: `/admin/documents/file/${model.id}`,
                method: 'PUT',
                data: model
            })
        } else {
            return await request({
                url: `/admin/documents/file`,
                method: 'POST',
                data: model
            })
        }
    }, {
        onSuccess: () => {
            queryCache.invalidateQueries(DOCUMENT_CACHE_KEY)
        }
    })
}

export const useWriteFolder = () => {
    return useMutation(async (model: CreateFolderModel) => {

        if (model.id) {
            return await request({
                url: `/admin/documents/folder/${model.id}`,
                method: 'PUT',
                data: model
            })
        } else {
            return await request({
                url: `/admin/documents/folder`,
                method: 'POST',
                data: model
            })
        }

    }, {
        onSuccess: () => {
            queryCache.invalidateQueries(DOCUMENT_CACHE_KEY)
        }
    })

}
export const useDeleteFile = () => {
    return useMutation(async (id: number) => {
        return await request({
            url: `/admin/documents/file/${id}`,
            method: 'DELETE'
        })
    }, {
        onSuccess: () => {
            queryCache.invalidateQueries(DOCUMENT_CACHE_KEY)
        }
    })
}

export const useDeleteFolder = () => {
    return useMutation(async (id: number) => {
        return await request({
            url: `/admin/documents/folder/${id}`,
            method: 'DELETE'
        })
    }, {
        onSuccess: () => {
            queryCache.invalidateQueries(DOCUMENT_CACHE_KEY)
        }
    })
}