import {queryCache, useMutation, useQuery} from "react-query";
import {request} from "Apis/BaseApi";
import OrganizationModel from "Apis/Models/OrganizationModel";
import {organizationCacheKey, organizationsCacheKey} from "./OrganizationAdminApi";
import {userContextCacheKey} from "./UserApi";

export const allowedOrganizationCacheKey = 'allowedOrganizations';
export const useAllowedOrganization = () => {
    return useQuery<OrganizationModel[]>(allowedOrganizationCacheKey, async () => {
        return await request<OrganizationModel[]>({
            url: `/organizations/allowed`,
            method: 'GET'
        })
    })
}

export const useSaveAgreementGrantToken = () => {
    return useMutation(async (agreementGrantToken: string) => {
        return await request<OrganizationModel>({
            url: `/organizations`,
            method: 'PUT',
            data: {
                agreementGrantToken: agreementGrantToken
            }
        })
    }, {
        onSuccess: (organization: OrganizationModel) => {
            queryCache.invalidateQueries(userContextCacheKey)
            queryCache.invalidateQueries(organizationsCacheKey)
            queryCache.invalidateQueries([organizationCacheKey, organization.id])
            queryCache.invalidateQueries(allowedOrganizationCacheKey)
        },
    })
}