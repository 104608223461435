import React from "react"
import styles from "./QaField.module.scss";

interface Props {
    text?: string;
}
const QaHeadlineTemplate = ({text}: Props) => {
    
    return (
        <div className={`${styles.answer} ${styles.answerHeadline}`}>
            <b>{text}</b>
        </div>
    )
}

export default QaHeadlineTemplate
