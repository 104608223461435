import {useHistory, useParams} from "react-router-dom";
import {useForm} from "react-hook-form";
import {UserModel} from "Apis/Models/UserModel";
import {useDeleteUser, useUpdateUser, useUser} from "Apis/UserAdminApi";
import {hasErrors} from "Utils/CssUtils";
import DefaultPageLayout from "Pages/Layouts/DefaultPageLayout";
import User from "Assets/Icons/User";
import UserForm from "Pages/Admin/Users/Forms/UserForm";
import React, {useEffect} from "react";
import FlexRow from "Components/FlexRow";
import StyledButton from "Components/StyledButton";
import {openSuccessNotification} from "Utils/NotificationUtils";

const EditUserPage = () => {

    const { id } = useParams<{ id }>();
    const history = useHistory();

    const {register, handleSubmit, trigger, errors, reset} = useForm<UserModel>();

    const {data: user, isLoading} = useUser(id);

    const [updateUserMutation, {isLoading: isSaving}] = useUpdateUser();
    const [deleteUserMutation, {isLoading: isDeletingUser}] = useDeleteUser();

    useEffect(() => {
        reset(user);
    },[reset, user])

    const deleteUser = async () => {
        await deleteUserMutation(user);

        openSuccessNotification('Success', `Brugeren blev slettet`)

        history.goBack();
    }

    const onSubmitHandler = async (user: UserModel) => {
        await trigger();

        if (!hasErrors(errors)) {
            await updateUserMutation(user)

            history.push('/admin/users');
        }
    }

    return (
        <DefaultPageLayout title={"Opdater bruger"} icon={<User />} isLoading={isLoading}
         fab={<FlexRow justify={"end"}><StyledButton loading={isDeletingUser} onClick={deleteUser} color={"danger"}>Slet bruger</StyledButton></FlexRow>}>
            <UserForm
                register={register}
                errors={errors}
                handleSubmit={handleSubmit(onSubmitHandler)}
                isLoading={isSaving}
            />
        </DefaultPageLayout>
    )
}

export default EditUserPage;
