import {queryCache, useMutation, useQuery} from "react-query";
import {request} from "Apis/BaseApi";
import {ReminderModel} from "Apis/Admin/Reminders/Models/ReminderModel";

export const REMINDERS_READY_FOR_APPROVAL_CACHE_KEY = 'REMINDERS_READY_FOR_APPROVAL_CACHE_KEY';
export const useRemindersReadyForApproval = () => {
    return useQuery<ReminderModel[]>(REMINDERS_READY_FOR_APPROVAL_CACHE_KEY, async () => {
        return await request<ReminderModel[]>({
            url: `/admin/reminders/readyForApproval`,
            method: 'GET'
        })
    })
}

export const REMINDERS_ADMIN_CACHE_KEY = 'REMINDERS_ADMIN_CACHE_KEY';
export const useReminder = (id: number) => {
    return useQuery<ReminderModel>([REMINDERS_ADMIN_CACHE_KEY, id], async () => {
        return await request<ReminderModel>({
            url: `/admin/reminders/${id}`,
            method: 'GET'
        })
    })
}

export const useApproveReminder = () => {
    return useMutation((id: number) => {
        return request<ReminderModel>({
            url: `/admin/reminders/approve/${id}`,
            method: 'POST'
        })
    }, {
        onSuccess: (invoice: ReminderModel) => {
            queryCache.invalidateQueries(REMINDERS_READY_FOR_APPROVAL_CACHE_KEY)
            queryCache.invalidateQueries(REMINDERS_ADMIN_CACHE_KEY)
        }
    })
}

export const usePostponeReminder = () => {
    return useMutation((id: number) => {
        return request<ReminderModel>({
            url: `/admin/reminders/postpone/${id}`,
            method: 'PUT'
        })
    }, {
        onSuccess: (invoice: ReminderModel) => {
            queryCache.invalidateQueries(REMINDERS_READY_FOR_APPROVAL_CACHE_KEY)
            queryCache.invalidateQueries(REMINDERS_ADMIN_CACHE_KEY)
        }
    })
}


export const useDeleteReminder = () => {
    return useMutation((id: number) => {
        return request<ReminderModel>({
            url: `/admin/reminders/${id}`,
            method: 'DELETE'
        })
    }, {
        onSuccess: (invoice: ReminderModel) => {
            queryCache.invalidateQueries(REMINDERS_READY_FOR_APPROVAL_CACHE_KEY)
            queryCache.invalidateQueries(REMINDERS_ADMIN_CACHE_KEY)
        }
    })
}

