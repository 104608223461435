import React, { FC } from "react";
import styles from './DocumentContainer.module.scss';

interface Props {
    before?: any;
}
const DocumentContainer: FC<Props> = ({children, before}) => {
    
    return (
        <div className={styles.container}>
            {before}
            {children}
        </div>
    )
}

export default DocumentContainer
