import React from 'react';
import {OrderModel} from "Apis/Models/OrderModel";
import {QASchemeModel} from "Apis/Models/QASchemeModel";
import {Table} from "antd";
import moment from "moment";
import {openErrorNotification, openSuccessNotification} from "Utils/NotificationUtils";
import {getQaSchemePdf, useQASchemesForOrder} from "Apis/QASchemeApi";
import {useAllQaSchemeDefinitions} from "Apis/QaSchemeDefinitionApi";

interface Props {
    order: OrderModel
}
const OrderQa = ({order}: Props) => {

    const {data: qaDefinitions, isLoading: isLoadingQaDefinitions} = useAllQaSchemeDefinitions();
    const {data: qa, isLoading: isLoadingQa} = useQASchemesForOrder(order.id);

    const downloadQaPdf = async (schemeModel: QASchemeModel) => {
        openSuccessNotification('Henter KLS skema', 'Det tager et øjeblik')

        try {
            await getQaSchemePdf(schemeModel.id);
        }
        catch (e) {
            openErrorNotification('Der skete en fejl under download af KLS skema', '')
        }
    }

    const renderFilledQuestions = (schemeModel: QASchemeModel) => {
        if (!qaDefinitions) {
            return <></>
        }

        const schemeDefinition = qaDefinitions.find(x => x.id === schemeModel.qaSchemeDefinitionId)!;

        const numberOfQuestions = schemeDefinition.schemeQuestions.filter(question => {
            return question.fields.some(field => field.type !== 'Headline')
        }).length

        const numberOfUnansweredQuestions = schemeDefinition.schemeQuestions.filter(question => {
            return question.fields.some(field => field.type !== 'Headline') && schemeModel.answers.find(x => x.order === question.order)!.value === '';
        }).length

        return `${numberOfQuestions - numberOfUnansweredQuestions} / ${numberOfQuestions}`;
    }

    return (
        <>
            <Table dataSource={qa} pagination={false} rowKey={(record: QASchemeModel) => record.id.toString()} loading={isLoadingQaDefinitions || isLoadingQa}
                   rowClassName={"clickable"}
                   onRow={(record) => {
                       return {
                           onClick: () => downloadQaPdf(record),
                       };}
                   }>
                <Table.Column title={"Type"} dataIndex={"type"} render={(value, record: QASchemeModel) => qaDefinitions?.find(x => x.id === record.qaSchemeDefinitionId)?.schemeName} />
                <Table.Column title={"Spørgsmål"} dataIndex={"type"} render={(value, record: QASchemeModel) => renderFilledQuestions(record)} />
                <Table.Column title={"Afsluttet"} dataIndex={"finishedAt"} render={(value, record: QASchemeModel) => value ? moment(value).format('DD MMMM YYYY') : 'Ikke afsluttet'} />
            </Table>
        </>
    )
}

export default OrderQa
