import {useQuery} from "react-query";
import {request} from "Apis/BaseApi";
import {OrderImageDocumentation} from "Apis/Models/OrderImageDocumentation";

export const orderImageDocumentationCacheKey = 'orderImageDocumentation';
export const useOrderImageDocumentations = (orderId: number | undefined, enabled: boolean = true) => {
    return useQuery<OrderImageDocumentation[]>([orderImageDocumentationCacheKey, orderId], async () => {
        return await request<OrderImageDocumentation[]>({
            url: `/orders/${orderId}/documentation`,
            method: 'GET'
        })
    }, {
        enabled
    })
}

export const orderImageDocumentationFileCacheKey = 'orderImageDocumentationImage'
export const useOrderImageDocumentationFile = (orderId: number, documentationId: number, enabled) => {
    return useQuery<any>([orderImageDocumentationFileCacheKey, documentationId], async () => {
        return await request<any>({
            url: `/orders/${orderId}/documentation/${documentationId}`,
            method: 'GET',
            responseType: 'arraybuffer',
        })
    }, {
        enabled,
        refetchOnWindowFocus: false,
        staleTime: Infinity,
        cacheTime: 3_600_000 // 1 Hour 60 * 60 * 1000
    })
}
