import React, { useEffect, useState } from 'react';
import FlexRow from "Components/FlexRow";
import { Col, DatePicker, Modal, Row, Space, Table } from "antd";
import styles from "Pages/Projects/Orders/ShowOrderPage.module.scss";
import StyledButton from "Components/StyledButton";
import Graph from "Assets/Icons/Graph";
import { getNumberAsCurrency } from "Utils/NumberUtils";
import { EnrichedOrderEconomyMonthModel, OrderModel } from "Apis/Models/OrderModel";
import { BarChart, CartesianGrid, YAxis, XAxis, Bar, ResponsiveContainer, Tooltip } from 'recharts';
import { useOrderEconomy } from "Apis/OrderApi";
import { useHistory } from "react-router-dom";
import { useOrderAccrual, useSaveOrderAccrual } from "Apis/OrderAccrualApi";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { OrderAccrualModel } from "Apis/Models/OrderAccrualModel";
import { getInputClasses } from "Utils/CssUtils";
import Label from "Components/Label";
import moment from "moment";
import _ from 'lodash';
import { openErrorNotification, openSuccessNotification } from "Utils/NotificationUtils";
import { useUserProvider } from "Providers/UserProvider";

interface Props {
    order: OrderModel
}

const OrderDetails = ({ order }: Props) => {
    
    const history = useHistory();
    const { userContext } = useUserProvider();
    
    const [ showAccrualModal, setShowAccrualModal ] = useState<boolean>(false);
    const [ remainingHours, setRemainingHours ] = useState<number>(0);
    
    const { data: orderEconomy } = useOrderEconomy(order.id);
    const { data: orderAccrual } = useOrderAccrual(order.id);
    const [ saveAccrualMutation, { isLoading: isSavingAccrual } ] = useSaveOrderAccrual();
    
    const { register, control, handleSubmit, errors, getValues, setValue, trigger, reset } = useForm<OrderAccrualModel>();
    const { fields, append } = useFieldArray({
        keyName: 'idKey',
        control,
        name: "accrualMonths",
    });
    
    const [enrichedEconomyMonths, setEnrichedEconomyMonths] = useState<EnrichedOrderEconomyMonthModel[]>([]);
    const [selectedData, setSelectedData] = useState<EnrichedOrderEconomyMonthModel>();
    
    useEffect(() => {
        if (orderEconomy) {
            const enriched = orderEconomy.months.map((x) => {
                return {
                    ...x,
                    monthString: moment(x.month, 'M').format('MMMM'),
                }
            })
            
            setEnrichedEconomyMonths(enriched);
            setSelectedData(enriched[enriched.length - 1])
        }
    }, [orderEconomy])
    
    const mouseOver = (data) => {
        setSelectedData(data)
    }
    
    useEffect(() => {
        if (orderAccrual) {
            reset(orderAccrual)
        }
        
        /* This will be enabled when it functionality has been approved by ELi A/S
        if (orderAccrual && (!orderAccrual.expectedStartDate || !orderAccrual.expectedEndDate)) {
            setShowAccrualModal(true);
        }
         */
    }, [ orderAccrual, reset ])
    
    const calculateAccrualMonths = async() => {
        const startDate = getValues('expectedStartDate');
        const endDate = getValues('expectedEndDate');
        
        if (!!startDate && !!endDate) {
            await trigger();
            
            setValue('accrualMonths', [])
            
            const momentStartDate = moment(startDate);
            const momentEndDate = moment(endDate);
            
            const noOfMonths = momentEndDate.diff(momentStartDate, 'months');
            
            if (noOfMonths === 0) {
                appendMonth(momentStartDate, getValues('expectedWorkHours'));
            } else {
                appendMonth(momentStartDate, 0);
            }
            
            for (let i = 0; i < noOfMonths; i++) {
                appendMonth(momentStartDate.add(1, 'month'), 0);
            }
            
            await calculateRemainingHours();
        }
    }
    
    const appendMonth = (date, hours: number) => {
        let month = date.startOf('month').format('YYYY-MM-DD');
        let id: number | null = null;
        
        const existingMonth = orderAccrual?.accrualMonths?.find(x => moment(x.month).format('YYYY-MM-DD') === month)
        
        if (existingMonth) {
            id = existingMonth.id
        }
        
        append({
            id: id,
            month: date.startOf('month').format('YYYY-MM-DD'),
            expectedWorkHours: hours
        })
    }
    
    const calculateRemainingHours = async() => {
        await trigger();
        
        const totalHours = getValues('expectedWorkHours');
        const totalFilledHours = getValues().accrualMonths?.map(x => Number(x.expectedWorkHours)).reduce((a, b) => a + b);
        
        setRemainingHours(totalHours - totalFilledHours);
    }
    
    const saveAccrual = async(data: OrderAccrualModel) => {
        if (Number(data.expectedWorkHours) !== data.accrualMonths.map(x => Number(x.expectedWorkHours)).reduce((a, b) => a + b)) {
            openErrorNotification('Fejl', 'Det forventede antal arbejdstimer passer ikke med time fordelingen pr måned')
            return;
        }
        
        data = {
            ...data,
            orderId: order.id
        }
        
        await saveAccrualMutation(data);
        
        openSuccessNotification('Success', 'Periodisering blev gemt');
        
        closeAccrualModel(true);
    }
    
    const closeAccrualModel = (force: boolean = false) => {
        if (force || userContext?.accessLevel === 'Admin') {
            setShowAccrualModal(false);
            return;
        }
        
        if (!orderAccrual?.expectedStartDate || !orderAccrual.expectedEndDate) {
            openErrorNotification('Du skal udfylde periodiseringen', 'Du kan ikke fortsætte med din ordre før at du har udfyldt ordrens periodisering')
            return;
        }
        
        setShowAccrualModal(false);
    }
    
    return (
        <>
            <Space direction={"vertical"} className={"w-100"} size={"large"}>
                <FlexRow justify={"space-between"} align={"start"}>
                    <Space direction={"vertical"}>
                        <div className={styles.title}>
                            {order?.invoicingAddress.customerName}
                        </div>
                        
                        <div>
                            {order?.invoicingAddress?.street} <br/>
                            {order?.invoicingAddress.zipCode} {order?.invoicingAddress?.city} <br/>
                            {order?.invoicingAddress?.vatNumber ? `Cvr-nr: ${order?.invoicingAddress.vatNumber}` : ''}
                        </div>
                        
                        <div>
                            <span className={styles.title}>
                                Installationsadresse: <br/>
                            </span>
                            {order?.deliveryAddress?.street} <br/>
                            {order?.deliveryAddress.zipCode} {order?.deliveryAddress?.city} <br/>
                        </div>
                        
                        <div className={styles.projectTitle}>
                            Sagsbeskrivelse: {order?.title}
                        </div>
                        <div className={"display-linebreak"}>
                            {order?.description}
                        </div>
                    </Space>
                    <StyledButton onClick={() => history.push(`/projects/orders/edit/${order.id}`)}>Opdater sag</StyledButton>
                </FlexRow>
                
                <div>
                    <hr/>
                    <FlexRow justify={"space-between"}>
                        <h2>Økonomi</h2>
                        <div>
                            <StyledButton onClick={() => setShowAccrualModal(true)}>Opdater periodisering</StyledButton>
                        </div>
                    </FlexRow>
                </div>
                
                <Table dataSource={orderEconomy?.orderEconomyItems} pagination={false} rowKey={"type"}>
                    <Table.Column title={"Dato"} dataIndex={"date"} render={text => moment(text).format('DD/MM/YYYY')} />
                    <Table.Column title={"Type"} dataIndex={"type"}  />
                    <Table.Column title={"Beløb"} dataIndex={"amount"} render={text => getNumberAsCurrency(text)} />
                    <Table.Column title={"Restbeløb"} dataIndex={"total"} render={text => getNumberAsCurrency(text)}  />
                </Table>
    
                <hr/>
                
                <ResponsiveContainer minHeight={400} maxHeight={400}>
                    <BarChart data={enrichedEconomyMonths} margin={{ top: 20, right: 30, left: 0, bottom: 0 }}>
                        <CartesianGrid strokeWidth="3 3" stroke={"#F0F0F0"} />
                        <XAxis axisLine={false} stroke={'#9F9F9F'} tickSize={0} dataKey="monthString"/>
                        <YAxis axisLine={false} stroke={'#9F9F9F'} tickSize={0} tickCount={4}  />
                        <Tooltip cursor={false}  />
                        
                        <Bar name={"Forventet omsætning"} dataKey="estimatedRevenue" fill="#9F9F9F" onMouseEnter={mouseOver} />
                        <Bar name={"Realiseret Omsætning"} dataKey="revenue" fill="#F39324" onMouseEnter={mouseOver} />
                        <Bar name={"Omkostninger"} dataKey="expenses" fill="#9F9F9F" onMouseEnter={mouseOver} />
                        <Bar name={"Dækningsbidrag"} dataKey="profit" fill="#4B69A5" onMouseEnter={mouseOver} />
                        
                    </BarChart>
                </ResponsiveContainer>
                
                <Row>
                    <Col flex={"auto"} style={{
                        padding: 20,
                        borderWidth: 0,
                        borderRightWidth: 1,
                        borderTopWidth: 1,
                        borderStyle: 'solid',
                        borderColor: '#DBDBDB'
                    }}>
                        <FlexRow direction={"column"} align={"start"} justify={"center"}>
                            <Graph fill={'#9F9F9F'}/>
                            <span style={{ color: '#9F9F9F', whiteSpace: 'nowrap' }}>Forventet omsætning - {selectedData?.monthString}</span>
                            <span style={{
                                fontWeight: 'bold',
                                fontSize: 20,
                                color: '#9F9F9F'
                            }}>{getNumberAsCurrency(selectedData?.estimatedRevenue)} DKK</span>
                        </FlexRow>
                    </Col>
                    
                    <Col flex={"auto"} style={{
                        padding: 20,
                        borderWidth: 0,
                        borderRightWidth: 1,
                        borderTopWidth: 1,
                        borderStyle: 'solid',
                        borderColor: '#DBDBDB'
                    }}>
                        <FlexRow direction={"column"} align={"start"} justify={"center"}>
                            <Graph fill={'#9F9F9F'}/>
                            <span style={{ color: '#9F9F9F', whiteSpace: 'nowrap' }}>Realiseret Omsætning - {selectedData?.monthString}</span>
                            <span style={{
                                fontWeight: 'bold',
                                fontSize: 20,
                                color: '#F39324'
                            }}>{getNumberAsCurrency(selectedData?.revenue)} DKK</span>
                        </FlexRow>
                    </Col>
    
                    <Col flex={"auto"} style={{
                        padding: 20,
                        borderWidth: 0,
                        borderRightWidth: 1,
                        borderTopWidth: 1,
                        borderStyle: 'solid',
                        borderColor: '#DBDBDB'
                    }}>
                        <FlexRow direction={"column"} align={"start"} justify={"center"}>
                            <Graph fill={'#9F9F9F'}/>
                            <span style={{ color: '#9F9F9F', whiteSpace: 'nowrap' }}>Materialeforbrug - {selectedData?.monthString}</span>
                            <span style={{
                                fontWeight: 'bold',
                                fontSize: 20,
                                color: '#9F9F9F'
                            }}>{getNumberAsCurrency(selectedData?.expenses)} DKK</span>
                        </FlexRow>
                    </Col>
    
                    <Col flex={"auto"} style={{
                        padding: 20,
                        borderWidth: 0,
                        borderTopWidth: 1,
                        borderStyle: 'solid',
                        borderColor: '#DBDBDB'
                    }}>
                        <FlexRow direction={"column"} align={"start"} justify={"center"}>
                            <Graph fill={'#9F9F9F'}/>
                            <span style={{ color: '#9F9F9F', whiteSpace: 'nowrap' }}>Dækningsbidrag - {selectedData?.monthString}</span>
                            <span style={{
                                fontWeight: 'bold',
                                fontSize: 20,
                                color: '#4B69A5'
                            }}>{getNumberAsCurrency(selectedData?.profit)} DKK</span>
                        </FlexRow>
                    </Col>
                </Row>
            </Space>
            
            <Modal
                title={"Periodisering"}
                visible={showAccrualModal}
                onCancel={() => closeAccrualModel(false)}
                footer={false}
            >
                <form onSubmit={handleSubmit(saveAccrual)}>
                    
                    <input type="hidden" defaultValue={orderAccrual?.id} value={orderAccrual?.id} name={`id`} ref={register}/>
                    
                    <Space direction={"vertical"} className={"w-100"}>
                        <div>
                            <Label>Forventet omsætning</Label>
                            <input type="number" className={getInputClasses(errors.expectedRevenue)} name={"expectedRevenue"} ref={register({ required: true })}/>
                        </div>
                        
                        <div>
                            <Label>Forventet antal arbejdstimer</Label>
                            <input type="number" className={getInputClasses(errors.expectedWorkHours)} name={"expectedWorkHours"} ref={register({ required: true })}/>
                        </div>
                        
                        <div>
                            <Label>Forventet start dato</Label>
                            <Controller name={"expectedStartDate"} control={control} defaultValue={null}
                                        rules={{ required: true }}
                                        render={props => (
                                            <DatePicker
                                                format={"DD-MM-YYYY"}
                                                className={getInputClasses(errors.expectedStartDate)}
                                                value={props.value ? moment(props.value) : null}
                                                onChange={(e => {
                                                    props.onChange(e);
                                                    calculateAccrualMonths();
                                                })}
                                            />
                                        )}
                            />
                        </div>
                        
                        <div>
                            <Label>Forventet slut dato</Label>
                            <Controller name={"expectedEndDate"} control={control} defaultValue={null}
                                        rules={{ required: true }}
                                        render={props => (
                                            <DatePicker
                                                format={"DD-MM-YYYY"}
                                                className={getInputClasses(errors.expectedEndDate)}
                                                value={props.value ? moment(props.value) : null}
                                                onChange={(e => {
                                                    props.onChange(e);
                                                    calculateAccrualMonths();
                                                })}
                                            />
                                        )}
                            />
                        </div>
                        
                        {fields.map(({ idKey, id, month, expectedWorkHours }, index) => (
                            <div key={idKey}>
                                <Label>
                                    Forventet arbejdstimer i {moment(month).format('LLLL')}
                                </Label>
                                <input type="hidden" defaultValue={id} value={id} name={`accrualMonths[${index}].id`} ref={register}/>
                                <input type="hidden" defaultValue={month} value={month} name={`accrualMonths[${index}].month`} ref={register}/>
                                <input type="number" className={getInputClasses(_.get(errors, `accrualMonths[${index}].expectedWorkHours`))} defaultValue={expectedWorkHours} ref={register} name={`accrualMonths[${index}].expectedWorkHours`} onChange={calculateRemainingHours}/>
                            </div>
                        ))}
                        
                        {!!remainingHours && (
                            <FlexRow justify={"space-between"}>
                                <div>
                                    Manglende timer
                                </div>
                                <Label>{remainingHours}</Label>
                            </FlexRow>
                        )}
                        
                        <FlexRow justify={"end"}>
                            <StyledButton color={"primary"} type={"submit"} loading={isSavingAccrual} disabled={isSavingAccrual}>Gem</StyledButton>
                        </FlexRow>
                    </Space>
                </form>
            </Modal>
        </>
    )
}

export default OrderDetails
