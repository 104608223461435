import {queryCache, useMutation, useQuery} from "react-query";
import {QASchemeModel} from "Apis/Models/QASchemeModel";
import {request} from "Apis/BaseApi";
import { saveAs } from 'file-saver';

import {QaSchemeDefinitionModel} from "Apis/Models/QaSchemeDefinitionModel";

export const useQASchemeDefinitions = () => {
    return useQuery([QASchemeCacheKey], async () => {
        return request<QaSchemeDefinitionModel[]>({
            url: `/qascheme`,
            method: 'GET'
        })
    })
}

export const QASchemeCacheKey = 'QAScheme';
export const useQAScheme = (id: number, enabled: boolean) => {
    return useQuery([QASchemeCacheKey, id], async () => {
        return request<QASchemeModel>({
            url: `/qascheme/${id}`,
            method: 'GET'
        })
    }, {
        enabled
    })
}

export const getQaSchemePdf = async (id: number): Promise<any> => {
    const responseBlob = await request<Blob>({
        responseType: 'blob',
        url: `/qascheme/${id}/pdf`,
        method: 'GET',
    });

    const blob = new Blob([responseBlob], {type: 'application/pdf' });
    const fileName = `KLS.pdf`;
    saveAs(blob, fileName);
}

export const QASchemeByOrderCacheKey = 'QASchemeByOrder';
export const useQASchemesForOrder = (orderId: number | undefined, enabled: boolean = true) => {
    return useQuery([QASchemeByOrderCacheKey, orderId], async () => {
        return request<QASchemeModel[]>({
            url: `/qascheme/order/${orderId}`,
            method: 'GET'
        })
    }, {
        enabled
    })
}

export const useSaveQAScheme = () => {
    return useMutation(async (scheme: QASchemeModel) => {
        if (scheme.id) {
            return await request<QASchemeModel>({
                url: `/qascheme/${scheme.id}`,
                method: 'PUT',
                data: scheme
            })
        } else {
            return await request<QASchemeModel>({
                url: `/qascheme`,
                method: 'POST',
                data: scheme
            })
        }
    }, {
        onSuccess: data => {
            queryCache.invalidateQueries(QASchemeCacheKey)
            queryCache.invalidateQueries(QASchemeByOrderCacheKey)
        }
    })
}

export const useFinishQAScheme = () => {
    return useMutation(async (scheme: QASchemeModel) => {
        return await request<QASchemeModel>({
            url: `/qascheme/${scheme.id}/finish`,
            method: 'POST'
        })
    }, {
        onSuccess: data => {
            queryCache.invalidateQueries(QASchemeCacheKey)
            queryCache.invalidateQueries(QASchemeByOrderCacheKey)
        }
    })
}
