import React, {useEffect, useState} from 'react';
import DefaultPageLayout from "Pages/Layouts/DefaultPageLayout";
import ShoppingList from "Assets/Icons/ShoppingList";
import PaperLayout from "Components/PaperLayout";
import Ribbon from "Components/Ribbon";
import moment from 'moment';
import FlexRow from "Components/FlexRow";
import {Col, DatePicker, Row, Space} from "antd";
import styles from "./EditInvoicePage.module.scss";
import {InvoiceModel} from "Apis/Models/InvoiceModel";
import StyledButton from "Components/StyledButton";
import {Controller, useFieldArray, useForm} from "react-hook-form";
import FormInvoiceTotal from "Pages/Economy/Components/FormInvoiceTotal";
import {getInputClasses, getPaperInputClasses} from "Utils/CssUtils";
import {useDefaultInvoiceText} from "Apis/TextApi";
import {useUserProvider} from "Providers/UserProvider";
import {useHistory, useParams} from "react-router-dom";
import {
    useCreateCreditNoteFromInvoice,
    useDeleteInvoice,
    useInvoice,
    useInvoiceReadyForApproval, useRegisterPayment, useResendInvoice,
    useSaveInvoice
} from "Apis/InvoiceApi";
import {openSuccessNotification, openWarningNotification} from "Utils/NotificationUtils";
import {getInvoiceTypeAsString, getInvoiceStateAsString, getInvoiceStateColor} from "Utils/InvoiceUtils";
import InvoiceDetails from "Pages/Economy/Components/InvoiceDetails";
import Notice from "Components/Notice";
import EditInvoiceLine from "Pages/Economy/Components/EditInvoiceLine";
import Modal from "antd/es/modal";
import _ from 'lodash';
import {CloseCircleFilled} from "@ant-design/icons";
import {useOrderFiles} from "../../Apis/OrderFileApi";
import {useOrderImageDocumentations} from "../../Apis/OrderImageDocumentationApi";
import Report from 'Assets/Icons/Report';
import {useNotesForOrder} from "../../Apis/NoteApi";
import {OrderImageDocumentation} from "../../Apis/Models/OrderImageDocumentation";
import {OrderFileModel} from "../../Apis/Models/OrderFileModel";
import {NoteModel} from "../../Apis/Models/NoteModel";
import OrderDocument from "../../Components/Document/OrderDocument";
import TimeEntryDocument from "../../Components/Document/TimeEntryDocument";
import NoteDocument from "../../Components/Document/NoteDocument";
import QADocument from "../../Components/Document/QADocument";
import {useOrder} from "../../Apis/OrderApi";
import {useTimeEntries} from "../../Apis/TimeEntryApi";
import DocumentContainer from 'Components/Document/DocumentContainer';
import {useQASchemeDefinitions, useQASchemesForOrder} from "../../Apis/QASchemeApi";

const EditInvoicePage = () => {

    const { id } = useParams<{ id }>();
    const user = useUserProvider();
    const history = useHistory();

    const [resendModalOpen, setResendModalOpen] = useState<boolean>(false);
    const [registerPaymentModalOpen, setRegisterModalOpen] = useState<boolean>(false);
    const [addFilesModalOpen, setAddFilesModalOpen] = useState<boolean>(false);

    const [selectedImages, setSelectedImages] = useState<OrderImageDocumentation[]>([])
    const [selectedFiles, setSelectedFiles] = useState<OrderFileModel[]>([])
    const [selectedNotes, setSelectedNotes] = useState<NoteModel[]>([])

    const {data: defaultInvoiceText, isLoading: isLoadingDefaultText } = useDefaultInvoiceText(user.userContext?.lastOrganizationId!, true)
    const {data: invoiceModel, isLoading: isLoadingInvoice } = useInvoice(id)
    const {data: order} = useOrder(invoiceModel?.orderId, !!invoiceModel);
    const {data: timeEntries} = useTimeEntries(invoiceModel?.orderId, !!invoiceModel);
    const {data: orderFiles} = useOrderFiles(invoiceModel?.orderId, !!invoiceModel);
    const {data: orderImages} = useOrderImageDocumentations(invoiceModel?.orderId, !!invoiceModel);
    const {data: orderNotes} = useNotesForOrder(invoiceModel?.orderId, !!invoiceModel);
    const { data: qaSchemesDefinitions } = useQASchemeDefinitions()
    const { data: qaSchemes } = useQASchemesForOrder(invoiceModel?.orderId, !!invoiceModel)

    const [saveInvoiceMutation, {isLoading: isSavingMutation}] = useSaveInvoice()
    const [readyForApprovalMutation, {isLoading: isSendingForApproval}] = useInvoiceReadyForApproval();
    const [createCreditNotaMutation, {isLoading: isCreatingCreditNote}] = useCreateCreditNoteFromInvoice();
    const [deleteInvoiceMutation, {isLoading: isDeletingInvoice}] = useDeleteInvoice();
    const [resendInvoiceMutation, {isLoading: isSendingInvoice}] = useResendInvoice();
    const [registerPaymentMutation, {isLoading: isLoadingPayment}] = useRegisterPayment();

    const {register, control, handleSubmit, reset, errors, getValues, setValue} = useForm<InvoiceModel>();
    const { control:paymentControl,handleSubmit:handelPaymentSubit, errors:paymenErrors} = useForm();

    const registerPayments =  async (data) => {
        data = {
            ...data,
            invoiceId:id
        }
         await registerPaymentMutation(data);

        openSuccessNotification("Succces", "Fakturaen blev betalt")

        setRegisterModalOpen(false);
    }

    const { fields, append, remove} = useFieldArray({
        keyName: 'idKey',
        control,
        name: "lines",
    });

    const {control: resendControl, register: registerResend, handleSubmit: handleResendSubmit, errors: resendErrors, reset: resetResend} = useForm<{emails: { value: string }[]}>({mode: 'all'});
    const { fields: resendFields, append: appendResend, remove: removeResend} = useFieldArray({
        keyName: 'idKey',
        control: resendControl,
        name: "emails",
    });

    useEffect(() => {
        reset(invoiceModel);
        if (!!invoiceModel) {
            setSelectedImages(invoiceModel.images);
            setSelectedFiles(invoiceModel.files);
            setSelectedNotes(invoiceModel.notes);
        }
        if (invoiceModel?.invoicingAddress.email) {
            resetResend({emails: [{value: invoiceModel?.invoicingAddress.email}]})
        }
    }, [reset, invoiceModel, resetResend])

    const save = async (data: InvoiceModel) => {
        if (invoiceModel && invoiceModel.state === 'WaitingForApproval') {
            openWarningNotification('Hov hov', 'Du kan ikke ændre en faktura der afventer godkendelse')
        } else {
            if (invoiceModel) {
                data.id = invoiceModel.id;
                data.state = invoiceModel.state;
                data.notes = selectedNotes;
                data.files = selectedFiles;
                data.images = selectedImages;
            }

            data.invoiceType = invoiceModel?.invoiceType!;

            return await saveInvoiceMutation(data);
        }
    }

    const saveDraft = async (data: InvoiceModel) => {
        const response = await save(data)

        if (response) {
            openSuccessNotification('Success', 'Informationen blev gemt')
        }
    }

    const saveAndSendToApproval = async (data: InvoiceModel) => {
        let invoice = invoiceModel;

        if (invoiceModel?.invoiceType === 'Invoice') {
            invoice = await save(data);
        }

        if (invoice) {
            await readyForApprovalMutation(invoice);

            openSuccessNotification('Success', `${getInvoiceTypeAsString(invoice.invoiceType)} blev sendt til godkendelse`)
        }
    }

    const createCreditNote = async () => {
        const response = await createCreditNotaMutation(invoiceModel!);

        if (response) {
            history.push(`/economy/invoices/edit/${response.id}`)
        }
    }

    const deleteDraft = async () => {
        await deleteInvoiceMutation(invoiceModel!);

        openSuccessNotification('Success', `${getInvoiceTypeAsString(invoiceModel!.invoiceType)} blev slettet`)

        history.goBack();
    }

    const resendInvoice = async (data: {emails: { value: string }[]}) => {
        const emails = (data.emails.map(x => x.value));

        await resendInvoiceMutation({invoiceId: invoiceModel!.id, emails})

        openSuccessNotification('Success', 'Fakturaen blev gensendt');

        resetResend({});
        setResendModalOpen(false)
    }

    const selectFiles = () => {
        setAddFilesModalOpen(true);
    }

    return (
        <DefaultPageLayout title={`${invoiceModel?.isAdvance ? 'Aconto ' : ''} ${getInvoiceTypeAsString(invoiceModel?.invoiceType ?? 'Invoice')}`} icon={<ShoppingList />} isLoading={isLoadingDefaultText || isLoadingInvoice}>
            <DocumentContainer>
                <PaperLayout beforePaper={(
                <FlexRow align={"end"} direction={"column"}>
                    {(!invoiceModel || invoiceModel.state === 'Draft' || invoiceModel.state === 'Rejected') && (
                        <FlexRow justify={"space-between"}>
                            <div>
                                <StyledButton disabled={isSavingMutation || isSendingForApproval || isDeletingInvoice} loading={isDeletingInvoice} color={"danger"} onClick={handleSubmit(deleteDraft)}>Slet kladde</StyledButton>
                            </div>
                            <Space>
                                <StyledButton onClick={selectFiles} color={"default"}>Tilføj filer til faktura ({selectedFiles.length + selectedNotes.length + selectedImages.length})</StyledButton>
                                {invoiceModel?.invoiceType === 'Invoice' && (
                                    <StyledButton disabled={isSavingMutation || isSendingForApproval || isDeletingInvoice} loading={isSavingMutation || isSendingForApproval} onClick={handleSubmit(saveDraft)}>Gem</StyledButton>
                                )}
                                <StyledButton disabled={isSavingMutation || isSendingForApproval || isDeletingInvoice} loading={isSavingMutation || isSendingForApproval} onClick={handleSubmit(saveAndSendToApproval)} color={"primary"}>Send til godkendelse</StyledButton>
                            </Space>
                        </FlexRow>
                    )}
                    {invoiceModel?.approval && invoiceModel.approval.approvedAt && (
                        <Notice type={getInvoiceStateColor(invoiceModel)}>
                            <FlexRow justify={"space-between"}>
                                <FlexRow direction={"column"} align={"start"}>
                                    <b>
                                        {getInvoiceStateAsString(invoiceModel)}
                                    </b>
                                    <span>
                                    {invoiceModel?.approval?.reason}
                                </span>
                                </FlexRow>
                                <Space>
                                    {!invoiceModel.creditNoteReferenceId && invoiceModel.invoiceType === 'Invoice' && invoiceModel.state === "Approved" && (
                                        <StyledButton color={'danger'} onClick={createCreditNote} loading={isCreatingCreditNote} disabled={isCreatingCreditNote}>
                                            Opret kreditnota
                                        </StyledButton>
                                    )}
                                    {!invoiceModel.creditNoteReferenceId && invoiceModel.invoiceType === 'Invoice' && invoiceModel.approval.isApproved && (
                                        <StyledButton color={'default'} onClick={() => setResendModalOpen(true)}>
                                            Gensend faktura på mail
                                        </StyledButton>
                                    )}
                                    {invoiceModel.state !== "Paid" && invoiceModel.state !== "Deprecated" && (
                                        <StyledButton color={'warning'} onClick={() => setRegisterModalOpen(true)}>
                                            Registrer betaling
                                        </StyledButton>
                                    )}
                                    {invoiceModel.creditNoteReferenceId && (
                                        <StyledButton onClick={() => history.push(`/economy/invoices/edit/${invoiceModel.creditNoteReferenceId}`)}>
                                            Vis kreditnota
                                        </StyledButton>
                                    )}
                                </Space>
                            </FlexRow>
                        </Notice>
                    )}
                </FlexRow>
            )}
            >
                <Ribbon text={getInvoiceStateAsString(invoiceModel)} color={getInvoiceStateColor(invoiceModel)}  />
                {((invoiceModel?.state !== "Draft" && invoiceModel?.state !== "Rejected") || invoiceModel.invoiceType === 'CreditNote') && <InvoiceDetails invoice={invoiceModel!} />}
                {((invoiceModel?.state === 'Draft' || invoiceModel?.state === 'Rejected') && invoiceModel.invoiceType !== 'CreditNote') && (
                    <form onSubmit={handleSubmit(saveDraft)}>
                        <FlexRow justify={"space-between"}>

                            <div style={{width: '40%', fontSize: '.9em'}}>
                                <Row gutter={[5, 5]}>
                                    <Col span={24}>
                                        <input type="text" className={getPaperInputClasses(errors.invoicingAddress?.customerName, true)} name={`invoicingAddress.customerName`} ref={register} />
                                    </Col>
                                </Row>

                                <span><input type="text" className={getPaperInputClasses(errors.invoicingAddress?.street, true)} name={`invoicingAddress.street`} ref={register} /></span>

                                <Row gutter={5}>
                                    <Col span={12}>
                                        <input type="text" className={getPaperInputClasses(errors.invoicingAddress?.zipCode, true)} name={`invoicingAddress.zipCode`} ref={register} />
                                    </Col>
                                    <Col span={12}>
                                        <input type="text" className={getPaperInputClasses(errors.invoicingAddress?.city, true)} name={`invoicingAddress.city`} ref={register} />
                                    </Col>
                                    <Col span={24}>
                                        <div className={getPaperInputClasses(errors.invoicingAddress?.vatNumber, true)}>
                                            <div>CVR: </div>
                                            <input name={"invoicingAddress.vatNumber"} ref={register} placeholder={"CVR"} />
                                        </div>
                                    </Col>
                                    <Col span={24}>
                                        <div className={getPaperInputClasses(errors.invoicingAddress?.att, true)}>
                                            <div>ATT: </div>
                                            <input name={"invoicingAddress.att"} ref={register} placeholder={"ATT"} />
                                        </div>
                                    </Col>
                                    <Col span={24}>
                                        <div className={getPaperInputClasses(errors.invoicingAddress?.ean, true)}>
                                            <div>EAN: </div>
                                            <input name={"invoicingAddress.ean"} ref={register} placeholder={"EAN"} />
                                        </div>
                                    </Col>
                                    <Col span={24}>
                                        <div className={getPaperInputClasses(errors.invoicingAddress?.email, true)}>
                                            <div>Email: </div>
                                            <input name={"invoicingAddress.email"} ref={register} placeholder={"Email"} />
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                            <div>
                                <img width={250} src="/images/Logo.png" alt="ELi A/S Badge"/>
                            </div>
                        </FlexRow>

                        <div style={{width: '40%', fontSize: '.9em', padding: '10px 0'}}>
                            <FlexRow justify={"space-between"}>
                                <b>Installationsadresse</b>
                            </FlexRow>

                            <span><input type="text" className={getPaperInputClasses(errors.deliveryAddress?.street, true)} name={`deliveryAddress.street`} ref={register} /></span>

                            <Row gutter={5}>
                                <Col span={12}>
                                    <input type="text" className={getPaperInputClasses(errors.deliveryAddress?.zipCode, true)} name={`deliveryAddress.zipCode`} ref={register} />
                                </Col>
                                <Col span={12}>
                                    <input type="text" className={getPaperInputClasses(errors.deliveryAddress?.city, true)} name={`deliveryAddress.city`} ref={register} />
                                </Col>
                            </Row>
                        </div>

                        <div style={{display: 'flex', justifyContent: 'space-between'}} >
                            <div style={{width: '55%', minHeight: '170px'}}>
                                <div style={{height: '20px'}} />
                                <hr className={styles.seperator}/>
                                <div style={{fontWeight: 600}}>
                                    <input type="text" className={getPaperInputClasses(errors.title)} name={`title`} ref={register} />
                                </div>
                                <div className={styles.defaultTextContainer}>
                                    {defaultInvoiceText}
                                </div>
                            </div>

                            <div className={"flex column"} style={{minWidth: '40%', minHeight: '170px'}}>
                                <b style={{height: '20px', textTransform: 'uppercase'}}>
                                    Faktura
                                </b>
                                <hr className={styles.seperator}/>
                                <FlexRow justify={"space-between"}>
                                    Dato:
                                    <span>
                                        <Controller
                                            name={"date"}
                                            control={control}
                                            defaultValue={moment().toDate()}
                                            render={props => (
                                                <DatePicker bordered={false}
                                                    size={"small"}
                                                    format={"DD-MM-YYYY"}
                                                    style={{fontSize: '1em'}}
                                                    value={props.value ? moment(props.value) : null}
                                                    disabled
                                                    onChange={(e => {
                                                        props.onChange(e?.toDate())
                                                    })}
                                                />
                                            )}
                                        />
                                    </span>
                                </FlexRow>
                                <FlexRow justify={"space-between"}>
                                    Forfaldsdato:
                                    <span>
                                        <span>
                                        <Controller
                                            name={"dueDate"}
                                            control={control}
                                            defaultValue={invoiceModel.dueDate}
                                            render={props => (
                                                <DatePicker bordered={false}
                                                    size={"small"}
                                                    format={"DD-MM-YYYY"}
                                                    style={{fontSize: '1em'}}
                                                    value={props.value ? moment(props.value) : null}
                                                    onChange={(e => {
                                                        props.onChange(e?.toDate())
                                                    })}
                                                />
                                            )}
                                        />
                                    </span>
                                    </span>
                                </FlexRow>
                                <FlexRow justify={"space-between"}>
                                    Rekvistionsnr:
                                    <span>
                                        <input className={getPaperInputClasses(errors.requisitionNumber, true)} style={{float: 'right', clear: 'both', direction: 'rtl'}} name={"requisitionNumber"} ref={register}/>
                                    </span>
                                </FlexRow>
                            </div>
                        </div>

                        <hr className={styles.seperator}/>

                        <Row gutter={10}>
                            <Col span={10}>Tekst</Col>
                            <Col span={3}>Antal</Col>
                            <Col span={3}>Stk. pris</Col>
                            <Col span={3}>Rabat %</Col>
                            <Col span={4}>
                                <span style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    Pris
                                </span>
                            </Col>
                        </Row>

                        <hr className={styles.seperator}/>

                        <div className={styles.descriptionContainer}>
                            {fields.sort((a,b) => a.lineNumber - b.lineNumber).map(({idKey, id , text, amount, unitSalesPrice, discount}, index) => (
                                <EditInvoiceLine key={idKey} idKey={idKey} id={id} text={text} amount={amount} unitSalesPrice={unitSalesPrice} discount={discount} index={index} control={control} register={register} errors={errors} remove={remove} getValues={getValues} setValue={setValue} />
                            ))}
                            <StyledButton onClick={append}>Tilføj linje</StyledButton>
                        </div>

                        <hr className={styles.seperator}/>

                        <FlexRow justify={"end"} align={'end'} direction={"column"}>
                            <FormInvoiceTotal control={control}/>
                        </FlexRow>
                    </form>
                )}

            </PaperLayout>
                {!!order && (
                    <OrderDocument order={order} />
                )}
                {!!timeEntries && timeEntries.length > 0 && (
                    <TimeEntryDocument order={order} timeEntries={timeEntries} />
                )}
                {selectedNotes?.map(note => (
                    <NoteDocument note={note} order={order} />
                ))}
                {qaSchemes?.map(scheme => (
                    <QADocument order={order} qaSchema={scheme} qaSchemaDefinition={qaSchemesDefinitions?.find(x => x.id === scheme.qaSchemeDefinitionId)} />
                ))}
            </DocumentContainer>



            <Modal visible={resendModalOpen}
               title={"Gendsend faktura på mail"}
               onCancel={() => setResendModalOpen(false)}
               footer={false}
            >
                <form onSubmit={handleResendSubmit(resendInvoice)}>
                    <Row gutter={[20, 20]}>
                        {resendFields.map((field, index) => (
                            <React.Fragment key={field.idKey}>
                                <Col span={22}>
                                    <input
                                        type="email"
                                        className={getInputClasses(_.get(resendErrors, `emails[${index}].value`))}
                                        ref={registerResend({required: true})}
                                        name={`emails[${index}].value`}
                                        defaultValue={field.value}
                                    />
                                </Col>
                                <Col span={2} onClick={() => removeResend(index)}>
                                    <FlexRow justify={"center"} align={"center"} className={"h-100"}>
                                        <CloseCircleFilled/>
                                    </FlexRow>
                                </Col>
                            </React.Fragment>
                        ))}

                        <Col span={24}>
                            <StyledButton color={"default"} onClick={() => appendResend({email: ''})}>Tilføj
                                modtager</StyledButton>
                        </Col>
                    </Row>

                    <FlexRow justify={"end"}>
                        <Space>
                            <StyledButton onClick={() => setResendModalOpen(false)}>Afbryd</StyledButton>
                            <StyledButton color={"primary"} disabled={isSendingInvoice} loading={isSendingInvoice}
                                          type={"submit"}>Gensend faktura</StyledButton>
                        </Space>
                    </FlexRow>
                </form>
            </Modal>
            <Modal visible={registerPaymentModalOpen} onCancel={() => setRegisterModalOpen(false)}
                   title={"Register betaling"} footer={false}
            >
                <div>
                    <form onSubmit={handelPaymentSubit(registerPayments)}>
                        <Space direction={"vertical"} className={"w-100"}>
                            <Controller
                                name={"date"}
                                control={paymentControl}
                                defaultValue={new Date()}
                                render={props => (
                                    <DatePicker className={getInputClasses(paymenErrors.date)}
                                                size={"small"}
                                                format={"DD-MM-YYYY"}
                                                style={{fontSize: "1em"}}
                                                value={props.value ? moment(props.value) : null}
                                                onChange={(error => {
                                                    props.onChange(error?.toDate())
                                                })}
                                    />
                                )}
                            />
                            <FlexRow justify={"end"}>
                                <Space>
                                    <StyledButton color={"default"} onClick={() => setRegisterModalOpen(false)}>
                                        Afbryd
                                    </StyledButton>
                                    <StyledButton loading={isLoadingPayment} disabled={isLoadingPayment}
                                                  color={"primary"} type={"submit"}>
                                        Gem
                                    </StyledButton>
                                </Space>
                            </FlexRow>
                        </Space>
                    </form>
                </div>
            </Modal>
            <Modal visible={addFilesModalOpen} onCancel={() => setAddFilesModalOpen(false)} width={1024} title={"Tilføj filer"} footer={false}>
                <Row gutter={[10, 10]}>
                    {orderImages?.map((image) => (
                        <Col xs={24} md={12} lg={4}>
                            <div
                                key={image.id}
                                className={`${styles.uploadedFileContainer} ${selectedImages.filter(x => x.id === image.id).length > 0 && styles.selected}`}
                                onClick={() => {
                                    if (selectedImages.filter(x => x.id === image.id).length > 0) {
                                        setSelectedImages(selectedImages.filter(x => x.id !== image.id))
                                    } else {
                                        setSelectedImages([...selectedImages, image])
                                    }
                                }}>
                                <img height={'auto'} width={'100%'} style={{borderRadius: 10}} src={image.link}
                                     alt="Billede"/>
                            </div>
                        </Col>
                    ))}
                    {orderFiles?.map((file) => (
                        <Col xs={24} md={12} lg={4}>
                            <div
                                key={file.id}
                                className={`${styles.uploadedFileContainer} ${selectedFiles.filter(x => x.id === file.id).length > 0 && styles.selected}`}
                                onClick={() => {
                                    if (selectedFiles.filter(x => x.id === file.id).length > 0) {
                                        setSelectedFiles(selectedFiles.filter(x => x.id !== file.id))
                                } else {
                                    setSelectedFiles([...selectedFiles, file])
                                }
                            }}>
                                <div className={styles.uploadedFile}>
                                    <Report height={70}/>
                                    <div className={styles.uploadedFileDescription}>
                                        {file.description}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    ))}
                    {orderNotes?.map((note) => (
                        <Col xs={24} md={12} lg={4}>
                            <div key={note.id} className={`${styles.uploadedFileContainer} ${selectedNotes.filter(x => x.id === note.id).length > 0 && styles.selected}`} onClick={() => {
                                if (selectedNotes.filter(x => x.id === note.id).length > 0) {
                                    setSelectedNotes(selectedNotes.filter(x => x.id !== note.id))
                                } else {
                                    setSelectedNotes([...selectedNotes, note])
                                }
                            }}>
                                <div className={styles.uploadedFile}>
                                    <Report height={70}/>
                                    <div className={styles.uploadedFileDescription}>
                                        {note.title}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>
            </Modal>
        </DefaultPageLayout>
    )
}

export default EditInvoicePage

