import React, { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { useWriteFile } from "Apis/Admin/Documents/DocumentsAdminApi";
import DefaultPageLayout from "Pages/Layouts/DefaultPageLayout";
import Report from "Assets/Icons/Report";
import { Col, Row, Space } from "antd";
import FlexRow from "Components/FlexRow";
import StyledButton from "Components/StyledButton";
import DocumentAdminFolder from "./Components/DocumentAdminFolder";
import DocumentAdminFile from "./Components/DocumentAdminFile";
import DocumentCrudModal from "./Components/DocumentCrudModal";
import Dragger from "antd/es/upload/Dragger";
import styles from './DocumentsPageAdmin.module.scss'
import { toBase64 } from "Utils/Base64Utils";
import Box from "Components/Box";
import _ from 'lodash';
import { useDocumentRead } from "Apis/Documents/DocumentsApi";
import { openSuccessNotification } from "Utils/NotificationUtils";
import Spinner from "Components/Spinner";
import { DocumentType } from "Apis/Documents/Models/DocumentReadModel";
import DocumentAdminLink from "Pages/Admin/Documents/Components/DocumentAdminLink";

const DocumentsPageAdmin = () => {

    const [searchFilter, setSearchFilter] = useState<string>('');
    const { id } = useParams<{ id }>();
    const { data } = useDocumentRead(id, searchFilter);
    const [uploadingFiles, setUploadingFiles] = useState<string[]>([]);
    

    const [createDocumentType, setCreateDocumentType] = useState<DocumentType>('Folder');
    
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [writeFileMutation] = useWriteFile();

    const closeModal = () => {
        setModalVisible(false)
    }

    const Upload = (file: any) => {
        const guid = Math.random().toString(36).substring(7);
        
        setUploadingFiles(files => [...files, guid])

        toBase64(file).then(async base64 => {
            const File = {
                data: base64,
                name: file.name,
                parentId: id,
                contentType: file.type
            }

            await writeFileMutation(File);
    
            setUploadingFiles(files => files.filter(x => x !== guid))
            
            openSuccessNotification('Success', `Filen ${file.name} blev uploadet`)
        })

        return false;
    }
    
    const createNewLink = () => {
        setCreateDocumentType('Link');
        setModalVisible(true);
    }
    
    const createNewFolder = () => {
        setCreateDocumentType('Folder');
        setModalVisible(true);
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const search = useCallback(_.debounce(async (newSearchFilter: string) => {
        setSearchFilter(newSearchFilter);
    }, 300), [])

    return (
        <DefaultPageLayout title={"Dokumenter"} icon={<Report/>}
           beforeBox={
               <Box style={{ marginBottom: 15, padding: 10 }}>
                   <FlexRow>
                       <input onChange={e => search(e.target.value)} type="text" className={"input input-invisible full-width"} placeholder={"Søg...."}/>
                   </FlexRow>
               </Box>
           }
        >
            <FlexRow justify={"space-between"}>
                <div>
                    {uploadingFiles.length > 0 ? (
                        <Space>
                            <Spinner />
                            <span>
                                Uploader {uploadingFiles.length} {uploadingFiles.length > 1 ? 'filer' : 'fil'}
                            </span>
                        </Space>
                    ) : ''}
                </div>
                <Space direction={"horizontal"} >
                    <StyledButton color={"primary"} onClick={createNewLink}>Opret link</StyledButton>
                    <StyledButton color={"primary"} onClick={createNewFolder}>Opret mappe</StyledButton>
                </Space>
            </FlexRow>

            {!(!!searchFilter && data?.length === 0) ? (

                <Dragger multiple disabled={!!searchFilter} openFileDialogOnClick={data?.length === 0 && !searchFilter} className={data?.length !== 0 ? styles.dragger : ''} showUploadList={false} beforeUpload={(file) => Upload(file)}>
                    <Row gutter={[20, 20]}>
                        {data?.filter(x => x.type === "Folder").map(x =>
                            <Col xxl={3} xl={4} lg={6} md={8} sm={12} xs={24}>
                                <DocumentAdminFolder document={x}/>
                            </Col>
                        )}
                        {data?.filter(x => x.type === "File").map(x =>
                            <Col xxl={3} xl={4} lg={6} md={8} sm={12} xs={24}>
                                <DocumentAdminFile file={x}/>
                            </Col>
                        )}
                        {data?.filter(x => x.type === 'Link').map(x =>
                            <Col xxl={3} xl={4} lg={6} md={8} sm={12} xs={24}>
                                <DocumentAdminLink file={x}/>
                            </Col>
                        )}
                    </Row>

                    {data?.length === 0 && !searchFilter && (
                        <FlexRow justify={"center"} align={"center"}>
                            Klik her, eller træk og slip, for at uploade filer
                        </FlexRow>
                    )}
                </Dragger>
            ) : (
                <FlexRow justify={"center"}>
                    Vi fandt ingen filer med navnet {searchFilter}
                </FlexRow>
            )}
            <DocumentCrudModal parentId={id} document={{ type: createDocumentType }} visible={modalVisible} onCancel={closeModal}/>
        </DefaultPageLayout>
    )

}

export default DocumentsPageAdmin
