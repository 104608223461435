import {queryCache, useMutation, useQuery} from "react-query";
import {request} from "Apis/BaseApi";
import {UserContextModel} from "Apis/Models/UserModel";

export const useChangeOrganizationContext = () => {
    return useMutation(async (organizationId: number) => {
        return await request({
            url: `/users/context/${organizationId}`,
            method: 'PUT'
        })
    }, {
        onSuccess: () => {
            queryCache.clear();
        }
    })
}

export const userContextCacheKey = 'UserContext';
export const useUserContext = (enabled: boolean) => {
    return useQuery<UserContextModel>(userContextCacheKey, async () => {
        return await request<UserContextModel>({
            url: '/users/context',
            method: 'GET'
        })
    }, {
        enabled
    })
}
