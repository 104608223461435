import React from 'react';
import {Col, Dropdown, Row} from "antd";
import {getPaperInputClasses} from "Utils/CssUtils";
import FlexRow from "Components/FlexRow";
import {UseFormMethods, useWatch} from "react-hook-form";
import {useProductsSearchMenu} from "Hooks/UseProductSearch";
import {KlarPrisProduct} from "Apis/Models/KlarPrisIntegrationModel";
import {ProductModel} from "Apis/Models/ProductModel";
import {roundNumber} from "Utils/NumberUtils";
import {OrderMaterialModel} from "Apis/Models/OrderMaterialModel";
import MaterialFormLineTotal from "Pages/Projects/Orders/Components/MaterialFormLineTotal";

interface Props {
    orderId: number,
    line: any,
    index: number,
    formMethods: UseFormMethods<{ materials: OrderMaterialModel[] }>
    remove: any,
}
const MaterialFormLine = ({orderId, line, index, remove, formMethods}: Props) => {

    const {setValue, register, getValues, control, errors} = formMethods;
    let {idKey, id, text, quantity, unitPriceExVat, margin, salesPriceExVat, discount} = line;

    const [descriptionProductSuggestionsMenu, searchForProducts] = useProductsSearchMenu(async (product: KlarPrisProduct) => {
        setLineValuesFromProduct(product);
    }, (product: ProductModel) => {
        setValue(`materials[${index}].text`, product.description)
        setValue(`materials[${index}].unitPriceExVat`, 0)
        setValue(`materials[${index}].margin`, 0)
        setValue(`materials[${index}].salesPriceExVat`, product.unitPriceExVat)
    });

    const setLineValuesFromProduct = (product: KlarPrisProduct) => {
        const bestPrice = product.prices.sort((a,b) => a.price - b.price)[0];

        setValue(`materials[${index}].text`, product.description)
        setValue(`materials[${index}].unitPriceExVat`, bestPrice.price)

        let salesPrice = bestPrice.price;
        let currentMargin = getValues().materials[index].margin;

        if (!currentMargin) {
            currentMargin = 100
            setValue(`materials[${index}].margin`, currentMargin);
        }

        salesPrice = roundNumber(salesPrice + (salesPrice / 100 * currentMargin));

        setValue(`materials[${index}].salesPriceExVat`, salesPrice);
    }

    const values = useWatch<any>({
        control,
        defaultValue: {
            unitPriceExVat: unitPriceExVat,
            margin: margin,
            salesPriceExVat: salesPriceExVat,
        },
        name: `materials[${index}]`
    });

    const calculateSalesPriceFromMargin = (localMargin: number) => {
        let newValue = values.unitPriceExVat;

        if (values.unitPriceExVat && localMargin) {
            newValue = roundNumber(parseFloat(values.unitPriceExVat) + (values.unitPriceExVat / 100 * localMargin));
        }

        setValue(`materials[${index}].salesPriceExVat`, newValue);
    }

    const calculateMarginFromUnitPrice = (localPurchasePrice: number) => {
        if (values.salesPriceExVat && localPurchasePrice) {
            const newValue = (values.salesPriceExVat - localPurchasePrice) / values.unitPriceExVat * 100

            setValue(`materials[${index}].margin`, newValue);
        }
    }

    const calculateMarginFromSalesPrice = (localSalesPrice: number) => {
        if (values.unitPriceExVat && localSalesPrice) {
            const newValue = (localSalesPrice - values.unitPriceExVat) / values.unitPriceExVat * 100

            setValue(`materials[${index}].margin`, newValue);
        }
    }

    return (
        <React.Fragment key={idKey}>
            <input type="hidden" name={`materials[${index}].id`} ref={register()} defaultValue={id} />
            <input type="hidden" name={`materials[${index}].orderId`} ref={register()} defaultValue={orderId} />

            <Dropdown overlay={descriptionProductSuggestionsMenu} visible={true}>
                <Row gutter={[5, 20]} style={{width: '100%'}}>
                    <Col span={8}>
                        <input type={'text'}
                               onChange={(e) => searchForProducts(e.target.value)}
                               className={getPaperInputClasses(errors.materials && errors.materials[index]?.text)}
                               name={`materials[${index}].text`}
                               ref={register({required: true})}
                               onFocus={e => e.target.select()}
                               onBlur={() => searchForProducts('')}
                               defaultValue={text}
                        />
                    </Col>

                    <Col span={2}>
                        <input type={'number'}
                               className={getPaperInputClasses(errors.materials && errors.materials[index]?.quantity)}
                               name={`materials[${index}].quantity`}
                               ref={register({required: true})}
                               onFocus={e => e.target.select()}
                               defaultValue={quantity}
                        />
                    </Col>

                    <Col span={3}>
                        <input type={'number'}
                               className={getPaperInputClasses(errors.materials && errors.materials[index]?.unitPriceExVat)}
                               name={`materials[${index}].unitPriceExVat`}
                               onChange={e => calculateMarginFromUnitPrice(parseFloat(e.target.value))}
                               ref={register({required: true})}
                               onFocus={e => e.target.select()}
                               defaultValue={unitPriceExVat}
                        />
                    </Col>

                    <Col span={3}>
                        <input type={'number'}
                               className={getPaperInputClasses(errors.materials && errors.materials[index]?.margin)}
                               name={`materials[${index}].margin`}
                               onChange={e => calculateSalesPriceFromMargin(parseFloat(e.target.value))}
                               ref={register({required: true, min: 0})}
                               onFocus={e => e.target.select()}
                               defaultValue={margin}
                        />
                    </Col>

                    <Col span={3}>
                        <input type={'number'}
                               className={getPaperInputClasses(errors.materials && errors.materials[index]?.salesPriceExVat)}
                               name={`materials[${index}].salesPriceExVat`}
                               ref={register({required: true})}
                               onChange={e => calculateMarginFromSalesPrice(parseFloat(e.target.value))}
                               onFocus={e => e.target.select()}
                               defaultValue={salesPriceExVat}
                        />
                    </Col>

                    <Col span={2}>
                        <input type={'number'}
                               className={getPaperInputClasses(errors.materials && errors.materials[index]?.discount)}
                               name={`materials[${index}].discount`}
                               ref={register({required: true, min: 0, max: 100})}
                               onFocus={e => e.target.select()}
                               defaultValue={discount ?? 0}
                        />
                    </Col>

                    <Col span={2}>
                        <FlexRow style={{height: '100%'}} justify={"end"}>
                            <MaterialFormLineTotal control={control} index={index} />
                        </FlexRow>
                    </Col>

                    <Col span={1} onClick={() => remove(index)}>
                        <FlexRow style={{height: '100%'}} justify={"end"}>
                            x
                        </FlexRow>
                    </Col>
                </Row>
            </Dropdown>
        </React.Fragment>
    )
}

export default MaterialFormLine
