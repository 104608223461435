import React, {useState} from 'react';
import DefaultPageLayout from "Pages/Layouts/DefaultPageLayout";
import {useDeleteProduct, useProducts, useSaveProduct} from "Apis/ProductApi";
import {Space, Table} from "antd";
import {getNumberAsCurrency} from "Utils/NumberUtils";
import {ProductModel} from "Apis/Models/ProductModel";
import FlexRow from "Components/FlexRow";
import StyledButton from "Components/StyledButton";
import {getInputClasses} from "Utils/CssUtils";
import Modal from "antd/es/modal";
import {useForm} from "react-hook-form";
import {openSuccessNotification} from "Utils/NotificationUtils";
import Label from "Components/Label";

const ProductsPage = () => {

    const [selectedProduct, setSelectedProduct] = useState<ProductModel>();
    const [showProductModal, setShowProductModal] = useState<boolean>(false);

    const {register, handleSubmit, errors, reset} = useForm<ProductModel>();

    const {data: products, isLoading: isLoadingProducts} = useProducts();
    const [saveProductMutation, {isLoading: isSavingProduct}] = useSaveProduct();
    const [deleteProductMutation, {isLoading: isDeletingProduct}] = useDeleteProduct();

    const selectProduct = (product: ProductModel) => {
        setSelectedProduct(product);
        setShowProductModal(true)
        reset(product);
    }

    const saveProduct = async (data: ProductModel) => {
        data = {
            ...selectedProduct,
            ...data
        }

        await saveProductMutation(data);

        openSuccessNotification('Success', 'Produktet blev gemt');
        closeModal();
    }

    const deleteProduct = async () => {
        await deleteProductMutation(selectedProduct);

        openSuccessNotification('Success', 'Produktet blev slettet');
        closeModal();
    }

    const closeModal = () => {
        setSelectedProduct(undefined);
        setShowProductModal(false);
        reset({});
    }

    return (
        <DefaultPageLayout title={"Produkter"} fab={
            <FlexRow justify={"end"}>
                <StyledButton onClick={() => setShowProductModal(true)}>Opret produkt</StyledButton>
            </FlexRow>
        }>
            <Table
                dataSource={products}
                pagination={false}
                rowClassName={"clickable"}
                loading={isLoadingProducts}
                rowKey={'id'}
                onRow={(record) => {
                    return {
                        onClick: () => selectProduct(record),
                    };}
                }
            >
                <Table.Column title={"Nummber"} dataIndex={"productNumber"} />
                <Table.Column title={"Beskrivelse"} dataIndex={"description"} />
                <Table.Column title={"Pris ex moms"} dataIndex={"unitPriceExVat"} render={(text) => `${getNumberAsCurrency(text)} DKK`} />
            </Table>
            <Modal visible={showProductModal} title={"Produkt"}
                   onCancel={closeModal}
                   footer={false}
            >
                <form onSubmit={handleSubmit(saveProduct)}>
                    <Space className={"w-100"} direction={"vertical"}>

                        <div>
                            <Label>Produktnummer</Label>
                            <input type={"text"} placeholder={"Titel"} name="productNumber" className={getInputClasses(errors.productNumber)} ref={register({required: true})} />
                        </div>

                        <div>
                            <Label>Produktnavn</Label>
                            <input type={"text"} placeholder={"Produktnavn"} name="description" className={getInputClasses(errors.description)} ref={register({required: true})} />
                        </div>

                        <div>
                            <Label>Pris excl. moms</Label>
                            <input type={"text"} placeholder={"Pris excl moms."} name="unitPriceExVat" className={getInputClasses(errors.unitPriceExVat)} ref={register({required: true, pattern: /^[0-9]*$/})} />
                        </div>

                        <FlexRow justify={"space-between"}>
                            <div>
                                {selectedProduct?.id && (
                                    <StyledButton loading={isDeletingProduct} color={'danger'} onClick={deleteProduct}>Slet</StyledButton>
                                )}
                            </div>
                            <Space>
                                <StyledButton onClick={closeModal}>Afbryd</StyledButton>
                                <StyledButton loading={isSavingProduct} color={"primary"} type={"submit"}>Gem</StyledButton>
                            </Space>
                        </FlexRow>
                    </Space>
                </form>
            </Modal>
        </DefaultPageLayout>
    )
}

export default ProductsPage
