import DefaultPageLayout from "Pages/Layouts/DefaultPageLayout";
import User from "Assets/Icons/User";
import FlexRow from "Components/FlexRow";
import {Table} from "antd";
import Spinner from "Components/Spinner";
import React, {useCallback, useState} from "react";
import {useHistory} from "react-router-dom";
import {useCustomers} from "Apis/CustomerApi";
import {CustomerModel} from "Apis/Models/CustomerModel";
import Box from "Components/Box";
import _ from 'lodash';
import StyledButton from "Components/StyledButton";

const CustomersOverviewPage = () => {

    const history = useHistory();

    const [searchFilter, setSearchFilter] = useState<string>('')

    const {data: customers, isLoading} = useCustomers(searchFilter);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const searchForCustomers = useCallback(_.debounce(async (searchFilter: string) => {
        setSearchFilter(searchFilter)
    }, 300), [])

    return (
        <DefaultPageLayout title={"Kunder"} icon={<User/>}
            beforeBox={
                <Box style={{marginBottom: 15, padding: 10}}>
                    <input onChange={e => searchForCustomers(e.target.value)} type="text" className={"input input-invisible full-width"} placeholder={"Søg...."}/>
                </Box>
            }
           fab={
               <StyledButton color={"primary"} onClick={() => history.push('/projects/customers/create')}>
                   Opret kunde
               </StyledButton>
           }
        >
            <Table
                rowKey={'id'}
                dataSource={customers}
                loading={{indicator: <Spinner/>, spinning: isLoading}}
                pagination={false}
                onRow={(record) => {
                    return {
                        onClick: () => history.push(`/projects/customers/edit/${record.id}`),
                    };
                }}
                rowClassName={'clickable'}
            >
                <Table.Column title={"Navn"} dataIndex={"name"} render={(text, record: CustomerModel) => (
                    <FlexRow direction={"column"} justify={"start"} align={"start"}>
                        <b>
                            {text}
                        </b>
                        <span style={{fontSize: '.8em'}}>
                            {record.att}
                        </span>
                    </FlexRow>
                )}/>
                <Table.Column title={"Adresse"} dataIndex={"address"} render={(text, record: CustomerModel) => (
                    <FlexRow direction={"column"} justify={"start"} align={"start"}>
                        {record.street}
                        <span style={{fontSize: '.8em'}}>
                            {record.zipCode} - {record.city}
                        </span>
                    </FlexRow>
                )}/>
                <Table.Column title={"CVR"} dataIndex={"vatNumber"}/>
                <Table.Column title={"Telefon"} dataIndex={"phone"}/>
                <Table.Column title={"EMail"} dataIndex={"email"}/>
            </Table>
        </DefaultPageLayout>
    )
}

export default CustomersOverviewPage;
