import React from "react";
import {
    Switch,
    Route, useRouteMatch
} from "react-router-dom";
import PublicApproveOfferPage from "Pages/Public/PublicApproveOfferPage";
import PublicApproveAgreementPage from "Pages/Public/PublicApproveAgreementPage";
import NewSupplierPage from "Pages/Public/NewSupplier/NewSupplierPage";

const PublicPage = () => {

    let {path} = useRouteMatch();

    return (
        <Switch>
            <Route path={`${path}/:organizationId/approval/offer/:offerId`}>
                <PublicApproveOfferPage />
            </Route>
            <Route path={`${path}/:organizationId/approval/agreement/:agreementId`}>
                <PublicApproveAgreementPage />
            </Route>
            <Route path={`${path}/newsupplier`}>
                <NewSupplierPage />
            </Route>
        </Switch>
    )

}

export default PublicPage;
