import {Link, useRouteMatch} from "react-router-dom";
import React, {useState} from "react";
import FlexRow from "Components/FlexRow";
import styles from './MenuLink.module.scss';

type Props = {
    to: string;
    icon: any;
    children: any;
    onClick?: any;
    subLinks?: any[];
    enableActive?: boolean;
    exact?: boolean;
}
const MenuLink = ({to, icon, children, onClick, subLinks, enableActive = true, exact = false}: Props) => {

    const [submenuOpen, setSubmenuOpen] = useState<boolean>(false)

    let match = useRouteMatch({
        path: to,
        exact
    });

    return (
        <span onClick={() => setSubmenuOpen(true)}>
            <Link to={to} onClick={onClick}>
                <FlexRow justify={'center'} style={{paddingBottom: '1.3em'}}>
                    <div className={match && enableActive ? `${styles.menuLink} ${styles.active}`: styles.menuLink}>
                        <div className={styles.menuLinkIcon}>
                            {icon}
                        </div>
                        <div className={styles.menuLinkContent}>
                            {children}
                        </div>
                    </div>
                </FlexRow>
            </Link>
            <div className={match || (match && submenuOpen) ? `${styles.submenu} ${styles.open}` : styles.submenu}>
                {subLinks?.map(link => link)}
            </div>
        </span>
    )
}

export default MenuLink;

