import {ReminderModel} from "Apis/Admin/Reminders/Models/ReminderModel";

export const getReminderTypeText = (reminder: ReminderModel | null | undefined) => {
    switch (reminder?.reminderScheduleItem.reminderType) {
        case "PreReminder":
            return 'Betalingspåmindelse';
        case "Reminder":
            return 'Rykker';
        case "DebtCollection":
            return 'Inkassoskrivelse';
    }
}

export const getReminderTypeColor = (reminder: ReminderModel | null | undefined): 'Default' | 'Success' | 'Danger' | 'Warning' => {
    switch (reminder?.reminderScheduleItem.reminderType) {
        case "PreReminder":
            return 'Success';
        case "Reminder":
            return 'Warning';
        case "DebtCollection":
            return 'Danger';
        default:
            return "Default";
    }
}

export const getReminderReceiver = (reminder: ReminderModel | null | undefined): string => {
    switch (reminder?.reminderScheduleItem.reminderType) {
        case "PreReminder":
        case "Reminder":
            return 'kunden';
        case "DebtCollection":
            return 'Inkassofirmaet';
        default:
            return ""
    }
}
