import {queryCache, useMutation, useQuery} from "react-query";
import {request} from "Apis/BaseApi";
import {
    KlarPrisIntegrationModel, KlarPrisProduct,
    LoginToWholesalerModel,
    SelectWholesalerAccountModel, UpdateVendorApplicationModel,
    WholesalerModel
} from "Apis/Models/KlarPrisIntegrationModel";
import {openErrorNotification} from "Utils/NotificationUtils";
import _ from 'lodash';

export const klarPrisProductSearchCacheKey = 'klarPrisProductSearch';
export const useSearchForProducts = (searchText) => {
    return useQuery<KlarPrisProduct[]>([klarPrisProductSearchCacheKey, searchText], async () => {
        return await request<KlarPrisProduct[]>({
            url: `/klarpris/products/search`,
            method: 'GET',
            data: {
                searchText
            }
        })
    })
}

export const searchForKlarPrisProducts = async (searchText: string): Promise<KlarPrisProduct[]> => {
    return await request<KlarPrisProduct[]>({
        url: `/klarpris/products/search?searchText=${searchText}`,
        method: 'GET',
    })
}

export const klarPrisIntegrationCacheKey = 'klarPrisIntegration';
export const useKlarPrisAccount = () => {
    return useQuery<KlarPrisIntegrationModel | null>(klarPrisIntegrationCacheKey, async () => {
        try {
            return await request<KlarPrisIntegrationModel>({
                url: `/klarpris`,
                method: 'GET'
            })
        }
        catch (e) {
            return null;
        }
    })
}

export const klarPrisTermsCacheKey = 'klarPrisTerms';
export const useKlarPrisTerms = (enabled: boolean) => {
    return useQuery<string>(klarPrisTermsCacheKey, async () => {
        return await request<string>({
            url: `/klarpris/terms`,
            method: 'GET'
        })
    }, {
        enabled
    })
}

export const klarPrisWholeSalersCackeKey = 'klarPrisWholeSalers';
export const useKlarPrisWholeSalers = (enabled: boolean) => {
    return useQuery<WholesalerModel[]>(klarPrisWholeSalersCackeKey, async () => {
        return await request<WholesalerModel[]>({
            url: `/klarpris/wholesalers`,
            method: 'GET'
        })
    }, {
        enabled
    })
}


export const useCreateKlarPrisAccount = () => {
    return useMutation(async () => {
        return await request({
            url: `/klarpris`,
            method: 'POST',
        })
    }, {
        onSuccess: () => {
            queryCache.invalidateQueries(klarPrisIntegrationCacheKey)
            queryCache.invalidateQueries(klarPrisTermsCacheKey)
        },
    })
}

export const useAcceptKlarPrisTerms = () => {
    return useMutation(async () => {
        return await request({
            url: `/klarpris/terms`,
            method: 'POST',
        })
    }, {
        onSuccess: () => {
            queryCache.invalidateQueries(klarPrisIntegrationCacheKey)
        },
    })
}

export const useLoginToWholesaler = () => {
    return useMutation(async (loginToWholesalerModel: LoginToWholesalerModel) => {
        return await request<string[]>({
            url: `/klarpris/wholesaler/login`,
            method: 'PUT',
            data: loginToWholesalerModel
        })
    }, {
        onSuccess: () => {

        },
    })
}

export const useSelectWholesalerAccount = () => {
    return useMutation(async (selectModel: SelectWholesalerAccountModel) => {
        return await request({
            url: `/klarpris/wholesaler/selectAccount`,
            method: 'PUT',
            data: selectModel
        })
    }, {
        onSuccess: () => {
            queryCache.invalidateQueries(klarPrisWholeSalersCackeKey)
        },
    })
}

export const useUpdateVendorApplication = () => {
    return useMutation(async (vendorApplicationModel: UpdateVendorApplicationModel) => {
        return await request({
            url: `/klarpris/vendorapplication/${vendorApplicationModel.vendorApplicationId}`,
            method: 'PUT',
            data: vendorApplicationModel
        })
    }, {
        onSuccess: () => {
            queryCache.invalidateQueries(klarPrisWholeSalersCackeKey)
        },
        onError: (error) => {
            const errors = _.get(error, 'data.errors');

            const keys = Object.keys(errors);

            if (errors && keys.length > 0) {
                openErrorNotification('Der skete en fejl', errors[keys[0]])
            }
        }
    })
}
