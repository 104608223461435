import {Col, Divider, Row} from "antd";
import React, {useState} from "react";
import FlexRow from "Components/FlexRow";
import {getNumberAsCurrency, roundNumber} from "Utils/NumberUtils";
import StyledButton from "Components/StyledButton";
import {useFieldArray, UseFormMethods} from "react-hook-form";
import {getLineTotalExVat} from "Utils/CalculationLineUtils";
import {OfferModel} from "Apis/Models/OfferModel";
import PriceCalculatorTotal from "Pages/Projects/Offers/Components/PriceCalculatorTotal";
import PriceCalculatorLine from "Pages/Projects/Offers/Components/PriceCalculatorLine";

interface Props {
    formMethods: UseFormMethods<OfferModel>,
    offer: OfferModel,
    price?: number,
    onOk?: (price: number) => void,
}
const PriceCalculator = (props: Props) => {

    const [enteredPrice, setEnteredPrice] = useState<number>(props.price ?? 0);

    const {getValues, control, errors, trigger} = props.formMethods;
    const { fields, append, remove} = useFieldArray({
        keyName: 'idKey',
        control: control,
        name: "calculationLines",
    });

    const useEnteredPrice = async () => {
        if (props.onOk) {
            await props.onOk(enteredPrice)
        }
    }

    const useCalculatedPrice = async () => {
        const validated = await trigger();

        if (validated) {
            if (props.onOk && Object.keys(errors).length === 0) {
                const value = getLinesTotalExVat();

                setEnteredPrice(value);

                await props.onOk(value)
            }
        }

    }

    const getLinesTotalExVat = () => {
        const calculationLines = getValues().calculationLines;

        if (calculationLines?.length > 0) {
            return roundNumber(calculationLines.map(line => getLineTotalExVat(line)).reduce((a, b) => a + b));
        }

        return 0;
    }

    return (
        <>
            <Row gutter={[5, 20]}>
                <>
                    <Col span={3} className={"text-ellipsis"}>
                        Varenr
                    </Col>
                    <Col span={8} className={"text-ellipsis"}>
                        Beskrivelse
                    </Col>
                    <Col span={2} className={"text-ellipsis"}>
                        Antal
                    </Col>
                    <Col span={2} className={"text-ellipsis"}>
                        Indkøbspris
                    </Col>
                    <Col span={2} className={"text-ellipsis"}>
                        Avance i %
                    </Col>
                    <Col span={2} className={"text-ellipsis"}>
                        Salgspris
                    </Col>
                    <Col span={2} className={"text-ellipsis"}>
                        Rabat i %
                    </Col>
                    <Col span={2} className={"text-ellipsis"}>
                        <FlexRow justify={"end"}>
                            Total
                        </FlexRow>
                    </Col>

                    {fields.map((line, index) => (
                        <PriceCalculatorLine line={line} index={index} remove={remove} key={line.idKey} formMethods={props.formMethods} />
                    ))}

                    <Col span={24}>
                        <StyledButton onClick={append}>+ Tilføj linje</StyledButton>
                    </Col>
                </>
            </Row>

            <hr/>

            <FlexRow justify={"end"} align={'end'} direction={"column"}>
                <PriceCalculatorTotal control={control} />
            </FlexRow>

            <FlexRow justify={"end"}>
                <StyledButton color={"primary"} onClick={useCalculatedPrice}>Brug beregnet pris</StyledButton>
            </FlexRow>

            <Divider orientation="right" plain>
                Eller indtast pris manuelt
            </Divider>

            <FlexRow justify={"end"} align={'end'} direction={"column"}>
                <div style={{width: '30%'}}>
                <FlexRow justify={"space-between"}>
                    <span>Subtotal</span>
                    <div className={"paper-input"}>
                        <input type={"number"} style={{direction: 'rtl'}} name={"price"} placeholder={"Manuel pris"} value={enteredPrice} onChange={(e) => setEnteredPrice(parseFloat(e.target.value))} />
                        <span>DKK</span>
                    </div>
                </FlexRow>
                <FlexRow justify={"space-between"}>
                    <span>Moms (25%)</span>
                    <span>{(getNumberAsCurrency(roundNumber(enteredPrice * 0.25)))} DKK</span>
                </FlexRow>
                <FlexRow justify={"space-between"}>
                    <span><b>I alt</b></span>
                    <span>{(getNumberAsCurrency(roundNumber(enteredPrice * 1.25)))} DKK</span>
                </FlexRow>
                <div style={{marginTop: 10}} >
                    <StyledButton color={"primary"} onClick={useEnteredPrice}>Brug indtastet pris</StyledButton>
                </div>
                </div>
            </FlexRow>
        </>
    )

}

export default PriceCalculator;
