import React from 'react';
import DefaultPageLayout from "Pages/Layouts/DefaultPageLayout";
import {getAdminBankDataPayoutsFile, getAdminBECPayoutsFile, useAdminPayouts} from "Apis/PayoutsApi";
import Spinner from "Components/Spinner";
import { Space, Table } from "antd";
import FlexRow from "Components/FlexRow";
import {PayoutModel} from "Apis/Models/PayoutModel";
import moment from "moment";
import {getNumberAsCurrency} from "Utils/NumberUtils";
import Payout from "Assets/Icons/Payout";
import StyledButton from "Components/StyledButton";
import {useIsLoading} from "Hooks/UseIsLoading";

const PayoutsPage = () => {

    const {data: payouts, isLoading} = useAdminPayouts();
    
    const [isDownloadingBankDataFile, downloadBankDataFile] = useIsLoading(() => getAdminBankDataPayoutsFile());
    const [isDownloadingERHFile, downloadERHFile] = useIsLoading(() => getAdminBECPayoutsFile());

    return (
        <DefaultPageLayout title={"Leverandørbetalinger"} icon={<Payout />}>
            <FlexRow justify={"end"}>
                <Space>
                    <StyledButton color={"primary"} disabled={isDownloadingBankDataFile || payouts?.length === 0} loading={isDownloadingBankDataFile} onClick={downloadBankDataFile} >Hent BankData betalingsfil</StyledButton>
                    <StyledButton color={"primary"} disabled={isDownloadingERHFile || payouts?.length === 0} loading={isDownloadingERHFile} onClick={downloadERHFile} >Hent ERH betalingsfil</StyledButton>
                </Space>
            </FlexRow>
            <Table
                size={"small"}
                rowKey={'id'}
                dataSource={payouts}
                loading={{indicator: <Spinner />, spinning: isLoading}}
                pagination={false}
            >
                <Table.Column title={"Firma"} dataIndex={"organizationName"} />
                <Table.Column title={"Overskrift"} dataIndex={"description"} />
                <Table.Column title={"Faktura nr"} dataIndex={"invoiceNumber"} />
                <Table.Column title={"Leveringsadresse"} dataIndex={"deliveryAddress"} render={(text, record: PayoutModel) => (
                    <FlexRow direction={"column"} justify={"start"} align={"start"}>
                        <span style={{fontSize: '.8em'}}>
                            {record.deliveryAddress.street} - {record.deliveryAddress.zipCode} - {record.deliveryAddress.city}
                        </span>
                    </FlexRow>
                ) } />
                <Table.Column title={"Beløb inkl moms"} dataIndex={"amount"} render={(text) => getNumberAsCurrency(text)} />
                <Table.Column title={"Betalt d."} dataIndex={"paidAt"} render={(text) => moment(text).format('DD/MM/YYYY HH:mm')} />
            </Table>
        </DefaultPageLayout>
    )
}

export default PayoutsPage
