import {Link, useRouteMatch} from "react-router-dom";
import FlexRow from "Components/FlexRow";
import React from "react";
import styles from './SubMenuLink.module.scss';

type Props = {
    to: string;
    children: any;
    onClick?: any;
    exact?: boolean
}
const SubMenuLink = ({to, children, onClick, exact}: Props) => {

    let match = useRouteMatch({
        path: to,
        exact: exact ?? false
    });

    return (
        <Link to={to} onClick={onClick}>
            <FlexRow justify={'center'} style={{paddingBottom: '.7em'}}>
                <div className={styles.subMenuLink}>
                    <div className={styles.subMenuLinkIcon}>
                        <svg height="26" width="26">
                            <circle cx="13" cy="13" r="4" stroke="#F39324" strokeWidth="2" fill={match ? "#F39324" : "white"} />
                        </svg>
                    </div>
                    <div className={styles.subMenuLinkContent} style={match ? {fontWeight: 600, color: '#F39324'} : {}}>
                        {children}
                    </div>
                </div>
            </FlexRow>
        </Link>
    )
}

export default SubMenuLink;
