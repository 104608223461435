import {queryCache, useMutation, useQuery} from "react-query";
import {request} from "Apis/BaseApi";
import {deserialize, OfferModel} from "Apis/Models/OfferModel";

export const offersForApprovalQueryCacheKey = 'offersForApproval';
export const useOffersForApproval = () => {
    return useQuery<OfferModel[]>(offersForApprovalQueryCacheKey, async () => {
        const response = await request<OfferModel[]>({
            url: `/admin/offers/readyForApproval`,
            method: 'GET'
        })

        return response.map(x => deserialize(x));
    })
}

export const offerAdminQueryCacheKey = 'offerAdmin';
export const useOffer = (id: number) => {
    return useQuery<OfferModel>([offerAdminQueryCacheKey, id], async () => {
        const response = await request<OfferModel>({
            url: `/admin/offers/${id}`,
            method: 'GET'
        })

        return deserialize(response);
    })
}

export const useApproveOffer = () => {
    return useMutation((id: number) => {
        return request<OfferModel>({
            url: `/admin/offers/approve/${id}`,
            method: 'POST'
        })
    }, {
        onSuccess: (offer: OfferModel) => {
            queryCache.invalidateQueries(offerAdminQueryCacheKey)
            queryCache.invalidateQueries(offersForApprovalQueryCacheKey)
        }
    })
}

export const useRejectOffer = () => {
    return useMutation(async ({id, reason}: any): Promise<OfferModel> => {
        return await request<OfferModel>({
            url: `/admin/offers/reject/${id}`,
            method: 'POST',
            data: {
                reason
            }
        })
    }, {
        onSuccess: (offer: OfferModel) => {
            queryCache.invalidateQueries(offerAdminQueryCacheKey)
            queryCache.invalidateQueries(offersForApprovalQueryCacheKey)
        }
    })
}
