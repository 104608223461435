import React, { useState } from 'react';
import { Space } from "antd";
import { useAdminDeleteNews, useAdminNews, useAdminSaveNews, useAdminSendNews } from "Apis/NewsAdminApi";
import StyledButton from "Components/StyledButton";
import { getInputClasses } from "Utils/CssUtils";
import FlexRow from "Components/FlexRow";
import Modal from "antd/es/modal";
import { useForm } from "react-hook-form";
import { NewsModel } from "Apis/Models/NewsModel";
import { openSuccessNotification } from "Utils/NotificationUtils";
import { getDateWithTime } from "Utils/DateUtils";
import PageTitle from "Components/PageTitle";
import Bullhorn from "Assets/Icons/Bullhorn";
import DefaultStyles from "Styles/Constants";
import Spinner from "Components/Spinner";

const NewsOverviewPage = () => {

    const { data: adminNews, isLoading } = useAdminNews();

    const [selectedNews, setSelectedNews] = useState<NewsModel>();
    const [showModal, setShowModal] = useState<boolean>(false);
    const { register, handleSubmit, reset, errors } = useForm<NewsModel>();

    const [saveNewsMutation, { isLoading: isSavingNews }] = useAdminSaveNews();
    const [sendNewsMutation, { isLoading: isSendingNews }] = useAdminSendNews();
    const [deleteNewsMutation, { isLoading: isDeletingNews }] = useAdminDeleteNews();

    const selectNews = (news: NewsModel) => {
        setSelectedNews(news);
        reset(news);

        setShowModal(true)
    }

    const saveNews = async (data: NewsModel) => {
        const news = {
            ...selectedNews,
            ...data,
            //her
        }

        const response = await saveNewsMutation(news);

        setSelectedNews(response)

        openSuccessNotification('Success', 'Nyheden blev gemt')
    }

    const saveAndSend = async (data: NewsModel) => {
        let news: NewsModel = {
            ...selectedNews,
            ...data,
        }

        const savedNews = await saveNewsMutation(news);

        setSelectedNews(savedNews)

        if (savedNews) {
            await sendNewsMutation(savedNews);

            openSuccessNotification('Success', 'Nyheden blev sendt')

            closeModal();
        }
    }

    const closeModal = () => {
        setSelectedNews(undefined)
        setShowModal(false)
        reset({ title: '', text: '' });
    }

    const deleteNews = async () => {
        await deleteNewsMutation(selectedNews);
        closeModal();
    }

    if (isLoading) {
        return (
            <>
                <PageTitle title={"Nyheder"} icon={<Bullhorn/>}/>
                <FlexRow justify={"center"}>
                    <Spinner/>
                </FlexRow>
            </>
        )
    }

    return (
        <>
            <PageTitle title={"Nyheder"} icon={<Bullhorn/>} fab={
                <StyledButton color={"primary"} onClick={() => setShowModal(true)}>
                    Opret nyhed
                </StyledButton>
            }/>
            {adminNews?.map(news => (
                <div key={news.id} style={{ ...styles.box, ...{ borderLeftWidth: 10, borderLeftStyle: 'solid', borderLeftColor: news.sentAt ? '#F39324' : '#989898' } }} onClick={() => selectNews(news)}>
                    <FlexRow justify={"space-between"}>
                        <h2>{news.title}</h2>
                        <span>{news.sentAt ? getDateWithTime(news.sentAt) : 'Ikke sendt'}</span>
                    </FlexRow>
                    <p className={'display-linebreak'}>{news.text}</p>
                </div>
            ))}
            <Modal visible={showModal} title={"Nyheder"}
                   onCancel={closeModal}
                   footer={false}
                   width={'50%'}
            >
                <form onSubmit={handleSubmit(saveNews)}>
                    <Space className={"w-100"} size={"large"} direction={"vertical"}>
                        <div>
                            <select className={getInputClasses(errors.audience)} name={"audience"} ref={register({ required: true })}>
                                <option value={"All"}>Alle</option>
                                <option value={"Sanitarian"}> Alle VVS</option>
                                <option value={"Electrician"}> Alle elektriker</option>
                                <option value={"Mine"}>Mine Firmaer</option>
                            </select>
                        </div>
                        <input type={"text"} placeholder={"Titel"} name="title" className={getInputClasses(errors.title)} ref={register({ required: true })}/>
                        <textarea rows={20} placeholder={"Nyhed"} name="text" className={getInputClasses(errors.text)} ref={register({ required: true })}/>
                        <FlexRow justify={"space-between"}>
                            <div>
                                {selectedNews?.id && (
                                    <StyledButton loading={isDeletingNews} color={'danger'} onClick={deleteNews}>Slet</StyledButton>
                                )}
                            </div>
                            <Space>
                                <StyledButton onClick={closeModal}>Afbryd</StyledButton>
                                <StyledButton loading={isSavingNews} type={"submit"}>Gem</StyledButton>
                                {!selectedNews?.sentAt && (
                                    <StyledButton loading={isSendingNews || isSavingNews} type={"button"} onClick={handleSubmit(saveAndSend)} color={"primary"}>Gem & Udgiv</StyledButton>
                                )}
                            </Space>
                        </FlexRow>
                    </Space>
                </form>
            </Modal>
        </>
    )
}

const styles = {
    box: {
        cursor: 'pointer',
        marginBottom: 20,
        backgroundColor: DefaultStyles.colors.white,
        borderRadius: 6,
        borderColor: DefaultStyles.colors.border,
        boxShadow: '0px 3px 20px rgba(0, 0, 0, .16)',
        padding: DefaultStyles.padding
    }
}

export default NewsOverviewPage
