import FlexRow from "Components/FlexRow";
import React from "react";
import styles from "Components/Document/DocumentLayout.module.scss";

interface Props {
    children?: any;
    showFooter?: boolean
}
const DocumentLayout = (props: Props) => {

    const showFooter = props.showFooter ?? true;

    return (
        <>
            <FlexRow justify={"center"} style={{width: '794px'}}>
                <div className={styles.paper} >
                    <div>
                        {props.children}
                    </div>
                    <FlexRow justify={"center"} direction={"column"} style={{paddingTop: 40}}>
                        {showFooter && (
                            <>
                                <div className={styles.footer}>
                                    <b>ELi A/S</b> - Mandal Alle 6 - 5500 Middelbart - Danmark - CVR:
                                    42976091
                                </div>
                                <div className={styles.footer}>
                                    Tlf: 93999783 - Email: kontakt@el-ias.dk - Hjemmeside: <a
                                    href="https://el-ias.dk">https://el-ias.dk</a>
                                </div>
                            </>
                        )}
                    </FlexRow>
                </div>
            </FlexRow>
        </>
    )
}

export default DocumentLayout;
