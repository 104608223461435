import {queryCache, useMutation, useQuery} from "react-query";
import {request} from "Apis/BaseApi";
import {AgreementModel} from "Apis/Models/AgreementModel";
import {OfferModel} from "Apis/Models/OfferModel";

export const publicAgreementCacheKey = 'publicAgreement';
export const usePublicAgreement = (organizationId: number, id: number) => {
    return useQuery<AgreementModel>([publicAgreementCacheKey, id], async () => {
        return await request<AgreementModel>({
            url: `/public/agreements/${organizationId}/${id}`,
            method: 'GET'
        })
    })
}

export const useApprovePublicAgreement = () => {
    return useMutation(async ({organizationId, agreementId}: any) => {
        return await request<OfferModel>({
            url: `/public/agreements/${organizationId}/${agreementId}/approve`,
            method: 'POST'
        })
    }, {
        onSuccess: () => {
            queryCache.invalidateQueries(publicAgreementCacheKey)
        }
    })
}

export const useRejectPublicAgreement = () => {
    return useMutation(async ({organizationId, agreementId, reason}: any) => {
        return await request<OfferModel>({
            url: `/public/agreements/${organizationId}/${agreementId}/reject`,
            method: 'POST',
            data: {
                reason
            }
        })
    }, {
        onSuccess: () => {
            queryCache.invalidateQueries(publicAgreementCacheKey)
        }
    })
}
