import React from "react";

const Label = ({children}) => {

    return (
        <label className={"text-ellipsis"}>
            {children}
        </label>
    )
}

export default Label;
