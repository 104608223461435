import * as React from "react"

function Add(props: React.SVGProps<SVGSVGElement>) {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" {...props}>
            <circle cx={25} cy={25} r={25} fill="#F39324" />
            <path
                fill="none"
                stroke="#fff"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                d="M25 13v25M37.5 25h-25"
            />
        </svg>
    )
}

export default Add;
