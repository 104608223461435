import React, {useContext} from "react";
import {UserContextProvider} from "Providers/UserProvider";
import {Redirect, Route} from "react-router-dom";

export default function AccessLevelRoute({requiredAccessLevel, children, ...rest}) {

    const userContext = useContext(UserContextProvider);

    if (userContext.loading) {
        return (
            <React.Fragment />
        )
    }

    if (requiredAccessLevel !== userContext.accessLevel) {
        return (
            <Redirect to={"/"} />
        )
    }

    return (
        <Route {...rest} render={() => children} />
    )
}
