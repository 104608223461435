import React, {useCallback,  useState} from 'react';
import DefaultPageLayout from "Pages/Layouts/DefaultPageLayout";
import FlexRow from "Components/FlexRow";
import {Table} from "antd";
import Spinner from "Components/Spinner";
import Pill from "Components/Pill";
import {useColleagues} from "Apis/ColleaguesApi";
import {ColleagueModel} from "Apis/Models/ColleagueModel";
import User from "Assets/Icons/User";
import Box from "../../Components/Box";
import _ from 'lodash';


const ColleaguesPage = () => {

    const [searchFilter, setSearchFilter] = useState<string>('')
    const {data: colleagues, isLoading} = useColleagues(searchFilter);

    const getOccupationColor = (colleauge: ColleagueModel) => {
        return colleauge.occupation === 'Electrician' ? 'Success' : 'Warning'
    }

    const getOccupationText = (colleage: ColleagueModel) => {
        return colleage.occupation === 'Electrician' ? 'Elektriker' : 'VVS\'er'
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const search = useCallback(_.debounce(async (newSearchFilter: string) => {
        setSearchFilter(newSearchFilter);
    }, 300), [])

    return (
        <DefaultPageLayout title={"Kolleager"} icon={<User />}
           beforeBox={
               <Box style={{marginBottom: 15, padding: 10}}>
                   <FlexRow>
                       <input onChange={e => search(e.target.value)} type="text" className={"input input-invisible full-width"} placeholder={"Søg...."}/>
                   </FlexRow>
               </Box>
           }
        >

            <Table
                size={"small"}
                rowKey={'id'}
                dataSource={colleagues}
                loading={{indicator: <Spinner />, spinning: isLoading}}
                pagination={false}
                rowClassName={'clickable'}
            >
                <Table.Column title={"Type"} dataIndex={"offerType"} render={(text, record: ColleagueModel) => (
                    <FlexRow justify={"start"}>
                        <Pill color={getOccupationColor(record)} />
                        <FlexRow align={"start"} justify={"start"} direction={"column"}>
                            <span>
                                {getOccupationText(record)}
                            </span>
                        </FlexRow>
                    </FlexRow>
                )} />

                <Table.Column title={"Navn"} dataIndex={"name"} render={(text) => (<b>{text}</b>)} />
                <Table.Column title={"Kontaktperson"} dataIndex={"contactName"} />
                <Table.Column title={"Telefon"} dataIndex={"contactPhone"} />
                <Table.Column title={"Email"} dataIndex={"contactEmail"} />

            </Table>
        </DefaultPageLayout>
    )
}

export default ColleaguesPage
