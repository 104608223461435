import {queryCache, useMutation, useQuery} from "react-query";
import {request} from "./BaseApi";
import {deserialize, TimeEntryModel} from "Apis/Models/TimeEntryModel";
import { saveAs } from 'file-saver';

export const timeEntriesCacheKey = 'timeEntries';
export const useTimeEntries = (orderId: number | undefined, enabled: boolean = true) => {
    return useQuery<TimeEntryModel[]>([timeEntriesCacheKey, orderId], async () => {
        const response =  await request<TimeEntryModel[]>({
            url: `/timeentries/${orderId}`,
            method: 'GET'
        })

        return response.map(x => deserialize(x))
    }, {
        enabled
    })
}

export const getTimeEntryPdf = async (orderId: number): Promise<any> => {
    const responseBlob = await request<Blob>({
        responseType: 'blob',
        url: `/timeentries/${orderId}/pdf`,
        method: 'GET',
    });

    const blob = new Blob([responseBlob], {type: 'application/pdf' });
    const fileName = `Timeregistrering.pdf`;
    saveAs(blob, fileName);
}

export const useSaveTimeEntry = () => {
    return useMutation(async ({orderId, entry}: any): Promise<TimeEntryModel> => {
        let response;

        if (entry.id) {
            response = await request<TimeEntryModel>({
                url: `/timeentries/${orderId}/${entry.id}`,
                method: 'PUT',
                data: entry
            })
        } else {
            response = await request<TimeEntryModel>({
                url: `/timeentries/${orderId}`,
                method: 'POST',
                data: entry
            })
        }

        return deserialize(response);
    }, {
        onSuccess: (entry: TimeEntryModel) => {
            queryCache.invalidateQueries(timeEntriesCacheKey)
            queryCache.invalidateQueries([timeEntriesCacheKey, entry.id])
        },
    })
}

export const useDeleteTimeEntry = () => {
    return useMutation(async ({orderId, entry}: any): Promise<TimeEntryModel> => {
        return await request<TimeEntryModel>({
            url: `/timeentries/${orderId}/${entry.id}`,
            method: 'DELETE',
        })
    }, {
        onSuccess: (entry: TimeEntryModel) => {
            queryCache.invalidateQueries(timeEntriesCacheKey)
            queryCache.invalidateQueries([timeEntriesCacheKey, entry.id])
        },
    })
}
