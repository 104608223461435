import DefaultPageLayout from "Pages/Layouts/DefaultPageLayout";
import React from "react";
import CustomerForm from "Pages/Projects/Customers/Forms/CustomerForm";
import { useParams } from "react-router-dom";
import {useCustomer} from "Apis/CustomerApi";
import User from "Assets/Icons/User";

const EditCustomerPage = () => {

    const { id } = useParams<{ id }>();

    const {data: customer, isLoading} = useCustomer(id);

    return (
        <DefaultPageLayout
            isLoading={isLoading}
            title={"Opdater kunde"}
            icon={<User />}
        >
            <CustomerForm customer={customer} />
        </DefaultPageLayout>
    )
}

export default EditCustomerPage;
