import React, {createContext, useContext, useEffect, useState} from "react";
import { onAuthStateChanged } from "FirebaseAuth";
import { User } from "firebase/auth";
import {useChangeOrganizationContext, useUserContext} from "Apis/UserApi";
import { useHistory } from "react-router-dom";
import {UserContextModel} from "Apis/Models/UserModel";
import Spinner from "Components/Spinner";

interface Props {
  firebaseUser: User | null,
  loading: boolean,
  accessLevel: 'Standard' | 'Admin',
  changeOrganizationContext: any,
  userContext: UserContextModel | undefined
}

export const UserContextProvider = createContext<Props>({
  firebaseUser: null,
  loading: true,
  accessLevel: 'Standard',
  changeOrganizationContext: null,
  userContext: undefined
});

const UserProvider = ({children}) => {
  const history = useHistory();

  const [firebaseUser, setFirebaseUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [accessLevel, setAccessLevel] = useState<'Standard' | 'Admin'>('Standard');

  const {data: userContext, refetch, isLoading: isLoadingUserContext} = useUserContext(!!firebaseUser);
  const [changeContextMutation] = useChangeOrganizationContext();

  const changeOrganizationContext = async (id: number) => {
    setLoading(true);

    await changeContextMutation(id);
    history.push('/')

    setLoading(false)
  }

  useEffect(() => {
    if (userContext && userContext.accessLevel !== 'Admin' && !userContext.lastOrganizationId) {
      history.push('/404')
    }
  }, [history, userContext])

  useEffect(() => {
    onAuthStateChanged(userAuth => {

      if (userAuth) {

        userAuth?.getIdTokenResult().then(s => {
          // Must be uppercase Claim name
          setAccessLevel(s.claims.AccessLevel === "Admin" ? 'Admin' : 'Standard');
          setFirebaseUser(userAuth);
          setLoading(false);
          refetch();
        })

      } else {
        setFirebaseUser(userAuth);
        setLoading(false);
      }
    });
  }, [refetch])

  if (loading || isLoadingUserContext) {
    return (
        <div style={{width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <Spinner size="large" />
        </div>
    )
  }

  return (
      <UserContextProvider.Provider value={{firebaseUser, loading, accessLevel, changeOrganizationContext, userContext}}>
        {children}
      </UserContextProvider.Provider>
  );
}

export default UserProvider;

export const useUserProvider = () => {
  return useContext(UserContextProvider);
}
