import {useQuery} from "react-query";
import {request} from "Apis/BaseApi";

export const defaultOfferTextCacheKey = 'defaultOfferText';
export const useDefaultOfferText = (organizationId: number, enabled: boolean = true) => {
    return useQuery<string>([defaultOfferTextCacheKey, organizationId], async () => {
        return await request<string>({
            url: `/texts/offer/${organizationId}`,
            method: 'GET'
        })
    }, {
        enabled
    })
}

export const defaultInvoiceTextCacheKey = 'defaultInvoiceText';
export const useDefaultInvoiceText = (organizationId: number, enabled: boolean = true) => {
    return useQuery<string>([defaultInvoiceTextCacheKey, organizationId], async () => {
        return await request<string>({
            url: `/texts/invoice/${organizationId}`,
            method: 'GET'
        })
    }, {
        enabled
    })
}
