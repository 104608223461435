import PageTitle from "Components/PageTitle";
import Box from "Components/Box";
import FlexRow from "Components/FlexRow";
import Spinner from "Components/Spinner";
import React from "react";

interface Props {
    isLoading?: boolean;
    icon?: any;
    title: string;
    fab?: any;
    children?: any;
    beforeBox?: any;
}
const DefaultPageLayout = ({isLoading, icon, title, fab, beforeBox, children}: Props) => {

    if (isLoading) {
        return (
            <div>
                <PageTitle title={title} icon={icon} fab={fab} />
                <Box>
                    <FlexRow justify={'center'}>
                        <Spinner size={"large"} />
                    </FlexRow>
                </Box>
            </div>
        )
    } else {
        return (
            <div>
                <PageTitle title={title} icon={icon} fab={fab} />
                {beforeBox && beforeBox}
                <Box>
                    {children}
                </Box>
            </div>
        )
    }
}

export default DefaultPageLayout;
