import { queryCache, useMutation, useQuery } from "react-query";
import { request } from "Apis/BaseApi";
import { OrganizationContributionModel, OrganizationContributionReadModel } from "Apis/Admin/OrganizationContributions/Models/OrganizationContributionModel";

export const CONTRIBUTIONS_FOR_ORGANIZATION_CACHE_KEY = 'CONTRIBUTIONS_FOR_ORGANIZATION_CACHE_KEY';
export const useOrganizationContributions = (organizationId: number) => {
    return useQuery<OrganizationContributionReadModel[]>(CONTRIBUTIONS_FOR_ORGANIZATION_CACHE_KEY, async () => {
        return await request<OrganizationContributionReadModel[]>({
            url: `/admin/contributions/${organizationId}`,
            method: 'GET'
        })
    })
}

export const useSaveOrganizationContributions = () => {
    return useMutation(async (model: OrganizationContributionModel) => {
        return await request({
            url: `/admin/contributions`,
            method: 'POST',
            data: model
        })
    }, {
        onSuccess: () => {
            queryCache.invalidateQueries(CONTRIBUTIONS_FOR_ORGANIZATION_CACHE_KEY)
        }
    })
}

export const useDeleteOrganizationContributions = () => {
    return useMutation(async (model: OrganizationContributionReadModel) => {
        return await request({
            url: `/admin/contributions/${model.id}`,
            method: 'DELETE',
        })
    }, {
        onSuccess: () => {
            queryCache.invalidateQueries(CONTRIBUTIONS_FOR_ORGANIZATION_CACHE_KEY)
        }
    })
}
