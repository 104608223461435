import React from "react"
import styles from "./QaField.module.scss";

interface Props {
    value?: string;
    possibleValues?: string[];
    text?: string;
}
const QaFieldRadioTemplate = ({value, possibleValues, text}: Props) => {
    
    return (
        <div className={`${styles.answer} ${styles.answerRadio}`}>
            <span>{text}</span>
            <div>
                {possibleValues?.map(x => (
                    <div className={`${styles.answerRadioOption}`}>
                        <div className={`${styles.answerRadioBox}`}>{x === value ? 'X' : ''}</div>
                        <span>{x}</span>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default QaFieldRadioTemplate
