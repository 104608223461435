import {useHistory} from "react-router-dom";
import {useCustomers} from "Apis/CustomerApi";
import DefaultPageLayout from "Pages/Layouts/DefaultPageLayout";
import ShoppingList from "Assets/Icons/ShoppingList";
import FlexRow from "Components/FlexRow";
import {Table} from "antd";
import StyledButton from "Components/StyledButton";
import Spinner from "Components/Spinner";
import React, {useCallback, useState} from "react";
import {useOrders} from "Apis/OrderApi";
import {OrderModel} from "Apis/Models/OrderModel";
import Box from "Components/Box";
import _ from "lodash";
import Pill from "Components/Pill";
import {useUserProvider} from "../../../Providers/UserProvider";

const OrdersOverviewPage = () => {
    const history = useHistory();

    const { userContext } = useUserProvider();

    const [searchFilter, setSearchFilter] = useState<string>('')
    const [userId, setUserId] = useState<string>('')
    const [includeFinished, setIncludeFinished] = useState<string>('')

    const {data: offers, isLoading} = useOrders(searchFilter, includeFinished, userId);
    const {data: customers, isLoading: isLoadingCustomers} = useCustomers();
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const search = useCallback(_.debounce(async (searchFilter: string) => {
        setSearchFilter(searchFilter)
    }, 300), [])

    return (
        <DefaultPageLayout
            title={"Ordre"}
            icon={<ShoppingList />}
            beforeBox={
                <Box style={{marginBottom: 15, padding: 10}}>
                    <FlexRow>
                        <input onChange={e => search(e.target.value)} type="text"
                               className={"input input-invisible full-width"} placeholder={"Søg...."}/>

                        <select placeholder={"Vælg medarbejder"} onChange={e => setUserId((e.target.value))} style={{paddingRight: 20, marginRight: 20}}>
                            <option value="">Alle medarbejdere</option>
                            {userContext!.users!.map((user) => (
                                <option value={user.id}>{user.name}</option>
                            ))}
                        </select>

                        <select placeholder={"Vælg status"} onChange={e => setIncludeFinished((e.target.value))}
                                style={{paddingRight: 20, marginRight: 20}}>
                            <option value="false">Åbne</option>
                            <option value="true">Alle</option>
                        </select>
                    </FlexRow>
                </Box>
            }
            fab={
                <StyledButton color={'primary'} onClick={() => history.push('/projects/orders/create')}>
                    Opret ordre
                </StyledButton>
            }>
            <Table
                size={"small"}
                rowKey={'id'}
                dataSource={offers}
                loading={{indicator: <Spinner/>, spinning: isLoading || isLoadingCustomers}}
                pagination={false}
                onRow={(record) => {
                    return {
                        onClick: () => history.push(`/projects/orders/show/${record.id}`),
                    };}
                }
                rowClassName={'clickable'}
            >
                <Table.Column title={"Ordrenummer"} dataIndex={"orderNumber"} render={(text, record: OrderModel) => (
                    <FlexRow justify={"start"}>
                        <Pill color={record.finishedAt === null ? 'Success' : 'Danger'} />
                        {text}
                    </FlexRow>
                )} />

                <Table.Column title={"Overskrift"} dataIndex={"title"} />

                <Table.Column title={"Kunde"} dataIndex={"customerId"} render={(text, record: OrderModel) => (
                    <FlexRow direction={"column"} justify={"start"} align={"start"}>
                        <span style={{fontWeight: 600}}>
                            {customers?.find(x => x.id === record.customerId)!?.name}
                        </span>
                        <span style={{fontSize: '.8em'}}>
                            {record.invoicingAddress.street} - {record.invoicingAddress.zipCode} - {record.invoicingAddress.city}
                        </span>
                    </FlexRow>
                ) } />

                <Table.Column title={"Installationsadresse"} dataIndex={"deliveryAddress"} render={(text, record: OrderModel) => (
                    <FlexRow direction={"column"} justify={"start"} align={"start"}>
                        {record.deliveryAddress.street}
                        <span style={{fontSize: '.8em'}}>
                            {record.deliveryAddress.zipCode} - {record.deliveryAddress.city}
                        </span>
                    </FlexRow>
                ) } />

                <Table.Column title={"Ansvarlig"} dataIndex={"responsibleId"} render={(text, record: OrderModel) => (
                    <FlexRow direction={"column"} justify={"start"} align={"start"}>
                        {userContext!.users?.find(x => x.id === record.responsibleId)?.name}
                    </FlexRow>
                ) } />

            </Table>
        </DefaultPageLayout>
    )
}

export default OrdersOverviewPage;
