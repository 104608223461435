import React from 'react';
import {useWatch} from "react-hook-form";
import {getNumberAsCurrency, roundNumber} from "Utils/NumberUtils";
import FlexRow from "Components/FlexRow";

const PriceCalculatorTotal = ({control}) => {

    const lines = useWatch<any[]>({
        control,
        name: `calculationLines`
    })

    const getLineTotalExVat = (amount = 0, salesPrice  = 0 , discount = 0) => {
        return roundNumber(amount * (salesPrice * (1 - (discount / 100))))
    }

    const total = lines && lines.length > 0 ? lines?.map(line => getLineTotalExVat(line.amount, line.salesPrice, line.discount)).reduce((a, b) => a + b) : 0;

    return (
        <div style={{width: '30%'}}>
            <FlexRow justify={"space-between"}>
                <span>Subtotal</span>
                <span>{getNumberAsCurrency(roundNumber(total))} DKK</span>
            </FlexRow>
            <FlexRow justify={"space-between"}>
                <span>Moms (25%)</span>
                <span>{(getNumberAsCurrency(roundNumber(total * 0.25)))} DKK</span>
            </FlexRow>
            <FlexRow justify={"space-between"}>
                <span><b>I alt</b></span>
                <span>{(getNumberAsCurrency(roundNumber(total * 1.25)))} DKK</span>
            </FlexRow>
        </div>
    )
}

export default PriceCalculatorTotal
