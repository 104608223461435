import Ribbon from "Components/Ribbon";
import {
    getInvoiceTypeAsString,
    getLinesTotalExVat,
    getLineTotalExVat,
    getInvoiceStateAsString,
    getInvoiceStateColor
} from "Utils/InvoiceUtils";
import FlexRow from "Components/FlexRow";
import {Col, Row} from "antd";
import styles from "Pages/Projects/Offers/OfferForm.module.scss";
import {getNumberAsCurrency} from "Utils/NumberUtils";
import React from "react";
import {InvoiceModel} from "Apis/Models/InvoiceModel";
import moment from "moment";
import {useDefaultInvoiceText} from "Apis/TextApi";

interface Props {
    invoice: InvoiceModel,
}
const InvoiceDetails = ({invoice}: Props) => {

    const {data: defaultInvoiceText } = useDefaultInvoiceText(invoice?.organizationId!, !!invoice)

    return (
        <>
            <Ribbon text={getInvoiceStateAsString(invoice)} color={getInvoiceStateColor(invoice)}  />
            <FlexRow justify={"space-between"}>

                <div style={{width: '40%', fontSize: '.9em'}}>
                    <Row gutter={[5, 5]}>
                        <Col span={24}>
                            <b>
                                {invoice?.invoicingAddress.customerName}
                            </b>
                        </Col>
                    </Row>

                    <span>{invoice?.invoicingAddress.street}</span>

                    <Row gutter={5}>
                        <Col span={24}>
                            <span>{invoice?.invoicingAddress.zipCode}</span> <span>{invoice?.invoicingAddress.city}</span>
                        </Col>
                        <Col span={24}>
                            <span>CVR: {invoice?.invoicingAddress.vatNumber}</span>
                        </Col>
                        <Col span={24}>
                            <span>ATT: {invoice?.invoicingAddress.att}</span>
                        </Col>
                        <Col span={24}>
                            <span>Email: {invoice?.invoicingAddress.email}</span>
                        </Col>
                        <Col span={24}>
                            <span>EAN: {invoice?.invoicingAddress.ean}</span>
                        </Col>
                    </Row>
                </div>

                <div>
                    <img width={250} src="/images/Logo.png" alt="ELi A/S Badge"/>
                </div>
            </FlexRow>

            <div style={{width: '40%', fontSize: '.9em', padding: '10px 0'}}>
                <FlexRow justify={"space-between"}>
                    <b>Installationsadresse</b>
                </FlexRow>
                <span>{invoice?.deliveryAddress.street}</span>

                <Row gutter={5}>
                    <Col span={24}>
                        <span>{invoice?.deliveryAddress.zipCode}</span> <span>{invoice?.deliveryAddress.city}</span>
                    </Col>
                </Row>
            </div>

            <div style={{display: 'flex', justifyContent: 'space-between'}} >
                <div style={{width: '55%', minHeight: '170px'}}>
                    <div style={{height: '20px'}} />
                    <hr className={styles.seperator}/>
                    <div style={{fontWeight: 600}}>
                        {invoice.title}
                    </div>
                    <div className={styles.defaultTextContainer}>
                        {defaultInvoiceText}
                    </div>
                </div>

                <div className={"flex column"} style={{minWidth: '40%', minHeight: '170px'}}>
                    <b style={{height: '20px', textTransform: 'uppercase'}}>
                        {getInvoiceTypeAsString(invoice.invoiceType)}
                    </b>
                    <hr className={styles.seperator}/>
                    <FlexRow justify={"space-between"}>
                        Dato
                        <span>
                            {moment(invoice.date).format('DD.MM.YYYY')}
                        </span>
                    </FlexRow>
                    <FlexRow justify={"space-between"}>
                        Forfaldsdato
                        <span>
                            {moment(invoice.dueDate).format('DD.MM.YYYY')}
                        </span>
                    </FlexRow>
                    <FlexRow justify={"space-between"}>
                        Rekvistionsnr:
                        <span>
                            {invoice.requisitionNumber}
                        </span>
                    </FlexRow>
                </div>
            </div>

            <hr className={styles.seperator}/>

            <Row gutter={10}>
                <Col span={12}>Tekst</Col>
                <Col span={3}>Antal</Col>
                <Col span={3}>Stk. pris</Col>
                <Col span={2}>Rabat</Col>
                <Col span={4}>
                    <span style={{display: 'flex', justifyContent: 'flex-end'}}>
                        Pris
                    </span>
                </Col>
            </Row>

            <hr className={styles.seperator}/>

            <div className={styles.descriptionContainer}>
                <Row gutter={[10, 10]} align={'middle'}>
                    {invoice.lines.map(line => (
                        <React.Fragment key={line.id}>
                            <Col span={12}>
                                {line.text}
                            </Col>
                            <Col span={3}>
                                {line.amount}
                            </Col>
                            <Col span={3}>
                                {getNumberAsCurrency(line.unitSalesPrice)}
                            </Col>
                            <Col span={2}>
                                {`${line.discount} %`}
                            </Col>
                            <Col span={4}>
                                <span style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    {getNumberAsCurrency(getLineTotalExVat(line.amount, line.unitSalesPrice, line.discount))}
                                </span>
                            </Col>
                        </React.Fragment>
                    ))}
                </Row>
            </div>

            <hr className={styles.seperator}/>

            <FlexRow justify={"end"} align={'end'} direction={"column"}>
                <div style={{width: '30%'}}>
                    <FlexRow justify={"space-between"}>
                        <span>Subtotal</span>
                        <span>{(getNumberAsCurrency(getLinesTotalExVat(invoice.lines)))} DKK</span>
                    </FlexRow>
                    <FlexRow justify={"space-between"}>
                        <span>Moms</span>
                        <span>{(getNumberAsCurrency(getLinesTotalExVat(invoice.lines) * 0.25))} DKK</span>
                    </FlexRow>
                    <FlexRow justify={"space-between"}>
                        <span><b>I alt</b></span>
                        <span>{(getNumberAsCurrency(getLinesTotalExVat(invoice.lines) * 1.25))} DKK</span>
                    </FlexRow>
                </div>
            </FlexRow>

        </>
    )
}

export default InvoiceDetails;
