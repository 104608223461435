import {useQuery} from "react-query";
import {request} from "Apis/BaseApi";
import {DashboardOverviewModel} from "Apis/Models/DashboardOverviewModel";
import {TimeEntryModel} from "./Models/TimeEntryModel";

export const dashboardCacheKey = "dashboard";
export const useDashboardOverview = (userId: string | undefined) => {
    return useQuery([dashboardCacheKey, userId], async () => {
        return request<DashboardOverviewModel[]>({
            url: `/dashboard?userId=${userId ?? ''}`,
            method: 'GET'
        })
    })
}


export const DASHBOARD_TIME_ENTRIES_CACHE_KEY = "DASHBOARD_TIME_ENTRIES_CACHE_KEY";
export const useDashboardTimeEntries = (userId: string | undefined, fromDate: Date, toDate: Date) => {
    return useQuery([DASHBOARD_TIME_ENTRIES_CACHE_KEY, fromDate, toDate, userId], async () => {
        return request<TimeEntryModel[]>({
            url: `/dashboard/timeEntries?userId=${userId ?? ''}&fromDate=${fromDate.toISOString()}&toDate=${toDate.toISOString()}`,
            method: 'GET'
        })
    })
}
