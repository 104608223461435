import React, {useEffect} from "react";
import Building from "Assets/Icons/Building";
import {useHistory, useParams} from "react-router-dom";
import {
    useCreateOrganizationFromSupplierApplication, useDeleteSupplierApplication,
    useSendSupplierApplicationMail,
    useSupplierApplication, useUpdateSupplierApplication
} from "Apis/Public/SupplierApplications/PublicSupplierApplicationApi";
import { Col, Dropdown, Popconfirm, Row, Space, Upload } from "antd";
import GroupLabel from "Components/GroupLabel";
import Label from "Components/Label";
import {getInputClasses} from "Utils/CssUtils";
import FlexRow from "Components/FlexRow";
import StyledButton from "Components/StyledButton";
import {useForm} from "react-hook-form";
import {FileType, SupplierApplicationModel} from "Apis/Public/SupplierApplications/Models/SupplierApplicationModel";
import {useAddressSuggestionMenu} from "Hooks/UseAddressSuggestions";
import {DawaAutocompleteModel} from "Apis/Models/DawaAutocompleteModel";
import _ from 'lodash';
import styles from "Pages/Public/NewSupplier/NewSupplierPage.module.scss";
import {base64toFile, toBase64} from "Utils/Base64Utils";
import {openSuccessNotification} from "Utils/NotificationUtils";
import PageTitle from "Components/PageTitle";
import Box from "Components/Box";

const EditSupplierApplicationPage = () => {

    const {id} = useParams<{ id }>();
    const history = useHistory();

    const {data: application} = useSupplierApplication(id);
    const [updateApplicationMutation, {isLoading: isUpdatingApplication}] = useUpdateSupplierApplication()
    const [sendApplicationMailMutation, {isLoading: isSendingApplicationMail}] = useSendSupplierApplicationMail()
    const [createOrganizationMutation, {isLoading: isCreatingOrganization}] = useCreateOrganizationFromSupplierApplication()
    const [deleteApplicationMutation, {isLoading: isDeletingApplication}] = useDeleteSupplierApplication()

    const {register, handleSubmit, errors, reset, setValue, trigger} = useForm<SupplierApplicationModel>({mode: 'all'});
    
    const [addressSuggestions, addressSuggestionsMenu, searchAddress, clearAddressSuggestions] = useAddressSuggestionMenu((address: DawaAutocompleteModel) => {
        setValue('address.street', `${address.adresse.vejnavn} ${address.adresse.husnr} ${address.adresse.etage ?? ''} ${address.adresse.dør ?? ''}`);
        setValue('address.city', address.adresse.postnrnavn)
        setValue('address.zipCode', address.adresse.postnr)
        trigger();
    })

    const [companyAddressSuggestions, companyAddressSuggestionsMenu, companySearchAddress, companyClearAddressSuggestions] = useAddressSuggestionMenu((address: DawaAutocompleteModel) => {
        setValue('companyAddress.street', `${address.adresse.vejnavn} ${address.adresse.husnr} ${address.adresse.etage ?? ''} ${address.adresse.dør ?? ''}`);
        setValue('companyAddress.city', address.adresse.postnrnavn)
        setValue('companyAddress.zipCode', address.adresse.postnr)
        trigger();
    })

    useEffect(() => {
        if (application) {
            reset(application);
        }
    }, [application, reset])

    const sendApplicationMail = async (mailType: 'Accountant' | 'Lawyer') => {
        await sendApplicationMailMutation({id: application!.id, mailType: mailType});

        openSuccessNotification('Success', 'Mailen blev sendt');
    }

    const createOrganization = async () => {
        const response = await createOrganizationMutation(application!.id);

        if (!response) {
            openSuccessNotification('Der skete en fejl', '');
            return;
        }

        openSuccessNotification('Success', 'Bruger og firma blev oprettet');
        history.push(`/admin/organizations/edit/${response.id}`);
    }
    
    const deleteApplication = async () => {
        const response = await deleteApplicationMutation(application);
        
        if (!response) {
            openSuccessNotification('Der skete en fejl', '');
            return;
        }
        
        openSuccessNotification('Success', 'Ansøgningen blev slettet');
        history.push(`/admin/supplierApplications`);
    }

    const updateApplication = async (data: SupplierApplicationModel) => {

        data = {
            ...application,
            ...data
        }

        await updateApplicationMutation(data);

        openSuccessNotification('Success', 'Ansøgningen blev opdateret');
    }

    const getApplicationFile = async (fileType: FileType) => {

        switch (fileType) {
            case "CriminalRecord":
                await base64toFile(application!.criminalRecordBase64, `${application?.firstName} ${application?.lastName} - Straffeattest`);
                break;
            case "DriversLicense":
                await base64toFile(application!.driversLicenseBase64, `${application?.firstName} ${application?.lastName} - Kørekort`);
                break;
            case "EducationCertificate":
                await base64toFile(application!.educationCertificateBase64, `${application?.firstName} ${application?.lastName} - Svendebrev`);
                break;
            case "HealthInsuranceCard":
                await base64toFile(application!.healthInsuranceCardBase64, `${application?.firstName} ${application?.lastName} - Sygesikring`);
                break;
            case "ProfilePicture":
                await base64toFile(application!.profilePictureBase64, `${application?.firstName} ${application?.lastName} - Profilebillede`);
                break;
        }
    }

    const getFileField = (fileType: FileType, text: string, fieldName: string) => {
        return (
            <Row gutter={[20, 20]}>
                <Col span={20}>

                    <input type="text" style={{display: 'none'}} ref={register} name={fieldName}/>
                    <Upload
                        className={styles.upload}
                        listType={"picture"}
                        multiple={false}
                        showUploadList={false}
                        accept={"image/*,.pdf"}
                        beforeUpload={(file) => {
                            toBase64(file, true).then(base64 => {
                                setValue(fieldName, base64);
                            });

                            return false;
                        }}
                    >
                        <FlexRow justify={"space-between"}>
                            <GroupLabel>
                                {text}
                            </GroupLabel>

                            <StyledButton>
                                Upload ny fil
                            </StyledButton>
                        </FlexRow>
                    </Upload>
                </Col>
                <Col span={4}>
                    <StyledButton block color={"primary"} onClick={() => getApplicationFile(fileType)}>
                        Download fil
                    </StyledButton>
                </Col>
            </Row>
        )
    }

    return (
        <>
            <PageTitle title={"Ansøgning"} icon={<Building/>}/>
            <form onSubmit={handleSubmit(updateApplication)}>
                <Space className={"w-100"} direction={"vertical"} size={"large"}>
                    <Box>
                        <GroupLabel>Handlinger</GroupLabel>
                        <hr/>
                        <FlexRow justify={"space-between"} align={"center"}>
                            <FlexRow direction={"column"} align={"start"}>
                                <GroupLabel>Send mail til advokaten</GroupLabel>
                                <span>Sender en mail til advokaten om omrettelse af ny underleverandør</span>
                            </FlexRow>
                            <StyledButton disabled={isSendingApplicationMail} loading={isSendingApplicationMail} onClick={() => sendApplicationMail('Lawyer')}>Send Mail</StyledButton>
                        </FlexRow>
                        <hr/>
                        <FlexRow justify={"space-between"} align={"center"}>
                            <FlexRow direction={"column"} align={"start"}>
                                <GroupLabel>Send mail til revisoren</GroupLabel>
                                <span>Sender en mail til revisoren om omrettelse af ny underleverandør</span>
                            </FlexRow>
                            <StyledButton disabled={isSendingApplicationMail} loading={isSendingApplicationMail} onClick={() => sendApplicationMail('Accountant')}>Send Mail</StyledButton>
                        </FlexRow>
                        <hr/>
                        <FlexRow justify={"space-between"} align={"center"}>
                            <FlexRow direction={"column"} align={"start"}>
                                <GroupLabel>Opret bruger og firma i ASAP'en</GroupLabel>
                                <span>Opretter en bruger og firma i ASAP'en til {application?.firstName} så han er klar til at gå i gang. Det sender en velkomst mail til {application?.firstName} om hvordan han kommer i gang med ASAP'en. Skal først gøres når leverandøren er helt klar og har modtaget sit CVR nummer</span>
                            </FlexRow>
                            <StyledButton disabled={isCreatingOrganization} loading={isCreatingOrganization} onClick={createOrganization}>Opret bruger og firma</StyledButton>
                        </FlexRow>
                    </Box>
                    <Box>
                        <Row gutter={[20, 20]}>

                            <Col span={24}>
                                <GroupLabel>Personlige oplysninger</GroupLabel>
                            </Col>
                            <Col span={12}>
                                <div>
                                    <Label>Fornavn</Label>
                                    <input className={getInputClasses(_.get(errors, 'firstName'))} name={"firstName"}
                                           ref={register({required: true})}/>
                                </div>
                            </Col>

                            <Col span={12}>
                                <div>
                                    <Label>Efternavn</Label>
                                    <input className={getInputClasses(_.get(errors, 'lastName'))} name={"lastName"}
                                           ref={register({required: true})}/>
                                </div>
                            </Col>

                            <Col span={12}>
                                <div>
                                    <Label>Telefon</Label>
                                    <input className={getInputClasses(_.get(errors, 'phone'))} name={"phone"}
                                           ref={register({required: true})}/>
                                </div>
                            </Col>

                            <Col span={12}>
                                <div>
                                    <Label>E-mail</Label>
                                    <input className={getInputClasses(_.get(errors, 'email'))} name={"email"}
                                           ref={register({required: true})}/>
                                </div>
                            </Col>

                            <Col span={24}>
                                <div>
                                    <Label>Adresse</Label>
                                    <Dropdown visible={addressSuggestions.length > 0} overlay={addressSuggestionsMenu}>
                                        <input onChange={e => searchAddress(e.target.value)}
                                               onBlur={() => setTimeout(() => clearAddressSuggestions(), 100)}
                                               className={getInputClasses(_.get(errors, 'address.street'))}
                                               name={"address.street"} ref={register({required: true})}/>
                                    </Dropdown>
                                </div>
                            </Col>

                            <Col span={12}>
                                <div>
                                    <Label>Postnr</Label>
                                    <input className={getInputClasses(_.get(errors, 'address.zipCode'))}
                                           name={"address.zipCode"} ref={register({required: true})}/>
                                </div>
                            </Col>

                            <Col span={12}>
                                <div>
                                    <Label>By</Label>
                                    <input className={getInputClasses(_.get(errors, 'address.city'))} name={"address.city"}
                                           ref={register({required: true})}/>
                                </div>
                            </Col>

                            <Col span={24}>
                                <hr/>
                                <GroupLabel>Firma oplysninger</GroupLabel>
                            </Col>

                            <Col span={24}>
                                <Label>
                                    Beskæftigelse
                                </Label>
                                <select className={getInputClasses(errors.occupation)} name={"occupation"}
                                        ref={register({required: true})}>
                                    <option value={""}>Vælg</option>
                                    <option value={"Electrician"}>Elektriker</option>
                                    <option value={"Sanitarian"}>VVS</option>
                                </select>
                            </Col>

                            <Col span={24}>
                                <div>
                                    <Label>Eksisterende CVR nummer</Label>
                                    <input placeholder={"Udfyldes kun hvis du har et eksisterende CVR nummer, du ønsker at bruge sammen med ELi A/S"}
                                           className={getInputClasses(_.get(errors, 'companyVatNumber'))}
                                           name={"companyVatNumber"} ref={register({required: false})}/>
                                </div>
                            </Col>

                            <Col span={24}>
                                <div>
                                    <Label>Firmanavn</Label>
                                    <input placeholder={"Hvad skal dit firma hedde?"}
                                           className={getInputClasses(_.get(errors, 'companyName'))} name={"companyName"}
                                           ref={register({required: true})}/>
                                </div>
                            </Col>

                            <Col span={12}>
                                <div>
                                    <Label>Firmatelefon</Label>
                                    <input className={getInputClasses(_.get(errors, 'companyPhone'))} name={"companyPhone"}
                                           ref={register({required: true})}/>
                                </div>
                            </Col>

                            <Col span={12}>
                                <div>
                                    <Label>Firma e-mail</Label>
                                    <input className={getInputClasses(_.get(errors, 'companyEmail'))} name={"companyEmail"}
                                           ref={register({required: true})}/>
                                </div>
                            </Col>

                            <Col span={24}>
                                <div>
                                    <Label>Firma adresse</Label>
                                    <Dropdown visible={companyAddressSuggestions.length > 0}
                                              overlay={companyAddressSuggestionsMenu}>
                                        <input onChange={e => companySearchAddress(e.target.value)}
                                               onBlur={() => setTimeout(() => companyClearAddressSuggestions(), 100)}
                                               className={getInputClasses(_.get(errors, 'companyAddress.street'))}
                                               name={"companyAddress.street"} ref={register({required: true})}/>
                                    </Dropdown>
                                </div>
                            </Col>

                            <Col span={12}>
                                <div>
                                    <Label>Postnr</Label>
                                    <input className={getInputClasses(_.get(errors, 'companyAddress.zipCode'))}
                                           name={"companyAddress.zipCode"} ref={register({required: true})}/>
                                </div>
                            </Col>

                            <Col span={12}>
                                <div>
                                    <Label>By</Label>
                                    <input className={getInputClasses(_.get(errors, 'companyAddress.city'))}
                                           name={"companyAddress.city"} ref={register({required: true})}/>
                                </div>
                            </Col>

                            <Col span={24}>
                                <div>
                                    <Label>Firmabeskrivelse</Label>
                                    <textarea className={getInputClasses(_.get(errors, 'companyDescription'))}
                                              name={"companyDescription"} ref={register({required: true})}/>
                                </div>
                            </Col>

                        </Row>
                    </Box>
                    <Box>
                        <Row gutter={[20, 20]}>

                            <Col span={24}>
                                <GroupLabel>Filer</GroupLabel>
                            </Col>

                            <Col span={24}>
                                {getFileField('ProfilePicture', 'Profilbillede', 'profilePictureBase64')}
                                <hr/>
                            </Col>

                            <Col span={24}>
                                {getFileField('DriversLicense', 'Kørekort', 'driversLicenseBase64')}
                                <hr/>
                            </Col>

                            <Col span={24}>
                                {getFileField('HealthInsuranceCard', 'Sygesikringskort', 'healthInsuranceCardBase64')}
                                <hr/>
                            </Col>

                            <Col span={24}>
                                {getFileField('EducationCertificate', 'Svendebrev', 'educationCertificateBase64')}
                                <hr/>
                            </Col>

                            <Col span={24}>
                                {getFileField('CriminalRecord', 'Straffeattest', 'criminalRecordBase64')}
                            </Col>
                        </Row>

                        <FlexRow justify={"space-between"}>
                            <Popconfirm title={"Er du sikker på at du vil slette ansøgningen?"} onConfirm={deleteApplication} okText={"Slet ansøgning"}>
                                <StyledButton color="danger" loading={isDeletingApplication} disabled={isDeletingApplication}>Slet ansøgning</StyledButton>
                            </Popconfirm>
                            <StyledButton color="primary" type={"submit"} loading={isUpdatingApplication} disabled={isUpdatingApplication}>Gem</StyledButton>
                        </FlexRow>
                    </Box>
                </Space>
            </form>
        </>
    )
}

export default EditSupplierApplicationPage
