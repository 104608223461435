import DocumentHeader from "Components/Document/DocumentHeader";
import styles from './InvoiceDocument.module.scss';
import { getInvoiceStateAsString, getInvoiceStateColor, getInvoiceTypeAsString, getLinesTotalExVat, getLineTotalExVat } from "Utils/InvoiceUtils";
import FlexRow from "Components/FlexRow";
import moment from "moment";
import { Col, Row } from "antd";
import React from "react";
import { getNumberAsCurrency } from "Utils/NumberUtils";
import { InvoiceModel } from "Apis/Models/InvoiceModel";
import { useDefaultInvoiceText } from "Apis/TextApi";
import DocumentLayout from "Components/Document/DocumentLayout";
import Ribbon from "Components/Ribbon";

interface Props {
  invoice?: InvoiceModel;
}
const InvoiceDocument = ({invoice}: Props) => {
    
    const {data: defaultInvoiceText } = useDefaultInvoiceText(invoice?.organizationId!, !!invoice)
    
    if (!invoice) {
        return null;
    }
    
    return (
        <DocumentLayout>
            <Ribbon text={getInvoiceStateAsString(invoice)} color={getInvoiceStateColor(invoice)}  />
            
            <DocumentHeader invoicingAddress={invoice.invoicingAddress} deliveryAddress={invoice.deliveryAddress} />
    
            <div style={{display: 'flex', justifyContent: 'space-between'}} >
                <div style={{width: '55%', minHeight: '170px'}}>
                    <div style={{height: '20px'}} />
                    <hr className={styles.separator}/>
                    <div style={{fontWeight: 600}}>
                        {invoice.title}
                    </div>
                    <div className={styles.defaultTextContainer}>
                        {defaultInvoiceText}
                    </div>
                </div>
        
                <div className={"flex column"} style={{minWidth: '40%', minHeight: '170px'}}>
                    <b style={{height: '20px', textTransform: 'uppercase'}}>
                        {getInvoiceTypeAsString(invoice.invoiceType)}
                    </b>
                    <hr className={styles.separator}/>
                    <FlexRow justify={"space-between"}>
                        Dato
                        <span>
                            {moment(invoice.date).format('DD.MM.YYYY')}
                        </span>
                    </FlexRow>
                    <FlexRow justify={"space-between"}>
                        Forfaldsdato
                        <span>
                            {moment(invoice.dueDate).format('DD.MM.YYYY')}
                        </span>
                    </FlexRow>
                    <FlexRow justify={"space-between"}>
                        Rekvistionsnr:
                        <span>
                            {invoice.requisitionNumber}
                        </span>
                    </FlexRow>
                </div>
            </div>
    
            <hr className={styles.separator}/>
    
            <Row gutter={10}>
                <Col span={12}>Tekst</Col>
                <Col span={3}>Antal</Col>
                <Col span={3}>Stk. pris</Col>
                <Col span={2}>Rabat</Col>
                <Col span={4}>
                    <span style={{display: 'flex', justifyContent: 'flex-end'}}>
                        Pris
                    </span>
                </Col>
            </Row>
    
            <hr className={styles.separator}/>
    
            <div className={styles.descriptionContainer}>
                <Row gutter={[10, 10]} align={'middle'}>
                    {invoice.lines.map(line => (
                        <React.Fragment key={line.id}>
                            <Col span={12}>
                                {line.text}
                            </Col>
                            <Col span={3}>
                                {line.amount}
                            </Col>
                            <Col span={3}>
                                {getNumberAsCurrency(line.unitSalesPrice)}
                            </Col>
                            <Col span={2}>
                                {`${line.discount} %`}
                            </Col>
                            <Col span={4}>
                                <span style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    {getNumberAsCurrency(getLineTotalExVat(line.amount, line.unitSalesPrice, line.discount))}
                                </span>
                            </Col>
                        </React.Fragment>
                    ))}
                </Row>
            </div>
    
            <hr className={styles.separator}/>
    
            <FlexRow justify={"end"} align={'end'} direction={"column"}>
                <div style={{width: '30%'}}>
                    <FlexRow justify={"space-between"}>
                        <span>Subtotal</span>
                        <span>{(getNumberAsCurrency(getLinesTotalExVat(invoice.lines)))} DKK</span>
                    </FlexRow>
                    <FlexRow justify={"space-between"}>
                        <span>Moms</span>
                        <span>{(getNumberAsCurrency(getLinesTotalExVat(invoice.lines) * 0.25))} DKK</span>
                    </FlexRow>
                    <FlexRow justify={"space-between"}>
                        <span><b>I alt</b></span>
                        <span>{(getNumberAsCurrency(getLinesTotalExVat(invoice.lines) * 1.25))} DKK</span>
                    </FlexRow>
                </div>
            </FlexRow>
        </DocumentLayout>
    )
}

export default InvoiceDocument
