import * as React from "react"

function Building(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 480 480" height={"512"} width={"512"} {...props}>
            <path d="M472 464h-48V200a8 8 0 00-8-8H304V8a8 8 0 00-8-8H88a8 8 0 00-8 8v456H8a8 8 0 000 16h464a8 8 0 000-16zm-304 0h-32v-72a8 8 0 018-8h16a8 8 0 018 8v72zm32-264v264h-16v-72c0-13.255-10.745-24-24-24h-16c-13.255 0-24 10.745-24 24v72H96V16h192v176h-80a8 8 0 00-8 8zm208 264H216V208h192v256z" />
            <path d="M256 112h-48a8 8 0 00-8 8v48a8 8 0 008 8h48a8 8 0 008-8v-48a8 8 0 00-8-8zm-8 48h-32v-32h32v32zM256 32h-48a8 8 0 00-8 8v48a8 8 0 008 8h48a8 8 0 008-8V40a8 8 0 00-8-8zm-8 48h-32V48h32v32zM176 112h-48a8 8 0 00-8 8v48a8 8 0 008 8h48a8 8 0 008-8v-48a8 8 0 00-8-8zm-8 48h-32v-32h32v32zM176 32h-48a8 8 0 00-8 8v48a8 8 0 008 8h48a8 8 0 008-8V40a8 8 0 00-8-8zm-8 48h-32V48h32v32zM176 192h-48a8 8 0 00-8 8v48a8 8 0 008 8h48a8 8 0 008-8v-48a8 8 0 00-8-8zm-8 48h-32v-32h32v32zM176 272h-48a8 8 0 00-8 8v48a8 8 0 008 8h48a8 8 0 008-8v-48a8 8 0 00-8-8zm-8 48h-32v-32h32v32zM328 368h48a8 8 0 008-8v-48a8 8 0 00-8-8h-48a8 8 0 00-8 8v48a8 8 0 008 8zm8-48h32v32h-32v-32zM328 288h48a8 8 0 008-8v-48a8 8 0 00-8-8h-48a8 8 0 00-8 8v48a8 8 0 008 8zm8-48h32v32h-32v-32zM328 448h48a8 8 0 008-8v-48a8 8 0 00-8-8h-48a8 8 0 00-8 8v48a8 8 0 008 8zm8-48h32v32h-32v-32zM248 368h48a8 8 0 008-8v-48a8 8 0 00-8-8h-48a8 8 0 00-8 8v48a8 8 0 008 8zm8-48h32v32h-32v-32zM248 288h48a8 8 0 008-8v-48a8 8 0 00-8-8h-48a8 8 0 00-8 8v48a8 8 0 008 8zm8-48h32v32h-32v-32zM248 448h48a8 8 0 008-8v-48a8 8 0 00-8-8h-48a8 8 0 00-8 8v48a8 8 0 008 8zm8-48h32v32h-32v-32z" />
        </svg>
    )
}

export default Building
