import config from "Config";
import { initializeApp }  from "firebase/app";
import * as firebaseAuthFunctions from "firebase/auth";

const app = initializeApp(config.firebaseConfig);

export const auth = firebaseAuthFunctions.getAuth(app)

export const signIn = (email: string, password: string) => firebaseAuthFunctions.signInWithEmailAndPassword(auth, email, password);
export const onAuthStateChanged = (fn: firebaseAuthFunctions.NextOrObserver<firebaseAuthFunctions.User>) => firebaseAuthFunctions.onAuthStateChanged(auth, fn);
export const resetPassword = (email: string) => firebaseAuthFunctions.sendPasswordResetEmail(auth, email);
export const verifyPasswordReset = (code: string) => firebaseAuthFunctions.verifyPasswordResetCode(auth, code);
export const confirmPasswordReset = (code: string, newPassword: string) => firebaseAuthFunctions.confirmPasswordReset(auth, code, newPassword);
