import React, {useEffect, useState} from 'react';
import { BarChart, Tooltip,  CartesianGrid, XAxis, YAxis, Bar, ResponsiveContainer } from 'recharts';
import {Col, Row} from "antd";
import Graph from "Assets/Icons/Graph";
import FlexRow from "Components/FlexRow";
import Box from "Components/Box";
import {useDashboardOverview} from "Apis/DashboardApi";
import moment from "moment";
import {EnrichedDashboardOverviewModel} from "Apis/Models/DashboardOverviewModel";
import {getNumberAsCurrency} from "Utils/NumberUtils";
import styles from './DashboardPage.module.scss';
import {useUserProvider} from "../../Providers/UserProvider";
import DefaultPageLayout from "../Layouts/DefaultPageLayout";

const DashboardPage = () => {

    const { userContext } = useUserProvider();
    const [userId, setUserId] = useState<string | undefined>()

    const {data} = useDashboardOverview(userId);
    const [enrichedData, setEnrichedData] = useState<EnrichedDashboardOverviewModel[]>([]);
    const [selectedData, setSelectedData] = useState<EnrichedDashboardOverviewModel>();

    useEffect(() => {
        if (data) {
            const enriched = data.map((x) => {
                return {
                    ...x,
                    monthString: moment(x.month, 'M').format('MMMM'),
                    revenueRealised: x.revenue,
                    revenueMissing: x.budgetRevenue ? x.budgetRevenue - x.revenue : 0
                }
            })

            setEnrichedData(enriched);
            setSelectedData(enriched[enriched.length - 1])
        }
    }, [data])

    const mouseOver = (data) => {
        setSelectedData(data)
    }

    return (
        <DefaultPageLayout
            title={"Økonomi"}
            icon={<Graph />}
            beforeBox={
                <Box style={{marginBottom: 15, padding: 10}}>
                    <FlexRow>
                        <select placeholder={"Vælg medarbejder"} onChange={e => setUserId((e.target.value))} className={"input input-invisible full-width"} >
                            <option value="">Alle medarbejdere</option>
                            {userContext!.users!.map((user) => (
                                <option value={user.id}>{user.name}</option>
                            ))}
                        </select>
                    </FlexRow>
                </Box>
        }>
            <div style={{maxWidth: 1300}}>
                <ResponsiveContainer minHeight={500}>
                    <BarChart data={enrichedData} margin={{ top: 20, right: 0, left: 0, bottom: 0 }}>
                        <CartesianGrid strokeWidth="3 3" stroke={"#F0F0F0"} />
                        <XAxis axisLine={false} stroke={'#9F9F9F'} tickSize={0} dataKey="monthString"/>
                        <YAxis axisLine={false} stroke={'#9F9F9F'} tickSize={0} tickCount={4}  />
                        <Tooltip cursor={false}  />

                        <Bar  stackId={"revenue"} name={"Realisered omsætning"} dataKey="revenueRealised" fill="#F39324" onMouseEnter={mouseOver} />
                        <Bar stackId={"revenue"} name={"Budgedetteret omsætning"} dataKey="revenueMissing" fill="#C7C7C7" onMouseEnter={mouseOver} />
                        <Bar stackId={"expense"} name={"Bil & værktøj"} dataKey="car" fill="#2b2b2b" onMouseEnter={mouseOver} />
                        <Bar stackId={"expense"} name={"Omkostninger"} dataKey="expenses" fill="#9F9F9F" onMouseEnter={mouseOver} />
                        <Bar stackId={"salary"} name={"Din løn"} dataKey="salary" fill="#4B69A5" onMouseEnter={mouseOver} />
                    </BarChart>
                </ResponsiveContainer>
                <Row>
                    <Col flex={"auto"} className={styles.column}>
                        <FlexRow direction={"column"} align={"start"} justify={"center"}>
                            <Graph fill={'#9F9F9F'} />
                            <span className={styles.columnHeadline}>Budgetteret Omsætning - {selectedData?.monthString}</span>
                            <span className={`${styles.columnText} ${styles.grey}`}>{getNumberAsCurrency(selectedData?.budgetRevenue ?? 0)} DKK</span>
                        </FlexRow>
                    </Col>
                    <Col flex={"auto"} className={styles.column}>
                        <FlexRow direction={"column"} align={"start"} justify={"center"}>
                            <Graph fill={'#9F9F9F'} />
                            <span className={styles.columnHeadline}>Realisered Omsætning - {selectedData?.monthString}</span>
                            <span className={`${styles.columnText} ${styles.green}`}>{getNumberAsCurrency(selectedData?.revenue ?? 0)} DKK</span>
                        </FlexRow>
                    </Col>
                    <Col flex={"auto"} className={styles.column}>
                        <FlexRow direction={"column"} align={"start"} justify={"center"}>
                            <Graph fill={'#9F9F9F'} />
                            <span className={styles.columnHeadline}>Materialeforbrug - {selectedData?.monthString}</span>
                            <span className={`${styles.columnText} ${styles.grey}`}>{getNumberAsCurrency(selectedData?.expenses ?? 0)} DKK</span>
                        </FlexRow>
                    </Col>
                    <Col flex={"auto"} className={styles.column}>
                        <FlexRow direction={"column"} align={"start"} justify={"center"}>
                            <Graph fill={'#9F9F9F'} />
                            <span className={styles.columnHeadline}>Faste omkostninger - {selectedData?.monthString}</span>
                            <span className={`${styles.columnText} ${styles.darkGrey}`}>{getNumberAsCurrency(selectedData?.car ?? 0)} DKK</span>
                        </FlexRow>
                    </Col>
                    <Col flex={"auto"} className={styles.column}>
                        <FlexRow direction={"column"} align={"start"} justify={"center"}>
                            <Graph fill={'#9F9F9F'} />
                            <span className={styles.columnHeadline}>Din løn - {selectedData?.monthString}</span>
                            <span className={`${styles.columnText} ${styles.blue}`}>{getNumberAsCurrency(selectedData?.salary ?? 0)} DKK</span>
                        </FlexRow>
                    </Col>
                </Row>
            </div>
        </DefaultPageLayout>
    )
}

export default DashboardPage
