import React from 'react';
import {Redirect, Route, Switch, useRouteMatch} from "react-router-dom";
import KlarPrisPage from "Pages/Settings/Klarpris/KlarPrisPage";
import ProductsPage from "Pages/Settings/ProductsPage";
import BudgetPage from "Pages/Settings/BudgetPage";
import EmployeesPage from "./EmployeesPage";

const SettingsRoutes = () => {

    let {path} = useRouteMatch();

    return (
        <Switch>
            <Route path={`${path}/employees`} exact>
                <EmployeesPage />
            </Route>

            <Route path={`${path}/products`} exact>
                <ProductsPage />
            </Route>

            <Route path={`${path}/klarpris`} exact>
                <KlarPrisPage />
            </Route>

            <Route path={`${path}/budget`} exact>
                <BudgetPage />
            </Route>

            <Route path={`${path}`}>
                <Redirect to={`${path}/products`} />
            </Route>
        </Switch>
    )
}

export default SettingsRoutes
