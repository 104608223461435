import {queryCache, useMutation, useQuery} from "react-query";
import {deserialize, OfferModel} from "Apis/Models/OfferModel";
import {request} from "Apis/BaseApi";
import {PublicOfferModel} from "Apis/Models/PublicOfferModel";

export const offerQueryCacheKey = 'offer';
export const useOffer = (organizationId: number, id: number) => {
    return useQuery<PublicOfferModel>([offerQueryCacheKey, id], async () => {
        const response = await request<PublicOfferModel>({
            url: `/public/offers/${organizationId}/${id}`,
            method: 'GET'
        })

        return {
            offer: deserialize(response.offer),
            customer: response.customer,
            defaultText: response.defaultText
        }
    })
}


export const useApproveOffer = () => {
    return useMutation(async ({organizationId, offerId}: any) => {
        return await request<OfferModel>({
            url: `/public/offers/${organizationId}/${offerId}/approve`,
            method: 'POST'
        })
    }, {
        onSuccess: (offer: OfferModel) => {
            queryCache.invalidateQueries(offerQueryCacheKey)
        }
    })
}

export const useRejectOffer = () => {
    return useMutation(async ({organizationId, offerId, reason}: any) => {
        return await request<OfferModel>({
            url: `/public/offers/${organizationId}/${offerId}/reject`,
            method: 'POST',
            data: {
                reason
            }
        })
    }, {
        onSuccess: (offer: OfferModel) => {
            queryCache.invalidateQueries(offerQueryCacheKey)
        }
    })
}
