import React from 'react';
import {OrderModel} from "Apis/Models/OrderModel";
import {downloadOrderFile, useOrderFiles, useUploadOrderFile, useDeleteOrderFile} from "Apis/OrderFileApi";
import Report from "Assets/Icons/Report";
import styles from './OrderFiles.module.scss'
import {Empty, Upload} from "antd";
import StyledButton from "Components/StyledButton";
import {toBase64} from "Utils/Base64Utils";
import {openSuccessNotification} from "Utils/NotificationUtils";
import FlexRow from "Components/FlexRow";
import {useIsLoading} from "Hooks/UseIsLoading";
import Spinner from "Components/Spinner";

interface Props {
    order: OrderModel
}
const OrderFiles = ({order}: Props) => {

    const {data: orderFiles} = useOrderFiles(order.id);
    const [uploadFileMutation, {isLoading}] = useUploadOrderFile();

    const uploadFileHandler = async (e) => {
        const base64 = await toBase64(e.file)

        await uploadFileMutation({
            description: e.file.name,
            orderId: order.id,
            fileContent: base64,
            contentType: e.file.type
        })

        openSuccessNotification('Success', 'Filen blev uploadet')
    }


    return (
        <div>
            <FlexRow justify={"end"}>
                <Upload customRequest={uploadFileHandler} showUploadList={false}>
                    <StyledButton loading={isLoading}>Upload fil</StyledButton>
                </Upload>
            </FlexRow>
            {orderFiles?.length === 0 && (
                <Empty description={"Du har ikke uploadet nogen filer til ordren"} image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
            <div className={styles.uploadedFilesContainer}>
                {orderFiles?.map(file => (
                    <OrderFile file={file} />
                ))}
            </div>
        </div>
    )
}

const OrderFile = ({file}) => {

    const [loading, downloadFile] = useIsLoading(() => downloadOrderFile(file.orderId, file.id, file.description))
    const [deleteFileMutation, {isLoading: isDeletingFile}] = useDeleteOrderFile();

    const removeFile = async () => {
        await deleteFileMutation({orderId: file.orderId, fileId: file.id})
    }

    return (
        <div className={styles.uploadedFileContainer}>
            <FlexRow justify={"end"} onClick={removeFile} className={"clickable"} >X</FlexRow>
            <div onClick={() => downloadFile()} className={styles.uploadedFile}>
                {loading || isDeletingFile ? <Spinner /> : <Report />}
                <div className={styles.uploadedFileDescription}>
                    {file.description}
                </div>
            </div>
        </div>
    )
}

export default OrderFiles
