import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import DocumentsPageAdmin from "./DocumentsPageAdmin";

const DocumentsRouteAdmin = () => {

    let { path } = useRouteMatch();

    return (<>
        <Switch>
            <Route path={[`${path}/:id`, path]}>
                <DocumentsPageAdmin/>
            </Route>
        </Switch>
    </>)
}
export default DocumentsRouteAdmin;