import React from 'react';
import {useWatch} from "react-hook-form";
import FlexRow from "Components/FlexRow";
import {getNumberAsCurrency, roundNumber} from "Utils/NumberUtils";

const FormInvoiceTotal = ({control}) => {

    const lines = useWatch<any[]>({
        control,
        defaultValue: [{
            unitSalesPrice: 0,
            amount: 0,
            discount: 0
        }],
        name: `lines`
    })

    const getLineTotalExVat = (amount, unitSalesPrice, discount) => {
        return roundNumber(amount * (unitSalesPrice * (1 - (discount / 100))))
    }

    const total = lines.map(line => getLineTotalExVat(line.amount, line.unitSalesPrice, line.discount)).reduce((a, b) => a + b)

    return (
        <div style={{width: '30%'}}>
            <FlexRow justify={"space-between"}>
                <span>Subtotal</span>
                <span>{(getNumberAsCurrency(roundNumber(total)))} DKK</span>
            </FlexRow>
            <FlexRow justify={"space-between"}>
                <span>Moms</span>
                <span>{(getNumberAsCurrency(roundNumber(total * 0.25)))} DKK</span>
            </FlexRow>
            <FlexRow justify={"space-between"}>
                <span><b>I alt</b></span>
                <span>{(getNumberAsCurrency(roundNumber(total * 1.25)))} DKK</span>
            </FlexRow>
        </div>
    )
}

export default FormInvoiceTotal
