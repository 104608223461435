import React, {useEffect, useState} from 'react';
import {useFieldArray, useForm} from "react-hook-form";
import {OrderMaterialModel} from "Apis/Models/OrderMaterialModel";
import MaterialFormLine from "Pages/Projects/Orders/Components/MaterialFormLine";
import FlexRow from "Components/FlexRow";
import StyledButton from "Components/StyledButton";
import {Col, Row} from "antd";

interface Props {
    orderId: number,
    materials?: OrderMaterialModel[],
    onSave: (materials) => void
}
const MaterialForm = ({orderId, materials, onSave}: Props) => {

    const [isSaving, setIsSaving] = useState<boolean>(false);

    const formMethods = useForm<{materials: OrderMaterialModel[]}>({mode: 'all'});
    const { reset } = formMethods;

    const { fields, append, remove} = useFieldArray({
        keyName: 'idKey',
        control: formMethods.control,
        name: "materials",
    });

    useEffect(() => {
        reset({
            materials: materials
        })
    }, [materials, reset])

    const handleSubmit = async (data) => {
        setIsSaving(true);
        try {
            await onSave(data);
        }
        catch (e){ }
        finally {
            setIsSaving(false)
        }

    }

    return (
        <>
            <Row gutter={[5, 20]}>
                <>
                    <Col span={8} className={"text-ellipsis"}>
                        Beskrivelse
                    </Col>
                    <Col span={2} className={"text-ellipsis"}>
                        Antal
                    </Col>
                    <Col span={3} className={"text-ellipsis"}>
                        Indkøbspris
                    </Col>
                    <Col span={3} className={"text-ellipsis"}>
                        Avance i %
                    </Col>
                    <Col span={3} className={"text-ellipsis"}>
                        Salgspris
                    </Col>
                    <Col span={2} className={"text-ellipsis"}>
                        Rabat i %
                    </Col>
                    <Col span={2} className={"text-ellipsis"}>
                        <FlexRow justify={"end"}>
                            Total
                        </FlexRow>
                    </Col>
                </>
                {fields.map((line, index) => (
                    <MaterialFormLine orderId={orderId} line={line} index={index} remove={remove} key={line.idKey} formMethods={formMethods} />
                ))}
            </Row>
            <FlexRow justify={"space-between"}>
                <StyledButton onClick={() => append({})}>Tilføj materiale</StyledButton>
                <StyledButton loading={isSaving} color={"primary"} onClick={formMethods.handleSubmit(handleSubmit)}>Gem materialer</StyledButton>
            </FlexRow>
        </>
    )
}

export default MaterialForm
