import { useHistory, useParams } from "react-router-dom";
import Approve from "Assets/Icons/Approve";
import DefaultPageLayout from "Pages/Layouts/DefaultPageLayout";
import React from "react";
import FlexRow from "Components/FlexRow";
import { Popconfirm, Space } from "antd";
import Notice from "Components/Notice";
import {getDateWithTime} from "Utils/DateUtils";
import { useApproveReminder, useDeleteReminder, usePostponeReminder, useReminder } from "Apis/Admin/Reminders/ReminderApi";
import StyledButton from "Components/StyledButton";
import {getReminderReceiver, getReminderTypeText} from "Utils/ReminderUtils";
import {openSuccessNotification} from "Utils/NotificationUtils";
import DocumentContainer from "Components/Document/DocumentContainer";
import ReminderDocument from "Components/Document/ReminderDocument";
import InvoiceDocument from "Components/Document/InvoiceDocument";

const ReminderDetailsPage = () => {

    const { id } = useParams<{ id }>();
    
    const history = useHistory();

    const {data: reminder, isLoading: isLoadingReminder} = useReminder(id);
    
    const [approveReminderMutation, {isLoading: isApprovingReminder}] = useApproveReminder();
    const [postponeReminderMutation, {isLoading: isPostponingReminder}] = usePostponeReminder();
    const [deleteReminderMutation, {isLoading: isDeletingReminder}] = useDeleteReminder();

    const approveReminder = async () => {
        await approveReminderMutation(reminder!.id);

        openSuccessNotification('Success', `${getReminderTypeText(reminder)} blev godkendt`)
    }
    
    const postponeReminder = async () => {
        await postponeReminderMutation(reminder!.id);
        
        openSuccessNotification('Success', `${getReminderTypeText(reminder)} blev udsat`)
        
        history.push('/admin/reminders');
    }
    
    const deleteReminder = async () => {
        await deleteReminderMutation(reminder!.id);
        
        openSuccessNotification('Success', `${getReminderTypeText(reminder)} blev slettet`)
        
        history.push('/admin/reminders');
    }

    return (
        <DefaultPageLayout title={"Rykker til godkendelse"} isLoading={isLoadingReminder} icon={<Approve />}>
            <DocumentContainer before={(
                <>
                    {!reminder?.approvedAt ? (
                            <Notice type={'Warning'}>
                                <Space direction={"vertical"} size={"large"} className={"w-100"}>
                                    <FlexRow>
                                        <b>
                                            Følgende {getReminderTypeText(reminder)} sendes til {getReminderReceiver(reminder)}
                                        </b>
                                    </FlexRow>
                                    <FlexRow align={"center"} justify={"space-between"}>
                                        {!reminder?.approvedAt && (
                                            <>
                                                <Popconfirm okText={"Ja, Slet den"} okType={"danger"} title={`Er du sikker på at du vil slette ${getReminderTypeText(reminder)}. Den kommer aldrig igen`} onConfirm={deleteReminder}>
                                                    <StyledButton disabled={isDeletingReminder} loading={isDeletingReminder} color={"danger"}>Slet {getReminderTypeText(reminder)}</StyledButton>
                                                </Popconfirm>
                                                <Space>
                                                    <StyledButton disabled={isPostponingReminder} loading={isPostponingReminder} onClick={postponeReminder} color={"default"}>Udsæt i 3 dage</StyledButton>
                                                    <StyledButton disabled={isApprovingReminder} loading={isApprovingReminder} onClick={approveReminder} color={"primary"}>Godkend</StyledButton>
                                                </Space>
                                            </>
                                        )}
                                    </FlexRow>
                                </Space>
                            </Notice>
                        ) :
                        (
                            <Notice type={'Success'}>
                                <FlexRow direction={"column"} align={"start"}>
                                    <b>
                                        Godkendt {getDateWithTime(new Date(reminder?.approvedAt))}
                                    </b>
                                </FlexRow>
                            </Notice>
                        )
                    }
                </>
            )}>
                <ReminderDocument reminder={reminder} />
                <InvoiceDocument invoice={reminder?.invoice} />
            </DocumentContainer>
        </DefaultPageLayout>

    )
}

export default ReminderDetailsPage
