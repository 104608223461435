import DefaultPageLayout from "Pages/Layouts/DefaultPageLayout";
import React from "react";
import {Table} from "antd";
import {useOrganizations} from "Apis/OrganizationAdminApi";
import Spinner from "Components/Spinner";
import FlexRow from "Components/FlexRow";
import { useHistory } from "react-router-dom";
import {useInvoicesForApproval} from "Apis/InvoiceAdminApi";
import {getInvoiceTypeAsString} from "Utils/InvoiceUtils";
import {InvoiceModel} from "Apis/Models/InvoiceModel";
import Approve from "Assets/Icons/Approve";

const InvoiceApprovalPage = () => {

    const history = useHistory();

    const {data: organizations, isLoading: isLoadingOrganizations} = useOrganizations();
    const {data: invoices, isLoading: isLoadingInvoices} = useInvoicesForApproval();

    return (
        <DefaultPageLayout title={"Faktura til godkendelse"} isLoading={!organizations} icon={<Approve />}>
            <Table
                size={"small"}
                rowKey={'id'}
                dataSource={invoices}
                loading={{indicator: <Spinner />, spinning: isLoadingOrganizations || isLoadingInvoices}}
                pagination={false}
                onRow={(record) => {
                    return {
                        onClick: () => history.push(`/admin/approval/invoices/${record.id}`),
                    };}
                }
                rowClassName={'clickable'}
            >

                <Table.Column title={"Type"} dataIndex={"invoiceType"} render={(text, record: InvoiceModel) => (
                    <FlexRow justify={"start"}>
                        <FlexRow align={"start"} justify={"start"} direction={"column"}>
                            {getInvoiceTypeAsString(record.invoiceType)}
                        </FlexRow>
                    </FlexRow>
                )} />

                <Table.Column title={"Håndværker"} dataIndex={"organizationId"} render={(text, record: InvoiceModel) => organizations?.find(x => x.id === record.organizationId)!.name } />
                <Table.Column title={"Overskrift"} dataIndex={"title"} />

                <Table.Column title={"Installationsadresse"} dataIndex={"customerId"} render={(text, record: InvoiceModel) => (
                    <FlexRow direction={"column"} justify={"start"} align={"start"}>
                        <span style={{fontSize: '.8em'}}>
                            {record.deliveryAddress.street} - {record.deliveryAddress.zipCode} - {record.deliveryAddress.city}
                        </span>
                    </FlexRow>
                ) } />

            </Table>
        </DefaultPageLayout>
    )

}

export default InvoiceApprovalPage;
