import React from "react";
import Folder from "Assets/Icons/Folder";
import { DocumentReadModel } from "Apis/Documents/Models/DocumentReadModel";
import FlexRow from "Components/FlexRow";
import styles from './DocumentFolder.module.scss'
import { useHistory } from "react-router-dom";

interface Props {
    document: DocumentReadModel,
}

const DocumentFolder = ( props: Props) => {

    const history = useHistory();
    return (
        <FlexRow justify={"space-between"} align={"center"} direction={"column"} className={styles.container} onClick={() => history.push(`/documents/${props.document.id}`)}>
            <FlexRow justify={"center"} align={"center"} className={"h-100"}>
                <Folder width={"30%"} fill="rgba(147, 147, 147, 0.5)" />
            </FlexRow>
            <FlexRow align={"center"} justify={"center"} >
                <div className={"w-100 text-ellipsis"}>
                    {props.document.name}
                </div>
            </FlexRow>
        </FlexRow>
    )
}

export default DocumentFolder;