import React from 'react';

const Purchase = () => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" id="Capa_1" enableBackground="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512"><g><path d="m476.222 80.96-352.296-.608-9.986-54.993c-2.669-14.694-15.445-25.359-30.378-25.359h-68.562c-8.284 0-15 6.716-15 15s6.716 15 15 15c72.603 0 69.22-.399 69.422.719 16.276 89.632 5.904 27.004 49.678 306.415l-38.487.2c-18.023.069-32.061 15.891-29.809 33.898l4.252 34.017c1.882 15.06 14.747 26.417 29.925 26.417h26.619c-18.801 36.505 7.827 80.334 49.067 80.334 41.22 0 67.875-43.811 49.066-80.333h142.867c-18.801 36.504 7.827 80.333 49.067 80.333 41.22 0 67.875-43.811 49.066-80.333h31.267c8.284 0 15-6.716 15-15s-6.716-15-15-15c-415.738 0-397.149.077-397.176-.138-4.556-36.484-4.372-34.148-4.075-34.194 199.757-1.039-277.653.065 368.987.065 26.945 0 47.264-20.378 47.264-47.4v-203.25c0-19.7-16.05-35.756-35.778-35.79zm-275.389 375.873c0 13.877-11.289 25.167-25.166 25.167s-25.167-11.29-25.167-25.167 11.29-25.167 25.167-25.167 25.166 11.291 25.166 25.167zm241 0c0 13.877-11.289 25.167-25.166 25.167s-25.167-11.29-25.167-25.167 11.29-25.167 25.167-25.167 25.166 11.291 25.166 25.167zm40.167-340.083v92.25h-97.009l12.272-98.176 78.906.136c3.216.005 5.831 2.603 5.831 5.79zm-212.709 220.65-12.3-98.4h94.018l-12.3 98.4zm-104.783 0-15.416-98.4h77.667l12.3 98.4zm88.733-128.4-12.306-98.446 126.102.218-12.278 98.228zm-42.545-98.498 12.313 98.498h-78.617l-15.454-98.64zm254.04 226.898h-95.795l12.3-98.4h100.759v81c0 10.407-6.937 17.4-17.264 17.4z"/></g></svg>
    )
}

export default Purchase
