import React, {useState} from 'react';
import {Space, Table} from "antd";
import {OrderModel} from "Apis/Models/OrderModel";
import FlexRow from "Components/FlexRow";
import StyledButton from "Components/StyledButton";
import Modal from "antd/es/modal";
import {NoteModel} from "Apis/Models/NoteModel";
import {useNotesForOrder, useSaveNote, useDeleteNote} from "Apis/NoteApi";
import moment from "moment";
import {useForm} from "react-hook-form";
import {getInputClasses} from "Utils/CssUtils";
import {openSuccessNotification} from "Utils/NotificationUtils";

interface Props {
    order: OrderModel
}
const OrderNotes = ({order}: Props) => {

    const [showNoteModal, setShowNoteModel] = useState<boolean>(false);
    const [selectedNote, setSelectedNote] = useState<NoteModel>();
    const {register, handleSubmit, errors, reset} = useForm<NoteModel>();

    const {data: notes, isLoading: isLoadingNotes} = useNotesForOrder(order.id);
    const [saveNoteMutation, {isLoading: isSavingNote}] = useSaveNote();
    const [deleteNoteMutation, {isLoading: isDeletingNote}] = useDeleteNote();

    const selectNote = (note: NoteModel) => {
        setSelectedNote(note);
        setShowNoteModel(true);
        reset(note);
    }

    const saveNote = async (data: NoteModel) => {
        data = {
            ...selectedNote,
            ...data,
        }

        if (!data.orderId) {
            data.orderId = order.id
        }

        await saveNoteMutation(data);
        closeModal();
        openSuccessNotification('Success', 'Noten blev gemt')
    }

    const deleteNote = async () => {
        await deleteNoteMutation(selectedNote!);
        closeModal();

        openSuccessNotification('Success', 'Noten blev slettet')
    }

    const closeModal = () => {
        setSelectedNote(undefined);
        setShowNoteModel(false);
        reset({});
    }

    return (
        <>
            <FlexRow justify={"end"}>
                <StyledButton onClick={() => setShowNoteModel(true)}>Opret note</StyledButton>
            </FlexRow>
            <Table
                dataSource={notes}
                pagination={false}
                loading={isLoadingNotes}
                rowKey={'id'}
                rowClassName={"clickable"}
                onRow={(record) => {
                    return {
                        onClick: () => selectNote(record),
                    };}
                }
            >
                <Table.Column title={"Titel"} dataIndex={"title"} />
                <Table.Column title={"Oprettet"} dataIndex={"createdAt"} render={(text) => moment(text).format('DD/MM/YYYY HH:mm')} />
                <Table.Column title={"Opdateret"} dataIndex={"updatedAt"} render={(text) => moment(text).format('DD/MM/YYYY HH:mm')} />
            </Table>

            <Modal visible={showNoteModal} title={"Note"}
                   onCancel={closeModal}
                   footer={false}
            >
                <form onSubmit={handleSubmit(saveNote)}>
                    <Space className={"w-100"} size={"large"} direction={"vertical"}>
                        <input type={"text"} placeholder={"Titel"} name="title" className={getInputClasses(errors.title)} ref={register({required: true})} />
                        <textarea rows={5} placeholder={"Note"} name="text" className={getInputClasses(errors.text)} ref={register({required: true})} />

                        <FlexRow justify={"space-between"}>
                            <div>
                                {selectedNote?.id && (
                                    <StyledButton loading={isDeletingNote} color={'danger'} onClick={deleteNote}>Slet</StyledButton>
                                )}
                            </div>
                            <Space>
                                <StyledButton onClick={closeModal}>Afbryd</StyledButton>
                                <StyledButton loading={isSavingNote} type={"submit"}>Gem</StyledButton>

                            </Space>
                        </FlexRow>
                    </Space>
                </form>
            </Modal>
        </>
    )
}

export default OrderNotes
