import React, {useState} from "react";
import { useParams } from "react-router-dom";
import Spinner from "Components/Spinner";
import {Col, Row, Space} from "antd";
import StyledButton from "Components/StyledButton";
import FlexRow from "Components/FlexRow";
import {getInputClasses} from "Utils/CssUtils";
import Modal from "antd/es/modal";
import {useForm} from "react-hook-form";
import {openSuccessNotification} from "Utils/NotificationUtils";
import Box from "Components/Box";
import {useApprovePublicAgreement, usePublicAgreement, useRejectPublicAgreement} from "Apis/PublicAgreementApi";
import Notice from "Components/Notice";
import PageTitle from "Components/PageTitle";
import Report from "Assets/Icons/Report";
import {addVat, getNumberAsCurrency, getVat} from "Utils/NumberUtils";

const PublicApproveAgreementPage = () => {

    const { organizationId, agreementId } = useParams<{ organizationId, agreementId }>();

    const {data: agreement, isLoading} = usePublicAgreement(organizationId, agreementId);

    const [showRejectModal, setShowRejectModal] = useState<boolean>(false);

    const {register, errors, handleSubmit} = useForm<{reason}>();

    const [approveAgreementMutation, {isLoading: isApproving}] = useApprovePublicAgreement();
    const [rejectAgreementMutation, {isLoading: isRejecting}] = useRejectPublicAgreement();

    const approveOffer = async () => {
        await approveAgreementMutation({organizationId, agreementId});

        openSuccessNotification('Success', `Aftalesedlen blev godkendt`)
    }

    const rejectOffer = async (data: {reason}) => {
        await rejectAgreementMutation({organizationId, agreementId, reason: data.reason});

        openSuccessNotification('Success', `Aftalesedlen blev afvist`)

        setShowRejectModal(false)
    }

    if (isLoading) {
        return (
            <div style={{width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Spinner size="large" />
            </div>
        )
    }

    return (
        <div style={{overflowY: 'scroll', height: '100%', paddingTop: 20, paddingBottom: 50}}>
            <Row>
                <Col sm={24} md={{span: 10, offset: 7}}>
                    <PageTitle title={"Aftaleseddel"} icon={<Report />} />
                    {!agreement?.customerApproval?.approvedAt && (
                        <Notice type={"Default"}>
                            <FlexRow justify={"space-between"}>
                                <span>
                                    Afventer
                                </span>
                                <Space>
                                    <StyledButton disabled={isRejecting || isApproving} loading={isRejecting} onClick={() => setShowRejectModal(true)} color={"danger"}>Afvis</StyledButton>
                                    <StyledButton disabled={isRejecting || isApproving} loading={isApproving} onClick={approveOffer} color={"primary"}>Godkend</StyledButton>
                                </Space>
                            </FlexRow>
                        </Notice>
                    )}
                    {agreement?.customerApproval?.isApproved === true && (
                        <Notice type={"Success"}>
                            Godkendt
                        </Notice>
                    )}
                    {agreement?.customerApproval?.isApproved === false && (
                        <Notice type={"Danger"}>
                            <FlexRow justify={"space-between"}>
                                <FlexRow direction={"column"} align={"start"}>
                                    <b>
                                        Afvist
                                    </b>
                                    <span>
                                        {agreement.customerApproval?.reason}
                                    </span>
                                </FlexRow>
                                <Space>
                                    <StyledButton disabled={isRejecting || isApproving} loading={isApproving}  onClick={approveOffer} color={"primary"}>Godkend</StyledButton>
                                </Space>
                            </FlexRow>
                        </Notice>
                    )}
                    <div>

                    <Box>
                        <div style={{fontSize: 20}}>
                            {agreement?.title}
                        </div>
                        <div className={"display-linebreak"}>
                            {agreement?.description}
                        </div>
                        {agreement?.priceExVat && (
                            <FlexRow align={"end"} direction={"column"}>
                                <FlexRow justify={"space-between"}>
                                    <span>Subtotal</span>
                                    <span>{(getNumberAsCurrency(agreement?.priceExVat))} DKK</span>
                                </FlexRow>
                                <FlexRow justify={"space-between"}>
                                    <span>Moms</span>
                                    <span>{(getNumberAsCurrency(getVat(agreement?.priceExVat)))} DKK</span>
                                </FlexRow>
                                <FlexRow justify={"space-between"}>
                                    <span><b>I alt</b></span>
                                    <span>{(getNumberAsCurrency(addVat(agreement?.priceExVat)))} DKK</span>
                                </FlexRow>
                            </FlexRow>
                        )}
                    </Box>
                    </div>
                </Col>
            </Row>
            <Modal visible={showRejectModal} title={"Afvis aftaleseddel"}
                   onCancel={() => setShowRejectModal(false)}
                   footer={false}
            >
                <form onSubmit={handleSubmit(rejectOffer)}>
                    <textarea placeholder={"Indtast begundelse"} name="reason" rows={4} className={getInputClasses(errors.reason)} ref={register({required: true})} />
                    <FlexRow justify={"end"}>
                        <Space>
                            <StyledButton onClick={() => setShowRejectModal(false)}>Afbryd</StyledButton>
                            <StyledButton color={"danger"} type={"submit"}>Afvis</StyledButton>
                        </Space>
                    </FlexRow>
                </form>
            </Modal>
        </div>
    )

}

export default PublicApproveAgreementPage;
