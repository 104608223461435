import React from "react";
import styles from './Notice.module.scss';
import {lowerCaseFirst} from "Utils/TextUtils";

interface Props {
    type?: 'Success' | 'Danger' | 'Warning' | 'Default',
    children?: any,
}
const Notice = (props: Props) => {

    const colorClass = props.type ?? 'default'

    return (
        <div className={`${styles.notice} ${styles[lowerCaseFirst(colorClass)]}`}>
            {props.children}
        </div>
    )
}

export default Notice;
