import {Route, Switch, useRouteMatch} from "react-router-dom";
import React from "react";
import CustomersOverviewPage from "Pages/Projects/Customers/CustomersOverviewPage";
import CreateCustomerPage from "Pages/Projects/Customers/CreateCustomerPage";
import EditCustomerPage from "Pages/Projects/Customers/EditCustomerPage";

const CustomersPage = () => {
    let {path} = useRouteMatch();

    return (
        <Switch>
            <Route path={`${path}`} exact>
                <CustomersOverviewPage />
            </Route>
            <Route path={`${path}/create`}>
                <CreateCustomerPage />
            </Route>
            <Route path={`${path}/edit/:id`}>
                <EditCustomerPage />
            </Route>
        </Switch>
    )
}

export default CustomersPage;
