import React, {useState} from 'react';
import DefaultPageLayout from "Pages/Layouts/DefaultPageLayout";
import {useParams} from "react-router-dom";
import PaperLayout from "Components/PaperLayout";
import FlexRow from "Components/FlexRow";
import {Col, Row, Select, Space, Tooltip} from "antd";
import StyledButton from "Components/StyledButton";
import styles from "Pages/Projects/Offers/OfferForm.module.scss";
import moment from "moment";
import {getInvoiceTypeAsString} from "Utils/InvoiceUtils";
import {getNumberAsCurrency, roundNumber} from "Utils/NumberUtils";
import {
    useApproveSupplierInvoice,
    useAssignSupplierInvoiceLines, useAvailableSupplierInvoicesForCreditNote, useLinkSupplierInvoiceCreditNote,
    useRejectSupplierInvoice,
    useSupplierInvoice
} from "Apis/SupplierInvoiceApi";
import Modal from "antd/es/modal";
import {useOrders} from "Apis/OrderApi";
import Notice from "Components/Notice";
import {getDateWithTime} from "Utils/DateUtils";
import {getInputClasses} from "Utils/CssUtils";
import {useForm} from "react-hook-form";
import {openSuccessNotification} from "Utils/NotificationUtils";
import Purchase from "Assets/Icons/Purchase";
import DocumentContainer from "../../../Components/Document/DocumentContainer";

const SupplierInvoiceDetails = () => {

    const { id } = useParams<{ id }>();
    const [selectedInvoice, setSelectedInvoice] = useState<any>();

    const {data: supplierInvoice, isLoading} = useSupplierInvoice(id);
    const {data: availableInvoices, isLoading: isLoadingAvailableInvoices} = useAvailableSupplierInvoicesForCreditNote(id, supplierInvoice?.invoiceType === 'CreditNote' && !supplierInvoice.creditNoteReferenceId);
    const {data: orders} = useOrders();

    const [assignLinesMutation, {isLoading: isAssigningLines}] = useAssignSupplierInvoiceLines();
    const [approveInvoiceMutation, {isLoading: isApprovingInvoice}] = useApproveSupplierInvoice();
    const [rejectInvoiceMutation, {isLoading: isRejectingInvoice}] = useRejectSupplierInvoice();
    const [linkCreditNoteMutation, {isLoading: isLinkingCreditNote}] = useLinkSupplierInvoiceCreditNote();

    const [selectedLines, setSelectedLines] = useState<number[]>([]);
    const [allSelected, setAllSelected] = useState<boolean>(false);

    const [showAssignModal, setShowAssignModal] = useState<boolean>(false);
    const [selectedOrder, setSelectedOrder] = useState<string | null>(null);

    const [showRejectModal, setShowRejectModal] = useState<boolean>(false);
    const {register, errors, handleSubmit} = useForm<{reason}>();

    const toggleLineSelected = (id: number) => {
        if (selectedLines.findIndex(x => x === id) > -1) {
            setSelectedLines(selectedLines.filter(x => x !== id));
        } else {
            setSelectedLines([id].concat(selectedLines))
        }
        setAllSelected(false)
    }

    const toggleAllSelected = () => {
        if (allSelected) {
            setAllSelected(false);
            setSelectedLines([]);
        } else {
            setAllSelected(true);
            setSelectedLines(supplierInvoice!.supplierInvoiceLines.map(x => x.id));
        }
    }

    const orderSelectedHandler = (value) => {
        value = value === 'null' ? null : value;

        setSelectedOrder(value)
    }

    const assignLines = async () => {
        await assignLinesMutation({lines: selectedLines, orderId: selectedOrder})

        setSelectedOrder(null);
        setSelectedLines([]);
        setAllSelected(false);
        setShowAssignModal(false)
    };

    const approveInvoice = async () => {
        await approveInvoiceMutation(supplierInvoice!.id);

        openSuccessNotification('Success', `${getInvoiceTypeAsString(supplierInvoice?.invoiceType!)} blev godkendt`)
    }

    const rejectInvoice = async (data: {reason}) => {
        await rejectInvoiceMutation({id: supplierInvoice!.id, reason: data.reason});

        openSuccessNotification('Success', `${getInvoiceTypeAsString(supplierInvoice?.invoiceType!)} blev afvist`)

        setShowRejectModal(false)
    }

    const linkCreditNote = async () => {
        console.log(selectedInvoice)
        await linkCreditNoteMutation({creditNoteId: supplierInvoice!.id, invoiceId: selectedInvoice});
    }

    return (
        <DefaultPageLayout title={"Leverandørfaktura"} isLoading={isLoading} icon={<Purchase />}>
            <DocumentContainer>
            <PaperLayout showFooter={false} beforePaper={(
                <>
                    <FlexRow justify={"end"} align={"end"} direction={"column"}>
                        {supplierInvoice?.invoiceType === 'CreditNote' && !supplierInvoice.creditNoteReferenceId ? (
                            <Notice type={'Default'}>
                                <FlexRow direction={"column"} align={"start"}>
                                        <FlexRow direction={'column'} align={"start"} justify={"center"}>
                                                <Space direction={"vertical"}>
                                                    <b>
                                                        {!isLoadingAvailableInvoices && availableInvoices?.length === 0
                                                                ? "Vi fandt ikke nogen faktura som matcher denne kreditnota, det er derfor ikke muligt at godkende den"
                                                                : "Du skal specificere hvilken leverandørfaktura denne kreditnota hører til"
                                                        }
                                                    </b>
                                                    {(availableInvoices && availableInvoices?.length !== 0) && (
                                                        <>
                                                            <select placeholder={'Vælg faktura'} className={"input full-width"} onChange={value => setSelectedInvoice(value.target.value)}>
                                                                <option value={""}>Vælg faktura</option>
                                                                {availableInvoices?.map((invoice) => (
                                                                    <option key={invoice.id} value={invoice.id}>Leverandørfaktura {invoice.invoiceNumber} fra {invoice.supplierName}</option>
                                                                ))}
                                                            </select>
                                                            <StyledButton loading={isLinkingCreditNote} onClick={linkCreditNote} color={"primary"}>Forbind kredinota med faktura</StyledButton>
                                                        </>
                                                    )}
                                                </Space>
                                        </FlexRow>
                                </FlexRow>
                            </Notice>
                        ) : (
                            <Notice type={supplierInvoice?.approval?.approvedAt ? supplierInvoice.approval.isApproved ? 'Success' : 'Danger' : 'Default'}>
                                <FlexRow justify={'space-between'} align={"center"}>
                                    <FlexRow direction={"column"} align={"start"}>
                                        <b>
                                            {!supplierInvoice?.approval?.approvedAt ? ('Afventer') : (
                                                `${supplierInvoice.approval.isApproved ? 'Godkendt' : 'Afvist'} ${getDateWithTime(supplierInvoice?.approval?.approvedAt!)}`
                                            )}
                                        </b>
                                        <span>
                                        {supplierInvoice?.approval?.reason}
                                    </span>
                                    </FlexRow>
                                    <Space>

                                        {(!supplierInvoice?.approval || !!supplierInvoice?.approval?.isApproved) && (
                                            <StyledButton onClick={() => setShowRejectModal(true)} color={"danger"}>Afvis</StyledButton>
                                        )}
                                        {(!supplierInvoice?.approval || !supplierInvoice?.approval?.isApproved) && (
                                            <Tooltip title={"Du skal tildele alle linjerne før du kan godkende fakturaen"} overlayStyle={{zIndex: 99}}>
                                                <span>
                                                    <StyledButton disabled={supplierInvoice?.supplierInvoiceLines.filter(x => x.orderMaterials.length === 0).length! > 0 || isApprovingInvoice} loading={isApprovingInvoice} onClick={approveInvoice} color={"primary"}>Godkend</StyledButton>
                                                </span>
                                            </Tooltip>
                                        )}
                                    </Space>
                                </FlexRow>
                            </Notice>
                        )}
                    </FlexRow>
                </>
            )}>
                <>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <div style={{width: '40%', fontSize: '.9em', height: '170px'}}>
                            <Row gutter={[5, 5]}>
                                <Col span={24}>
                                    <b>
                                        {supplierInvoice?.supplierName}
                                    </b>
                                </Col>
                            </Row>

                            <span>
                                CVR: {supplierInvoice?.supplierVatNumber}
                            </span>
                        </div>

                        <span style={{fontSize: 25, fontWeight: 'bold'}}>
                            {supplierInvoice?.supplierName}
                        </span>
                    </div>


                    <FlexRow justify={"space-between"} style={{margin: '20px 0'}}>
                        <div style={{width: '55%'}}>
                            <span>
                                Dato: {moment(supplierInvoice?.invoiceDate).format('DD.MM.YYYY')}
                            </span>
                        </div>

                        <div className={"flex column"} style={{minWidth: '40%'}}>

                            <FlexRow justify={"space-between"}>
                                <span>
                                    Ordre reference:
                                </span>
                                <span>
                                    {supplierInvoice?.orderReference}
                                </span>
                            </FlexRow>
                        </div>
                    </FlexRow>

                    <FlexRow>
                        <div style={{fontWeight: 600, fontSize: 20, marginBottom: '10px'}}>
                            {getInvoiceTypeAsString(supplierInvoice?.invoiceType!)} {supplierInvoice?.invoiceNumber}
                        </div>
                    </FlexRow>

                    <hr className={styles.seperator}/>

                    <Row gutter={10}>
                        <Col span={1} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <input type="checkbox" checked={allSelected} onChange={toggleAllSelected} />
                        </Col>
                        <Col span={13}>Tekst</Col>
                        <Col span={3}>Antal</Col>
                        <Col span={3}>Stk. pris</Col>
                        <Col span={4}>
                            <span style={{display: 'flex', justifyContent: 'flex-end'}}>
                                Pris
                            </span>
                        </Col>
                    </Row>

                    <hr className={styles.seperator}/>

                    <div style={{fontSize: '0.9em'}}>
                        {supplierInvoice?.supplierInvoiceLines.map((line) => (
                            <Row key={line.id} gutter={[10, 10]} align={'middle'} onClick={() => toggleLineSelected(line.id)} style={selectedLines.findIndex(x => x === line.id) > -1 ? {backgroundColor: '#eee'} : {}}>
                                <Col span={1} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <input type="checkbox" checked={selectedLines.findIndex(x => x === line.id) > -1} readOnly />
                                </Col>
                                <Col span={13}>
                                    <FlexRow direction={"column"} align={'start'}>
                                        <span>
                                            {line.lineText}
                                        </span>
                                        <span style={{fontSize: '0.8em'}}>
                                            {line.orderMaterials.length > 0 ? line.orderMaterials[0].orderId ? 'Tildelt sag' : 'Tildelt lager / bil' : 'Ikke tildelt sag'}
                                        </span>
                                    </FlexRow>
                                </Col>
                                <Col span={3}>
                                    {line.quantity}
                                </Col>
                                <Col span={3}>
                                    {line.unitPriceExVat}
                                </Col>
                                <Col span={4}>
                                    <span style={{display: 'flex', justifyContent: 'flex-end'}}>
                                        {getNumberAsCurrency(line.lineTotalExVat)} DKK
                                    </span>
                                </Col>
                            </Row>
                        ))}
                    </div>

                    <hr className={styles.seperator}/>

                    <FlexRow justify={"space-between"} >
                        <div>
                            <StyledButton onClick={() => setShowAssignModal(true)} disabled={selectedLines.length === 0} color={selectedLines.length > 0 ? 'primary' : 'default'}>Tildel {selectedLines.length} linje{selectedLines.length === 1 ? '' : 'r'}</StyledButton>
                        </div>

                        <div style={{width: '30%'}}>
                            <FlexRow justify={"space-between"}>
                                <span>Subtotal</span>
                                <span>{(getNumberAsCurrency(supplierInvoice?.totalExVat))} DKK</span>
                            </FlexRow>
                            <FlexRow justify={"space-between"}>
                                <span>Moms</span>
                                <span>{(getNumberAsCurrency(roundNumber(supplierInvoice?.totalInclVat! - supplierInvoice?.totalExVat!)))} DKK</span>
                            </FlexRow>
                            <FlexRow justify={"space-between"}>
                                <span><b>I alt</b></span>
                                <span>{(getNumberAsCurrency(roundNumber(supplierInvoice?.totalInclVat!)))} DKK</span>
                            </FlexRow>
                        </div>
                    </FlexRow>

                    <hr className={styles.seperator}/>

                    <div style={{paddingTop: 10}}>
                        {supplierInvoice?.paymentDate && (
                            <>
                                <b>
                                    Betalingsoplysninger
                                </b>
                                <p>
                                    Forfaldsdato: {moment(supplierInvoice?.paymentDate).format('DD.MM.YYYY')}
                                </p>
                                <div className={'display-linebreak'}>
                                    {supplierInvoice?.paymentTerms}
                                </div>
                            </>
                        )}
                    </div>

                </>

            </PaperLayout>
            </DocumentContainer>

            <Modal visible={showRejectModal} title={"Afvis faktura"}
                   onCancel={() => setShowRejectModal(false)}
                   footer={false}
            >
                <form onSubmit={handleSubmit(rejectInvoice)}>
                    <textarea placeholder={"Indtast begundelse"} name="reason" rows={4} className={getInputClasses(errors.reason)} ref={register({required: true})} />
                    <FlexRow justify={"end"}>
                        <Space>
                            <StyledButton onClick={() => setShowRejectModal(false)}>Afbryd</StyledButton>
                            <StyledButton color={"danger"} loading={isRejectingInvoice} disabled={isRejectingInvoice} type={"submit"}>Afvis</StyledButton>
                        </Space>
                    </FlexRow>
                </form>
            </Modal>

            <Modal visible={showAssignModal} title={"Tildel"}
                   onCancel={() => setShowAssignModal(false)}
                   footer={false}
            >
                <Select
                    className={'input full-width'}
                    bordered={false}
                    placeholder={'Vælg ordre'}
                    value={selectedOrder ?? undefined}
                    onSelect={orderSelectedHandler}
                    style={{marginBottom: 10}}
                >
                    <Select.Option key={'none'} value={'null'}>{'Til lager / Bil'}</Select.Option>
                    {orders?.map((customer) => (
                        <Select.Option key={customer.id} value={customer.id}>{customer.title}</Select.Option>
                    ))}
                </Select>
                <FlexRow justify={"end"}>
                    <Space>
                        <StyledButton onClick={() => setShowAssignModal(false)}>Afbryd</StyledButton>
                        <StyledButton onClick={() => assignLines()} disabled={isAssigningLines} loading={isAssigningLines} color={"primary"} type={"submit"}>Tildel</StyledButton>
                    </Space>
                </FlexRow>
            </Modal>

        </DefaultPageLayout>
    )
}

export default SupplierInvoiceDetails
