import React from "react"
import styles from "./QaField.module.scss";

interface Props {
    text?: string;
    value?: string;
}
const QaFieldTextAreaTemplate = ({text, value}: Props) => {
    
    return (
        <div className={`${styles.answer} ${styles.answerTextarea}`}>
            <span>{text}</span>
            <span>{value}</span>
        </div>
    )
}

export default QaFieldTextAreaTemplate
