import {queryCache, useMutation, useQuery} from "react-query";
import {request} from "Apis/BaseApi";
import {UserModel} from "Apis/Models/UserModel";
import {openErrorNotification} from "Utils/NotificationUtils";
import {BadRequestModel, DomainExceptionModel} from "Apis/Models/DomainExceptionModel";

export const useCreateUser = () => {
    return useMutation(async (user: UserModel) => {
        return await request({
            url: '/admin/users',
            method: 'POST',
            data: user
        })
    }, {
        onSuccess: () => {
            queryCache.invalidateQueries(usersCacheKey)
            queryCache.invalidateQueries(adminUsersCacheKey)
            queryCache.invalidateQueries(standardUsersCacheKey)
        }
    })
}

export const useUpdateUser = () => {
    return useMutation(async (user: UserModel) => {
        return await request({
            url: `/admin/users/${user.id}`,
            method: 'PUT',
            data: user
        })
    }, {
        onSuccess: () => {
            queryCache.invalidateQueries(usersCacheKey)
            queryCache.invalidateQueries(adminUsersCacheKey)
            queryCache.invalidateQueries(standardUsersCacheKey)
        }
    })
}

export const adminUsersCacheKey = 'adminUsers';
export const useAdminUsers = () => {
    return useQuery<UserModel[]>(adminUsersCacheKey, async () => {
        return await request<UserModel[]>({
            url: '/admin/users/admin',
            method: 'GET'
        })
    })
}

export const standardUsersCacheKey = 'standardUsers';
export const useStandardUsers = () => {
    return useQuery<UserModel[]>(standardUsersCacheKey, async () => {
        return await request<UserModel[]>({
            url: '/admin/users/standard',
            method: 'GET'
        })
    })
}

export const usersCacheKey = 'users';
export const useUsers = () => {
    return useQuery<UserModel[]>(usersCacheKey, async () => {
        return await request<UserModel[]>({
            url: '/admin/users',
            method: 'GET'
        })
    })
}

export const userCacheKey = 'user';
export const useUser = (id: number) => {
    return useQuery<UserModel>([userCacheKey, id], async () => {
        return await request<UserModel>({
            url: `/admin/users/${id}`,
            method: 'GET'
        })
    })
}

export const useDeleteUser = () => {
    return useMutation(async (user: UserModel) => {
        return await request({
            url: `/admin/users/${user.id}`,
            method: 'DELETE',
        })
    }, {
        onSuccess: () => {
            queryCache.invalidateQueries(usersCacheKey)
            queryCache.invalidateQueries(adminUsersCacheKey)
            queryCache.invalidateQueries(standardUsersCacheKey)
        },
        onError: (error: BadRequestModel<DomainExceptionModel>) => {
            console.log(error)
            openErrorNotification('Der skete en fejl', error.data.message)
        }
    })
}
