import {queryCache, useMutation, useQuery} from "react-query";
import {request} from "Apis/BaseApi";
import {FileType, SupplierApplicationModel} from "Apis/Public/SupplierApplications/Models/SupplierApplicationModel";
import saveAs from 'file-saver';
import OrganizationModel from "Apis/Models/OrganizationModel";

export const useCreateSupplierApplication = () => {
    return useMutation(async (application: any): Promise<any> => {
        return await request({
            url: `/public/supplierApplication`,
            method: 'POST',
            data: application
        })
    })
}


export const SUPPLIER_APPLICATIONS_CACHE_KEY = 'SUPPLIER_APPLICATIONS_CACHE_KEY';
export const useSupplierApplications = () => {
    return useQuery(SUPPLIER_APPLICATIONS_CACHE_KEY, async () => {
        return await request<SupplierApplicationModel[]>({
            url: '/admin/supplierApplications',
            timeout: 120000
        })
    })
}

export const useSupplierApplication = (id: number) => {
    return useQuery([SUPPLIER_APPLICATIONS_CACHE_KEY, id], async () => {
        return await request<SupplierApplicationModel>({
            url: `/admin/supplierApplications/${id}`
        })
    })
}

export const getSupplierApplicationFile = async (id: number, fileType: FileType): Promise<any> => {
    const responseBlob = await request<Blob>({
        responseType: 'blob',
        url: `/admin/supplierApplications/${id}/file/${fileType}`,
        method: 'GET',
    });

    const blob = new Blob([responseBlob]);
    saveAs(blob);
}

export const useUpdateSupplierApplication = () => {
    return useMutation(async (entry: SupplierApplicationModel) => {
        return await request({
            url: `/admin/supplierApplications/${entry.id}`,
            method: 'PUT',
            data: entry
        })

    }, {
        onSuccess: () => {
            queryCache.invalidateQueries(SUPPLIER_APPLICATIONS_CACHE_KEY)
        },
    })
}

export const useSendSupplierApplicationMail = () => {
    return useMutation(async ({id, mailType}: {id: number, mailType: 'Accountant' | 'Lawyer'}) => {
        return await request({
            url: `/admin/supplierApplications/${id}/mail/${mailType}`,
            method: 'PUT',
        })
    })
}

export const useCreateOrganizationFromSupplierApplication = () => {
    return useMutation(async (id: number) => {
        return await request<OrganizationModel>({
            url: `/admin/supplierApplications/${id}/createOrganization`,
            method: 'PUT',
        })
    })
}

export const useDeleteSupplierApplication = () => {
    return useMutation(async (entry: SupplierApplicationModel) => {
        return await request({
            url: `/admin/supplierApplications/${entry.id}`,
            method: 'DELETE',
        })
        
    }, {
        onSuccess: () => {
            queryCache.invalidateQueries(SUPPLIER_APPLICATIONS_CACHE_KEY)
        },
    })
}
