import {useQuery} from "react-query";
import {ColleagueModel} from "Apis/Models/ColleagueModel";
import {request} from "Apis/BaseApi";

export const COLLEAGUES_CACHE_KEY = 'COLLEAGUES_CACHE_KEY';
export const useColleagues = (searchFilter : string) => {
    const params = new URLSearchParams();

    if (searchFilter) {
        params.set('queryFilter', searchFilter);
    }

    return useQuery<ColleagueModel[]>([COLLEAGUES_CACHE_KEY, searchFilter], async () => {
        return await request<ColleagueModel[]>({
            url: `/colleagues?${params.toString()}`,
            method: 'GET'
        });
    })
}

export const colleagueQueryKey = 'colleage';
export const useColleague = (id: number) => {
    return useQuery<ColleagueModel>([colleagueQueryKey, id], async () => {
        return await request<ColleagueModel>({
            url: `/colleagues/${id}`,
            method: 'GET'
        });
    })
}

