import { useParams } from "react-router-dom";
import React, {useEffect, useState} from "react";
import DefaultPageLayout from "Pages/Layouts/DefaultPageLayout";
import FlexRow from "Components/FlexRow";
import {Space} from "antd";
import StyledButton from "Components/StyledButton";
import Modal from "antd/es/modal";
import {getInputClasses} from "Utils/CssUtils";
import {useForm} from "react-hook-form";
import {openSuccessNotification} from "Utils/NotificationUtils";
import {useApproveInvoice, useInvoice, useRejectInvoice} from "Apis/InvoiceAdminApi";
import {getInvoiceTypeAsString} from "Utils/InvoiceUtils";
import Approve from "Assets/Icons/Approve";
import {InvoiceModel} from "Apis/Models/InvoiceModel";
import {OfferModel} from "Apis/Models/OfferModel";
import {OrderModel} from "Apis/Models/OrderModel";
import DocumentContainer from "Components/Document/DocumentContainer";
import InvoiceDocument from "Components/Document/InvoiceDocument";
import Notice from "Components/Notice";
import { getDateWithTime } from "Utils/DateUtils";
import OrderDocument from "Components/Document/OrderDocument";
import QADocument from "Components/Document/QADocument";
import { QASchemeModel } from "Apis/Models/QASchemeModel";
import { QaSchemeDefinitionModel } from "Apis/Models/QaSchemeDefinitionModel";
import OfferDocument from "Components/Document/OfferDocument";
import TimeEntryDocument from "../../../../Components/Document/TimeEntryDocument";
import {TimeEntryModel} from "../../../../Apis/Models/TimeEntryModel";
import NoteDocument from "../../../../Components/Document/NoteDocument";

const InvoiceDetailsPage = () => {
    
    const { id } = useParams<{ id }>();

    const {register, errors, handleSubmit} = useForm<{reason}>();

    const [showRejectModal, setShowRejectModal] = useState<boolean>(false);
    const [order, setOrder] = useState<OrderModel>();
    const [timeEntries, setTimeEntries] = useState<TimeEntryModel[]>([]);
    const [invoice, setInvoice] = useState<InvoiceModel>();
    const [offers, setOffers] = useState<OfferModel[]>();
    const [qaSchemes, setQASchemes] = useState<{qaSchema: QASchemeModel, qaSchemaDefinition: QaSchemeDefinitionModel}[]>([]);

    const {data: adminInvoice, isLoading: isLoadingInvoice} = useInvoice(id);
    const [approveInvoiceMutation, {isLoading: isApprovingInvoice}] = useApproveInvoice();
    const [rejectInvoiceMutation, {isLoading: isRejectingInvoice}] = useRejectInvoice();

    useEffect(() => {
        if (adminInvoice) {
            setOrder(adminInvoice.order);
            setTimeEntries(adminInvoice.timeEntries);
            setInvoice(adminInvoice.invoice);
            setOffers(adminInvoice.offers);
            
            let schemas = adminInvoice.qaSchemes.map(scheme => {
                let definition = adminInvoice.schemeDefinitions.find(definition => definition.id === scheme.qaSchemeDefinitionId);
                
                return {
                    qaSchema: scheme!,
                    qaSchemaDefinition: definition!
                };
            });
            setQASchemes(schemas);
        }
    }, [adminInvoice])

    const approveInvoice = async () => {
        await approveInvoiceMutation(invoice!.id);

        openSuccessNotification('Success', `${getInvoiceTypeAsString(invoice?.invoiceType!)} blev godkendt`)
    }

    const rejectInvoice = async (data: {reason}) => {
        await rejectInvoiceMutation({id: invoice!.id, reason: data.reason});

        openSuccessNotification('Success', `${getInvoiceTypeAsString(invoice?.invoiceType!)} blev afvist`)

        setShowRejectModal(false)
    }

    return (
        <DefaultPageLayout title={"Faktura til godkendelse"} isLoading={isLoadingInvoice} icon={<Approve />}>
            <DocumentContainer before={(
                <FlexRow justify={"center"}>
                    <FlexRow justify={"end"} align={"end"} direction={"column"} style={{maxWidth: '794px'}}>
                        {invoice?.state === 'WaitingForApproval' && (<Space>
                            <StyledButton disabled={isRejectingInvoice || isApprovingInvoice} loading={isRejectingInvoice} onClick={() => setShowRejectModal(true)} color={"danger"}>Afvis</StyledButton>
                            <StyledButton disabled={isRejectingInvoice || isApprovingInvoice} loading={isApprovingInvoice} onClick={() => approveInvoice()} color={"primary"}>Godkend</StyledButton>
                        </Space>)}
                    </FlexRow>
        
                    {invoice?.approval && invoice.approval.approvedAt && (
                        <Notice type={invoice.approval.isApproved ? 'Success' : 'Danger'}>
                            <FlexRow direction={"column"} align={"start"}>
                                <b>
                                    {invoice.approval.isApproved ? 'Godkendt' : 'Afvist'} {getDateWithTime(invoice.approval.approvedAt)}
                                </b>
                                <span>
                                {invoice?.approval?.reason}
                            </span>
                            </FlexRow>
                        </Notice>
                    )}
                </FlexRow>
            )}>
                <InvoiceDocument invoice={invoice} />
                <OrderDocument order={order} />
                <TimeEntryDocument order={order} timeEntries={timeEntries} />
                {invoice?.notes.map(note => (
                    <NoteDocument note={note} order={order} />
                ))}
                {offers?.map(offer => (
                    <OfferDocument offer={offer} />
                ))}
                {qaSchemes.map(x => (
                    <QADocument order={order} qaSchema={x.qaSchema} qaSchemaDefinition={x.qaSchemaDefinition} />
                ))}
            </DocumentContainer>
            <Modal visible={showRejectModal} title={"Afvis faktura"}
                   onCancel={() => setShowRejectModal(false)}
                footer={false}
            >
                <form onSubmit={handleSubmit(rejectInvoice)}>
                    <textarea placeholder={"Indtast begundelse"} name="reason" rows={4} className={getInputClasses(errors.reason)} ref={register({required: true})} />
                    <FlexRow justify={"end"}>
                        <Space>
                            <StyledButton onClick={() => setShowRejectModal(false)}>Afbryd</StyledButton>
                            <StyledButton color={"danger"} type={"submit"}>Afvis</StyledButton>
                        </Space>
                    </FlexRow>
                </form>
            </Modal>
        </DefaultPageLayout>
    )

}

export default InvoiceDetailsPage;
