import React from 'react';
import {useKlarPrisAccount} from "Apis/klarprisApi";
import DefaultPageLayout from "Pages/Layouts/DefaultPageLayout";
import FlexRow from "Components/FlexRow";
import StyledButton from "Components/StyledButton";
import {useHistory} from "react-router-dom";
import Purchase from "Assets/Icons/Purchase";

const KlarPrisPage = () => {

    const history = useHistory();

    const {data: klarPrisAccount, isLoading} = useKlarPrisAccount();

    const gotoKlarpris = () => {
        history.push('/settings/klarpris')
    }

    if (isLoading) {
        return (
            <DefaultPageLayout title={""} isLoading={true} />
        )
    }

    if (!klarPrisAccount) {
        return (
            <DefaultPageLayout title={"Ingen klarpris konto"} icon={<Purchase />}>
                <FlexRow justify={"space-between"}>
                <span>
                    Du skal oprette en KlarPris konto inden du kan bruge vareindkøb
                </span>
                    <StyledButton onClick={gotoKlarpris}>Gå til oprettelse</StyledButton>
                </FlexRow>
            </DefaultPageLayout>
        )
    }


    return (
        <iframe title={"KlarPris"}  loading={"eager"} style={{boxShadow: '0px 3px 20px rgba(0, 0, 0, .16)', height: '100%', borderRadius: 10}} width={'100%'} height={'100%'} src={`https://api.klarpris.dk?token=${klarPrisAccount.token}`} frameBorder="0" />
    )
}

export default KlarPrisPage
