import {useHistory} from "react-router-dom";
import {useOrganizations} from "Apis/OrganizationAdminApi";
import DefaultPageLayout from "Pages/Layouts/DefaultPageLayout";
import Approve from "Assets/Icons/Approve";
import {Table} from "antd";
import Spinner from "Components/Spinner";
import FlexRow from "Components/FlexRow";
import React from "react";
import {useRemindersReadyForApproval} from "Apis/Admin/Reminders/ReminderApi";
import {ReminderModel} from "Apis/Admin/Reminders/Models/ReminderModel";
import moment from "moment";

const ReminderApprovalList = () => {

    const history = useHistory();

    const {data: organizations, isLoading: isLoadingOrganizations} = useOrganizations(true);
    const {data: offers, isLoading: isLoadingOffers} = useRemindersReadyForApproval();
    
    const getOrganizationName = (id) => {
        return organizations?.find(x => x.id === id)?.name ?? "";
    }

    return (
        <DefaultPageLayout title={"Rykkere til godkendelse"} isLoading={!organizations} icon={<Approve />}>
            <Table
                size={"small"}
                rowKey={'id'}
                dataSource={offers}
                loading={{indicator: <Spinner />, spinning: isLoadingOrganizations || isLoadingOffers}}
                pagination={false}
                onRow={(record) => {
                    return {
                        onClick: () => history.push(`/admin/approval/reminders/${record.id}`),
                    };}
                }
                rowClassName={'clickable'}
            >
                <Table.Column sorter={(a: ReminderModel, b: ReminderModel) => a.invoice.invoicingAddress.customerName < b.invoice.invoicingAddress.customerName ? -1 : 1} title={"Kunde"} render={(_, record) => record.invoice.invoicingAddress.customerName} />
                <Table.Column sorter={(a: ReminderModel, b: ReminderModel) => a.invoice.date! < b.invoice.date! ? -1 : 1} title={"Faktura dato"} render={(_, record) => moment(record.invoice.date).format("DD/MM/YYYY")} />
                <Table.Column sorter={(a: ReminderModel, b: ReminderModel) => getOrganizationName(a.organizationId)! < getOrganizationName(b.organizationId)! ? -1 : 1} title={"Håndværker"} dataIndex={"organizationId"} render={(text, record: ReminderModel) => getOrganizationName(record.organizationId) } />

                <Table.Column title={"Installationsadresse"} dataIndex={"customerId"} render={(text, record: ReminderModel) => (
                    <FlexRow direction={"column"} justify={"start"} align={"start"}>
                        <span style={{fontSize: '.8em'}}>
                            {record.invoice.deliveryAddress.street} - {record.invoice.deliveryAddress.zipCode} - {record.invoice.deliveryAddress.city}
                        </span>
                    </FlexRow>
                ) } />

            </Table>
        </DefaultPageLayout>
    )

}

export default ReminderApprovalList
