import React from 'react';
import {
    useAcceptKlarPrisTerms,
    useCreateKlarPrisAccount,
    useKlarPrisAccount,
    useKlarPrisTerms,
    useKlarPrisWholeSalers
} from "Apis/klarprisApi";
import DefaultPageLayout from "Pages/Layouts/DefaultPageLayout";
import FlexRow from "Components/FlexRow";
import StyledButton from "Components/StyledButton";
import {Row} from "antd";
import WholeSalerItem from "Pages/Settings/Klarpris/Components/WholeSalerItem";
import Purchase from "Assets/Icons/Purchase";

const KlarPrisPage = () => {

    const {data: klarPrisAccount, isLoading: isLoadingKlarPrisAccount} = useKlarPrisAccount();
    const {data: klarPrisTerms, isLoading: isLoadingTerms} = useKlarPrisTerms(klarPrisAccount === null);
    const {data: wholesalers, isLoading: isLoadingWholesalers} = useKlarPrisWholeSalers(klarPrisAccount !== null);

    const [createKlarPrisAccount, {isLoading: isCreatingAccount }] = useCreateKlarPrisAccount();
    const [acceptKlarPrisTerms, {isLoading: isAcceptingTerms}] = useAcceptKlarPrisTerms();

    const createAccountAndAcceptTerms = async () => {
        await createKlarPrisAccount()

        await acceptKlarPrisTerms();
    }

    return (
        <DefaultPageLayout title={"Leverandører"} isLoading={isLoadingKlarPrisAccount || isLoadingTerms || isLoadingWholesalers} icon={<Purchase />}>

            {!klarPrisAccount && klarPrisTerms && (
                <div>
                    <h2>
                        Inden at du kan oprette forbindelse til dine leverandører igennem ASAP'en, skal du først oprette en KlarPris konto.
                    </h2>

                    <span>Du skal acceptere KlarPris vilkår:</span>

                    <div style={{maxHeight: '40vh', overflow: "scroll", backgroundColor: '#F5F5F2', padding: 20, borderRadius: 10, margin: '20px 0'}}>
                        <div dangerouslySetInnerHTML={{__html: klarPrisTerms}} />
                    </div>

                    <FlexRow justify={"end"}>
                        <StyledButton onClick={createAccountAndAcceptTerms} color={"primary"} disabled={isCreatingAccount || isAcceptingTerms} loading={isCreatingAccount || isAcceptingTerms}>Jeg acceptere vilkårne - Opret KlarPris konto</StyledButton>
                    </FlexRow>
                </div>

            )}

            <Row gutter={[20, 20]}>
                {wholesalers?.map(x => (<WholeSalerItem key={x.id} wholesaler={x} />))}
            </Row>

        </DefaultPageLayout>
    )
}

export default KlarPrisPage
