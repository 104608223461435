import DocumentLayout from "Components/Document/DocumentLayout";
import styles from './OrderDocument.module.scss';
import React from "react";
import { OrderModel } from "Apis/Models/OrderModel";
import DocumentHeader from "Components/Document/DocumentHeader";
import {NoteModel} from "../../Apis/Models/NoteModel";

interface Props {
    order?: OrderModel;
    note?: NoteModel
}
const NoteDocument = ({order, note}: Props) => {
    
    if (!order || !note) {
        return null;
    }

    return (
        <DocumentLayout>
            <DocumentHeader invoicingAddress={order.invoicingAddress} deliveryAddress={order.deliveryAddress} />

            <div>
                Note
            </div>

            <hr className={styles.separator}/>

            <div style={{fontWeight: 600}}>
                {note.title}
            </div>

            <hr className={styles.separator}/>

            <div className={styles.descriptionContainer}>
                <span className={"display-linebreak"}>
                    {note.text}
                </span>
            </div>
        </DocumentLayout>
    )
}

export default NoteDocument
