import FlexRow from "Components/FlexRow";
import StyledButton from "Components/StyledButton";
import {Space} from "antd";
import React, {useState} from "react";
import {useForm} from "react-hook-form";
import { resetPassword } from "FirebaseAuth";
import {getInputClasses} from "Utils/CssUtils";
import {Link} from "react-router-dom";

const ResetPasswordForm = () => {

    const [error, setError] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const {register, handleSubmit, errors} = useForm<{email}>();

    const onSubmitHandler = async (data) => {
        setLoading(true);

        resetPassword(data.email).then(() => {
            setError("Check din email");
        }).catch(e => {
            if (e.code === 'auth/invalid-email') {
                setError("Indtast en gyldigt email");
            }
            if (e.code === 'auth/network-request-failed') {
                setError("Der skete en uventet fejl, prøv igen");
            }
        }).finally(() => setLoading(false));

    }

    return (
        <form onSubmit={handleSubmit(onSubmitHandler)}>
            <Space direction={"vertical"} size={"middle"} className={"w-100"}>

                {error && (<FlexRow justify={'center'}>
                    {error}
                </FlexRow>)}

                <div>
                    <label>
                        E-Mail
                    </label>
                    <input className={getInputClasses(errors.email)} type="text" name={"email"} ref={register({required: true})} />
                </div>

                <FlexRow justify={'space-between'}>
                    <Link to={'/auth/login'}>Log ind</Link>
                    <StyledButton type={"submit"} loading={loading} color={"primary"}>Nulstil kodeord</StyledButton>
                </FlexRow>

            </Space>
        </form>
    )
}

export default ResetPasswordForm
