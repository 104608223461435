export interface TimeEntryModel {
    id?: number;
    organizationId?: number;
    orderId?: number;
    description: string;
    from: Date;
    to: Date;
    userId: number;
}

export function deserialize(timeEntry: TimeEntryModel): TimeEntryModel {
    const from = new Date(timeEntry.from);
    const to = new Date(timeEntry.to);

    return {...timeEntry, from, to}
}
