import React, {useCallback, useState} from "react";
import Report from "../../../Assets/Icons/Report";
import Box from "../../../Components/Box";
import FlexRow from "../../../Components/FlexRow";
import DefaultPageLayout from 'Pages/Layouts/DefaultPageLayout';
import {useCurrentQaSchemeDefinitions} from "../../../Apis/QaSchemeDefinitionApi";
import {getQaSchemePdf, useQaSchemes} from "../../../Apis/Admin/QaSchemes/AdminQASchemeApi";
import Spinner from "../../../Components/Spinner";
import {Table} from "antd";
import {openErrorNotification, openSuccessNotification} from "../../../Utils/NotificationUtils";
import {QASchemeOverviewModel} from "../../../Apis/Admin/QaSchemes/Models/QASchemeOverviewModel";
import {QASchemeModel} from "../../../Apis/Models/QASchemeModel";
import moment from "moment/moment";
import _ from 'lodash';

const QaSchemesPageAdmin = () => {

    const [selectedSchemaName, setSelectedSchemaName] = useState<string>("")
    const [query, setQuery] = useState<string>('')
    const [page, setPage] = useState<number>(0);

    const { data: qaSchemesDefinitions } = useCurrentQaSchemeDefinitions();

    const { data: schemes, isLoading: isLoadingSchemes } = useQaSchemes(page, 50, selectedSchemaName, query);

    const downloadQaPdf = async (schemeModel: QASchemeOverviewModel) => {
        openSuccessNotification('Henter KLS skema', 'Det tager et øjeblik')

        try {
            await getQaSchemePdf(schemeModel.id);
        }
        catch (e) {
            openErrorNotification('Der skete en fejl under download af KLS skema', '')
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const search = useCallback(_.debounce(async (searchFilter: string) => {
        setQuery(searchFilter)
    }, 300), [])

    return (
        <DefaultPageLayout title={"Kvalitetssikring"} icon={<Report/>}
                           beforeBox={
                               <Box style={{ marginBottom: 15, padding: 10 }}>
                                   <FlexRow>
                                       <input onChange={e => search(e.target.value)} type="text"
                                              className={"input input-invisible full-width"} placeholder={"Søg...."}/>
                                       <select style={{paddingRight: 20, marginRight: 20, width: '100%'}}
                                               onChange={x => {
                                                   setSelectedSchemaName(x.currentTarget.value);
                                                   setPage(0)
                                               }}>
                                           <option value={''}>Filtrer efter type</option>
                                           {qaSchemesDefinitions?.map(x => <option key={x.id}
                                                                                   value={x.schemeName}>{x.schemeName}</option>)}
                                       </select>
                                   </FlexRow>
                               </Box>
                           }
        >
            <Table
                size={"small"}
                rowKey={'id'}
                dataSource={schemes?.results}
                loading={{indicator: <Spinner />, spinning: isLoadingSchemes}}
                onRow={(record) => {
                    return {
                        onClick: () => downloadQaPdf(record),
                    };}
                }
                pagination={{
                    pageSize: 50,
                    current: page + 1,
                    total: schemes?.totalResults,
                    onChange: (newPage, pageSize) => {
                        setPage(newPage - 1)
                    }
                }}
                rowClassName={'clickable'}
            >

                <Table.Column title={"Type"} dataIndex={"type"} render={(value, record: QASchemeOverviewModel) => record.qaSchemeDefinition.schemeName} />
                <Table.Column title={"Afsluttet"} dataIndex={"finishedAt"} render={(value, record: QASchemeModel) => value ? moment(value).format('DD MMMM YYYY') : 'Ikke afsluttet'} />
                <Table.Column title={"Håndværker"} dataIndex={"user"} render={(text, record: QASchemeOverviewModel) => record.user.name } />

                <Table.Column title={"Installationsadresse"} dataIndex={"customerId"} render={(text, record: QASchemeOverviewModel) => (
                    <FlexRow direction={"column"} justify={"start"} align={"start"}>
                        <span style={{fontSize: '.8em'}}>
                            {record.order.deliveryAddress.street} - {record.order.deliveryAddress.zipCode} - {record.order.deliveryAddress.city}
                        </span>
                    </FlexRow>
                ) } />

            </Table>
        </DefaultPageLayout>
    )

}

export default QaSchemesPageAdmin