import DocumentLayout from "Components/Document/DocumentLayout";
import styles from './OrderDocument.module.scss';
import React from "react";
import { OrderModel } from "Apis/Models/OrderModel";
import DocumentHeader from "Components/Document/DocumentHeader";
import {Col, Row} from "antd";
import {TimeEntryModel} from "../../Apis/Models/TimeEntryModel";
import moment from "moment/moment";

interface Props {
    order?: OrderModel;
    timeEntries?: TimeEntryModel[]
}
const TimeEntryDocument = ({order, timeEntries}: Props) => {
    
    if (!order) {
        return null;
    }

    const getTime = (time: number) => {
        return time < 10 ? '0' + time : time;
    }

    const getSecondsFromDuration = (duration: number) => {
        let unusedDuration = duration;

        const hours = Math.floor(unusedDuration / 3600);

        unusedDuration = unusedDuration % 3600;

        const minutes = Math.floor(unusedDuration / 60);

        return `${getTime(hours)}:${getTime(minutes)}`
    }
    
    return (
        <DocumentLayout>
            <DocumentHeader invoicingAddress={order.invoicingAddress} deliveryAddress={order.deliveryAddress} />
    
            <div>
                Detaljeret timeopgørelse
            </div>
    
            <hr className={styles.separator}/>
    
            <div style={{fontWeight: 600}}>
                {order.title}
            </div>
    
            <hr className={styles.separator}/>
    
            <div className={styles.descriptionContainer}>
                <Row gutter={10}>
                    <Col span={4}>Dato</Col>
                    <Col span={16}>Beskrivelse</Col>
                    <Col span={4}>Tid</Col>
                </Row>

                <hr className={styles.separator}/>

                <div className={styles.descriptionContainer}>
                    <Row gutter={[10, 10]} align={'middle'}>
                        {timeEntries?.map(line => (
                            <React.Fragment key={line.id}>
                                <Col span={4}>
                                    {moment(line.from).format('DD/MM/YYYY')}
                                </Col>
                                <Col span={16}>
                                    {line.description}
                                </Col>
                                <Col span={4}>
                                    {getSecondsFromDuration(moment(line.to).diff(moment(line.from), 'second'))}
                                </Col>
                            </React.Fragment>
                        ))}
                    </Row>
                </div>
        
        
                <br/>
                <div>
                    Via ELi A/S medlemskab af Tekniq arbejdsgiverne, er du som privatforbruger
                    omfattet af Tekniq’s garantiordning. Ligeledes gælder Tekniq’s standardforbehold for
                    el og vvs af 2007. <a href="https://www.tekniq.dk/forbruger">Klik her for at se
                    mere</a>
                </div>
            </div>
        </DocumentLayout>
    )
}

export default TimeEntryDocument
