import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import OverviewPage from "Pages/Admin/Organizations/Overview/OverviewPage";
import CreateOrganizationPage from "Pages/Admin/Organizations/Create/CreateOrganizationPage";
import EditOrganizationPage from "Pages/Admin/Organizations/Edit/EditOrganizationPage";

const OrganizationsPage = () => {

    let {path} = useRouteMatch();

    return (
        <Switch>
            <Route path={`${path}`} exact>
                <OverviewPage />
            </Route>
            <Route path={`${path}/create`}>
                <CreateOrganizationPage />
            </Route>
            <Route path={`${path}/edit/:id`}>
                <EditOrganizationPage />
            </Route>
        </Switch>
    )
}

export default OrganizationsPage;
