import React from "react";
import {Table} from "antd";
import Spinner from "Components/Spinner";
import { useHistory } from "react-router-dom";
import DefaultPageLayout from "Pages/Layouts/DefaultPageLayout";
import Building from "Assets/Icons/Building";
import {useSupplierApplications} from "Apis/Public/SupplierApplications/PublicSupplierApplicationApi";
import {SupplierApplicationModel} from "Apis/Public/SupplierApplications/Models/SupplierApplicationModel";
import OverviewPage from "Pages/Admin/Organizations/Overview/OverviewPage";

export const SupplierApplicationsOverviewPage = () => {

    const history = useHistory();

    const {data: applications, isLoading: isLoadingApplications} = useSupplierApplications();

    const getOccupationText = (text: string) => {
        return text === 'Sanitarian' ? 'VVS' : 'Elektriker';
    }

    return (
        <DefaultPageLayout title={"Ansøgninger"} icon={<Building />}>
            <Table rowKey={'id'} dataSource={applications} loading={{indicator: <Spinner />, spinning: isLoadingApplications}} pagination={false}
               onRow={(record: SupplierApplicationModel) => {
                   return {
                       onClick: () => history.push(`/admin/supplierApplications/${record.id}`),
                   };}
               }
               rowClassName={"clickable"}
            >
                <Table.Column title={"Navn"} dataIndex={"name"} render={(text, record: SupplierApplicationModel) => `${record.firstName} ${record.lastName}`} />
                <Table.Column title={"Firmanavn"} dataIndex={"companyName"} />
                <Table.Column title={"Beskæftigelse"} dataIndex={"occupation"} render={(getOccupationText)} />
                <Table.Column title={"E-Mail"} dataIndex={"email"} />
            </Table>
        </DefaultPageLayout>
    )

}

export default OverviewPage;
