import React from "react";
import DefaultPageLayout from "Pages/Layouts/DefaultPageLayout";
import ShoppingList from "Assets/Icons/ShoppingList";
import OrganizationForm from "Pages/Admin/Organizations/Forms/OrganizationForm";

const CreateOrganizationPage = () => {

    return (
        <DefaultPageLayout title={"Opret firma"} icon={<ShoppingList />}>
            <OrganizationForm />
        </DefaultPageLayout>
    )
}

export default CreateOrganizationPage;
