import moment from 'moment';

export const getDateOnlyString = (date: Date) => {
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
}

export const getBackendDateFormat = (date: Date) => {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
}

export const getDateWithTime = (date: Date) => {
    return moment(date).format('DD/MM/YYYY HH:mm')
}
