import {queryCache, useMutation, useQuery} from "react-query";
import {request} from "Apis/BaseApi";
import { AdminInvoiceModel, DeprecationReadModel, InvoiceModel } from "Apis/Models/InvoiceModel";
import {deserialize} from "Apis/Models/OfferModel";

export const invoicesForApprovalQueryCacheKey = 'invoicesForApproval';
export const useInvoicesForApproval = () => {
    return useQuery<InvoiceModel[]>(invoicesForApprovalQueryCacheKey, async () => {
        return await request<InvoiceModel[]>({
            url: `/admin/invoices/readyForApproval`,
            method: 'GET'
        })
    })
}

export const INVOICES_READY_FOR_DEPRECATION = 'INVOICES_READY_FOR_DEPRECATION';
export const useInvoicesReadyForDeprecation = () => {
    return useQuery<DeprecationReadModel[]>(INVOICES_READY_FOR_DEPRECATION, async () => {
        return await request<DeprecationReadModel[]>({
            url: `/admin/invoices/readyForDeprecation`,
            method: 'GET'
        })
    })
}


export const invoiceAdminQueryCacheKey = 'invoiceAdmin';
export const useInvoice = (id: number) => {
    return useQuery<AdminInvoiceModel>([invoiceAdminQueryCacheKey, id], async () => {
        const response =  await request<AdminInvoiceModel>({
            url: `/admin/invoices/${id}`,
            method: 'GET'
        })

        return {
            ...response,
            offers: response.offers.map(x => deserialize(x))
        }
    })
}

export const useApproveInvoice = () => {
    return useMutation((id: number) => {
        return request<InvoiceModel>({
            url: `/admin/invoices/approve/${id}`,
            method: 'POST'
        })
    }, {
        onSuccess: (invoice: InvoiceModel) => {
            queryCache.invalidateQueries(invoiceAdminQueryCacheKey)
            queryCache.invalidateQueries(invoicesForApprovalQueryCacheKey)
        }
    })
}

export const useRejectInvoice = () => {
    return useMutation(async ({id, reason}: any): Promise<InvoiceModel> => {
        return await request<InvoiceModel>({
            url: `/admin/invoices/reject/${id}`,
            method: 'POST',
            data: {
                reason
            }
        })
    }, {
        onSuccess: (invoice: InvoiceModel) => {
            queryCache.invalidateQueries(invoiceAdminQueryCacheKey)
            queryCache.invalidateQueries(invoicesForApprovalQueryCacheKey)
        }
    })
}

export const useDeprecateInvoice = () => {
    return useMutation(async ({id}: any): Promise<InvoiceModel> => {
        return await request<InvoiceModel>({
            url: `/admin/invoices/deprecate/${id}`,
            method: 'POST',
        })
    }, {
        onSuccess: (invoice: InvoiceModel) => {
            queryCache.invalidateQueries(INVOICES_READY_FOR_DEPRECATION)
        }
    })
}
