import {useState} from "react";

export const useIsLoading = (fn: () => void): [boolean, () => void] => {

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const execute = async () => {
        setIsLoading(true);

        try {
            await fn();
        }
        catch (e) {
            // Do nothing
        }
        finally {
            setIsLoading(false);
        }
    }

    return [isLoading, execute]
}
