import DefaultPageLayout from "Pages/Layouts/DefaultPageLayout";
import OrderForm from "Pages/Projects/Orders/Forms/OrderForm";
import React from "react";

const CreateOrderPage = () => {

    return (
        <DefaultPageLayout title={`Opret ordre`}>
            <OrderForm  />
        </DefaultPageLayout>
    )
}

export default CreateOrderPage;
