import {queryCache, useMutation, useQuery} from "react-query";
import {request} from "Apis/BaseApi";
import {ProductModel} from "Apis/Models/ProductModel";

export const searchForProducts = async (searchText: string): Promise<ProductModel[]> => {
    return await request<ProductModel[]>({
        url: `/products/search?searchTerm=${searchText}`,
        method: 'GET',
    })
}

export const productsCacheKey = 'products';
export const useProducts = () => {
    return useQuery<ProductModel[]>(productsCacheKey, async () => {
        return await request<ProductModel[]>({
            url: `/products`,
            method: 'GET'
        })
    })
}

export const useSaveProduct = () => {
    return useMutation(async (entry: ProductModel): Promise<ProductModel> => {
        let response;

        if (entry.id) {
            response = await request<ProductModel>({
                url: `/products/${entry.id}`,
                method: 'PUT',
                data: entry
            })
        } else {
            response = await request<ProductModel>({
                url: `/products`,
                method: 'POST',
                data: entry
            })
        }
        return response;
    }, {
        onSuccess: (entry: ProductModel) => {
            queryCache.invalidateQueries(productsCacheKey)
        },
    })
}

export const useDeleteProduct = () => {
    return useMutation(async (product: ProductModel): Promise<ProductModel> => {
        return await request<ProductModel>({
            url: `/products/${product.id}`,
            method: 'DELETE',
        })
    }, {
        onSuccess: (entry: ProductModel) => {
            queryCache.invalidateQueries(productsCacheKey)
        },
    })
}
