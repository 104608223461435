import React from "react";
import { DocumentReadModel } from "Apis/Documents/Models/DocumentReadModel";
import styles from './DocumentFolder.module.scss';
import Report from "Assets/Icons/Report";
import FlexRow from "Components/FlexRow";
import { downloadFile } from "Apis/FileApi";
import { useGetDocumentFileUrl } from "Apis/Documents/DocumentsApi";
import { saveAs } from 'file-saver';

interface Props {
    file: DocumentReadModel,
}

const DocumentFile = (props: Props) => {
    const [useGetDocumentFileUrlMutation] = useGetDocumentFileUrl();

    const DownloadFile = async () => {
        const file = await useGetDocumentFileUrlMutation(props.file.id);

        const blob = await downloadFile(file.link);
        saveAs(blob, file.fileName)

    }
    return (
        <FlexRow justify={"space-between"} align={"center"} direction={"column"} className={styles.container} onClick={DownloadFile}>
            <FlexRow justify={"center"} align={"center"} className={"h-100"}>
                <Report width={'20%'} fill="rgba(147, 147, 147, 0.5)"/>
            </FlexRow>
            <FlexRow align={"center"} justify={"center"}>
                <div className={"w-100 text-ellipsis"}>
                    {props.file.name}
                </div>
            </FlexRow>
        </FlexRow>
    )
}

export default DocumentFile;