import React from 'react';

const Bell = (props) => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Capa_1"
             x="0px" y="0px" viewBox="0 0 512 512" {...props}>
<g>
	<g>
		<path
            d="M256,0c-37.554,0-68.11,30.556-68.11,68.11v20.55h35.229V68.11c0-18.131,14.755-32.881,32.881-32.881    c18.131,0,32.887,14.749,32.887,32.881v20.55h35.229V68.11C324.116,30.556,293.555,0,256,0z"/>
	</g>
</g>
            <g>
	<g>
		<path
            d="M304.147,429.205c0,26.228-21.337,47.565-47.56,47.565h-1.174c-26.222,0-47.56-21.337-47.56-47.565h-35.229    c0,45.657,37.138,82.795,82.789,82.795h1.174c45.651,0,82.789-37.138,82.789-82.795H304.147z"/>
	</g>
</g>
            <g>
	<g>
		<path
            d="M483.952,422.623l-50.043-77.851v-99.928c0-99.071-79.812-179.67-177.908-179.67c-98.102,0-177.908,80.599-177.908,179.67    v99.928l-50.043,77.845c-3.488,5.419-3.734,12.313-0.646,17.967c3.088,5.654,9.013,9.177,15.46,9.177h426.275    c6.447,0,12.371-3.523,15.454-9.171C487.686,434.936,487.44,428.043,483.952,422.623z M75.127,414.532l35.394-55.063    c1.826-2.836,2.801-6.148,2.801-9.524V244.844c0-79.642,64.006-144.44,142.679-144.44c78.679,0,142.679,64.799,142.679,144.44    v105.101c0,3.376,0.969,6.682,2.795,9.524l35.394,55.063H75.127z"/>
	</g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
</svg>
    )
}

export default Bell
