import {getVat, roundNumber} from "Utils/NumberUtils";
import {CalculationLine} from "Apis/Models/CalculationLine";

export const getLineTotalExVat = (line: CalculationLine) => {
    return roundNumber(line.amount * (line.salesPrice * (1 - (line.discount / 100))))
}

export const getLineTotalVat = (line: CalculationLine) => {
    return roundNumber(getVat(getLineTotalExVat(line)));
}

export const getLineTotalInclVat = (line: CalculationLine) => {
    return getLineTotalExVat(line) + getLineTotalVat(line);
}
