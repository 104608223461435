import DefaultPageLayout from "Pages/Layouts/DefaultPageLayout";
import FlexRow from "Components/FlexRow";
import StyledButton from "Components/StyledButton";
import {Table} from "antd";
import Spinner from "Components/Spinner";
import React from "react";
import {useUsers} from "Apis/UserAdminApi";
import { useHistory } from "react-router-dom";
import User from "Assets/Icons/User";

const UsersOverviewPage = () => {

    const history = useHistory();

    const {data: users, isLoading: isLoadingUsers} = useUsers();

    const getAccessLevelLabel = (text: string) => {
        return text === 'Admin' ? 'Administrator' : 'Håndværker';
    }

    return (
        <DefaultPageLayout title={"Brugere"} icon={<User />}>
            <FlexRow justify={"end"}>
                <StyledButton color={"primary"} onClick={() => history.push('/admin/users/create')}>Opret Bruger</StyledButton>
            </FlexRow>
            <Table rowKey={'id'} dataSource={users} loading={{indicator: <Spinner />, spinning: isLoadingUsers}} pagination={false}
               onRow={(record) => {
                   return {
                       onClick: () => history.push(`/admin/users/edit/${record.id}`),
                   };}
               }
               rowClassName={"clickable"}
            >
                <Table.Column title={"Id"} dataIndex={'id'} />
                <Table.Column title={"Initials"} dataIndex={"initials"}/>
                <Table.Column title={"Navn"} dataIndex={"name"} />
                <Table.Column title={"Email"} dataIndex={"email"} />
                <Table.Column title={"Adgangsniveau"} dataIndex={"accessLevel"} render={getAccessLevelLabel} />
            </Table>
        </DefaultPageLayout>
    )
}

export default UsersOverviewPage;
