import DefaultPageLayout from "Pages/Layouts/DefaultPageLayout";
import React from "react";
import {Table} from "antd";
import {useOrganizations} from "Apis/OrganizationAdminApi";
import Spinner from "Components/Spinner";
import {OfferModel} from "Apis/Models/OfferModel";
import FlexRow from "Components/FlexRow";
import {getOfferTypeAsString} from "Utils/OfferUtils";
import {getDateOnlyString} from "Utils/DateUtils";
import { useHistory } from "react-router-dom";
import {useOffersForApproval} from "Apis/OfferAdminApi";
import Approve from "Assets/Icons/Approve";

const OfferApprovalPage = () => {

    const history = useHistory();

    const {data: organizations, isLoading: isLoadingOrganizations} = useOrganizations();
    const {data: offers, isLoading: isLoadingOffers} = useOffersForApproval();

    return (
        <DefaultPageLayout title={"Tilbud til godkendelse"} isLoading={!organizations} icon={<Approve />}>
            <Table
                size={"small"}
                rowKey={'id'}
                dataSource={offers}
                loading={{indicator: <Spinner />, spinning: isLoadingOrganizations || isLoadingOffers}}
                pagination={false}
                onRow={(record) => {
                    return {
                        onClick: () => history.push(`/admin/approval/offers/${record.id}`),
                    };}
                }
                rowClassName={'clickable'}
            >

                <Table.Column title={"Type"} dataIndex={"offerType"} render={(text, record: OfferModel) => (
                    <FlexRow justify={"start"}>
                        <FlexRow align={"start"} justify={"start"} direction={"column"}>
                            {getOfferTypeAsString(record.offerType)}
                        </FlexRow>
                    </FlexRow>
                )} />

                <Table.Column title={"Håndværker"} dataIndex={"organizationId"} render={(text, record: OfferModel) => organizations?.find(x => x.id === record.organizationId)!.name } />
                <Table.Column title={"Dato"} dataIndex={"date"} render={(text, record: OfferModel) => record.date ? getDateOnlyString(record.date) : '' } />
                <Table.Column title={"Overskrift"} dataIndex={"title"} />

                <Table.Column title={"Installationsadresse"} dataIndex={"customerId"} render={(text, record: OfferModel) => (
                    <FlexRow direction={"column"} justify={"start"} align={"start"}>
                        <span style={{fontSize: '.8em'}}>
                            {record.deliveryAddress.street} - {record.deliveryAddress.zipCode} - {record.deliveryAddress.city}
                        </span>
                    </FlexRow>
                ) } />

            </Table>
        </DefaultPageLayout>
    )

}

export default OfferApprovalPage;
