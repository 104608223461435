import {queryCache, useMutation, useQuery} from "react-query";
import {request} from "./BaseApi";
import {OrderMaterialModel} from "Apis/Models/OrderMaterialModel";

export const orderMaterialsCacheKey = 'orderMaterials';
export const useOrderMaterials = (orderId: number) => {
    return useQuery<OrderMaterialModel[]>([orderMaterialsCacheKey, orderId], async () => {
        return await request<OrderMaterialModel[]>({
            url: `/ordermaterials/order/${orderId}`,
            method: 'GET'
        })
    })
}

export const useSaveOrderMaterial = () => {
    return useMutation(async (entry: OrderMaterialModel): Promise<OrderMaterialModel> => {
        let response;

        if (entry.id) {
            response = await request<OrderMaterialModel>({
                url: `/ordermaterials/${entry.id}`,
                method: 'PUT',
                data: entry
            })
        } else {
            response = await request<OrderMaterialModel>({
                url: `/ordermaterials`,
                method: 'POST',
                data: entry
            })
        }

        return response;
    }, {
        onSuccess: (entry: OrderMaterialModel) => {
            queryCache.invalidateQueries(orderMaterialsCacheKey)
            queryCache.invalidateQueries([orderMaterialsCacheKey, entry.orderId])
        },
    })
}

export const useSaveOrderMaterials = () => {
    return useMutation(async ({orderId, materials}: any) => {
        return await request({
            url: `/ordermaterials/list/${orderId}`,
            method: 'PUT',
            data: materials
        })
    }, {
        onSuccess: (entry) => {
            queryCache.invalidateQueries(orderMaterialsCacheKey)
        },
    })
}


export const useDeleteOrderMaterial = () => {
    return useMutation(async (entry: OrderMaterialModel): Promise<OrderMaterialModel> => {
        return await request<OrderMaterialModel>({
            url: `/ordermaterials/${entry.id}`,
            method: 'DELETE',
        })
    }, {
        onSuccess: (entry: OrderMaterialModel) => {
            queryCache.invalidateQueries(orderMaterialsCacheKey)
            queryCache.invalidateQueries([orderMaterialsCacheKey, entry.orderId])
        },
    })
}
