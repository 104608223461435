import React from "react";
import { log } from "Apis/BaseApi";

class ErrorBoundary extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }
    
    componentDidCatch(error, info) {
        this.setState({
            hasError: true
        })
        
        log(error.message, error.stack, {
            componentStack: info.componentStack,
            location: window.location.href
        }).then();
    }
    
    render() {
        return this.props.children;
    }
}
export default ErrorBoundary;
