import {DocumentReadModel} from 'Apis/Documents/Models/DocumentReadModel';
import {  useMutation, useQuery } from "react-query";
import { request } from "../BaseApi";



export const DOCUMENT_CACHE_KEY= 'DOCUMENT_CACHE_KEY';
export  const useDocumentRead = (parentId, searchFilter:string) => {
    const params = new URLSearchParams()
    if (searchFilter) {
        params.set('queryFilter', searchFilter)
    }

    if (parentId) {params.append("parentId", parentId)}
    return useQuery<DocumentReadModel[]>([DOCUMENT_CACHE_KEY,parentId, searchFilter], async () => {
        return await request<DocumentReadModel[]>({
            url: `/documents?${params.toString()}`,
            method: 'GET'
        })
    })
}



export const useGetDocumentFileUrl = () => {
    return useMutation(async (id: number) => {
        return await request<any>({
            url:`/documents/file/${id}/url`,
            method:'GET'
        })
    })
}