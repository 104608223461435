import {auth} from "FirebaseAuth";
import axios from 'axios';
import config from "Config";

export async function request<T>(options: any): Promise<T> {

    const token = await auth.currentUser?.getIdToken() ?? '';

    let headers = {
        Authorization: `Bearer ${token}`
    };

    const client = axios.create({
        baseURL: options.baseUrl ?? config.baseUrl,
        headers
    });

    const onSuccess = function (response: any) {
        console.debug('Request Successful!', response);
        return response.data;
    };

    const onError = function (error: any) {
        console.log('Request Failed:', error.config);

        if (error.response) {
            // Request was made but server responded with something
            // other than 2xx
            console.log('Status:', error.response.status);
            console.log('Data:', error.response.data);
            console.log('Headers:', error.response.headers);

        } else {
            // Something else happened while setting up the request
            // triggered the error
            console.log('Error Message:', error.message);
        }
    
        const ignoreStatusCodes = [400, 404] //BadRequest, Not found
    
        if (!ignoreStatusCodes.includes(error?.response?.status) && error.message !== 'Network Error') {
            log(error.message, error.stack, {
                method: error.config.method,
                url: error.config.url,
                location: window.location.href
            }).then();
        }

        return Promise.reject(error.response || error.message);
    };


    return client(options)
        .then(onSuccess)
        .catch(onError);
}

export async function log(errorMessage: string, errorStack: string, properties: any = null, level: string = 'Error') {
    const token = await auth.currentUser?.getIdToken() ?? '';
    
    let headers = {
        Authorization: `Bearer ${token}`
    };
    
    const client = axios.create({
        baseURL: config.baseUrl + '/logging',
        headers
    });
    
    const logEvent = {
        serviceName: 'Asapen.Frontend',
        messageTemplate: errorMessage || 'No error message',
        timestamp: new Date(),
        properties: properties,
        level: level,
        error: {
            message: errorMessage || 'No error message',
            stacktrace: errorStack || 'No error stack',
        },
    };
    
    const options: any = {
        method: 'POST',
        data: {
            ...logEvent
        }
    }
    
    await client(options).then();
}
