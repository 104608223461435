import React from 'react';
import styles from './Badge.module.scss';

interface Props {
    children: any,
    count?: number;
}
const Badge = ({children, count}: Props) => {

    return (
        <div className={styles.badgeContainer}>
            {children}
            <div className={styles.badge} style={!count ? {display: 'none'} : {}}>
                {count}
            </div>
        </div>
    )
}

export default Badge
