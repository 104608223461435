import React from 'react';
import Bullhorn from "Assets/Icons/Bullhorn";
import {useNews} from "Apis/NewsApi";
import PageTitle from "Components/PageTitle";
import FlexRow from "Components/FlexRow";
import {getDateWithTime} from "Utils/DateUtils";
import DefaultStyles from "Styles/Constants";

const NewsPage = () => {

    const {data: news} = useNews()

    return (
        <>
            <PageTitle title={"Nyheder"} icon={<Bullhorn />} />
            {news?.map(news => (
                <div style={styles.box}>
                    <FlexRow justify={"space-between"}>
                        <h2>{news.title}</h2>
                        <span>{getDateWithTime(news.sentAt!)}</span>
                    </FlexRow>
                    <p className={'display-linebreak'}>{news.text}</p>
                </div>
            ))}
        </>
    )
}

const styles = {
    box: {
        marginBottom: 20,
        backgroundColor: DefaultStyles.colors.white,
        borderRadius: 6,
        borderColor: DefaultStyles.colors.border,
        boxShadow: '0px 3px 20px rgba(0, 0, 0, .16)',
        padding: DefaultStyles.padding
    }
}

export default NewsPage
