import React  from "react";
import {Route, Redirect} from "react-router-dom";
import {useUserProvider} from "Providers/UserProvider";
import Spinner from "./Spinner";

function AuthenticatedRoute({ children, ...rest }) {

    const user = useUserProvider();

    if (user.loading) {
        return (
            <div style={{width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Spinner size="large" />
            </div>
        )
    }

    return (
        <Route
            {...rest}
            render={({ location }) => user.firebaseUser != null ? (children) : (<Redirect to={{pathname: "/auth/login", state: { from: location }}} />)}
        />
    );

}

export default AuthenticatedRoute;
