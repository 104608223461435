import React from "react";
import styles from './Pill.module.scss';
import {lowerCaseFirst} from "Utils/TextUtils";

interface Props {
    color?: 'Default' | 'Success' | 'Danger' | 'Warning'
}
const Pill = ({color}: Props) => {

    const colorClass = color ?? 'default'

    return (
        <div className={`${styles.pill} ${styles[lowerCaseFirst(colorClass)]}`}>&nbsp;</div>
    )

}
export default Pill;
