import FlexRow from "Components/FlexRow";
import MenuLink from "Pages/Layouts/MenuLink";
import Logout from "Assets/Icons/Logout";
import React, {useState} from "react";
import {auth} from "FirebaseAuth";
import {useRouteMatch} from "react-router-dom";
import User from "Assets/Icons/User";
import {useUserProvider} from "Providers/UserProvider";
import {useAllowedOrganization} from "Apis/OrganizationApi";
import styles from './DefaultLayout.module.scss';
import Menu from "Assets/Icons/Menu";

type Props = {
    menu: any,
    children: any
}
const DefaultLayout = ({children, menu}: Props) => {

    let {path} = useRouteMatch();

    const [showMenu, setShowMenu] = useState<boolean>(true)
    const [isHoveringMenu, setIsHoveringMenu] = useState<boolean>(false)

    const user = useUserProvider();

    const {data: allowedOrganizations} = useAllowedOrganization();

    const setOrganizationContext = async (event) => {
        if (event.target.value) {
            await user.changeOrganizationContext(event.target.value)
        }
    }

    const logout = () => {
        auth.signOut();
    }

    return (
        <div className={styles.layout}>
            <div className={`${styles.menu} ${!showMenu && !isHoveringMenu ? styles.closed : ''}  `} onMouseEnter={() => setIsHoveringMenu(true)} onMouseLeave={() => setIsHoveringMenu(false)}>
                <div onClick={() => {setShowMenu(!showMenu)}} className={styles.handle}><Menu /></div>
                <div className={styles.header}>
                    {allowedOrganizations && allowedOrganizations.length > -1 && (
                        <>
                            <FlexRow justify={'center'}>
                                <div className={"flex column"} style={{width: '80%', paddingBottom: '5px'}}>
                                    <label style={{color: '#C4C4C4'}}>Vælg håndværker</label>
                                    <select style={{fontSize: '1.3em', fontWeight: 500}} value={user.userContext?.lastOrganizationId ?? ''} onChange={setOrganizationContext}>
                                        <option key={0} value="">Vælg</option>
                                        {allowedOrganizations.slice().sort((a,b) => a.name! > b.name! ? 1 : -1).map((organization) => <option key={organization.id} value={organization.id}>{organization.name}</option>)}
                                    </select>
                                </div>
                            </FlexRow>
                            <FlexRow justify={'center'}>
                                <div style={{width: '80%'}}>
                                    <hr />
                                </div>
                            </FlexRow>
                        </>
                    )}
                </div>
                <div style={{width: '100%', paddingTop: '20px', overflowY: 'scroll'}}>
                    {menu}
                </div>
                <div className={"footer"}>
                    <FlexRow justify={'center'}>
                        <div style={{width: '90%'}}>
                            <hr />
                        </div>
                    </FlexRow>
                    {user.accessLevel === 'Admin' && path.includes('admin') && (
                        <MenuLink enableActive={false} icon={<User />} to={"/"}>Gå til Firma</MenuLink>
                    )}
                    {user.accessLevel === 'Admin' && !path.includes('admin') && (
                        <MenuLink icon={<User />} to={"/admin"}>Gå til Admin</MenuLink>
                    )}
                    <MenuLink onClick={logout} icon={<Logout />} to={"/auth/login"}>Log ud</MenuLink>

                    <FlexRow justify={'center'}>
                        <div style={{width: '90%'}}>
                            <hr />
                        </div>
                    </FlexRow>

                    <FlexRow justify={'center'}>
                        <img style={{width: '80%'}} src="/images/Logo.png" alt="ELi A/S Text Logo"/>
                    </FlexRow>
                </div>
            </div>
            <div style={{overflowY: 'scroll', padding: '30px 2%', width: '100%'}}>{children}</div>
        </div>
    )
}
export default DefaultLayout;
