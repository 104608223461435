import {OfferModel} from "Apis/Models/OfferModel";

export const getOfferTypeAsString = (type: string | undefined) => {
    return type?.toLowerCase() === 'Estimate'.toLowerCase() ? 'Overslag' : 'Tilbud';
}

export const getOfferStateAsString = (offer: OfferModel | null | undefined) => {
    switch (offer?.state) {
        case "Approved":
            return 'Afventer kunde';
        case "Rejected":
            return 'Afvist af installatør';
        case "WaitingForApproval":
            return 'Afventer installatør';
        case "CustomerApproved":
            return 'Godkendt af kunde';
        case "CustomerRejected":
            return 'Afvist af kunde';
        default:
            return "Kladde";
    }
}

export const getOfferStateColor = (offer: OfferModel | null | undefined): 'Default' | 'Success' | 'Danger' | 'Warning' => {
    switch (offer?.state) {
        case "CustomerApproved":
            return 'Success';
        case "Rejected":
        case "CustomerRejected":
            return 'Danger';
        case "WaitingForApproval":
        case "Approved":
            return 'Warning';
        default:
            return "Default";
    }
}
