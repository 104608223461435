import React, {useState} from 'react';
import DefaultPageLayout from "Pages/Layouts/DefaultPageLayout";
import {Space, Table} from "antd";
import {getNumberAsCurrency} from "Utils/NumberUtils";
import FlexRow from "Components/FlexRow";
import StyledButton from "Components/StyledButton";
import {getInputClasses} from "Utils/CssUtils";
import Modal from "antd/es/modal";
import {useForm} from "react-hook-form";
import {openSuccessNotification} from "Utils/NotificationUtils";
import Label from "Components/Label";
import {OrganizationUserReadModel} from "../../Apis/Models/UserModel";
import {useUserProvider} from "../../Providers/UserProvider";
import {useOrganizationUsers, useSaveOrganizationUser} from "../../Apis/OrganizationUserApi";

const EmployeesPage = () => {

    const { data: users } = useOrganizationUsers();
    const [ saveUserMutation, { isLoading: isSavingUser } ] = useSaveOrganizationUser();
    const [selectedUser, setSelectedUser] = useState<OrganizationUserReadModel>();
    const [showModal, setshowModal] = useState<boolean>(false);

    const {register, handleSubmit, errors, reset} = useForm<OrganizationUserReadModel>();

    const { userContext } = useUserProvider()

    const selectUser = (user: OrganizationUserReadModel) => {
        setSelectedUser(user);
        setshowModal(true)
        reset(user);
    }

    const saveUser = async (data: OrganizationUserReadModel) => {
        data = {
            ...selectedUser,
            ...data
        }

        await saveUserMutation(data);

        openSuccessNotification('Success', 'Produktet blev gemt');
        closeModal();
    }

    const closeModal = () => {
        setSelectedUser(undefined);
        setshowModal(false);
        reset({});
    }

    const findUser = (user: {userId: number} | undefined) => {
        return userContext!.users.find(x => x.id === user?.userId)
    }

    return (
        <DefaultPageLayout title={"Medarbejdere"}>
            <Table
                dataSource={users ?? []}
                pagination={false}
                rowClassName={"clickable"}
                rowKey={'id'}
                onRow={(record) => {
                    return {
                        onClick: () => selectUser(record),
                    };}
                }
            >
                <Table.Column title={"Navn"} dataIndex={"name"} render={(text, record: OrganizationUserReadModel) => findUser(record)?.name} />
                <Table.Column title={"Timepris"} dataIndex={"hourlyRate"} render={(text) => `${getNumberAsCurrency(text)} DKK`} />
            </Table>
            <Modal visible={showModal} title={`Opdater medarbejder: ${findUser(selectedUser)?.name}`}
                   onCancel={closeModal}
                   footer={false}
            >
                <form onSubmit={handleSubmit(saveUser)}>
                    <Space className={"w-100"} direction={"vertical"}>

                        <div>
                            <Label>Timepris</Label>
                            <input type={"text"} placeholder={"Timepris"} name="hourlyRate" className={getInputClasses(errors.hourlyRate)} ref={register({required: true, pattern: /^[0-9]*$/})} />
                        </div>

                        <FlexRow justify={"end"}>
                            <Space>
                                <StyledButton onClick={closeModal}>Afbryd</StyledButton>
                                <StyledButton loading={isSavingUser} color={"primary"} type={"submit"}>Gem</StyledButton>
                            </Space>
                        </FlexRow>
                    </Space>
                </form>
            </Modal>
        </DefaultPageLayout>
    )
}

export default EmployeesPage
