import DefaultPageLayout from "Pages/Layouts/DefaultPageLayout";
import React from "react";
import { useParams } from "react-router-dom";
import OfferForm from "Pages/Projects/Offers/OfferForm";
import {getOfferTypeAsString} from "Utils/OfferUtils";
import ShoppingList from "Assets/Icons/ShoppingList";

const CreateOfferPage = () => {

    const { type } = useParams<{ type }>();

    return (
        <DefaultPageLayout
            title={`Opret ${getOfferTypeAsString(type)}`}
            icon={<ShoppingList />}
        >
            <OfferForm type={type === 'estimate' ? 'Estimate' : 'Offer'} />
        </DefaultPageLayout>
    )
}

export default CreateOfferPage;
