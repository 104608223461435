import {queryCache, useMutation, useQuery} from "react-query";
import {request} from "Apis/BaseApi";
import OrganizationModel from "Apis/Models/OrganizationModel";
import {OrganizationUserReadModel} from "./Models/UserModel";

export const ORGANIZATION_USERS_CACHE_KEY = 'ORGANIZATION_USERS_CACHE_KEY';
export const useOrganizationUsers = () => {
    return useQuery<OrganizationUserReadModel[]>(ORGANIZATION_USERS_CACHE_KEY, async () => {
        return await request<OrganizationUserReadModel[]>({
            url: `/organization/users`,
            method: 'GET'
        })
    })
}

export const useSaveOrganizationUser = () => {
    return useMutation(async (model: OrganizationUserReadModel) => {
        return await request<OrganizationModel>({
            url: `/organization/users`,
            method: 'PUT',
            data: model
        })
    }, {
        onSuccess: (organization: OrganizationModel) => {
            queryCache.invalidateQueries(ORGANIZATION_USERS_CACHE_KEY)
        },
    })
}