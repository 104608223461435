import { useQuery } from "react-query";
import { request } from "Apis/BaseApi";
import {QASchemeOverviewModel} from "./Models/QASchemeOverviewModel";
import {PaginatedResultModel} from "../../Models/PaginatedResultModel";
import { saveAs } from 'file-saver';

export const QA_SCHEMES_ADMIN_KEY = 'QA_SCHEMES_ADMIN_KEY';
export const useQaSchemes = (page: number, pageSize: number, qaSchemeName: string, query: string) => {
    const params= new URLSearchParams();

    params.set('page', page.toString());
    params.set('pageSize', pageSize.toString());

    if(qaSchemeName) {
        params.set('qaSchemeName', qaSchemeName);
    }

    if(query) {
        params.set('query', query);
    }

    return useQuery<PaginatedResultModel<QASchemeOverviewModel>>([QA_SCHEMES_ADMIN_KEY, page, pageSize, qaSchemeName, query], async () => {
        return await request<PaginatedResultModel<QASchemeOverviewModel>>({
            url: `/admin/qaschemes?${params.toString()}`,
            method: 'GET'
        })
    })
}

export const getQaSchemePdf = async (id: number): Promise<any> => {
    const responseBlob = await request<Blob>({
        responseType: 'blob',
        url: `/admin/qaschemes/${id}/pdf`,
        method: 'GET',
    });

    const blob = new Blob([responseBlob], {type: 'application/pdf' });
    const fileName = `KLS.pdf`;
    saveAs(blob, fileName);
}