import {TimeEntryModel} from "Apis/Models/TimeEntryModel";
import moment from "moment";

export const sumTimeEntries = (list: TimeEntryModel[] | undefined) => {
    let sum = 0;

    if (!list) {
        return sum;
    }

    list.forEach(item => sum = sum + moment(item.to).diff(moment(item.from), 'second'))

    return sum;
}
