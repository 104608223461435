import React from 'react';
import {Col, Dropdown, Row} from "antd";
import {getPaperInputClasses} from "Utils/CssUtils";
import FlexRow from "Components/FlexRow";
import PriceCalculatorLineTotal from "Pages/Projects/Offers/Components/PriceCalculatorLineTotal";
import {UseFormMethods, useWatch} from "react-hook-form";
import {useProductsSearchMenu} from "Hooks/UseProductSearch";
import {OfferModel} from "Apis/Models/OfferModel";
import {roundNumber} from "Utils/NumberUtils";
import {KlarPrisProduct} from "Apis/Models/KlarPrisIntegrationModel";
import {ProductModel} from "Apis/Models/ProductModel";

interface Props {
    line: any,
    index: number,
    formMethods: UseFormMethods<OfferModel>
    remove: any,
}
const PriceCalculatorLine = ({line, index, remove, formMethods}: Props) => {

    const {setValue, register, getValues, control, errors} = formMethods;
    let {idKey, id, productNumber, description, amount, purchasePrice, margin, salesPrice, discount} = line;


    const [descriptionProductSuggestionsMenu, searchForProducts] = useProductsSearchMenu(async (product: KlarPrisProduct) => {
        setLineValuesFromProduct(product);
    }, (product: ProductModel) => {
        setValue(`calculationLines[${index}].productNumber`, product.productNumber)
        setValue(`calculationLines[${index}].description`, product.description)
        setValue(`calculationLines[${index}].purchasePrice`, 0)
        setValue(`calculationLines[${index}].margin`, 0)
        setValue(`calculationLines[${index}].salesPrice`, product.unitPriceExVat)
    });

    const setLineValuesFromProduct = (product: KlarPrisProduct) => {
        const bestPrice = product.prices.sort((a,b) => a.price - b.price)[0];

        setValue(`calculationLines[${index}].productNumber`, product.number)
        setValue(`calculationLines[${index}].description`, product.description)
        setValue(`calculationLines[${index}].purchasePrice`, bestPrice.price)

        let salesPrice = bestPrice.price;
        let currentMargin = getValues().calculationLines[index].margin;

        if (!currentMargin) {
            currentMargin = 100
            setValue(`calculationLines[${index}].margin`, currentMargin);
        }

        salesPrice = roundNumber(salesPrice + (salesPrice / 100 * currentMargin));

        setValue(`calculationLines[${index}].salesPrice`, salesPrice);
    }

    const values = useWatch<any>({
        control,
        defaultValue: {
            purchasePrice: purchasePrice,
            margin: margin,
            salesPrice: salesPrice,
        },
        name: `calculationLines[${index}]`
    });

    const calculateSalesPriceFromMargin = (localMargin: number) => {
        let newValue = values.purchasePrice;

        if (values.purchasePrice && localMargin) {
            newValue = roundNumber(parseFloat(values.purchasePrice) + (values.purchasePrice / 100 * localMargin));
        }

        setValue(`calculationLines[${index}].salesPrice`, newValue);
    }

    const calculateMarginFromPurchasePrice = (localPurchasePrice: number) => {
        if (values.salesPrice && localPurchasePrice) {
            const newValue = (values.salesPrice - localPurchasePrice) / values.purchasePrice * 100

            setValue(`calculationLines[${index}].margin`, newValue);
        }
    }

    const calculateMarginFromSalesPrice = (localSalesPrice: number) => {
        if (values.purchasePrice && localSalesPrice) {
            const newValue = (localSalesPrice - values.purchasePrice) / values.purchasePrice * 100

            setValue(`calculationLines[${index}].margin`, newValue);
        }
    }

    return (
        <React.Fragment key={idKey}>
            <input type="hidden" name={`calculationLines[${index}].id`} ref={register()} defaultValue={id} />
            <input type="hidden" name={`calculationLines[${index}].lineNumber`} ref={register()} defaultValue={index} />

            <Dropdown overlay={descriptionProductSuggestionsMenu} visible={true}>
                <Row gutter={[5, 20]} style={{width: '100%'}}>
                    <Col span={3}>
                        <input type="text"
                               onChange={(e) => searchForProducts(e.target.value)}
                               className={getPaperInputClasses(errors.calculationLines && errors.calculationLines[index]?.productNumber)}
                               name={`calculationLines[${index}].productNumber`}
                               ref={register()}
                               onFocus={e => e.target.select()}
                               onBlur={() => searchForProducts('')}
                               defaultValue={productNumber}
                        />
                    </Col>

                    <Col span={8}>
                        <input type={'text'}
                               onChange={(e) => searchForProducts(e.target.value)}
                               className={getPaperInputClasses(errors.calculationLines && errors.calculationLines[index]?.description)}
                               name={`calculationLines[${index}].description`}
                               ref={register({required: true})}
                               onFocus={e => e.target.select()}
                               onBlur={() => searchForProducts('')}
                               defaultValue={description}
                        />
                    </Col>

                    <Col span={2}>
                        <input type={'number'}
                               className={getPaperInputClasses(errors.calculationLines && errors.calculationLines[index]?.amount)}
                               name={`calculationLines[${index}].amount`}
                               ref={register({required: true})}
                               onFocus={e => e.target.select()}
                               defaultValue={amount}
                        />
                    </Col>

                    <Col span={2}>
                        <input type={'number'}
                               className={getPaperInputClasses(errors.calculationLines && errors.calculationLines[index]?.purchasePrice)}
                               name={`calculationLines[${index}].purchasePrice`}
                               onChange={e => calculateMarginFromPurchasePrice(parseFloat(e.target.value))}
                               ref={register({required: true})}
                               onFocus={e => e.target.select()}
                               defaultValue={purchasePrice}
                        />
                    </Col>

                    <Col span={2}>
                        <input type={'number'}
                               className={getPaperInputClasses(errors.calculationLines && errors.calculationLines[index]?.margin)}
                               name={`calculationLines[${index}].margin`}
                               onChange={e => calculateSalesPriceFromMargin(parseFloat(e.target.value))}
                               ref={register({required: true, min: 0})}
                               onFocus={e => e.target.select()}
                               defaultValue={margin}
                        />
                    </Col>

                    <Col span={2}>
                        <input type={'number'}
                               className={getPaperInputClasses(errors.calculationLines && errors.calculationLines[index]?.salesPrice)}
                               name={`calculationLines[${index}].salesPrice`}
                               ref={register({required: true})}
                               onChange={e => calculateMarginFromSalesPrice(parseFloat(e.target.value))}
                               onFocus={e => e.target.select()}
                               defaultValue={salesPrice}
                        />
                    </Col>

                    <Col span={2}>
                        <input type={'number'}
                               className={getPaperInputClasses(errors.calculationLines && errors.calculationLines[index]?.discount)}
                               name={`calculationLines[${index}].discount`}
                               ref={register({required: true, min: 0, max: 100})}
                               onFocus={e => e.target.select()}
                               defaultValue={discount ?? 0}
                        />
                    </Col>

                    <Col span={2}>
                        <FlexRow style={{height: '100%'}} justify={"end"}>
                            <PriceCalculatorLineTotal control={control} index={index} />
                        </FlexRow>
                    </Col>

                    <Col span={1} onClick={() => remove(index)}>
                        <FlexRow style={{height: '100%'}} justify={"end"}>
                            x
                        </FlexRow>
                    </Col>
                </Row>
            </Dropdown>
        </React.Fragment>
    )
}

export default PriceCalculatorLine
