import {queryCache, useMutation, useQuery} from "react-query";
import {request} from "Apis/BaseApi";
import {AgreementModel} from "Apis/Models/AgreementModel";

export const agreementsCacheKey = 'agreements';
export const useAgreementsForOrder = (orderId: number) => {
    return useQuery<AgreementModel[]>([agreementsCacheKey, orderId], async () => {
        return await request<AgreementModel[]>({
            url: `/agreements/order/${orderId}`,
            method: 'GET'
        })
    })
}

export const useSaveAgreement = () => {
    return useMutation(async (entry: AgreementModel): Promise<AgreementModel> => {
        let response;

        if (entry.id) {
            response = await request<AgreementModel>({
                url: `/agreements/${entry.id}`,
                method: 'PUT',
                data: entry
            })
        } else {
            response = await request<AgreementModel>({
                url: `/agreements`,
                method: 'POST',
                data: entry
            })
        }
        return response;
    }, {
        onSuccess: (entry: AgreementModel) => {
            queryCache.invalidateQueries(agreementsCacheKey)
            queryCache.invalidateQueries([agreementsCacheKey, entry.orderId])
        },
    })
}

export const useSendAgreement = () => {
    return useMutation(async (id: number): Promise<AgreementModel> => {
        return await request<AgreementModel>({
            url: `/agreements/send/${id}`,
            method: 'PUT',
        })
    }, {
        onSuccess: (entry: AgreementModel) => {
            queryCache.invalidateQueries(agreementsCacheKey)
            queryCache.invalidateQueries([agreementsCacheKey, entry.orderId])
        },
    })
}
