import Ribbon from "Components/Ribbon";
import {getOfferStateAsString, getOfferStateColor, getOfferTypeAsString} from "Utils/OfferUtils";
import FlexRow from "Components/FlexRow";
import {Col, Row} from "antd";
import styles from "Pages/Projects/Offers/OfferForm.module.scss";
import {getDateOnlyString} from "Utils/DateUtils";
import {getNumberAsCurrency} from "Utils/NumberUtils";
import React, {useEffect, useState} from "react";
import {OfferModel} from "Apis/Models/OfferModel";
import {useDefaultOfferText} from "Apis/TextApi";

interface Props {
    offer: OfferModel,
    defaultOfferText?: string,
}
const OfferDetails = ({offer, defaultOfferText: defaultText}: Props) => {

    const [defaultOfferText, setDefaultOfferText] = useState<string>(defaultText ?? '');

    const {data: fetchedText } = useDefaultOfferText(offer?.organizationId!, !!offer)

    useEffect(() => {
        if (fetchedText) {
            setDefaultOfferText(fetchedText);
        }
    }, [fetchedText])


    return (
        <>
            <Ribbon text={getOfferStateAsString(offer)} color={getOfferStateColor(offer)}  />
            <FlexRow justify={"space-between"}>

                <div style={{width: '40%', fontSize: '.9em'}}>
                    <Row gutter={[5, 5]}>
                        <Col span={24}>
                            <b>
                                {offer.invoicingAddress.customerName}
                            </b>
                        </Col>
                    </Row>

                    <span>{offer?.invoicingAddress.street}</span>

                    <Row gutter={5}>
                        <Col span={24}>
                            <span>{offer?.invoicingAddress.zipCode}</span> <span>{offer?.invoicingAddress.city}</span>
                        </Col>
                        <Col span={24}>
                            <span>CVR: {offer?.invoicingAddress.vatNumber}</span>
                        </Col>
                        <Col span={24}>
                            <span>ATT: {offer?.invoicingAddress.att}</span>
                        </Col>
                        <Col span={24}>
                            <span>EAN: {offer?.invoicingAddress.ean}</span>
                        </Col>
                        <Col span={24}>
                            <span>Email: {offer?.invoicingAddress.email}</span>
                        </Col>
                    </Row>
                </div>

                <div>
                    <img src="/images/Logo.png"width={250} alt="ELi A/S Badge"/>
                </div>
            </FlexRow>

            <div style={{width: '40%', fontSize: '.9em', padding: '10px 0'}}>
                <FlexRow justify={"space-between"}>
                    <b>Installationsadresse</b>
                </FlexRow>
                <span>{offer.deliveryAddress.street}</span>

                <Row gutter={5}>
                    <Col span={24}>
                        <span>{offer.deliveryAddress.zipCode}</span> <span>{offer.deliveryAddress.city}</span>
                    </Col>
                </Row>
            </div>

            <div>
                {offer.offerType === 'Offer' ? 'Tilbud' : 'Overslag'}
            </div>

            <hr className={styles.seperator}/>

            <div style={{fontWeight: 600}}>
                {offer.title}
            </div>

            <FlexRow justify={"space-between"}>
                <div className={styles.defaultTextContainer}>
                    {defaultOfferText}
                </div>

                <div className={"flex column"} style={{minWidth: '30%'}}>
                    <FlexRow justify={"space-between"}>
                        Rekvistionsnr:
                        <span>
                            {offer.requisitionNumber}
                        </span>
                    </FlexRow>
                    <FlexRow justify={"space-between"}>
                        Dato:
                        <span>
                            {offer.date && getDateOnlyString(offer.date)}
                        </span>
                    </FlexRow>
                    <span>
                        <FlexRow justify={"space-between"}>
                            Gyldig indtil:
                            <span>
                                {offer.expirationDate && getDateOnlyString(offer.expirationDate)}
                            </span>
                        </FlexRow>
                    </span>
                </div>
            </FlexRow>

            <hr className={styles.seperator}/>

            <div className={styles.descriptionContainer}>
                <b>Specifikation af {getOfferTypeAsString(offer.offerType)}</b> <br/><br/>

                <span className={"display-linebreak"}>
                    {offer.description}
                </span>

                <br/><br/>


                <br/>
                <div>
                    Via ELi A/S Group’s medlemskab af Tekniq arbejdsgiverne, er du som privatforbruger
                    omfattet af Tekniq’s garantiordning. Ligeledes gælder Tekniq’s standardforbehold for
                    el og vvs af 2007. <a href="https://www.tekniq.dk/forbruger">Klik her for at se
                    mere</a>
                </div>
            </div>

            <hr className={styles.seperator}/>

            <FlexRow justify={"end"} align={'end'} direction={"column"}>
                <div style={{width: '30%'}}>
                    <FlexRow justify={"space-between"}>
                        <span>Subtotal</span>
                        <span>{(getNumberAsCurrency(offer.priceExVat!))} Kr.</span>
                    </FlexRow>
                    <FlexRow justify={"space-between"}>
                        <span>Moms</span>
                        <span>{(getNumberAsCurrency(offer.priceExVat! * 0.25))} Kr.</span>
                    </FlexRow>
                    <FlexRow justify={"space-between"}>
                        <span><b>I alt</b></span>
                        <span>{(getNumberAsCurrency(offer.priceExVat! * 1.25))} Kr.</span>
                    </FlexRow>
                </div>
            </FlexRow>

        </>
    )
}

export default OfferDetails;
