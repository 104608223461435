import OrganizationModel from "Apis/Models/OrganizationModel";
import { DatePicker, Popconfirm, Space, Table } from "antd";
import Spinner from "Components/Spinner";
import moment from "moment";
import React, { useState } from "react";
import { useDeleteOrganizationContributions, useOrganizationContributions, useSaveOrganizationContributions } from "Apis/Admin/OrganizationContributions/AdminContributionsApi";
import FlexRow from "Components/FlexRow";
import StyledButton from "Components/StyledButton";
import { getInputClasses } from "Utils/CssUtils";
import Modal from "antd/es/modal";
import { Controller, useForm } from "react-hook-form";
import { OrganizationContributionModel, OrganizationContributionReadModel } from "Apis/Admin/OrganizationContributions/Models/OrganizationContributionModel";
import { openSuccessNotification } from "Utils/NotificationUtils";
import Label from "Components/Label";

interface Props {
    organization: OrganizationModel;
}
const OrganizationContributions = ({organization}: Props) => {
    
    const { data, isLoading } = useOrganizationContributions(organization.id);
    const [ saveContributionMutation, { isLoading: isSavingContribution } ] = useSaveOrganizationContributions();
    const [ deleteContributionMutation, { isLoading: isDeletingContribution } ] = useDeleteOrganizationContributions();
    
    const [showContributionModal, setShowContributionModal] = useState<boolean>(false);
    
    const { register, control, handleSubmit, errors } = useForm<OrganizationContributionModel>()
    
    const openContributionModal = () => {
        setShowContributionModal(true)
    }
    
    const saveContribution = async (data: OrganizationContributionModel) => {
        data.organizationId = organization.id;
        
        await saveContributionMutation(data);
        
        openSuccessNotification('Success', 'Special bidraget blev oprettet')
        
        setShowContributionModal(false);
    }
    
    const deleteContribution = async (contribution: OrganizationContributionReadModel) => {
        await deleteContributionMutation(contribution);
    
        openSuccessNotification('Success', 'Special bidraget blev slettet')
    }
    
    return (
        <>
            <FlexRow justify={"end"}>
                <StyledButton color={"primary"} onClick={openContributionModal}>Opret special bidrag</StyledButton>
            </FlexRow>
            <Table
                size={"small"}
                rowKey={'id'}
                dataSource={data}
                loading={{indicator: <Spinner />, spinning: isLoading}}
                pagination={false}
            >
                <Table.Column title={"Fra"} dataIndex={"from"} render={text => moment(text).format('DD/MM/YYYY')} />
                <Table.Column title={"Til"} dataIndex={"to"} render={text => moment(text).format('DD/MM/YYYY')} />
                <Table.Column title={"Begrundelse"} dataIndex={"reason"} />
                <Table.Column title={"Bidrag"} dataIndex={"contribution"} render={text => `${text}%`} />
                <Table.Column title={"Mere"} render={(_, entity: OrganizationContributionReadModel) =>
                    <Popconfirm okButtonProps={{loading: isDeletingContribution}} okText={"Ja, Slet den"} okType={"danger"} title={`Er du sikker?`} onConfirm={() => deleteContribution(entity)}>
                        Slet
                    </Popconfirm>
                } />
            </Table>
            
            <Modal visible={showContributionModal} title={"Opret special bidrag"}
                   onCancel={() => setShowContributionModal(false)}
                   footer={false}
            >
                <form onSubmit={handleSubmit(saveContribution)}>
                    <Space direction={"vertical"} className={"w-100"}>
                        
                        <div>
                            <Label>
                                Begrundelse bag special bidrag
                            </Label>
                            <textarea placeholder={"Indtast begundelse"} name="reason" rows={4} className={getInputClasses(errors.reason)} ref={register({required: true})} />
                        </div>
    
                        <div>
                            <Label>
                                Fra:
                            </Label>
                            <Controller
                                name={"from"}
                                control={control}
                                defaultValue={null}
                                render={props => (
                                    <DatePicker
                                        className={getInputClasses(errors.from)}
                                        placeholder={"Fra"}
                                        showTime={false}
                                        format={"DD-MM-YYYY"}
                                        value={props.value ? moment(props.value) : null}
                                        onChange={(e => {
                                            props.onChange(e)
                                        })}
                                    />
                                )}
                            />
                        </div>
        
                        <div>
                            <Label>
                                Til:
                            </Label>
                            <Controller
                                name={"to"}
                                control={control}
                                defaultValue={null}
                                render={props => (
                                    <DatePicker
                                        className={getInputClasses(errors.to)}
                                        placeholder={"Til"}
                                        showTime={false}
                                        format={"DD-MM-YYYY"}
                                        value={props.value ? moment(props.value) : null}
                                        onChange={(e => {
                                            props.onChange(e)
                                        })}
                                    />
                                )}
                            />
                        </div>
                        
                        <div>
                            <Label>
                                Bidragsprocent:
                            </Label>
                            <input type={"number"} step={".01"} name={"contribution"} ref={register} className={getInputClasses(errors.contribution)} />
                        </div>
                        
                        <FlexRow justify={"end"}>
                            <Space>
                                <StyledButton onClick={() => setShowContributionModal(false)}>Afbryd</StyledButton>
                                <StyledButton loading={isSavingContribution} color={"primary"} type={"submit"}>Opret special bidrag</StyledButton>
                            </Space>
                        </FlexRow>
                    </Space>
                </form>
            </Modal>
        </>
    )
}

export default OrganizationContributions
