import OrganizationModel from "Apis/Models/OrganizationModel";
import { Popconfirm, Space, Table } from "antd";
import React, { useState } from "react";
import FlexRow from "Components/FlexRow";
import StyledButton from "Components/StyledButton";
import { getInputClasses } from "Utils/CssUtils";
import Modal from "antd/es/modal";
import { useForm } from "react-hook-form";
import { openSuccessNotification } from "Utils/NotificationUtils";
import Label from "Components/Label";
import {useStandardUsers} from "../../../../Apis/UserAdminApi";
import {OrganizationUserModel} from "../../../../Apis/Models/UserModel";
import {useAddOrganizationUser, useRemoveOrganizationUser} from "../../../../Apis/OrganizationAdminApi";

interface Props {
    organization: OrganizationModel;
}
const OrganizationUsers = ({organization}: Props) => {

    const [ addOrganizationUserMutation, { isLoading: isAddingUser } ] = useAddOrganizationUser();
    const [ removeOrganizationUserMutation, { isLoading: isRemovingUser } ] = useRemoveOrganizationUser();
    const {data: standardUsers} = useStandardUsers();

    const [showModal, setShowModal] = useState<boolean>(false);
    
    const { register, handleSubmit, errors } = useForm<OrganizationUserModel>()
    
    const openModal = () => {
        setShowModal(true)
    }
    
    const saveOrganizationUser = async (data: OrganizationUserModel) => {
        data.organizationId = organization.id;
        
        await addOrganizationUserMutation(data);
        
        openSuccessNotification('Success', 'Brugeradgang givet')
        
        setShowModal(false);
    }
    
    const removeUser = async (data: OrganizationUserModel) => {
        await removeOrganizationUserMutation(data);
    
        openSuccessNotification('Success', 'Brugeradgangen blev fjernet')
    }
    
    return (
        <>
            <FlexRow justify={"end"}>
                <StyledButton color={"primary"} onClick={openModal}>Tilføj brugeradgang</StyledButton>
            </FlexRow>
            <Table
                size={"small"}
                rowKey={'userId'}
                dataSource={organization.organizationUsers}
                pagination={false}
            >
                <Table.Column title={"Bruger ID"} dataIndex={"userId"}  />
                <Table.Column title={"Bruger"} dataIndex={"userId"} render={userId => standardUsers?.find(x => x.id === userId)?.name} />
                <Table.Column title={"E-mail"} dataIndex={"userId"} render={userId => standardUsers?.find(x => x.id === userId)?.email} />
                <Table.Column title={"Ejer"} dataIndex={"owner"} render={owner => owner ? "Ja" : "Nej"} />
                <Table.Column title={"Mere"} render={(_, entity: OrganizationUserModel) =>
                    <Popconfirm okButtonProps={{loading: isRemovingUser}} okText={"Ja, fjern den"} okType={"danger"} title={`Er du sikker?`} onConfirm={() => removeUser(entity)}>
                        Fjern brugeradgang
                    </Popconfirm>
                } />
            </Table>
            
            <Modal visible={showModal} title={"Opret brugeradgang"}
                   onCancel={() => setShowModal(false)}
                   footer={false}
            >
                <form onSubmit={handleSubmit(saveOrganizationUser)}>
                    <Space direction={"vertical"} className={"w-100"}>

                        <div>
                            <Label>Brugeradgang</Label>
                            <select className={getInputClasses(errors.userId)} name={'userId'}
                                    ref={register({required: true})}>
                                <option value={""}>Vælg</option>
                                {standardUsers?.map((user) => {
                                    return (
                                        <option key={user.id} value={user.id}>{user.name}</option>
                                    )
                                })}
                            </select>
                        </div>

                        <Space>
                            <input type="checkbox" ref={register} name={"owner"} defaultChecked={organization.organizationUsers.length === 0}/>
                            <Label>Er den primære ejer</Label>
                        </Space>

                        <FlexRow justify={"end"}>
                            <Space>
                                <StyledButton onClick={() => setShowModal(false)}>Afbryd</StyledButton>
                                <StyledButton loading={isAddingUser} color={"primary"} type={"submit"}>Opret
                                    brugeradgang</StyledButton>
                            </Space>
                        </FlexRow>
                    </Space>
                </form>
            </Modal>
        </>
    )
}

export default OrganizationUsers
