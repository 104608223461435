import {queryCache, useMutation, useQuery} from "react-query";
import {request} from "Apis/BaseApi";
import { saveAs } from 'file-saver';
import {OfferFileModel} from "Apis/Models/OfferFileModel";

export const offerFileCacheKey = 'offerFiles';
export const useOfferFiles = (offerId: number, enabled: boolean) => {
    return useQuery<OfferFileModel[]>([offerFileCacheKey, offerId], async () => {
        return await request<OfferFileModel[]>({
            url: `/offers/${offerId}/files`,
            method: 'GET'
        })
    }, {
        enabled
    })
}

export const useUploadOfferFile = () => {
    return useMutation(async (uploadOfferFile: any) => {
        return await request({
            url: `/offers/${uploadOfferFile.offerId}/files`,
            method: 'POST',
            data: uploadOfferFile
        })
    }, {
        onSuccess: () => {
            queryCache.invalidateQueries(offerFileCacheKey)
        }
    })
}

export const downloadOfferFile = async (offerId: number, fileId: number, fileName: string): Promise<any> => {
    const responseBlob = await request<Blob>({
        responseType: 'blob',
        url: `/offers/${offerId}/files/${fileId}`,
        method: 'GET',
    });

    const blob = new Blob([responseBlob], {type: 'application/pdf' });
    saveAs(blob, fileName);
}

export const useDeleteOfferFile = () => {
    return useMutation(async ({offerId, fileId}: any) => {
        return await request({
            url: `/offers/${offerId}/files/${fileId}`,
            method: 'DELETE',
        })
    }, {
        onSuccess: () => {
            queryCache.invalidateQueries(offerFileCacheKey)
        }
    })
}
