import {queryCache, useMutation, useQuery} from "react-query";
import {request} from "Apis/BaseApi";
import {NoteModel} from "Apis/Models/NoteModel";

export const notesForOrderCacheKey = 'notes';
export const useNotesForOrder = (orderId: number | undefined, enabled: boolean = true) => {
    return useQuery<NoteModel[]>([notesForOrderCacheKey, orderId], async () => {
        return await request<NoteModel[]>({
            url: `/notes/order/${orderId}`,
            method: 'GET'
        })
    }, {
        enabled
    })
}

export const useSaveNote = () => {
    return useMutation(async (entry: NoteModel): Promise<NoteModel> => {
        let response;

        if (entry.id) {
            response = await request<NoteModel>({
                url: `/notes/${entry.id}`,
                method: 'PUT',
                data: entry
            })
        } else {
            response = await request<NoteModel>({
                url: `/notes`,
                method: 'POST',
                data: entry
            })
        }
        return response;
    }, {
        onSuccess: (entry: NoteModel) => {
            queryCache.invalidateQueries(notesForOrderCacheKey)
        },
    })
}

export const useDeleteNote = () => {
    return useMutation(async (event: NoteModel): Promise<NoteModel> => {
        return await request<NoteModel>({
            url: `/notes/${event.id}`,
            method: 'DELETE',
        })
    }, {
        onSuccess: (entry: NoteModel) => {
            queryCache.invalidateQueries([notesForOrderCacheKey, entry.orderId])
        },
    })
}
