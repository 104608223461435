import {Redirect, Route, Switch, useRouteMatch} from "react-router-dom";
import React from "react";
import ReminderApprovalList from "Pages/Admin/Approval/Reminders/ReminderApprovalList";
import ReminderDetailsPage from "Pages/Admin/Approval/Reminders/ReminderDetailsPage";

const ReminderRoutes = () => {

    let {path} = useRouteMatch();

    return (
        <Switch>
            <Route path={`${path}/`} exact>
                <ReminderApprovalList />
            </Route>

            <Route path={`${path}/:id`} exact>
                <ReminderDetailsPage />
            </Route>

            <Redirect to={`${path}/`}/>
        </Switch>
    )
}

export default ReminderRoutes
