import DocumentLayout from "Components/Document/DocumentLayout";
import DocumentHeader from "Components/Document/DocumentHeader";
import { OfferModel } from "Apis/Models/OfferModel";
import React from "react";
import styles from "Pages/Projects/Offers/OfferForm.module.scss";
import FlexRow from "Components/FlexRow";
import { getDateOnlyString } from "Utils/DateUtils";
import { getOfferTypeAsString } from "Utils/OfferUtils";
import { getNumberAsCurrency } from "Utils/NumberUtils";
import { useDefaultOfferText } from "Apis/TextApi";

interface Props {
    offer: OfferModel;
}
const OfferDocument = ({offer}: Props) => {
    
    const {data: fetchedText } = useDefaultOfferText(offer?.organizationId!, !!offer)
    
    return (
        <DocumentLayout>
            <DocumentHeader invoicingAddress={offer.invoicingAddress} deliveryAddress={offer.deliveryAddress} />
    
            <div>
                {offer.offerType === 'Offer' ? 'Tilbud' : 'Overslag'}
            </div>
    
            <hr className={styles.seperator}/>
    
            <div style={{fontWeight: 600}}>
                {offer.title}
            </div>
    
            <FlexRow justify={"space-between"}>
                <div className={styles.defaultTextContainer}>
                    {fetchedText}
                </div>
        
                <div className={"flex column"} style={{minWidth: '30%'}}>
                    <FlexRow justify={"space-between"}>
                        Rekvistionsnr:
                        <span>
                            {offer.requisitionNumber}
                        </span>
                    </FlexRow>
                    <FlexRow justify={"space-between"}>
                        Dato:
                        <span>
                            {offer.date && getDateOnlyString(offer.date)}
                        </span>
                    </FlexRow>
                    <span>
                        <FlexRow justify={"space-between"}>
                            Gyldig indtil:
                            <span>
                                {offer.expirationDate && getDateOnlyString(offer.expirationDate)}
                            </span>
                        </FlexRow>
                    </span>
                </div>
            </FlexRow>
    
            <hr className={styles.seperator}/>
    
            <div className={styles.descriptionContainer}>
                <b>Specifikation af {getOfferTypeAsString(offer.offerType)}</b> <br/><br/>
        
                <span className={"display-linebreak"}>
                    {offer.description}
                </span>
        
                <br/><br/>
        
        
                <br/>
                <div>
                    Via ELi A/S medlemskab af Tekniq arbejdsgiverne, er du som privatforbruger
                    omfattet af Tekniq’s garantiordning. Ligeledes gælder Tekniq’s standardforbehold for
                    el og vvs af 2007. <a href="https://www.tekniq.dk/forbruger">Klik her for at se
                    mere</a>
                </div>
            </div>
    
            <hr className={styles.seperator}/>
    
            <FlexRow justify={"end"} align={'end'} direction={"column"}>
                <div style={{width: '30%'}}>
                    <FlexRow justify={"space-between"}>
                        <span>Subtotal</span>
                        <span>{(getNumberAsCurrency(offer.priceExVat!))} Kr.</span>
                    </FlexRow>
                    <FlexRow justify={"space-between"}>
                        <span>Moms</span>
                        <span>{(getNumberAsCurrency(offer.priceExVat! * 0.25))} Kr.</span>
                    </FlexRow>
                    <FlexRow justify={"space-between"}>
                        <span><b>I alt</b></span>
                        <span>{(getNumberAsCurrency(offer.priceExVat! * 1.25))} Kr.</span>
                    </FlexRow>
                </div>
            </FlexRow>

        </DocumentLayout>
    )
}

export default OfferDocument
