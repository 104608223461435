import {queryCache, useMutation, useQuery} from "react-query";
import {request} from "Apis/BaseApi";
import {OrderFileModel} from "Apis/Models/OrderFileModel";
import { saveAs } from 'file-saver';

export const orderFileCacheKey = 'orderFile';
export const useOrderFiles = (orderId: number | undefined, enabled: boolean = true) => {
    return useQuery<OrderFileModel[]>([orderFileCacheKey, orderId], async () => {
        return await request<OrderFileModel[]>({
            url: `/orders/${orderId}/files`,
            method: 'GET'
        })
    }, {
        enabled
    })
}

export const useUploadOrderFile = () => {
    return useMutation(async (uploadOrderFile: any) => {
        return await request({
            url: `/orders/${uploadOrderFile.orderId}/files`,
            method: 'POST',
            data: uploadOrderFile
        })
    }, {
        onSuccess: () => {
            queryCache.invalidateQueries(orderFileCacheKey)
        }
    })
}

export const downloadOrderFile = async (orderId: number, fileId: number, fileName: string): Promise<any> => {
    const responseBlob = await request<Blob>({
        responseType: 'blob',
        url: `/orders/${orderId}/files/${fileId}`,
        method: 'GET',
    });

    const blob = new Blob([responseBlob], {type: 'application/pdf' });
    saveAs(blob, fileName);
}

export const useDeleteOrderFile = () => {
    return useMutation(async ({orderId, fileId}: any) => {
        return await request({
            url: `/orders/${orderId}/files/${fileId}`,
            method: 'DELETE',
        })
    }, {
        onSuccess: () => {
            queryCache.invalidateQueries(orderFileCacheKey)
        }
    })
}
