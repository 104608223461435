import { UserTaskModel } from "Apis/UserTasks/Models/UserTaskModel";
import { queryCache, useMutation, useQuery } from "react-query";
import { request } from "Apis/BaseApi";
import { ScheduleUserTaskModel } from "Apis/UserTasks/Models/ScheduleUserTaskModel";

export const USERTASK_CACHE_KEY = 'USERTASK_CACHE_KEY';

export const useUserTasks = (searchFilter?: string) => {
    const params = new URLSearchParams()

    if (searchFilter) {
        params.set('queryFilter', searchFilter)
    }

    return useQuery<UserTaskModel[]>([USERTASK_CACHE_KEY, searchFilter], async () => {
        return await request<UserTaskModel[]>({
            url: `/userTask?${params.toString()}`,
            method: 'GET'
        })
    })
}

export const useSaveTask = () => {
    return useMutation(async (model: UserTaskModel) => {
        if (model.id) {
            return await request({
                url: `/userTask/${model.id}`,
                method: 'PUT',
                data: model,
            })
        } else {
            return await request({
                url: `/userTask`,
                method: 'POST',
                data: model,
            })
        }
    }, {
        onSuccess: () => {
            queryCache.invalidateQueries(USERTASK_CACHE_KEY);
        }
    })
}

export const useDeleteTask = () => {
    return useMutation(async (id: number) => {
        return await request({
            url: `userTask/${id}`,
            method: `DELETE`
        })
    },  {
        onSuccess: () => {
            queryCache.invalidateQueries(USERTASK_CACHE_KEY);
        }
    })
}

export const useScheduleTask = () => {
    return useMutation(async (model:ScheduleUserTaskModel) => {
        return await request({
            url:`userTask/${model.id}/schedule`,
            data:model,
            method:`PUT`
        })
    },  {
        onSuccess: () => {
            queryCache.invalidateQueries(USERTASK_CACHE_KEY);
        }
    })
}