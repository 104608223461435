import {DeliveryAddress, InvoicingAddress} from "Apis/Models/OrderModel";
import {ApprovalModel} from "Apis/Models/ApprovalModel";
import {CalculationLine} from "Apis/Models/CalculationLine";

export interface OfferModel {
    id: number;
    organizationId: number;

    offerType: 'Offer' | 'Estimate';
    state: 'Draft' | 'WaitingForApproval' | 'Approved' | 'Rejected' | 'CustomerApproved' | 'CustomerRejected';

    customerId: number;

    title: string;
    description: string;

    requisitionNumber: string;
    date: Date | null;
    expirationDate: Date | null;

    priceExVat: number;

    invoicingAddress: InvoicingAddress,
    deliveryAddress: DeliveryAddress,

    calculationLines: CalculationLine[],

    approval: ApprovalModel;
    customerApproval: ApprovalModel;
}

export function deserialize(offer: OfferModel): OfferModel {
    const date = offer.date !== null ? new Date(offer.date) : null;
    const expirationDate = offer.expirationDate !== null ? new Date(offer.expirationDate) : null;

    const approvalDate = offer.approval && offer.approval.approvedAt !== null ? new Date(offer.approval.approvedAt) : null;

    return {...offer, date, expirationDate, approval: {...offer.approval, approvedAt: approvalDate}}
}
