import {notification} from "antd";

export const openSuccessNotification = (title: string, description: string) => {
    notification.open({
        message: title,
        description: description,
        className: 'notification notification-success'
    });
};

export const openWarningNotification = (title: string, description: string) => {
    notification.open({
        message: title,
        description: description,
        className: 'notification notification-warning'
    });
};

export const openErrorNotification = (title: string, description: string, config: any = null) => {
    notification.open({
        message: title,
        description: description,
        className: 'notification notification-error',
        ...config,
    });
};

