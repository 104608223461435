import {queryCache, useMutation, useQuery} from "react-query";
import {request} from "Apis/BaseApi";
import {deserialize, OfferModel} from "Apis/Models/OfferModel";
import {openErrorNotification} from "Utils/NotificationUtils";
import { saveAs } from 'file-saver';

export const offersQueryCacheKey = 'offers';
export const useOffers = (queryFilter?: string, state?: string, offerType?: string, userId?: string) => {
    const params = new URLSearchParams();

    if (queryFilter) {
        params.set('queryFilter', queryFilter);
    }

    if (state) {
        params.set('state', state);
    }

    if (offerType) {
        params.set('offerType', offerType);
    }

    if (userId) {
        params.set('responsibleId', userId);
    }

    return useQuery<OfferModel[]>([offersQueryCacheKey, queryFilter, state, offerType, userId], async () => {
        const response = await request<OfferModel[]>({
            url: `/offers?${params.toString()}`,
            method: 'GET'
        })

        return response.map(x => deserialize(x));
    })
}


export const offerQueryCacheKey = 'offer';
export const useOffer = (id: number) => {
    return useQuery<OfferModel>([offerQueryCacheKey, id], async () => {
        const response = await request<OfferModel>({
            url: `/offers/${id}`,
            method: 'GET'
        })

        return deserialize(response);
    })
}

export const getOfferPdf = async (offerId: number): Promise<any> => {
    const responseBlob = await request<Blob>({
        responseType: 'blob',
        url: `/offers/${offerId}/pdf`,
        method: 'GET',
    });

    const blob = new Blob([responseBlob], {type: 'application/pdf' });
    const fileName = `Tilbud.pdf`;
    saveAs(blob, fileName);
}

export const useSaveOffer = () => {
    return useMutation(async (offer: OfferModel) => {
       let response;

        if (offer.id) {
            response = await request<OfferModel>({
                url: `/offers/${offer.id}`,
                method: 'PUT',
                data: offer
            })
        } else {
            response = await request<OfferModel>({
                url: `/offers`,
                method: 'POST',
                data: offer
            })
        }

        return deserialize(response)
    }, {
        onSuccess: (offer: OfferModel) => {
            queryCache.invalidateQueries(offersQueryCacheKey)
            queryCache.invalidateQueries([offerQueryCacheKey, offer.id])
        },
    })
}

export const useDeleteOffer = () => {
    return useMutation(async (offer: OfferModel): Promise<OfferModel> => {
        return await request({
            url: `/offers/${offer.id}`,
            method: 'DELETE'
        })
    }, {
        onSuccess: (offer: OfferModel) => {
            queryCache.invalidateQueries(offersQueryCacheKey)
            queryCache.invalidateQueries([offerQueryCacheKey, offer.id])
        },
    })
}

export const useOfferReadyForApproval = () => {
    return useMutation(async (offer: OfferModel) => {
        const response = await request<OfferModel>({
            url: `/offers/readyForApproval/${offer.id}`,
            method: 'POST',
        })

        return deserialize(response)
    }, {
        onSuccess: (offer: OfferModel) => {
            queryCache.invalidateQueries(offerQueryCacheKey)
            queryCache.invalidateQueries([offerQueryCacheKey, offer.id])
        },
        onError: (errors: any) => {
            const error = errors.data.join(', ')
            openErrorNotification('Der mangler informationer', error)
         }
    })
}


export const useApproveOfferOnBehalf = () => {
    return useMutation(async ({offerId, reason}: any) => {
        return await request<OfferModel>({
            url: `/offers/${offerId}/approveonbehalf`,
            method: 'POST',
            data: {
                reason
            }
        })
    }, {
        onSuccess: (offer: OfferModel) => {
            queryCache.invalidateQueries(offerQueryCacheKey)
            queryCache.invalidateQueries(offersQueryCacheKey)
        }
    })
}

export const useRejectOfferOnBehalf = () => {
    return useMutation(async ({offerId, reason}: any) => {
        return await request<OfferModel>({
            url: `/offers/${offerId}/rejectonbehalf`,
            method: 'POST',
            data: {
                reason
            }
        })
    }, {
        onSuccess: (offer: OfferModel) => {
            queryCache.invalidateQueries(offerQueryCacheKey)
            queryCache.invalidateQueries(offersQueryCacheKey)
        }
    })
}
