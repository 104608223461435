import React from 'react';
import {Route, Switch, useRouteMatch} from "react-router-dom";
import NewsPage from "Pages/News/NewsPage";

const NewsRoutes = () => {

    const {path} = useRouteMatch();

    return (
        <Switch>

            <Route path={`${path}`} exact>
                <NewsPage />
            </Route>

        </Switch>
    )
}

export default NewsRoutes
