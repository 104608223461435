import React, {useCallback, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useCustomers} from "Apis/CustomerApi";
import DefaultPageLayout from "Pages/Layouts/DefaultPageLayout";
import ShoppingList from "Assets/Icons/ShoppingList";
import Box from "Components/Box";
import FlexRow from "Components/FlexRow";
import {Table} from "antd";
import Spinner from "Components/Spinner";
import Pill from "Components/Pill";
import {getDateOnlyString} from "Utils/DateUtils";
import _ from 'lodash';
import {useInvoices} from "Apis/InvoiceApi";
import moment from "moment";
import { getInvoiceStateAsString, getInvoiceStateColor, getInvoiceTypeAsString, getLinesTotalExVat } from "Utils/InvoiceUtils";
import {InvoiceModel} from "Apis/Models/InvoiceModel";
import { getNumberAsCurrency } from "Utils/NumberUtils";

const InvoiceOverviewPage = () => {

    const history = useHistory();

    const [searchFilter, setSearchFilter] = useState<string>('')
    const [stateFilter, setStateFilter] = useState<string>('')
    const [invoiceType, setInvoiceType] = useState<string>('')

    const {data: invoices, isLoading} = useInvoices(searchFilter, stateFilter, invoiceType);
    const {data: customers, isLoading: isLoadingCustomers} = useCustomers()
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const search = useCallback(_.debounce(async (searchFilter: string) => {
        setSearchFilter(searchFilter)
    }, 300), [])

    return (
        <DefaultPageLayout
            title={"Faktura og kreditnota"}
            icon={<ShoppingList />}
            beforeBox={
                <Box style={{marginBottom: 15, padding: 10}}>
                    <FlexRow>
                        <input onChange={e => search(e.target.value)} type="text" className={"input input-invisible full-width"} placeholder={"Søg...."}/>
                        <select placeholder={"Vælg type"} onChange={e => setInvoiceType((e.target.value))} style={{paddingRight: 20, marginRight: 20}} >
                            <option value="">Vælg type</option>
                            <option value="Invoice">Faktura</option>
                            <option value="CreditNote">Kreditnota</option>
                        </select>
                        <select placeholder={"Vælg status"} onChange={e => setStateFilter((e.target.value))} style={{paddingRight: 20, marginRight: 20}} >
                            <option value="">Åbne</option>
                            <option value="Draft">Kladde</option>
                            <option value="WaitingForApproval">Afventer godkendelse</option>
                            <option value="Approved">Godkendt af installatør</option>
                            <option value="Rejected">Afvist af installatør</option>
                            <option value="Paid">Betalt</option>
                            <option value="Overdue">Over forfald</option>
                            <option value="Deprecated">Afskrevet</option>
                        </select>
                    </FlexRow>
                </Box>
            }>
            <Table
                size={"small"}
                rowKey={'id'}
                dataSource={invoices}
                loading={{indicator: <Spinner />, spinning: isLoading || isLoadingCustomers}}
                pagination={false}
                onRow={(record) => {
                    return {
                        onClick: () => history.push(`/economy/invoices/edit/${record.id}`),
                    };}
                }
                rowClassName={'clickable'}
            >

                <Table.Column title={"Type"} dataIndex={"invoiceType"} render={(text, record: InvoiceModel) => (
                    <FlexRow justify={"start"}>
                        <Pill color={getInvoiceStateColor(record)} />
                        <FlexRow align={"start"} justify={"start"} direction={"column"}>
                            <span>
                                {getInvoiceTypeAsString(record.invoiceType)}
                            </span>
                            <span style={{fontSize: '.7em'}}>
                                {getInvoiceStateAsString(record)}
                            </span>
                        </FlexRow>
                    </FlexRow>
                )} />
    
                <Table.Column title={"Dato"} dataIndex={"date"} render={(text, record: InvoiceModel) => record.date ? getDateOnlyString(moment(record.date).toDate()) : '' } />
                <Table.Column title={"Forfaldsdato"} dataIndex={"duedate"} render={(text, record: InvoiceModel) => record.dueDate ? getDateOnlyString(moment(record.dueDate).toDate()) : '' } />
                <Table.Column title={"Overskrift"} dataIndex={"title"} />

                <Table.Column title={"Kunde"} dataIndex={"customerId"} render={(text, record: InvoiceModel) => (
                    <FlexRow direction={"column"} justify={"start"} align={"start"}>
                        <span style={{fontWeight: 600}}>
                            {customers?.find(x => x.id === record.customerId)!?.name}
                        </span>
                        <span style={{fontSize: '.8em'}}>
                            {record.deliveryAddress.street} - {record.deliveryAddress.zipCode} - {record.deliveryAddress.city}s
                        </span>
                    </FlexRow>
                ) } />
    
                <Table.Column title={"Beløb excl. moms"} render={(text, invoice: InvoiceModel) => !!invoice.lines ? `${(getNumberAsCurrency(getLinesTotalExVat(invoice.lines)))} DKK` : '0 DKK'} />
            </Table>
        </DefaultPageLayout>
    )
}

export default InvoiceOverviewPage
