import {queryCache, useMutation, useQuery} from "react-query";
import {request} from "Apis/BaseApi";
import {CalendarEvent} from "Apis/Models/CalendarEvent";
import {getBackendDateFormat} from "Utils/DateUtils";

export const calendarEventsCacheKey = 'calendarEvents';
export const useCalendarEventsForOrder = (orderId: number) => {
    return useQuery<CalendarEvent[]>([calendarEventsCacheKey, orderId], async () => {
        return await request<CalendarEvent[]>({
            url: `/calendarEvents/order/${orderId}`,
            method: 'GET'
        })
    })
}

export const calendarEventsByPeriodCacheKey = 'calendarEvents';
export const useCalendarEventsForPeriod = (from: Date, to: Date) => {
    return useQuery<CalendarEvent[]>([calendarEventsByPeriodCacheKey, from], async () => {
        return await request<CalendarEvent[]>({
            url: `/calendarEvents/period?from=${getBackendDateFormat(from)}&to=${getBackendDateFormat(to)}`,
            method: 'GET'
        })
    })
}

export const useSaveCalendarEvent = () => {
    return useMutation(async (entry: CalendarEvent): Promise<CalendarEvent> => {
        let response;

        if (entry.id) {
            response = await request<CalendarEvent>({
                url: `/calendarEvents/${entry.id}`,
                method: 'PUT',
                data: entry
            })
        } else {
            response = await request<CalendarEvent>({
                url: `/calendarEvents`,
                method: 'POST',
                data: entry
            })
        }
        return response;
    }, {
        onSuccess: (entry: CalendarEvent) => {
            queryCache.invalidateQueries(calendarEventsCacheKey)
            queryCache.invalidateQueries(calendarEventsByPeriodCacheKey)
        },
    })
}

export const useDeleteCalendarEvent = () => {
    return useMutation(async (event: CalendarEvent): Promise<CalendarEvent> => {
        return await request<CalendarEvent>({
            url: `/calendarEvents/${event.id}`,
            method: 'DELETE',
        })
    }, {
        onSuccess: (entry: CalendarEvent) => {
            queryCache.invalidateQueries(calendarEventsCacheKey)
            queryCache.invalidateQueries([calendarEventsCacheKey, entry.orderId])
        },
    })
}
