import React from "react";
import { DocumentReadModel } from "Apis/Documents/Models/DocumentReadModel";
import styles from './DocumentFolder.module.scss';
import FlexRow from "Components/FlexRow";
import Link from "Assets/Icons/Link";

interface Props {
    file: DocumentReadModel,
}

const DocumentLink = (props: Props) => {

    const openLink = async () => {
        window.open(props.file.link, '_blank');
    }
    
    return (
        <FlexRow justify={"space-between"} align={"center"} direction={"column"} className={styles.container} onClick={openLink}>
            <FlexRow justify={"center"} align={"center"} className={"h-100"}>
                <Link width={'20%'} fill="rgba(147, 147, 147, 0.5)"/>
            </FlexRow>
            <FlexRow align={"center"} justify={"center"}>
                <div className={"w-100 text-ellipsis"}>
                    {props.file.name}
                </div>
            </FlexRow>
        </FlexRow>
    )
}

export default DocumentLink;
