import React, { useEffect, } from "react";
import { Modal, Space } from "antd";
import { useForm } from "react-hook-form";
import { useDeleteFile, useDeleteFolder, useWriteFile, useWriteFolder } from "Apis/Admin/Documents/DocumentsAdminApi";
import { DocumentAdminWriteModel } from "Apis/Admin/Documents/Models/DocumentAdminWriteModel";
import { openSuccessNotification } from "Utils/NotificationUtils";
import FlexRow from "Components/FlexRow";
import StyledButton from "Components/StyledButton";
import { getInputClasses } from "Utils/CssUtils";
import { DocumentReadModel } from "Apis/Documents/Models/DocumentReadModel";

interface Props {
    parentId: number;
    document: Partial<DocumentReadModel>;
    visible: boolean;
    onCancel: Function;
}

const DocumentCrudModal = ({ parentId, document, visible, onCancel }: Props) => {

    const { register, errors, handleSubmit, reset } = useForm<{ name: string }>();

    const [writeFolderMutation] = useWriteFolder();
    const [writeFileMutation] = useWriteFile();
    const [deleteFolderMutation] = useDeleteFolder();
    const [deleteFileMutation] = useDeleteFile();

    useEffect(() => {
        if (document) {
            reset(document);
        }
    }, [document, reset])

    const save = async (data: DocumentAdminWriteModel) => {
        data = {
            ...data,
            parentId: parentId,
        }

        if (document.type === "Folder") {
            await writeFolderMutation(data);
            openSuccessNotification("Success", "Mappen blev gemt");
        } else {
            await writeFileMutation(data)
            openSuccessNotification("Success", "Filen blev gemt")
        }

        onCancel();
    }

    const deleteFolder = async () => {
        if (document.type === "Folder") {
            await deleteFolderMutation(document.id);
            openSuccessNotification("Success", "Mappen blev slettet");
        } else {
            await deleteFileMutation(document.id);
            openSuccessNotification("Sucess", "Filen blev slettet")
        }
    }

    return (
        <Modal visible={visible}
               onCancel={() => onCancel()}
               footer={false}
               title={document.id ? 'Opdater' : 'Opret'}
        >
            <form onSubmit={handleSubmit(save)}>
                <input type={"hidden"} name={"id"} value={document.id} ref={register}/>

                <Space direction={"vertical"} className={"w-100"} size={"large"}>
                    <div>
                        <label>Navn</label>
                        <input type={"text"} name={"name"} placeholder={"navn"} className={getInputClasses(errors.name)} ref={register({ required: true })}/>
                    </div>
    
                    {document.type === "Link" && (
                        <div>
                            <label>Link</label>
                            <input type={"text"} name={"link"} placeholder={"Link"} className={getInputClasses(errors.name)} ref={register({ required: true })}/>
                        </div>
                    )}

                    <FlexRow justify={"space-between"}>
                        <div>
                            {!!document.id && (
                                <StyledButton color={"danger"} onClick={deleteFolder}>Slet</StyledButton>
                            )}
                        </div>
                        <StyledButton color={"primary"} type={"submit"}>Gem</StyledButton>
                    </FlexRow>
                </Space>
            </form>
        </Modal>
    )
}
export default DocumentCrudModal;
