import React from "react";
import {Route, Switch} from "react-router-dom";
import {useRouteMatch} from "react-router-dom";
import {Col, Row, Space} from "antd";
import Box from "Components/Box";
import FlexRow from "Components/FlexRow";
import LoginForm from "Pages/Auth/Components/LoginForm";
import ResetPasswordForm from "Pages/Auth/Components/ResetPasswordForm";
import ActionsForm from "Pages/Auth/Components/ActionsForm";

function AuthPage() {

    let {path} = useRouteMatch();

    return (
        <div className={"w-100 flex column justify-center h-100"}>
            <Row justify="center">
                <Col xs={22} sm={20} md={16} lg={16} xl={12} xxl={6}>
                    <Box>
                        <Space direction={"vertical"} size={"large"} className={"w-100"}>
                            <FlexRow justify={'center'}>
                                <img width={300} src="/images/Logo.png" alt="ELi A/S Text Logo"/>
                            </FlexRow>
                            <Switch>
                                <Route path={`${path}/login`}>
                                    <LoginForm />
                                </Route>
                                <Route path={`${path}/resetpassword`}>
                                    <ResetPasswordForm />
                                </Route>
                                <Route path={`${path}/actions`}>
                                    <ActionsForm />
                                </Route>
                            </Switch>
                        </Space>
                    </Box>
                </Col>
            </Row>
        </div>

    )
}

export default AuthPage;
