import React from 'react';
import {Route, Switch, useRouteMatch} from "react-router-dom";
import CalendarPage from "Pages/Calendar/CalendarPage";

const CalendarRoutes = () => {

    const {path} = useRouteMatch();

    return (
        <Switch>

            <Route path={`${path}`} exact>
                <CalendarPage />
            </Route>

        </Switch>
    )

}

export default CalendarRoutes
