import DefaultPageLayout from "Pages/Layouts/DefaultPageLayout";
import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import OfferForm from "Pages/Projects/Offers/OfferForm";
import { getOfferPdf, useApproveOfferOnBehalf, useOffer, useRejectOfferOnBehalf } from "Apis/OfferApi";
import OfferDetails from "Pages/Projects/Offers/Components/OfferDetails";
import PaperLayout from "Components/PaperLayout";
import FlexRow from "Components/FlexRow";
import Notice from "Components/Notice";
import { getDateWithTime } from "Utils/DateUtils";
import StyledButton from "Components/StyledButton";
import { useIsLoading } from "Hooks/UseIsLoading";
import { getOfferTypeAsString } from "Utils/OfferUtils";
import ShoppingList from "Assets/Icons/ShoppingList";
import { getInputClasses } from "Utils/CssUtils";
import { Space, Table } from "antd";
import Modal from "antd/es/modal";
import { useForm } from "react-hook-form";
import { openSuccessNotification } from "Utils/NotificationUtils";
import DocumentContainer from "Components/Document/DocumentContainer";

const EditOfferPage = () => {

    const history = useHistory();
    const { id } = useParams<{ id }>();

    const [showOnBehalfModal, setShowOnBehalfModal] = useState<boolean>(false);
    const [showCalculationLinesModal, setShowCalculationLinesModal] = useState<boolean>(false)
    const { register, errors, handleSubmit } = useForm<{ reason }>();

    const { data: offer, isLoading } = useOffer(id);

    const [approveOfferMutation, { isLoading: isApprovingOffer }] = useApproveOfferOnBehalf();
    const [rejectOfferMutation, { isLoading: isRejectingOffer }] = useRejectOfferOnBehalf();

    const [isDownloadingPdf, downloadPdf] = useIsLoading(() => getOfferPdf(offer?.id!));

    useEffect(() => {
        if (!isLoading && !offer) {
            history.push('/404');
        }
    }, [offer, isLoading, history])

    const approveOffer = async (data: { reason }) => {
        await approveOfferMutation({ offerId: id, reason: data.reason });

        openSuccessNotification('Success', `${getOfferTypeAsString(offer!.offerType!)} blev godkendt`)

        setShowOnBehalfModal(false);
    }

    const rejectOffer = async (data: { reason }) => {
        await rejectOfferMutation({ offerId: id, reason: data.reason });

        openSuccessNotification('Success', `${getOfferTypeAsString(offer!.offerType!)} blev afvist`);

        setShowOnBehalfModal(false);
    }

    return (
        <DefaultPageLayout
            isLoading={isLoading}
            title={getOfferTypeAsString(offer?.offerType)}
            icon={<ShoppingList/>}
        >
            {offer && offer.state !== 'Draft' && offer.state !== 'Rejected' && (
                <DocumentContainer>
                <PaperLayout beforePaper={(
                    <>
                        {offer?.customerApproval && offer.customerApproval.approvedAt && (
                            <Notice type={offer.customerApproval.isApproved ? 'Success' : 'Danger'}>
                                <FlexRow justify={"space-between"}>
                                    <FlexRow direction={"column"} align={"start"}>
                                        <b>
                                            {offer.customerApproval.isApproved ? 'Godkendt af kunde' : 'Afvist af kunde'} {getDateWithTime(offer.customerApproval.approvedAt)}
                                        </b>
                                        <span>
                                            {offer?.customerApproval?.reason}
                                        </span>
                                    </FlexRow>
    
                                    <Space>
                                        <StyledButton onClick={() => setShowOnBehalfModal(true)}>Godkend / Afvis</StyledButton>
                                        <StyledButton disabled={isDownloadingPdf} loading={isDownloadingPdf} onClick={downloadPdf}>Download</StyledButton>
                                        <StyledButton onClick={() => setShowCalculationLinesModal(true)}>Vis Matrialeliste</StyledButton>
                                    </Space>
                                </FlexRow>
                            </Notice>
                        )}
                        {!offer?.customerApproval && offer?.approval && offer.approval.approvedAt && (
                            <Notice type={offer.approval.isApproved ? 'Warning' : 'Danger'}>
                                <FlexRow justify={"space-between"}>
                                    <FlexRow direction={"column"} align={"start"}>
                                        <b>
                                            {offer.approval.isApproved ? 'Sendt til kunde' : 'Afvist af installatør'} {getDateWithTime(offer.approval.approvedAt)}
                                        </b>
                                        <span>
                                            {offer?.approval?.reason}
                                        </span>
                                    </FlexRow>

                                    <Space>
                                        <StyledButton onClick={() => setShowOnBehalfModal(true)}>Godkend / Afvis</StyledButton>
                                        <StyledButton disabled={isDownloadingPdf} loading={isDownloadingPdf} onClick={downloadPdf}>Download {getOfferTypeAsString(offer.offerType)}</StyledButton>
                                        <StyledButton onClick={() => setShowCalculationLinesModal(true)}>Vis Matrialeliste</StyledButton>
                                    </Space>
                                </FlexRow>
                            </Notice>
                        )}
                        {(!offer?.approval || !offer?.approval.approvedAt) && (
                            <Notice type={'Warning'}>
                                <FlexRow direction={"column"} align={"start"}>
                                    <b>
                                        Sendt til godkendelse hos Installatør
                                    </b>
                                </FlexRow>
                            </Notice>
                        )}
                    </>
                )}>
                    <OfferDetails offer={offer}/>
                </PaperLayout>
                </DocumentContainer>
            )}
            {offer && (offer.state === 'Draft' || offer.state === 'Rejected') && (
                <OfferForm offer={offer} type={offer?.offerType ?? 'Estimate'}/>
            )}

            <Modal visible={showOnBehalfModal} title={"Godkend eller afvis tilbud på vegne af kunden"}
                   onCancel={() => setShowOnBehalfModal(false)}
                   footer={false}
            >
                <textarea placeholder={"Angiv hvorfor du godkender eller afivser på vegne af kunden"} name="reason" rows={4} className={getInputClasses(errors.reason)} ref={register({ required: true })}/>
                <FlexRow justify={"end"}>
                    <Space>
                        <StyledButton loading={isRejectingOffer} disabled={isRejectingOffer || isApprovingOffer} onClick={handleSubmit(rejectOffer)} type={"submit"}>Afvis</StyledButton>
                        <StyledButton loading={isApprovingOffer} disabled={isRejectingOffer || isApprovingOffer} onClick={handleSubmit(approveOffer)} type={"submit"}>Godkend</StyledButton>
                    </Space>
                </FlexRow>
            </Modal>

            <Modal visible={showCalculationLinesModal}
                   onCancel={() => setShowCalculationLinesModal(false)}
                   footer={false}
                   width={'80%'}
            >
                <Table
                    rowKey={'id'}
                    dataSource={offer?.calculationLines}
                    pagination={false}
                >
                    <Table.Column title={"Varenr"} dataIndex={"productNumber"}/>
                    <Table.Column title={"Beskrivelse"} dataIndex={"description"}/>
                    <Table.Column title={"Antal"} dataIndex={"amount"}/>
                    <Table.Column title={"Inkøbspris"} dataIndex={"purchasePrice"}/>
                    <Table.Column title={"Avance i %"} dataIndex={"margin"}/>
                    <Table.Column title={"Salgspris"} dataIndex={"salesPrice"}/>
                    <Table.Column title={"Rabat i %"} dataIndex={"discount"}/>

                </Table>
                <FlexRow justify={"end"}>
                    <StyledButton color={"primary"} onClick={() => setShowCalculationLinesModal(false)}> Luk </StyledButton>
                </FlexRow>
            </Modal>
        </DefaultPageLayout>
    )
}

export default EditOfferPage;
