import React from "react";

const GroupLabel = ({children}) => {

    return (
        <label style={{fontWeight: 600, fontSize: '1.1em'}}>
            {children}
        </label>
    )

}

export default GroupLabel;
