import React, {useState} from "react";
import Box from "Components/Box";
import styles from './NewSupplierPage.module.scss';
import {useForm} from "react-hook-form";
import StyledButton from "Components/StyledButton";
import FlexRow from "Components/FlexRow";
import {getInputClasses} from "Utils/CssUtils";
import _ from 'lodash';
import Label from "Components/Label";
import {Col, Dropdown, Row, Space, Tabs, Upload} from "antd";
import GroupLabel from "Components/GroupLabel";
import {useAddressSuggestionMenu} from "Hooks/UseAddressSuggestions";
import {DawaAutocompleteModel} from "Apis/Models/DawaAutocompleteModel";
import {openErrorNotification} from "Utils/NotificationUtils";
import {toBase64} from "Utils/Base64Utils";
import {useCreateSupplierApplication} from "Apis/Public/SupplierApplications/PublicSupplierApplicationApi";
import {
    ApplicationFile,
    FileType,
    SupplierApplicationModel
} from "Apis/Public/SupplierApplications/Models/SupplierApplicationModel";

const NewSupplierPage = () => {

    const [activeTab, setActiveTab] = useState<string>("1");
    const [fileList, setFileList] = useState<ApplicationFile[]>([]);
    const [ createSupplierApplicationMutation, { isLoading: isCreatingApplication } ] = useCreateSupplierApplication();
    const {register, handleSubmit, errors, reset, setValue, trigger, getValues} = useForm<SupplierApplicationModel>({mode: 'all'});
    
    const [addressSuggestions, addressSuggestionsMenu, searchAddress, clearAddressSuggestions] = useAddressSuggestionMenu((address: DawaAutocompleteModel) => {
        setValue('address.street', `${address.adresse.vejnavn} ${address.adresse.husnr} ${address.adresse.etage ?? ''} ${address.adresse.dør ?? ''}`);
        setValue('address.city', address.adresse.postnrnavn)
        setValue('address.zipCode', address.adresse.postnr)
        trigger();
    })

    const [companyAddressSuggestions, companyAddressSuggestionsMenu, companySearchAddress, companyClearAddressSuggestions] = useAddressSuggestionMenu((address: DawaAutocompleteModel) => {
        setValue('companyAddress.street', `${address.adresse.vejnavn} ${address.adresse.husnr} ${address.adresse.etage ?? ''} ${address.adresse.dør ?? ''}`);
        setValue('companyAddress.city', address.adresse.postnrnavn)
        setValue('companyAddress.zipCode', address.adresse.postnr)
        trigger();
    })

    const getFileByType = (type: FileType): ApplicationFile | undefined => {
        return fileList.find(x => x.fileType === type)
    }

    const hasFileByType = (type: FileType) => {
        return !!getFileByType(type)
    }

    const createSupplierApplication = async (data: SupplierApplicationModel) => {
        const formReadyForSubmission = await trigger();

        const filesReadyForSubmission = hasFileByType('DriversLicense') && hasFileByType('HealthInsuranceCard') && hasFileByType('ProfilePicture') && hasFileByType('EducationCertificate') && hasFileByType('CriminalRecord')

        if (!formReadyForSubmission) {
            openErrorNotification('Der mangler info', 'Du skal udfylde alle de påkrævede felter på side 1')
            return;
        }

        if (!filesReadyForSubmission) {
            openErrorNotification('Der mangler filer', 'Du skal uploade både et profilebillede, dit sygesikringsbevis, dit kørekort, din straffeattest og dit svendebrev')
            return;
        }

        const dataAndFiles = {
            ...data,
            driversLicenseBase64: getFileByType('DriversLicense')!.base64,
            healthInsuranceCardBase64: getFileByType('HealthInsuranceCard')!.base64,
            profilePictureBase64: getFileByType('ProfilePicture')!.base64,
            educationCertificateBase64: getFileByType('EducationCertificate')!.base64,
            criminalRecordBase64: getFileByType('CriminalRecord')!.base64
        }

        await createSupplierApplicationMutation(dataAndFiles);

        reset();
        setActiveTab("3")
    }

    const gotoSecondTab = async () => {
        const result = await trigger();

        if (!result) {
            openErrorNotification('Du mangler noget', 'Du skal udfylde alle de påkrævede felter')
            return;
        }

        if (!getValues('hasAcceptedTerms')) {
            openErrorNotification('Du mangler noget', 'Du skal acceptere vores betingelser')
            return;
        }

        setActiveTab("2")
    }

    const getUploadField = (fileType: FileType, text: string) => {
        return (
            <Upload
                className={styles.upload}
                listType={"picture"}
                multiple={false}
                fileList={hasFileByType(fileType) ? [getFileByType(fileType)?.file] : []}
                accept={"image/*,.pdf"}
                onRemove={() => {
                    setFileList((files) => {
                        return [...files.filter(x => x.fileType !== fileType)]
                    })
                }}
                beforeUpload={(file) => {
                    toBase64(file, true).then(base64 => {
                        console.log(base64)
                        setFileList((files) => {
                            return [...files.filter(x => x.fileType !== fileType), {fileType: fileType, file: file, base64}]
                        })
                    });

                    return false;
                }}
            >
                <FlexRow justify={"space-between"}>
                    <GroupLabel>
                        {text}
                    </GroupLabel>

                    <StyledButton>
                        Upload
                    </StyledButton>

                </FlexRow>
            </Upload>
        )
    }

    return (
        <div className={styles.container}>
            <div className={styles.innerContainer}>
                <Box>
                    <FlexRow justify={"center"}>
                        <div className={styles.title}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="238.528" height="51" viewBox="0 0 238.528 51">
                                <g id="Group_55" data-name="Group 55" transform="translate(-695.123 -404.599)">
                                    <path id="Path_12" data-name="Path 12" d="M752.972,404.6l-21.857,51H716.907l-21.785-51h15.591l13.843,33.223L738.692,404.6Z" transform="translate(0)" fill="#F39324"/>
                                    <path id="Path_13" data-name="Path 13" d="M991.17,455.6l21.857-51h14.207l21.785,51h-15.592l-13.843-33.223L1005.45,455.6Z" transform="translate(-247.158)" fill="#F39324"/>
                                    <path id="Path_14" data-name="Path 14" d="M1378.171,404.6H1392.6v39.562h19.927V455.6h-34.353Z" transform="translate(-570.25)" fill="#F39324"/>
                                    <path id="Path_15" data-name="Path 15" d="M1677.323,455.6l-.146-27.03-13.114,22h-6.411L1644.609,429.3v26.3h-13.333v-51h11.876l17.923,29.435,17.486-29.435h11.876l.146,51Z" transform="translate(-781.557)" fill="#F39324"/>
                                    <path id="Path_16" data-name="Path 16" d="M2052.167,404.6h14.426v51h-14.426Z" transform="translate(-1132.942)" fill="#F39324"/>
                                </g>
                            </svg>
                        </div>
                    </FlexRow>
                    <form onSubmit={handleSubmit(createSupplierApplication)}>
                        <Tabs defaultActiveKey={"1"} activeKey={activeTab}>
                            <Tabs.TabPane key={"1"}>
                                <div className={styles.title}>
                                    Bliv en del af ELi A/S
                                </div>
                                <Row gutter={[20, 20]}>

                                    <Col span={24}>
                                        <GroupLabel>Personlige oplysninger</GroupLabel>
                                    </Col>
                                    <Col span={12}>
                                        <div>
                                            <Label>Fornavn</Label>
                                            <input className={getInputClasses(_.get(errors, 'firstName'))} name={"firstName"} ref={register({required: true})} />
                                        </div>
                                    </Col>

                                    <Col span={12}>
                                        <div>
                                            <Label>Efternavn</Label>
                                            <input className={getInputClasses(_.get(errors, 'lastName'))} name={"lastName"} ref={register({required: true})} />
                                        </div>
                                    </Col>

                                    <Col span={12}>
                                        <div>
                                            <Label>Telefon</Label>
                                            <input className={getInputClasses(_.get(errors, 'phone'))} name={"phone"} ref={register({required: true})} />
                                        </div>
                                    </Col>

                                    <Col span={12}>
                                        <div>
                                            <Label>E-mail</Label>
                                            <input className={getInputClasses(_.get(errors, 'email'))} name={"email"} ref={register({required: true})} />
                                        </div>
                                    </Col>

                                    <Col span={24}>
                                        <div>
                                            <Label>Adresse</Label>
                                            <Dropdown visible={addressSuggestions.length > 0} overlay={addressSuggestionsMenu}>
                                                <input onChange={e => searchAddress(e.target.value)} onBlur={() => setTimeout(() => clearAddressSuggestions(), 100)} className={getInputClasses(_.get(errors, 'address.street'))} name={"address.street"} ref={register({required: true})} />
                                            </Dropdown>
                                        </div>
                                    </Col>

                                    <Col span={12}>
                                        <div>
                                            <Label>Postnr</Label>
                                            <input className={getInputClasses(_.get(errors, 'address.zipCode'))} name={"address.zipCode"} ref={register({required: true})} />
                                        </div>
                                    </Col>

                                    <Col span={12}>
                                        <div>
                                            <Label>By</Label>
                                            <input className={getInputClasses(_.get(errors, 'address.city'))} name={"address.city"} ref={register({required: true})} />
                                        </div>
                                    </Col>

                                    <Col span={24}>
                                        <hr/>
                                        <GroupLabel>Firma oplysninger</GroupLabel>
                                    </Col>

                                    <Col span={24}>
                                        <Label>
                                            Beskæftigelse
                                        </Label>
                                        <select className={getInputClasses(errors.occupation)} name={"occupation"} ref={register({required: true})}>
                                            <option value={""}>Vælg</option>
                                            <option value={"Electrician"}>Elektriker</option>
                                            <option value={"Sanitarian"}>VVS</option>
                                        </select>
                                    </Col>

                                    <Col span={24}>
                                        <div>
                                            <Label>Eksisterende CVR nummer</Label>
                                            <input placeholder={"Udfyldes kun hvis du har et eksisterende CVR nummer, du ønsker at bruge sammen med ELi A/S"} className={getInputClasses(_.get(errors, 'companyVatNumber'))} name={"companyVatNumber"} ref={register({required: false})} />
                                        </div>
                                    </Col>

                                    <Col span={24}>
                                        <div>
                                            <Label>Firmanavn</Label>
                                            <input placeholder={"Hvad skal dit firma hedde?"} className={getInputClasses(_.get(errors, 'companyName'))} name={"companyName"} ref={register({required: true})} />
                                        </div>
                                    </Col>

                                    <Col span={12}>
                                        <div>
                                            <Label>Firmatelefon</Label>
                                            <input className={getInputClasses(_.get(errors, 'companyPhone'))} name={"companyPhone"} ref={register({required: true})} />
                                        </div>
                                    </Col>

                                    <Col span={12}>
                                        <div>
                                            <Label>Firma e-mail</Label>
                                            <input className={getInputClasses(_.get(errors, 'companyEmail'))} name={"companyEmail"} ref={register({required: true})} />
                                        </div>
                                    </Col>

                                    <Col span={24}>
                                        <div>
                                            <Label>Firma adresse</Label>
                                            <Dropdown visible={companyAddressSuggestions.length > 0} overlay={companyAddressSuggestionsMenu}>
                                                <input onChange={e => companySearchAddress(e.target.value)} onBlur={() => setTimeout(() => companyClearAddressSuggestions(), 100)} className={getInputClasses(_.get(errors, 'companyAddress.street'))} name={"companyAddress.street"} ref={register({required: true})} />
                                            </Dropdown>
                                        </div>
                                    </Col>

                                    <Col span={12}>
                                        <div>
                                            <Label>Postnr</Label>
                                            <input className={getInputClasses(_.get(errors, 'companyAddress.zipCode'))} name={"companyAddress.zipCode"} ref={register({required: true})} />
                                        </div>
                                    </Col>

                                    <Col span={12}>
                                        <div>
                                            <Label>By</Label>
                                            <input className={getInputClasses(_.get(errors, 'companyAddress.city'))} name={"companyAddress.city"} ref={register({required: true})} />
                                        </div>
                                    </Col>

                                    <Col span={24}>
                                        <div>
                                            <Label>Firmabeskrivelse</Label>
                                            <textarea className={getInputClasses(_.get(errors, 'companyDescription'))} name={"companyDescription"} ref={register({required: true})} />
                                        </div>
                                    </Col>

                                </Row>


                                <FlexRow justify={"space-between"} >
                                    <div>
                                        <label>
                                            <Space>
                                                <input type="checkbox" ref={register} name={"hasAcceptedTerms"} />
                                                Jeg acceptere betingelserne
                                            </Space>
                                        </label>
                                    </div>
                                    <StyledButton color="primary" onClick={gotoSecondTab}>Videre</StyledButton>
                                </FlexRow>
                            </Tabs.TabPane>

                            <Tabs.TabPane key={"2"}>
                                <div className={styles.title}>
                                    Upload dine dokumenter
                                </div>

                                <Row>
                                    <Col span={24}>
                                        {getUploadField('ProfilePicture', 'Profilbillede')}
                                        <hr/>
                                    </Col>

                                    <Col span={24}>
                                        {getUploadField('DriversLicense', 'Kørekort')}
                                        <hr/>
                                    </Col>

                                    <Col span={24}>
                                        {getUploadField('HealthInsuranceCard', 'Sygesikringskort')}
                                        <hr/>
                                    </Col>

                                    <Col span={24}>
                                        {getUploadField('EducationCertificate', 'Svendebrev')}
                                        <hr/>
                                    </Col>

                                    <Col span={24}>
                                        {getUploadField('CriminalRecord', 'Straffeattest')}
                                    </Col>
                                </Row>

                                <FlexRow justify={"space-between"} style={{paddingTop: '50px'}}>
                                    <StyledButton color="default" onClick={() => {setActiveTab("1")}}>Tilbage</StyledButton>
                                    <StyledButton color="primary" type={"submit"} disabled={isCreatingApplication} loading={isCreatingApplication} >Send ansøgning</StyledButton>
                                </FlexRow>
                            </Tabs.TabPane>
                            <Tabs.TabPane key={"3"}>
                                <div className={styles.title}>
                                    Din ansøgning er modtaget, vi vender tilbage
                                </div>
                            </Tabs.TabPane>
                        </Tabs>
                    </form>
                </Box>
            </div>
        </div>
    )
}

export default NewSupplierPage
