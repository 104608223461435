import React from "react"
import styles from './QaField.module.scss';


interface Props {
    title: string;
    children: any;
}
const QaQuestion = ({title, children}: Props) => {
    
    return (
        <div className={styles.questionRow}>
            <div>
                <b>
                    {title}
                </b>
            </div>
            {children}
        </div>

    )
}

export default QaQuestion
