import {queryCache, useMutation, useQuery} from "react-query";
import {request} from "Apis/BaseApi";
import {OrderAccrualModel} from "Apis/Models/OrderAccrualModel";
import { orderEconomyCacheKey } from "Apis/OrderApi";


export const ORDER_ACCRUAL_CACHE_KEY = 'ORDER_ACCRUAL_CACHE_KEY';
export const useOrderAccrual = (orderId: number) => {
    return useQuery<OrderAccrualModel>([ORDER_ACCRUAL_CACHE_KEY, orderId], async () => {
        return await request<OrderAccrualModel>({
            url: `/order/${orderId}/accrual`,
            method: 'GET'
        })
    })
}

export const useSaveOrderAccrual = () => {
    return useMutation(async (accrual: OrderAccrualModel ) => {
        if (!accrual.id) {
            return await request<OrderAccrualModel>({
                url: `/order/${accrual.orderId}/accrual`,
                method: 'POST',
                data: accrual
            })
        } else {
            return await request<OrderAccrualModel>({
                url: `/order/${accrual.orderId}/accrual`,
                method: 'PUT',
                data: accrual
            })
        }
    }, {
        onSuccess: (accrual: OrderAccrualModel) => {
            queryCache.invalidateQueries([orderEconomyCacheKey, accrual.orderId])
            queryCache.invalidateQueries([ORDER_ACCRUAL_CACHE_KEY, accrual.orderId])
        },
    })
}
