import React, { useState } from "react";
import styles from "./DocumentFolderAdmin.module.scss";
import { useHistory } from "react-router-dom";
import { DocumentReadModel } from "Apis/Documents/Models/DocumentReadModel";
import { Dropdown, Menu } from "antd";
import DocumentCrudModal from "./DocumentCrudModal";
import FlexRow from "Components/FlexRow";
import Folder from "Assets/Icons/Folder";

interface Props {
    document: DocumentReadModel,
}

const DocumentAdminFolder = (props: Props) => {

    const [showModal, setShowModal] = useState<boolean>(false);
    const history = useHistory();

    const menu = (<Menu>

        <Menu.Item onClick={() => {
            setShowModal(true)
        }} key={"1"}>Opdater</Menu.Item>
    </Menu>)

    return (
        <>
            <Dropdown overlay={menu} trigger={['contextMenu']}>
                <FlexRow justify={"space-between"} align={"center"} direction={"column"} className={styles.container} onClick={() => history.push(`/admin/documents/${props.document.id}`)}>
                    <FlexRow justify={"center"} align={"center"} className={"h-100"}>
                        <Folder width={"30%"} fill="rgba(147, 147, 147, 0.5)"/>
                    </FlexRow>
                    <FlexRow align={"center"} justify={"center"}>
                        <div className={"w-100 text-ellipsis"}>
                            {props.document.name}
                        </div>
                    </FlexRow>
                </FlexRow>
            </Dropdown>
            <DocumentCrudModal parentId={props.document.parentId} document={props.document} visible={showModal} onCancel={() => {
                setShowModal(false)
            }}/>
        </>
    )
}

export default DocumentAdminFolder;