import React, { useState } from 'react';
import { Space, Table } from "antd";
import { addVat, getNumberAsCurrency, getVat } from "Utils/NumberUtils";
import { AgreementModel } from "Apis/Models/AgreementModel";
import { getAgreementStatus } from "Utils/AgreementUtils";
import { OrderModel } from "Apis/Models/OrderModel";
import { useAgreementsForOrder, useSaveAgreement, useSendAgreement } from "Apis/AgreementApi";
import FlexRow from "Components/FlexRow";
import StyledButton from "Components/StyledButton";
import Modal from "antd/es/modal";
import { useForm } from "react-hook-form";
import { getInputClasses } from "Utils/CssUtils";
import { openSuccessNotification } from 'Utils/NotificationUtils';

interface Props {
    order: OrderModel
}

const OrderAgreements = ({ order }: Props) => {

    const [showAgreementModal, setShowAgreementModal] = useState<boolean>(false);
    const [showNewAgreementModal, setShowNewAgreementModal] = useState<boolean>(false);
    const [selectedAgreement, setSelectedAgreement] = useState<AgreementModel>();
    const { register, handleSubmit, reset, errors } = useForm<AgreementModel>();

    const { data: agreements, isLoading: isLoadingAgreements } = useAgreementsForOrder(order.id);
    const [saveNewAgreementMutation, { isLoading: isSavingNewAgreement }] = useSaveAgreement();
    const [saveAndSendNewAgreementMutation, { isLoading: isSendingNewAgreemeent }] = useSendAgreement();

    const saveNewAgreement = async (data: AgreementModel) => {
        const newAgreement = {
            ...data,
            orderId: order.id,
        }

        await saveNewAgreementMutation(newAgreement);

        openSuccessNotification('Success', 'Aftalesedlen blev gemt')

    }

    const saveAndSendNewAgreement = async (data: AgreementModel) => {
        const sendNewAgreement = {
            ...data,
            orderId: order.id,
        }

        const response = await saveNewAgreementMutation(sendNewAgreement);


        if (response) {
            await saveAndSendNewAgreementMutation(response.id)

            openSuccessNotification("Success", "Aftalesedlen blev sendt")

            closeModal()
        }
    }

    const closeModal = () => {
        setShowNewAgreementModal(false)
        reset({})
    }

    const showAgreement = (agreement: AgreementModel) => {
        setSelectedAgreement(agreement);
        setShowAgreementModal(true)
    }

    const onAgreementClick = (agreements: AgreementModel) => {
        if (!agreements.sentAt) {
            reset(agreements);
            setShowNewAgreementModal(true);
        } else {
            showAgreement(agreements)
        }
    }

    return (
        <>
            <FlexRow justify={"end"}>
                <StyledButton color={"primary"} onClick={() => setShowNewAgreementModal(true)}>
                    Opret Aftaleseddel
                </StyledButton>
            </FlexRow>
            <Table
                dataSource={agreements}
                pagination={false}
                rowClassName={"clickable"}
                loading={isLoadingAgreements}
                rowKey={'id'}
                onRow={(record) => {
                    return {
                        onClick: () => onAgreementClick(record),
                    };
                }
                }
            >
                <Table.Column title={"Titel"} dataIndex={"title"}/>
                <Table.Column title={"Pris ex moms"} dataIndex={"priceExVat"} render={(text, record) => getNumberAsCurrency(text)}/>
                <Table.Column title={"Staus"} render={(text, record: AgreementModel) => getAgreementStatus(record)}/>
            </Table>

            <Modal visible={showNewAgreementModal} title={"Opret aftalesedel"}
                   onCancel={() => setShowNewAgreementModal(false)}
                   footer={false}>

                <form onSubmit={handleSubmit(saveNewAgreement)}>
                    <input type={"hidden"} ref={register} name={"id"}/>
                    <Space className={"w-100"} direction={"vertical"}>
                        <div>
                            <label>Titel</label>
                            <input className={getInputClasses(errors.title)} placeholder={"title"} type={"text"} name={"title"} ref={register({ required: true })}/>
                        </div>
                        <div>
                            <label> Beskrivelse</label>
                            <textarea rows={20} placeholder={"Beskrivelse"} name={"description"} className={getInputClasses(errors.description)} ref={register({ required: true })}/>
                        </div>
                        <div>
                            <label>Pris eksl. moms</label>
                            <input className={getInputClasses(errors.priceExVat)} placeholder={"pris eksl. moms"} type={"number"} name={"priceExVat"} ref={register({ required: true })}/>
                        </div>
                        <FlexRow justify={"end"}>
                            <Space>
                                <StyledButton color={"default"} loading={isSendingNewAgreemeent} type={"submit"}>Gem</StyledButton>

                                    <StyledButton color={"primary"} loading={isSendingNewAgreemeent || isSavingNewAgreement} type={"button"} onClick={handleSubmit(saveAndSendNewAgreement)}>Gem og send</StyledButton>
                            </Space>
                        </FlexRow>
                    </Space>
                </form>
            </Modal>

            <Modal visible={showAgreementModal} title={"Aftaleseddel"}
                   onCancel={() => setShowAgreementModal(false)}
                   footer={false}>
                <div style={{ fontSize: 20, marginBottom: 20 }}>
                    {selectedAgreement?.title}
                </div>
                <div className={"display-linebreak"}>
                    {selectedAgreement?.description}
                </div>
                {selectedAgreement?.priceExVat && (
                    <FlexRow align={"end"} direction={"column"} style={{ marginTop: 20 }}>
                        <FlexRow justify={"space-between"}>
                            <span>Subtotal</span>
                            <span>{(getNumberAsCurrency(selectedAgreement?.priceExVat))} DKK</span>
                        </FlexRow>
                        <FlexRow justify={"space-between"}>
                            <span>Moms</span>
                            <span>{(getNumberAsCurrency(getVat(selectedAgreement?.priceExVat)))} DKK</span>
                        </FlexRow>
                        <FlexRow justify={"space-between"}>
                            <span><b>I alt</b></span>
                            <span>{(getNumberAsCurrency(addVat(selectedAgreement?.priceExVat)))} DKK</span>
                        </FlexRow>
                    </FlexRow>
                )}
                <FlexRow justify={"end"} style={{ marginTop: 20 }}>
                    <Space>
                        <StyledButton onClick={() => setShowAgreementModal(false)}>Luk</StyledButton>
                    </Space>
                </FlexRow>
            </Modal>
        </>
    )
}

export default OrderAgreements
