import React, {useState} from "react";
import { useUserProvider } from "Providers/UserProvider";
import styles from './VismaConnectConnectionLost.module.scss';
import StyledButton from "Components/StyledButton";
import { useAuthorizationUrl } from "Apis/VismaConnectApi";
import VismaConnectNotConnectedIcon from "Assets/Icons/VismaConnectNotConnectedIcon";
import { useHistory, useLocation } from "react-router-dom";
import VismaConnectConnectedIcon from "Assets/Icons/VismaConnectConnectedIcon";
import FlexRow from "Components/FlexRow";
import {useAllowedOrganization, useSaveAgreementGrantToken} from "Apis/OrganizationApi";
import { getInputClasses } from "Utils/CssUtils";

interface Props {
    children: any;
}
const VismaConnectConnectionLost = ({children}: Props) => {
    
    const history = useHistory();
    const queryParameters = new URLSearchParams(useLocation().search);
    const [hasOpendedEconomic, setHasOpenedEconomic] = useState<boolean>(false);
    const [agreementGrantToken, setAgreementGrantToken] = useState<string>('');
    
    const { changeOrganizationContext, userContext } = useUserProvider();
    
    const {data: allowedOrganizations} = useAllowedOrganization();
    const [getAuthorizationUrlMutation, { isLoading }] = useAuthorizationUrl();
    const [saveAgreementGrantTokenMutation, { isLoading: isSavingAgreementGrantToken }] = useSaveAgreementGrantToken();

    
    const reconnect = async () => {
        const response = await getAuthorizationUrlMutation();
        
        window.location.href = response!.url;
    }

    const reconnectToEconomic = async () => {
        window.open('https://secure.e-conomic.com/secure/api1/requestaccess.aspx?appPublicToken=axrSKMQe10NX2GuHYe4jaDrTrSSRuFJYXTW8hNYCGiM1', '_blank');
        setHasOpenedEconomic(true)
    }

    const saveAgreementGrantToken = async () => {
        await saveAgreementGrantTokenMutation(agreementGrantToken);

        history.push("?economicStatus=Running")
    }
    
    const closeOverlay = () => {
        history.push("/")
    }
    
    const setOrganizationContext = async (event) => {
        if (event.target.value) {
            await changeOrganizationContext(event.target.value)
        }
    }
    
    return (
        <div className={styles.container}>
            {children}
            {userContext?.invoicingIntegrationType === 'Dinero' && ((userContext!.vismaConnectStatus === 'ConnectionLost') || (userContext!.vismaConnectStatus === 'NotAuthorized')) && (
                <div className={styles.overlay}>
                    <div className={styles.overlayContent}>
                        <FlexRow align={"center"} style={{maxWidth: '500px'}}>
                            <VismaConnectNotConnectedIcon />
                        </FlexRow>

                        <div className={styles.text}>
                            ASAP'en har ikke længere forbindelse til din Dinero konto. <br/>

                            Du skal oprette forbindelsen før du kan fortsætte med at bruge ASAP'en
                        </div>

                        <StyledButton onClick={reconnect} loading={isLoading} color={"primary"}>Opret forbindelse til Dinero</StyledButton>

                        {allowedOrganizations && allowedOrganizations.length > 1 && (
                            <>
                                <hr/>

                                <FlexRow justify={'center'} direction={"column"}>
                                    <label style={{color: '#C4C4C4'}}>Eller skift håndværker</label>
                                    <select className={getInputClasses()} value={userContext?.lastOrganizationId ?? ''} onChange={setOrganizationContext}>
                                        <option key={0} value="">Eller skift firma</option>
                                        {allowedOrganizations.slice().sort((a,b) => a.name! > b.name! ? 1 : -1).map((organization) => <option key={organization.id} value={organization.id}>{organization.name}</option>)}
                                    </select>
                                </FlexRow>
                            </>
                        )}
                    </div>
                </div>
            )}
            {(userContext?.invoicingIntegrationType === 'Economic' && !userContext.canAccessEconomic) && (
                <div className={styles.overlay}>
                    <div className={styles.overlayContent}>
                        <FlexRow align={"center"} style={{maxWidth: '500px'}}>
                            <VismaConnectNotConnectedIcon />
                        </FlexRow>


                        {!hasOpendedEconomic && (
                            <>
                                <div className={styles.text}>
                                    ASAP'en har ikke forbindelse til din e-conomic konto. <br/>

                                    Du skal oprette forbindelsen før du kan fortsætte med at bruge ASAP'en
                                </div>

                                <div className={styles.text}>
                                    For at oprette forbindelsen skal du trykke på knappen herunder. Dette vil åbne
                                    e-conomic i et nyt vindue, hvor du skal logge ind og give ASAP'en adgang.

                                    <br/>

                                    Efter du har givet ASAP'en adgang til e-conomic, skal du kopiere den nøgle som
                                    e-conomic har vist i feltet herunder
                                </div>

                                <StyledButton onClick={reconnectToEconomic} color={"primary"}>Opret forbindelse til e-conomic</StyledButton>
                            </>
                        )}

                        {hasOpendedEconomic && (
                            <>
                                <div className={styles.text}>
                                    Efter du har givet ASAP'en adgang til e-conomic, skal du kopiere den nøgle som
                                    e-conomic har vist i feltet herunder
                                </div>

                                <input style={{marginBottom: 15}} placeholder={"Indsæt nøgle fra e-conomic"} className={getInputClasses()} type="text" name={'agreementGrantToken'} value={agreementGrantToken} onChange={e => setAgreementGrantToken(e.currentTarget.value)}/>

                                <StyledButton onClick={saveAgreementGrantToken} loading={isSavingAgreementGrantToken} color={"primary"} disabled={isSavingAgreementGrantToken}>Gem e-conomic nøgle</StyledButton>

                            </>
                        )}

                        {allowedOrganizations && allowedOrganizations.length > 1 && (
                            <>
                                <hr/>

                                <FlexRow justify={'center'} direction={"column"}>
                                    <label style={{color: '#C4C4C4'}}>Eller skift håndværker</label>
                                    <select className={getInputClasses()} value={userContext?.lastOrganizationId ?? ''} onChange={setOrganizationContext}>
                                        <option key={0} value="">Eller skift firma</option>
                                        {allowedOrganizations.slice().sort((a,b) => a.name! > b.name! ? 1 : -1).map((organization) => <option key={organization.id} value={organization.id}>{organization.name}</option>)}
                                    </select>
                                </FlexRow>
                            </>
                        )}
                    </div>
                </div>
            )}
            {(queryParameters?.get('vismaConnectStatus') === "Running") && (
                <div className={styles.overlay}>
                    <div className={styles.overlayContent}>
                        <FlexRow align={"center"} style={{maxWidth: '500px'}}>
                            <VismaConnectConnectedIcon />
                        </FlexRow>
                
                        <div className={styles.text}>
                            ASAP'en har fået genoprettet forbindelsen til Dinero. <br/>
                        </div>
                
                        <StyledButton onClick={closeOverlay} loading={isLoading} color={"primary"}>Fortsæt</StyledButton>
                    </div>
                </div>
            )}
            {(queryParameters?.get('economicStatus') === "Running") && (
                <div className={styles.overlay}>
                    <div className={styles.overlayContent}>
                        <FlexRow align={"center"} style={{maxWidth: '500px'}}>
                            <VismaConnectConnectedIcon />
                        </FlexRow>

                        <div className={styles.text}>
                            ASAP'en har nu forbindelse til e-conomic. <br/>
                        </div>

                        <StyledButton onClick={closeOverlay} loading={isLoading} color={"primary"}>Fortsæt</StyledButton>
                    </div>
                </div>
            )}
            {(queryParameters?.get('vismaConnectStatus') === "Error") && (
                <div className={styles.overlay}>
                    <div className={styles.overlayContent}>
                        <FlexRow align={"center"} style={{maxWidth: '500px'}}>
                            <VismaConnectNotConnectedIcon />
                        </FlexRow>
                
                        <div className={styles.text}>
                            Den forbindelse du netop har oprettet til Dinero har ikke adgang til Dinero Firma Id: {userContext!.dineroOrganizationId}<br/>
                        </div>
    
                        <StyledButton onClick={reconnect} loading={isLoading} color={"primary"}>Prøv igen</StyledButton>
                    </div>
                </div>
            )}
        </div>
    )
}

export default VismaConnectConnectionLost
