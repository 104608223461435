import { Route, Switch, useRouteMatch } from "react-router-dom"
import React from 'react';
import DocumentsPage from "./DocumentsPage";

const DocumentsRoutes = () => {

    let {path} = useRouteMatch();

    return (<>
        <Switch>
            <Route path={[`${path}/:id`, path]}>
                <DocumentsPage/>
            </Route>
        </Switch>
    </>)
}

export default DocumentsRoutes