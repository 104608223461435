import DefaultPageLayout from "Pages/Layouts/DefaultPageLayout";
import React from "react";
import { useParams } from "react-router-dom";
import {useOrder} from "Apis/OrderApi";
import OrderForm from "Pages/Projects/Orders/Forms/OrderForm";

const EditOrderPage = () => {

    const { id } = useParams<{ id }>();

    const {data: order, isLoading} = useOrder(id);

    return (
        <DefaultPageLayout title={`Opdater ordre`} isLoading={isLoading}>
            <OrderForm order={order} />
        </DefaultPageLayout>
    )

}

export default EditOrderPage;
