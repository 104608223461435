import React from "react";
import DefaultStyles from "Styles/Constants";

interface Props {
    children: any;
    style?: any;
}

const Box = ({children, style}: Props) => {
    return (
        <div style={{...styles.box, ...style}}>
            {children}
        </div>
    )
}

const styles = {
    box: {
        width: '100%',
        height: '100%',
        backgroundColor: DefaultStyles.colors.white,
        borderRadius: 6,
        borderColor: DefaultStyles.colors.border,
        boxShadow: '0px 3px 20px rgba(0, 0, 0, .16)',
        padding: DefaultStyles.padding
    }
}

export default Box;
