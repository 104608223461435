export const getNumberAsCurrency = (number: string | number | undefined) => {
    if (!number) {
        return "0,00";
    }

    if (typeof number !== "number") {
        number = parseFloat(number);
    }

    return number.toLocaleString('da-DK', {minimumFractionDigits: 2, maximumFractionDigits: 2}).replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export const roundNumber = (number: number) => {
    return Math.round(number * 100) / 100
}

export const addVat = (number: number) => {
    return roundNumber(number * 1.25)
}

export const getVat = (number: number) => {
    return roundNumber(number * 0.25)
}
