import DefaultPageLayout from "Pages/Layouts/DefaultPageLayout";
import React from "react";
import CustomerForm from "Pages/Projects/Customers/Forms/CustomerForm";
import {CustomerModel} from "Apis/Models/CustomerModel";
import { useHistory } from "react-router-dom";

export const CreateCustomerPage = () => {

    const history = useHistory();

    return (
        <DefaultPageLayout title={"Opret kunde"}>
            <CustomerForm onSave={(customer: CustomerModel) => history.push(`/projects/customers/edit/${customer.id}`)} />
        </DefaultPageLayout>
    )

}

export default CreateCustomerPage;
