import { VismaConnectAuthorizationUrlModel } from "Apis/Models/VismaConnectAuthorizationUrlModel";
import { useMutation } from "react-query";
import { request } from "Apis/BaseApi";

export const useAuthorizationUrl = () => {
    return useMutation(async ( ) => {
        return await request<VismaConnectAuthorizationUrlModel>({
            url: `/vismaconnect/authorize`,
            method: 'GET',
        })
    })
}
