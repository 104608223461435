import axios from "axios";
import {DawaAutocompleteModel} from "Apis/Models/DawaAutocompleteModel";

export const lookupAddress = async (address: string): Promise<DawaAutocompleteModel[]> => {

    const client = axios.create({
        baseURL: 'https://dawa.aws.dk/adresser/autocomplete'
    });

    const response = await client({
        method: 'GET',
        url: `?q=${address}`
    });

    return response.data;
}
