import React from 'react';
import {
    Switch,
    useLocation,
    Route, Redirect
} from "react-router-dom";
import RedirectIfLoggedInRoute from "Components/RedirectIfLoggedInRoute";
import AuthenticatedRoute from 'Components/AuthenticatedRoute';
import AuthPage from "Pages/Auth/AuthPage";
import 'antd/dist/antd.min.css';
import CompanyMenu from "Pages/Layouts/CompanyMenu";
import AdminMenu from "Pages/Layouts/AdminMenu";
import DefaultLayout from "Pages/Layouts/DefaultLayout";
import OrganizationsPage from "Pages/Admin/Organizations/OrganizationsPage";
import UsersPage from "Pages/Admin/Users/UsersPage";
import AccessLevelRoute from "Components/AccessLevelRoute";
import NotFound from "Pages/Layouts/NotFound";
import ProjectRoutes from "Pages/Projects/ProjectRoutes";
import {useUserProvider} from "Providers/UserProvider";
import FlexRow from "Components/FlexRow";
import ApprovalPage from "Pages/Admin/Approval/ApprovalPage";
import PublicPage from "Pages/Public/PublicPage";
import ColleaguesPage from "Pages/Colleagues/ColleaguesPage";
import SettingsRoutes from "Pages/Settings/SettingsRoutes";
import PurchaseRoutes from "Pages/Purchase/PurchaseRoutes";
import PayoutsPage from "Pages/Admin/Payouts/PayoutsPage";
import EconomyRoutes from "Pages/Economy/EconomyRoutes";
import CalendarRoutes from "Pages/Calendar/CalendarRoutes";
import NewsAdminRoutes from "Pages/Admin/News/NewsAdminRoutes";
import NewsRoutes from "Pages/News/NewsRoutes";
import NotificationsRoutes from "Pages/Notifications/NotificationsRoutes";
import SupplierApplicationRoutes from "Pages/Admin/SupplierApplications/SupplierApplicationRoutes";
import DocumentsRoutes from "./Pages/Documents/DocumentRoutes";
import DocumentsRouteAdmin from "./Pages/Admin/Documents/DocumentsRouteAdmin";
import AdminCalenderRoute from "Pages/Admin/Calender/AdminCalenderRoute";
import ErrorBoundary from "ErrorBoundary";
import VismaConnectConnectionLost from "Components/VismaConnectConnectionLost";
import QaSchemeRouteAdmin from 'Pages/Admin/QaSchemes/DocumentsRouteAdmin';

function App() {

    const location = useLocation();
    const userProvider = useUserProvider();

    return (
        <ErrorBoundary>
            <Switch location={location}>
    
                <Route path={"/public"}>
                    <PublicPage />
                </Route>
    
                <RedirectIfLoggedInRoute path="/auth">
                    <AuthPage />
                </RedirectIfLoggedInRoute>
    
                <AuthenticatedRoute path={"/admin"}>
                    <AccessLevelRoute path="/admin" requiredAccessLevel={"Admin"}>
                        <DefaultLayout menu={<AdminMenu />}>
                            <Switch>
                                <Route path={"/admin"} exact>
                                    <Redirect to={"/admin/news"} />
                                </Route>
                                <Route path={"/admin/news"}>
                                    <NewsAdminRoutes />
                                </Route>
                                <Route path={"/admin/organizations"}>
                                    <OrganizationsPage />
                                </Route>
                                <Route path={"/admin/users"}>
                                    <UsersPage />
                                </Route>
                                <Route path={"/admin/payouts"}>
                                    <PayoutsPage />
                                </Route>
                                <Route path={"/admin/approval"}>
                                    <ApprovalPage />
                                </Route>
                                <Route path={"/admin/supplierApplications"}>
                                    <SupplierApplicationRoutes />
                                </Route>
                                <Route path={"/admin/documents"}>
                                    <DocumentsRouteAdmin/>
                                </Route>
                                <Route path={"/admin/qaSchemes"}>
                                    <QaSchemeRouteAdmin/>
                                </Route>
                                <Route path={"/admin/calender"}>
                                    <AdminCalenderRoute/>
                                </Route>
                            </Switch>
                        </DefaultLayout>
                    </AccessLevelRoute>
                </AuthenticatedRoute>
    
                <AuthenticatedRoute>
                    {userProvider.userContext?.lastOrganizationId && (
                        <VismaConnectConnectionLost>
                            <DefaultLayout menu={<CompanyMenu />}>
                                <Switch>
                                    <Route path={"/"} exact>
                                        <Redirect to={"/calendar"} />
                                    </Route>
                                    <Route path={"/notifications"}>
                                        <NotificationsRoutes />
                                    </Route>
                                    <Route path={"/calendar"}>
                                        <CalendarRoutes />
                                    </Route>
                                    <Route path={"/economy"}>
                                        <EconomyRoutes />
                                    </Route>
                                    <Route path={"/projects"}>
                                        <ProjectRoutes />
                                    </Route>
                                    <Route path={"/colleges"}>
                                        <ColleaguesPage />
                                    </Route>
                                    <Route path={"/news"}>
                                        <NewsRoutes />
                                    </Route>
                                    <Route path={"/purchases"}>
                                        <PurchaseRoutes />
                                    </Route>
                                    <Route path={"/settings"}>
                                        <SettingsRoutes />
                                    </Route>
                                    <Route path={'/404'}>
                                        <NotFound />
                                    </Route>
                                    <Route path={"/documents"}>
                                        <DocumentsRoutes/>
                                    </Route>
                                </Switch>
                            </DefaultLayout>
                        </VismaConnectConnectionLost>
                    )}
                    {!userProvider.userContext?.lastOrganizationId && (
                        <Route path={"/"}>
                            <DefaultLayout menu={<></>}>
                                <FlexRow className={"h-100"} justify={"center"}>Du har ikke valgt et firma</FlexRow>
                            </DefaultLayout>
                        </Route>
                    )}
                </AuthenticatedRoute>
    
                <Route path={'/404'}>
                    <NotFound />
                </Route>
            </Switch>
        </ErrorBoundary>
    );
}

export default App;
