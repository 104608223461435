import React from "react";
import MenuLink from "Pages/Layouts/MenuLink";
import Graph from "Assets/Icons/Graph";
import User from "Assets/Icons/User";
import ShoppingList from "Assets/Icons/ShoppingList";
import SubMenuLink from "Pages/Layouts/SubMenuLink";
import Menu from "Assets/Icons/Menu";
import Purchase from "Assets/Icons/Purchase";
import Calendar from "Assets/Icons/Calendar";
import Bullhorn from "Assets/Icons/Bullhorn";
import {useNotifications} from "Apis/NotificationsApi";
import Badge from "Components/Badge";
import Bell from "Assets/Icons/Bell";
import Report from "Assets/Icons/Report";
import {useUserProvider} from "../../Providers/UserProvider";


const CompanyMenu = () => {

    const { data: notifications } = useNotifications();

    const { userContext } = useUserProvider();

    const getEconomyLinks = () => {
        const links: any[] = []

        if (userContext?.isOwner || userContext?.accessLevel === 'Admin') {
            links.push(<SubMenuLink key={"overview"} to={'/economy'} exact>Overblik</SubMenuLink>)
        }

        links.push(<SubMenuLink key={"invoices"} to={'/economy/invoices'}>Faktura & Kreditnota</SubMenuLink>)
        links.push(<SubMenuLink key={"invoices"} to={'/economy/timeEntries'}>Timer</SubMenuLink>)

        return links;
    }

    return (
        <React.Fragment>
            <Badge count={notifications?.results.filter(x => x.seenAt == null).length}>
                <MenuLink icon={<Bell />} to={"/notifications"}>Notifikationer</MenuLink>
            </Badge>
            <MenuLink icon={<Calendar />} to={"/calendar"}>Kalender</MenuLink>
            <MenuLink icon={<Graph />} to={"/economy"} subLinks={getEconomyLinks()}>Økonomi</MenuLink>
            <MenuLink icon={<ShoppingList />} to={"/projects"} subLinks={[
                <SubMenuLink key={"projects"} to={'/projects/offers'}>Tilbud & Overslag</SubMenuLink>,
                <SubMenuLink key={"orders"} to={'/projects/orders'}>Ordre</SubMenuLink>,
                <SubMenuLink key={"customers"} to={'/projects/customers'}>Kunder</SubMenuLink>,
            ]}>
                Opgaver
            </MenuLink>
            <MenuLink icon={<Purchase />} to={"/purchases"} subLinks={[
                <SubMenuLink key={"supplierInvoices"} to={"/purchases/supplierInvoices"}>Indkøbsfaktura</SubMenuLink>,
                <SubMenuLink key={"klarpris"} to={"/purchases/klarpris"}>Varebestilling</SubMenuLink>,
            ]}>Indkøb</MenuLink>
            <MenuLink icon={<User />} to={"/colleges"}>Find en kollega</MenuLink>
            <MenuLink icon={<Bullhorn />} to={"/news"}>Nyheder</MenuLink>
            <MenuLink to={"/Documents"} icon={<Report/>}>Dokumenter</MenuLink>
            <MenuLink icon={<Menu />} to={"/settings"} subLinks={[
                <SubMenuLink key={"employees"} to={"/settings/employees"}>Medarbejdere</SubMenuLink>,
                <SubMenuLink key={"products"} to={"/settings/products"}>Produkter</SubMenuLink>,
                <SubMenuLink key={"vendors"} to={"/settings/klarpris"}>Leverandører</SubMenuLink>,
                <SubMenuLink key={"budget"} to={"/settings/budget"}>Budget</SubMenuLink>,
            ]}>Indstillinger</MenuLink>
        </React.Fragment>
    )
}


export default CompanyMenu;
