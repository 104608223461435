import {queryCache, useMutation, useQuery} from "react-query";
import {request} from "Apis/BaseApi";
import {PaginatedResultModel} from "Apis/Models/PaginatedResultModel";
import {NotificationModel} from "Apis/Models/NotificationModel";

export const NOTIFICATIONS_CACHE_KEY = 'NOTIFICATIONS';
export const useNotifications = (page: number = 0) => {
    return useQuery<PaginatedResultModel<NotificationModel>>([NOTIFICATIONS_CACHE_KEY, page], async () => {
        const query = new URLSearchParams();

        query.set('page', page.toString())

        return await request<PaginatedResultModel<NotificationModel>>({
            url: `/notifications?${query.toString()}`,
            method: 'GET'
        });
    })
}

export const useSetNotificationSeen = () => {
    return useMutation((id: number) => {
        return request({
            url: `/notifications/${id}/seen`,
            method: 'PUT'
        })
    }, {
        onSuccess: () => {
            queryCache.invalidateQueries(NOTIFICATIONS_CACHE_KEY)
        }
    })
}
