import {Redirect, Route, Switch, useRouteMatch} from "react-router-dom";
import React from "react";
import {SupplierApplicationsOverviewPage} from "Pages/Admin/SupplierApplications/SupplierApplicationsOverviewPage";
import EditSupplierApplicationPage from "Pages/Admin/SupplierApplications/EditSupplierApplicationPage";

const SupplierApplicationRoutes = () => {

    let {path} = useRouteMatch();

    return (
        <Switch>
            <Route path={`${path}/`} exact>
                <SupplierApplicationsOverviewPage />
            </Route>

            <Route path={`${path}/:id`}>
                <EditSupplierApplicationPage />
            </Route>

            <Redirect to={`${path}/`}/>
        </Switch>
    )
}

export default SupplierApplicationRoutes
