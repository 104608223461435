import {queryCache, useMutation, useQuery} from "react-query";
import {
    OrderDocumentsModel,
    OrderEconomyModel,
    OrderInvoicingItemsModel,
    OrderModel
} from "Apis/Models/OrderModel";
import {request} from "Apis/BaseApi";

export const ordersCacheKey = 'Orders';
export const useOrders = (queryFilter?: string, includeFinished: string = "false", userId?: string) => {
    const params = new URLSearchParams();

    if (queryFilter) {
        params.set('queryFilter', queryFilter);
    }

    if (includeFinished) {
        params.set('includeFinished', includeFinished);
    }

    if (userId) {
        params.set('responsibleId', userId);
    }

    return useQuery<OrderModel[]>([ordersCacheKey, queryFilter, includeFinished, userId], async () => {
        return await request<OrderModel[]>({
            url: `/orders?${params.toString()}`,
            method: 'GET'
        })
    })
}

export const orderCacheKey = 'order';
export const useOrder = (id: number | undefined, enabled: boolean = true) => {
    return useQuery<OrderModel>([ordersCacheKey, id], async () => {
        return await request<OrderModel>({
            url: `/orders/${id}`,
            method: 'GET'
        })
    }, {
        enabled
    })
}

export const orderEconomyCacheKey = 'orderEconomy';
export const useOrderEconomy = (id: number) => {
    return useQuery<OrderEconomyModel>([orderEconomyCacheKey, id], async () => {
        return await request<OrderEconomyModel>({
            url: `/orders/${id}/economy`,
            method: 'GET'
        })
    })
}

export const orderInvoicingItems = 'orderInvoicingItems';
export const useOrderInvoicingItems = (id: number) => {
    return useQuery<OrderInvoicingItemsModel>([orderInvoicingItems, id], async () => {
        return await request<OrderInvoicingItemsModel>({
            url: `/orders/${id}/invoicingItems`,
            method: 'GET'
        })
    })
}

export const orderDocumentsCacheKey = 'orderDocuments';
export const useOrderDocuments = (id: number) => {
    return useQuery<OrderDocumentsModel>([orderDocumentsCacheKey, id], async () => {
        return await request<OrderDocumentsModel>({
            url: `/orders/${id}/documents`,
            method: 'GET'
        })
    })
}

export const useSaveOrder = () => {
    return useMutation(async (order: OrderModel) => {
        if (order.id) {
            return await request<OrderModel>({
                url: `/orders/${order.id}`,
                method: 'PUT',
                data: order
            })
        } else {
            return await request<OrderModel>({
                url: `/orders`,
                method: 'POST',
                data: order
            })
        }
    }, {
        onSuccess: (order: OrderModel) => {
            queryCache.invalidateQueries(ordersCacheKey)
            queryCache.invalidateQueries([orderCacheKey, order.id])
        },
    })
}

export const useFinishOrder = () => {
    return useMutation((orderId: number) => {
        return request<OrderModel>({
            url: `/orders/${orderId}/finish`,
            method: 'PUT'
        })
    }, {
        onSuccess: (order: OrderModel) => {
            queryCache.invalidateQueries(ordersCacheKey)
            queryCache.invalidateQueries([orderCacheKey, order.id])
        }
    })
}
