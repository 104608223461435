import { QASchemeModel } from "Apis/Models/QASchemeModel";
import { QaSchemeDefinitionModel, QaSchemeFieldModel, QaSchemeQuestionDefinitionModel } from "Apis/Models/QaSchemeDefinitionModel";
import DocumentHeader from "Components/Document/DocumentHeader";
import React, { useEffect, useState } from "react";
import { OrderModel } from "Apis/Models/OrderModel";
import DocumentLayout from "Components/Document/DocumentLayout";
import QaQuestion from "Components/Document/QaTemplates/QaQuestion";
import QaHeadlineTemplate from "Components/Document/QaTemplates/QaHeadlineTemplate";
import QaFieldTextTemplate from "Components/Document/QaTemplates/QaFieldTextTemplate";
import QaFieldTextAreaTemplate from "Components/Document/QaTemplates/QaFieldTextAreaTemplate";
import QaFieldRadioTemplate from "Components/Document/QaTemplates/QaFieldRadioTemplate";
import QaFieldCameraTemplate from "Components/Document/QaTemplates/QaFieldCameraTemplate";

interface Props {
    order?: OrderModel;
    qaSchema?: QASchemeModel;
    qaSchemaDefinition?: QaSchemeDefinitionModel;
}
const QADocument = ({order, qaSchema, qaSchemaDefinition}: Props) => {
    
    const [content, setContent] = useState<any>();
    
    const GetFieldText = (question: QaSchemeQuestionDefinitionModel, field: QaSchemeFieldModel, valueIndex: number) =>{
        let text = field.text;
        
        if (question.isExpandable === true && field.order === 1)
        {
            text = `${text} ${valueIndex + 1}`;
        }
        
        return text;
    }
    
    useEffect(() => {
        const appendixList: string[] = [];
        
        let content = qaSchemaDefinition?.schemeQuestions.map(question => {
            const answer = qaSchema!.answers.find(answer => answer.order === question.order)!;
    
            let children: any[] = [];
            
            let answerValues: string[][]
            
            if (!answer.value) {
                answerValues = question.fields.map(x => [""]);
            } else {
                answerValues = JSON.parse(answer.value);
            }
            
    
            for (let i = 0; i < answerValues.length; i++) {
                for (let j = 0; j < question.fields.length; j++) {
                    
                    let child;
                    
                    let qaSchemeDefinitionField = question.fields[j];
                    let fieldValue = answerValues[i][j];
    
                    if (qaSchemeDefinitionField.type === 'Headline') {
                        child = <QaHeadlineTemplate text={GetFieldText(question, qaSchemeDefinitionField, i)} />
                    }
                    if (qaSchemeDefinitionField.type === 'Text') {
                        child = <QaFieldTextTemplate text={GetFieldText(question, qaSchemeDefinitionField, i)} value={fieldValue} unit={qaSchemeDefinitionField.unit} />
                    }
                    if (qaSchemeDefinitionField.type === 'TextArea') {
                        child = <QaFieldTextAreaTemplate text={GetFieldText(question, qaSchemeDefinitionField, i)} value={fieldValue} />
                    }
                    if (qaSchemeDefinitionField.type === 'RadioButtons') {
                        child = <QaFieldRadioTemplate text={GetFieldText(question, qaSchemeDefinitionField, i)} value={fieldValue} possibleValues={qaSchemeDefinitionField.possibleValues} />
                    }
                    if (qaSchemeDefinitionField.type === 'Camera') {
                        if (fieldValue) {
                            appendixList.push(fieldValue);
                        }
                        const number = appendixList.length;
                        child = <QaFieldCameraTemplate appendixNumber={number} text={GetFieldText(question, qaSchemeDefinitionField, i)} value={fieldValue} />
                    }
                    
                    children.push(child);
                }
            }
    
            return (
                <QaQuestion title={question.title ?? ''}>
                    {children}
                </QaQuestion>
            )
        })
        
        setContent(content);
    }, [qaSchemaDefinition, qaSchema]);
    
    if (!order || !qaSchema || !qaSchemaDefinition) {
        return null;
    }
    
    return (
        <DocumentLayout>
            <DocumentHeader invoicingAddress={order.invoicingAddress} deliveryAddress={order.deliveryAddress} />
    
            {content}
        </DocumentLayout>
    )
}

export default QADocument
