import React, {useEffect} from "react";
import DefaultPageLayout from "Pages/Layouts/DefaultPageLayout";
import {Col, Row} from "antd";
import Label from "Components/Label";
import {getInputClasses} from "Utils/CssUtils";
import {useForm} from "react-hook-form";
import GroupLabel from "Components/GroupLabel";
import {BudgetModel} from "Apis/Organization/Models/BudgetModel";
import FlexRow from "Components/FlexRow";
import StyledButton from "Components/StyledButton";
import {useBudget, useSaveBudget} from "Apis/Organization/BudgetApi";
import {openSuccessNotification} from "Utils/NotificationUtils";
import {getNumberAsCurrency} from "Utils/NumberUtils";

const BudgetPage = () => {

    const { data: budget, isLoading: isLoadingBudget } = useBudget();
    const [ saveBudgetMutation, {isLoading: isSavingBudget}] = useSaveBudget();

    const {register, handleSubmit, reset, errors} = useForm<BudgetModel>();

    useEffect(() => {
        if (budget) {
            reset(budget)
        }
    }, [reset, budget])

    const saveBudget = async (data) => {
        await saveBudgetMutation(data);

        openSuccessNotification('Success', 'Dit budget blev gemt');
    }

    if (isLoadingBudget) {
        return <></>
    }


    return (
        <DefaultPageLayout title={"Budget"}>
            <form onSubmit={handleSubmit(saveBudget)}>
                <Row gutter={[20, 20]}>
                    <Col span={24}>
                        <GroupLabel>
                            Planlæg din ferie
                        </GroupLabel>
                    </Col>
                    <Col span={24}>
                        <div>
                            <Label>
                                Hvor mange ugers ferie vil du have om året?
                            </Label>
                            <input placeholder={"8"} className={getInputClasses(errors.vacationWeeks)} type="number"
                                   name={'vacationWeeks'} ref={register({required: true})}/>
                        </div>
                    </Col>
                    <Col span={4}>
                        <div>
                            <Label>
                                Januar
                            </Label>
                            <input placeholder={"0"} className={getInputClasses(errors.vacationMonths?.january)}
                                   type="number" name={'vacationMonths.january'} ref={register({required: true})}/>
                        </div>
                    </Col>
                    <Col span={4}>
                        <div>
                            <Label>
                                Februar
                            </Label>
                            <input placeholder={"1"} className={getInputClasses(errors.vacationMonths?.february)}
                                   type="number" name={'vacationMonths.february'} ref={register({required: true})}/>
                        </div>
                    </Col>
                    <Col span={4}>
                        <div>
                            <Label>
                                Marts
                            </Label>
                            <input placeholder={"0"} className={getInputClasses(errors.vacationMonths?.march)}
                                   type="number"
                                   name={'vacationMonths.march'} ref={register({required: true})}/>
                        </div>
                    </Col>
                    <Col span={4}>
                        <div>
                            <Label>
                                April
                            </Label>
                            <input placeholder={"1"} className={getInputClasses(errors.vacationMonths?.april)}
                                   type="number"
                                   name={'vacationMonths.april'} ref={register({required: true})}/>
                        </div>
                    </Col>
                    <Col span={4}>
                        <div>
                            <Label>
                                Maj
                            </Label>
                            <input placeholder={"0"} className={getInputClasses(errors.vacationMonths?.may)}
                                   type="number"
                                   name={'vacationMonths.may'} ref={register({required: true})}/>
                        </div>
                    </Col>
                    <Col span={4}>
                        <div>
                            <Label>
                                Juni
                            </Label>
                            <input placeholder={"1"} className={getInputClasses(errors.vacationMonths?.june)}
                                   type="number"
                                   name={'vacationMonths.june'} ref={register({required: true})}/>
                        </div>
                    </Col>
                    <Col span={4}>
                        <div>
                            <Label>
                                Juli
                            </Label>
                            <input placeholder={"2"} className={getInputClasses(errors.vacationMonths?.july)}
                                   type="number"
                                   name={'vacationMonths.july'} ref={register({required: true})}/>
                        </div>
                    </Col>

                    <Col span={4}>
                        <div>
                            <Label>
                                August
                            </Label>
                            <input placeholder={"1"} className={getInputClasses(errors.vacationMonths?.august)}
                                   type="number" name={'vacationMonths.august'} ref={register({required: true})}/>
                        </div>
                    </Col>

                    <Col span={4}>
                        <div>
                            <Label>
                                September
                            </Label>
                            <input placeholder={"0"} className={getInputClasses(errors.vacationMonths?.september)}
                                   type="number" name={'vacationMonths.september'} ref={register({required: true})}/>
                        </div>
                    </Col>
                    <Col span={4}>
                        <div>
                            <Label>
                                Oktober
                            </Label>
                            <input placeholder={"0"} className={getInputClasses(errors.vacationMonths?.october)}
                                   type="number" name={'vacationMonths.october'} ref={register({required: true})}/>
                        </div>
                    </Col>
                    <Col span={4}>
                        <div>
                            <Label>
                                November
                            </Label>
                            <input placeholder={"0"} className={getInputClasses(errors.vacationMonths?.november)}
                                   type="number" name={'vacationMonths.november'} ref={register({required: true})}/>
                        </div>
                    </Col>
                    <Col span={4}>
                        <div>
                            <Label>
                                December
                            </Label>
                            <input placeholder={"2"} className={getInputClasses(errors.vacationMonths?.december)}
                                   type="number" name={'vacationMonths.december'} ref={register({required: true})}/>
                        </div>
                    </Col>

                    <Col span={24}>
                        <hr/>
                    </Col>

                    <Col span={24}>
                        <GroupLabel>
                            Planlæg din arbejdsuge
                        </GroupLabel>
                    </Col>

                    <Col span={24}>
                        <div>
                            <Label>
                                Hvor mange timer vil du arbejde om ugen?
                            </Label>
                            <input placeholder={"37,5"} step={.1} className={getInputClasses(errors.workHours)} type="number"
                                   name={'workHours'} ref={register({required: true})}/>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div>
                            <Label>
                                Hvad skal din timepris være?
                            </Label>
                            <input placeholder={"500"} className={getInputClasses(errors.hourlyRate)} type="number"
                                   name={'hourlyRate'} ref={register({required: true})}/>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div>
                            <Label>
                                Tillæg for bil pr time?
                            </Label>
                            <input placeholder={"59"} className={getInputClasses(errors.hourlyCarRate)} type="number"
                                   name={'hourlyCarRate'} ref={register({required: true})}/>
                        </div>
                    </Col>

                    <Col span={24}>
                        <hr/>
                    </Col>

                    <Col span={24}>
                        <GroupLabel>
                            Andre faste omkostninger
                        </GroupLabel>
                    </Col>

                    <Col span={8}>
                        <div>
                            <Label>
                                Mobiltelefon og internet
                            </Label>
                            <input placeholder={"450"} className={getInputClasses(errors.phoneCost)} type="number"
                                   name={'phoneCost'} ref={register({required: true})}/>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div>
                            <Label>
                                Bil inkl. forsikring og afgiter
                            </Label>
                            <input placeholder={"5800"} className={getInputClasses(errors.carCosts)} type="number"
                                   name={'carCosts'} ref={register({required: true})}/>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div>
                            <Label>
                                Værktøj
                            </Label>
                            <input placeholder={"675"} className={getInputClasses(errors.toolsCost)} type="number"
                                   name={'toolsCost'} ref={register({required: true})}/>
                        </div>
                    </Col>

                    <Col span={24}>
                        <hr/>
                    </Col>

                    <Col span={24}>
                        <GroupLabel>
                            Dine mål
                        </GroupLabel>
                    </Col>

                    <Col span={24}>
                        <div>
                            <Label>
                                Omsætning pr år: <b>{getNumberAsCurrency(budget?.revenue)}</b>
                            </Label>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div>
                            <Label>
                                Overskud før skat pr år: <b>{getNumberAsCurrency(budget?.profit)}</b>
                            </Label>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div>
                            <Label>
                                Overskud før skat pr måned: <b>{getNumberAsCurrency(budget?.profitPrMonth)}</b>
                            </Label>
                        </div>
                    </Col>

                    <Col span={24}>
                        <FlexRow justify={"end"}>
                            <StyledButton disabled={isSavingBudget} loading={isSavingBudget} type={"submit"}>Gem</StyledButton>
                        </FlexRow>
                    </Col>
                </Row>
            </form>
        </DefaultPageLayout>
    )
}

export default BudgetPage
