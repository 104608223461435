import * as React from "react"

function Folder(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512.001 512.001"
            {...props}
        >
            <path   d="M448.916 118.259h-162.05c-6.578 0-13.003-2.701-17.44-7.292l-50.563-53.264c-12.154-12.115-28.783-18.443-45.625-18.346H63.084C28.301 39.356 0 67.657 0 102.439v307.123c0 34.783 28.301 63.084 63.084 63.084h386.122c34.764-.154 62.949-28.59 62.794-63.277V181.342c0-34.783-28.301-63.083-63.084-63.083zm24.501 291.188c.058 13.504-10.88 24.558-24.307 24.616H63.084c-13.504 0-24.5-10.996-24.5-24.5V102.439c0-13.504 10.996-24.5 24.5-24.52h111.293c6.443 0 12.694 2.566 16.899 6.733l50.293 53.013c11.806 12.192 28.32 19.176 45.297 19.176h162.05c13.504 0 24.5 10.996 24.5 24.5v228.106z" />
        </svg>
    )
}

export default Folder