import {AgreementModel} from "Apis/Models/AgreementModel";

export const getAgreementStatus = (agreement: AgreementModel): string => {
    let status = '';

    if (!agreement.sentAt) {
        status = 'Ikke sendt';
    } else if (!agreement.customerApproval?.approvedAt) {
        status = 'Afventer kunde'
    } else {
        status = agreement.customerApproval.isApproved ? 'Godkendt' : 'Afvist';
    }

    return status;
}
