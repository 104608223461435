import {Redirect, Route, Switch, useRouteMatch} from "react-router-dom";
import React from "react";
import CustomersPage from "Pages/Projects/Customers/CustomersPage";
import OffersOverviewPage from "Pages/Projects/Offers/OffersOverviewPage";
import EditOrderPage from "Pages/Projects/Orders/EditOrderPage";
import CreateOrderPage from "Pages/Projects/Orders/CreateOrderPage";
import ShowOrderPage from "Pages/Projects/Orders/ShowOrderPage";
import CreateOfferPage from "Pages/Projects/Offers/CreateOfferPage";
import EditOfferPage from "Pages/Projects/Offers/EditOfferPage";
import OrdersOverviewPage from "Pages/Projects/Orders/OrdersOverviewPage";

const ProjectRoutes = () => {

    let {path} = useRouteMatch();

    return (
        <Switch>
            <Route path={`${path}`} exact>
                <Redirect to={`/projects/offers`} />
            </Route>

            <Route path={`${path}/offers`} exact>
                <OffersOverviewPage />
            </Route>

            <Route path={`${path}/orders`} exact>
                <OrdersOverviewPage />
            </Route>
            <Route path={`${path}/orders/create`}>
                <CreateOrderPage />
            </Route>
            <Route path={`${path}/orders/show/:id`}>
                <ShowOrderPage />
            </Route>
            <Route path={`${path}/orders/edit/:id`}>
                <EditOrderPage />
            </Route>


            <Route path={`${path}/offers/create/:type`}>
                <CreateOfferPage />
            </Route>
            <Route path={`${path}/offers/:id`}>
                <EditOfferPage />
            </Route>

            <Route path={`${path}/customers`}>
                <CustomersPage />
            </Route>
        </Switch>
    )

}

export default ProjectRoutes;
