import React, { useState } from "react";
import styles from "./DocumentFolderAdmin.module.scss";
import { DocumentReadModel } from "Apis/Documents/Models/DocumentReadModel";
import { Dropdown, Menu } from "antd";
import DocumentCrudModal from "Pages/Admin/Documents/Components/DocumentCrudModal";
import { useGetDocumentFileUrl } from "Apis/Documents/DocumentsApi";
import { downloadFile } from "Apis/FileApi";
import { saveAs } from 'file-saver';
import FlexRow from "Components/FlexRow";
import Report from "Assets/Icons/Report";

interface Props {
    file: DocumentReadModel,
}

const DocumentFile = (props: Props) => {

    const [showModal, setShowModal] = useState<boolean>(false);

    const [useGetDocumentFileUrlMutation] = useGetDocumentFileUrl();

    const menu = (
        <Menu>
            <Menu.Item key={"1"} onClick={() => {
                setShowModal(true)
            }}>Opdater</Menu.Item>
        </Menu>
    )

    const DownloadFile = async () => {
        const file = await useGetDocumentFileUrlMutation(props.file.id);

        const blob = await downloadFile(file.link);

        saveAs(blob, file.fileName)
    }

    return (
        <>
            <Dropdown overlay={menu} trigger={['contextMenu']}>
                <FlexRow justify={"space-between"} align={"center"} direction={"column"} className={styles.container} onClick={DownloadFile}>
                    <FlexRow justify={"center"} align={"center"} className={"h-100"}>
                        <Report width={'20%'} fill="rgba(147, 147, 147, 0.5)"/>
                    </FlexRow>
                    <FlexRow align={"center"} justify={"center"}>
                        <div className={"w-100 text-ellipsis"}>
                            {props.file.name}
                        </div>
                    </FlexRow>
                </FlexRow>
            </Dropdown>

            <DocumentCrudModal parentId={props.file.parentId} document={props.file} visible={showModal} onCancel={() => {
                setShowModal(false)
            }}/>
        </>
    )
}

export default DocumentFile;
