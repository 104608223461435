import React, {useCallback, useState} from "react";
import {Col, Menu, Row} from "antd";
import _ from "lodash";
import {searchForKlarPrisProducts, useKlarPrisWholeSalers} from "Apis/klarprisApi";
import {KlarPrisProduct} from "Apis/Models/KlarPrisIntegrationModel";
import FlexRow from "Components/FlexRow";
import Spinner from "Components/Spinner";
import {getNumberAsCurrency} from "Utils/NumberUtils";
import {ProductModel} from "Apis/Models/ProductModel";
import {searchForProducts} from "Apis/ProductApi";

export const useProductSearch = () => {

    const [klarPrisProducts, setKlarprisProducts] = useState<KlarPrisProduct[]>([]);
    const [products, setProducts] = useState<ProductModel[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [searching, setSearching] = useState<boolean>(false);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const queryFn = useCallback(_.debounce(async (query: string) => {
        setSearchTerm(query)

        if (query !== '') {

            setSearching(true);

            searchForProducts(query).then((response) => {
                setProducts(response);
                setSearching(false);
            })
            searchForKlarPrisProducts(query).then((response) => {
                setKlarprisProducts(response);
                setSearching(false);
            })

        } else {
            setKlarprisProducts([])
            setProducts([])
        }
    }, 300), [])

    const clearSuggestions = () => {
        setKlarprisProducts([])
        setProducts([])
    }

    return [klarPrisProducts, products, queryFn, searchTerm, searching, clearSuggestions]
}

export const useProductsSearchMenu = (klarPrisProductSelected: Function, productSelected: Function) => {

    const [klarPrisProducts, products, queryFn, searchTerm, searching, clearSuggestions] = useProductSearch();
    const {data: wholesalers, isLoading: isLoadingWholeSalers} = useKlarPrisWholeSalers(searchTerm);

    const onKlarPrisProductClick = async (product: KlarPrisProduct) => {
        await klarPrisProductSelected(product);

        clearSuggestions();
    }

    const onProductClick = async (product: ProductModel) => {
        await productSelected(product);

        clearSuggestions();
    }

    let menu = <></>;

    if (searchTerm) {
         menu = (
            <Menu style={{overflowY: 'scroll', maxHeight: '40vh', overflowX: 'hidden'}}>

                <Row justify={"end"} style={{borderBottom: '1px solid #eee', padding: '10px 0'}}>
                    <Col flex={'auto'} />
                    <Col span={2} style={styles.vendorImageBox}>
                        <img height={20} width={'auto'} src={"/images/Logo.png"} alt={""} />
                    </Col>
                    {wholesalers?.filter(x => x.accountNumber !== null).sort((a, b) => a.id - b.id).map(x => (
                        <Col span={2} style={styles.vendorImageBox}>
                            {x.imageUrl && (
                                <img height={'auto'} style={styles.vendorImage} width={'auto'} src={x.imageUrl} alt={""} />
                            )}
                        </Col>
                    ))}
                </Row>

                {(searching || isLoadingWholeSalers) && (
                    <FlexRow justify={"center"} style={{padding: '10px 0'}}>
                        <Spinner size={"large"} />
                    </FlexRow>
                )}

                {products.map((product) => (
                    <Row style={{borderBottom: '1px solid #eee', padding: '10px 0'}} onClick={() => onProductClick(product)}>
                        <Col span={2} style={styles.vendorImageBox}>
                            <img height={50} width={'auto'} src={"/images/Logo.png"} alt={""} />
                        </Col>
                        <Col span={10}>
                            <FlexRow style={{marginLeft: 10}} direction={"column"} align={"start"}>
                                <div>
                                    <span>{product.description}</span>
                                    <span style={{marginLeft: 10, fontSize: 14, color: '#9F9F9F'}}>{product.productNumber}</span>
                                </div>
                            </FlexRow>
                        </Col>
                        <Col flex={'auto'} />
                        <Col span={2} style={styles.priceBox}>
                            <FlexRow style={{height: '100%'}} justify={"center"} align={"center"}>
                                <span style={{fontWeight: 'bold'}}>
                                    {getNumberAsCurrency(product.unitPriceExVat)}
                                </span>
                            </FlexRow>
                        </Col>
                        {wholesalers?.filter(x => x.accountNumber !== null).sort((a, b) => a.id - b.id).map(x => (
                            <Col span={2} style={styles.priceBox}>

                            </Col>
                        ))}
                    </Row>
                ))}

                {klarPrisProducts.map((product) => {
                    const bestPrice = product.prices.slice().sort((a,b) => a.price - b.price)[0];

                    return (
                        <Row style={{borderBottom: '1px solid #eee', padding: '10px 0'}} onClick={() => onKlarPrisProductClick(product)}>
                            <Col span={2}>
                                <FlexRow justify={"center"}>
                                    <img height={50} width={'auto'} src={product.imageUrl} alt={""} />
                                </FlexRow>
                            </Col>
                            <Col span={10}>
                                <FlexRow style={{marginLeft: 10}} direction={"column"} align={"start"}>
                                    <div>
                                        <span>{product.description}</span>
                                        <span style={{marginLeft: 10, fontSize: 14, color: '#9F9F9F'}}>{product.number}</span>
                                    </div>
                                    <div>
                                        <img height={20} width={'auto'} src={product.manufacturerLogo} alt={product.manufacturerName} />
                                        <span style={{marginLeft: 10, fontSize: 14, color: '#9F9F9F'}}>{product.manufacturerName}</span>
                                    </div>
                                </FlexRow>
                            </Col>
                            <Col flex={'auto'} />
                            <Col span={2} style={styles.priceBox}>
                                {/* Placeholder for ASAP'EN product */}
                            </Col>
                            <Col />
                            {wholesalers?.filter(x => x.accountNumber !== null).sort((a, b) => a.id - b.id).map(x => (
                                <Col span={2} style={styles.priceBox}>
                                    <FlexRow style={{height: '100%'}} justify={"center"} align={"center"}>
                                        <span style={{fontWeight: bestPrice?.wholesalerId === product.prices.find(p => p.wholesalerId === x.id)?.wholesalerId ? 'bold': 'normal'}}>
                                            {product.prices.find(p => p.wholesalerId === x.id) ? getNumberAsCurrency(product.prices.find(p => p.wholesalerId === x.id)?.price) : ''}
                                        </span>
                                    </FlexRow>
                                </Col>
                            ))}

                        </Row>
                    )
                })}

                {wholesalers?.filter(x => x.accountNumber !== null).length! === 0 && (
                    <Row style={{padding: '20px 0'}}>
                        <Col span={24}>
                            <FlexRow justify={"center"}>
                                <span>Du skal oprette forbindelse til KlarPris under Indstillinger før at du kan søge efter produkter</span>
                            </FlexRow>
                        </Col>
                    </Row>
                )}

                {!searching && wholesalers?.filter(x => x.accountNumber !== null).length! > 0 && searchTerm && klarPrisProducts?.length === 0 && products?.length === 0 && (
                    <Row>
                        <Col span={24}>
                            <span>Søgningen efter <b>{searchTerm}</b> gav ingen resultater</span>
                        </Col>
                    </Row>
                )}
            </Menu>
        )
    }

    return [menu, queryFn, clearSuggestions]
}

const styles = {
    vendorImage: {
        maxHeight: '20px',
        maxWidth: '80%'
    },
    vendorImageBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    priceBox: {
        borderRight: '1px solid #ddd',
        borderLeft: '1px solid #ddd',
    }
}
