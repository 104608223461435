import React, { useEffect, useState } from 'react';
import {useWatch} from "react-hook-form";
import {getNumberAsCurrency, roundNumber} from "Utils/NumberUtils";

const FormLineTotal = ({control, index}) => {

    const [total, setTotal] = useState<number>(0);
    
    const values = useWatch<any>({
        control,
        name: `lines[${index}]`
    })
    
    useEffect(() => {
        setTotal(values ? values.amount * (values.unitSalesPrice * (1 - (values.discount / 100))) : 0);
    }, [values])
    
    return (
        <span>{getNumberAsCurrency(roundNumber(total))}</span>
    )
}

export default FormLineTotal;
