import Approve from "Assets/Icons/Approve";
import React from "react";
import { useDeprecateInvoice, useInvoice } from "Apis/InvoiceAdminApi";
import { useParams } from "react-router-dom";
import DefaultPageLayout from "Pages/Layouts/DefaultPageLayout";
import InvoiceDetails from "Pages/Economy/Components/InvoiceDetails";
import PaperLayout from "Components/PaperLayout";
import FlexRow from "Components/FlexRow";
import StyledButton from "Components/StyledButton";
import DocumentContainer from "Components/Document/DocumentContainer";

const DeprecationDetailsPage = () => {
    
    const { id } = useParams<{ id }>();
    
    const { data: invoice, isLoading } = useInvoice(id);
    
    const [deprecateInvoiceMutation, {isLoading: isDeprecatingInvoice }] = useDeprecateInvoice();
    
    const deprecateInvoice = async () => {
        await deprecateInvoiceMutation({ id });
    }
    
    
    return (
        <DefaultPageLayout title={"Afskriv faktura"} isLoading={isLoading} icon={<Approve />}>
          <DocumentContainer>
            <PaperLayout beforePaper={(
                <>
                    <FlexRow justify={"end"} align={"end"} direction={"column"}>
                        <StyledButton disabled={isDeprecatingInvoice} loading={isDeprecatingInvoice} onClick={deprecateInvoice} color={"primary"}>Afskriv faktura til tab</StyledButton>
                    </FlexRow>
                </>
            )}>
                {invoice && (
                    <InvoiceDetails invoice={invoice.invoice} />
                )}
            </PaperLayout>
          </DocumentContainer>
        </DefaultPageLayout>
    )
}

export default DeprecationDetailsPage
