import React, {useState} from "react";
import PaperLayout from "Components/PaperLayout";
import OfferDetails from "Pages/Projects/Offers/Components/OfferDetails";
import { useParams } from "react-router-dom";
import {useApproveOffer, useOffer, useRejectOffer} from "Apis/PublicOfferApi";
import Spinner from "Components/Spinner";
import {Space} from "antd";
import StyledButton from "Components/StyledButton";
import FlexRow from "Components/FlexRow";
import {getInputClasses} from "Utils/CssUtils";
import Modal from "antd/es/modal";
import {useForm} from "react-hook-form";
import {openSuccessNotification} from "Utils/NotificationUtils";
import {getOfferTypeAsString} from "Utils/OfferUtils";
import DocumentContainer from "../../Components/Document/DocumentContainer";

const PublicApproveOfferPage = () => {

    const { organizationId, offerId } = useParams<{ organizationId, offerId }>();

    const {data: publicOfferModel, isLoading: isLoadingOffer} = useOffer(organizationId, offerId);

    const [showRejectModal, setShowRejectModal] = useState<boolean>(false);

    const {register, errors, handleSubmit} = useForm<{reason}>();

    const [approveOfferMutation, {isLoading: isApprovingOffer}] = useApproveOffer();
    const [rejectOfferMutation, {isLoading: isRejectingOffer}] = useRejectOffer();

    const approveOffer = async () => {
        await approveOfferMutation({organizationId, offerId});

        openSuccessNotification('Success', `${getOfferTypeAsString(publicOfferModel!.offer?.offerType!)} blev godkendt`)
    }

    const rejectOffer = async (data: {reason}) => {
        await rejectOfferMutation({organizationId, offerId, reason: data.reason});

        openSuccessNotification('Success', `${getOfferTypeAsString(publicOfferModel!.offer?.offerType!)} blev afvist`)

        setShowRejectModal(false)
    }

    if (isLoadingOffer) {
        return (
            <div style={{width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Spinner size="large" />
            </div>
        )
    }

    return (
        <div style={{overflowY: 'scroll', height: '100%'}}>
            <DocumentContainer>
            <PaperLayout beforePaper={
                publicOfferModel?.offer?.state === 'Approved' && (
                    <FlexRow justify={"end"} align={"end"} direction={"column"}>
                        <Space>
                            <StyledButton disabled={isRejectingOffer || isApprovingOffer} loading={isRejectingOffer}  onClick={() => setShowRejectModal(true)} color={"danger"}>Afvis</StyledButton>
                            <StyledButton disabled={isRejectingOffer || isApprovingOffer} loading={isApprovingOffer}  onClick={approveOffer} color={"primary"}>Godkend</StyledButton>
                        </Space>
                    </FlexRow>
                )
            }>
                <OfferDetails offer={publicOfferModel?.offer!} defaultOfferText={publicOfferModel?.defaultText!}  />
            </PaperLayout>
            </DocumentContainer>
            <Modal visible={showRejectModal} title={"Afvis"}
                   onCancel={() => setShowRejectModal(false)}
                   footer={false}
            >
                <form onSubmit={handleSubmit(rejectOffer)}>
                    <textarea placeholder={"Indtast begundelse"} name="reason" rows={4} className={getInputClasses(errors.reason)} ref={register({required: true})} />
                    <FlexRow justify={"end"}>
                        <Space>
                            <StyledButton onClick={() => setShowRejectModal(false)}>Afbryd</StyledButton>
                            <StyledButton color={"danger"} type={"submit"}>Afvis</StyledButton>
                        </Space>
                    </FlexRow>
                </form>
            </Modal>
        </div>
    )

}

export default PublicApproveOfferPage;
