import React from "react";
import styles from './Ribbon.module.scss';
import {lowerCaseFirst} from "Utils/TextUtils";

interface Props {
    text: string;
    color?: 'Default' | 'Success' | 'Danger' | 'Warning'
}
const Ribbon = ({text, color}: Props) => {

    const colorClass = color ?? 'default'

    return (
        <div className={`${styles.ribbon} ${styles[lowerCaseFirst(colorClass)]}`}>
            <span>
                {text}
            </span>
        </div>
    )

}

export default Ribbon;
