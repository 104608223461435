import React from 'react';
import {Col, Empty, Row} from "antd";
import OrderImageDocumentation from "Pages/Projects/Orders/Components/OrderImageDocumentation";
import {useOrderImageDocumentations} from "Apis/OrderImageDocumentationApi";
import {OrderModel} from "Apis/Models/OrderModel";

interface Props {
    order: OrderModel
}
const OrderDocumentation = ({order}: Props) => {

    const {data: images} = useOrderImageDocumentations(order.id)

    return (
        <>
            <Row gutter={[10, 10]}>
                {images?.map((image) => (
                    <Col xs={24} md={12} lg={4}>
                        <OrderImageDocumentation key={image.id} link={image.link} />
                    </Col>
                ))}
            </Row>
            {images?.length === 0 && (
                <Empty description={"Du har ikke uploadet nogen billeder til ordren"} image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
        </>
    )
}

export default OrderDocumentation
