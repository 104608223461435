import FlexRow from "Components/FlexRow";
import StyledButton from "Components/StyledButton";
import {Space} from "antd";
import React, {useState} from "react";
import {useForm} from "react-hook-form";
import { signIn } from "FirebaseAuth";
import {getInputClasses} from "Utils/CssUtils";
import {Link} from "react-router-dom";

const LoginForm = () => {

    const [error, setError] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const {register, handleSubmit, errors} = useForm<{email, password}>();

    const onSubmitHandler = async (data) => {
        setLoading(true);
        signIn(data.email, data.password).catch(e => {
            if (e.code === 'auth/user-not-found' || e.code === 'auth/wrong-password') {
                setError("Brugernavnet eller kodeordet er forkert");
            }
            if (e.code === 'auth/invalid-email') {
                setError("Indtast en gyldigt email");
            }
            if (e.code === 'auth/network-request-failed') {
                setError("Der skete en uventet fejl, prøv igen");
            }
        }).finally(() => setLoading(false));

    }

    return (
        <form onSubmit={handleSubmit(onSubmitHandler)}>
            <Space direction={"vertical"} size={"middle"} className={"w-100"}>

                {error && (<FlexRow justify={'center'}>
                    <div className="error-message">{error}</div>
                </FlexRow>)}

                <div>
                    <label>
                        E-Mail
                    </label>
                    <input className={getInputClasses(errors.email)} type="text" name={"email"} ref={register({required: true})} />
                </div>

                <div>
                    <label>
                        Kodeord
                    </label>
                    <input className={getInputClasses(errors.password)} type="password" name={"password"} ref={register({required: true, minLength: 6})} />
                </div>

                <FlexRow justify={'space-between'}>
                    <Link to={'/auth/resetpassword'}>Glemt dit kodeord?</Link>
                    <StyledButton type={"submit"} loading={loading} color={"primary"}>Log ind</StyledButton>
                </FlexRow>

            </Space>
        </form>
    )
}

export default LoginForm
