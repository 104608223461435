import React, { useCallback, useState } from "react";
import DefaultPageLayout from "../Layouts/DefaultPageLayout";
import { useDocumentRead } from "Apis/Documents/DocumentsApi";
import DocumentFolder from "./Components/DocumentFolder";
import { Col, Row } from "antd";
import { useParams } from "react-router-dom";
import DocumentFile from "./Components/DocumentFile";
import Box from "Components/Box";
import FlexRow from "Components/FlexRow";
import _ from 'lodash';
import Report from "Assets/Icons/Report";
import DocumentLink from "Pages/Documents/Components/DocumentLink";

const DocumentsPage = () => {

    const [searchFilter, setSearchFilter] = useState<string>('')
    const { id } = useParams<{ id }>()
    const { data } = useDocumentRead(id, searchFilter)
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const search = useCallback(_.debounce(async (newSearchFilter: string) => {
        setSearchFilter(newSearchFilter);
    }, 300), [])

    return (<>
        <DefaultPageLayout title={"Dokumenter"} icon={<Report/>}
           beforeBox={
               <Box style={{ marginBottom: 15, padding: 10 }}>
                   <FlexRow>
                       <input onChange={e => search(e.target.value)} type="text" className={"input input-invisible full-width"} placeholder={"Søg...."}/>
                   </FlexRow>
               </Box>
           }
        >
            {data?.length !== 0 ? (
                <Row gutter={[20, 20]}>
                    {data?.filter(x => x.type === "Folder").map(x =>
                        <Col xxl={3} xl={4} lg={6} md={8} sm={12} xs={24}>
                            <DocumentFolder document={x}/>
                        </Col>
                    )}
                    {data?.filter(x => x.type === "File").map(x =>
                        <Col xxl={3} xl={4} lg={6} md={8} sm={12} xs={24}>
                            <DocumentFile file={x}/>
                        </Col>
                    )}
                    {data?.filter(x => x.type === "Link").map(x =>
                        <Col xxl={3} xl={4} lg={6} md={8} sm={12} xs={24}>
                            <DocumentLink file={x}/>
                        </Col>
                    )}
                </Row>
            ) : (
                <FlexRow justify={"center"}>
                    {searchFilter ? `Vi fandt ingen filer med navnet ${searchFilter}` : 'Vi fandt ingen filer'}

                </FlexRow>
            )}
        </DefaultPageLayout>
    </>)
}

export default DocumentsPage;
