import React, {useEffect} from "react";
import {useForm} from "react-hook-form";
import {getInputClasses} from "Utils/CssUtils";
import {Col, Dropdown, Row} from "antd";
import StyledButton from "Components/StyledButton";
import FlexRow from "Components/FlexRow";
import Label from "Components/Label";
import GroupLabel from "Components/GroupLabel";
import {DawaAutocompleteModel} from "Apis/Models/DawaAutocompleteModel";
import {CustomerModel} from "Apis/Models/CustomerModel";
import {useSaveCustomer} from "Apis/CustomerApi";
import {useAddressSuggestionMenu} from "Hooks/UseAddressSuggestions";
import {openSuccessNotification} from "Utils/NotificationUtils";

interface Props {
    customer?: CustomerModel;
    onSave?: (customer: CustomerModel) => void;
}

const CustomerForm = ({customer, onSave}: Props) => {

    const {register, handleSubmit, errors, setValue, reset} = useForm<CustomerModel>();

    const [saveCustomerMutation, {isLoading}] = useSaveCustomer();
    
    const [addressSuggestions, addressSuggestionsMenu, searchAddress, clearAddressSuggestions] = useAddressSuggestionMenu((address: DawaAutocompleteModel) => {
        setValue('street', `${address.adresse.vejnavn} ${address.adresse.husnr} ${address.adresse.etage ?? ''} ${address.adresse.dør ?? ''}`);
        setValue('city', address.adresse.postnrnavn)
        setValue('zipCode', address.adresse.postnr)
    })

    useEffect(() => {
        reset(customer)
    }, [reset, customer])


    const onSubmitHandler = async (data: CustomerModel) => {
        const savedCustomer = await saveCustomerMutation(data);

        openSuccessNotification('Success', 'Kunden blev gemt')

        if (onSave) {
            await onSave(savedCustomer!);
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmitHandler)}>

            <input type={"hidden"} name={"id"} ref={register} />

            <Row gutter={[20,20]}>
                <Col xs={24} sm={12}>
                    <div>
                        <Label>
                            Navn / Firmanavn
                        </Label>
                        <input className={getInputClasses(errors.name)} name={"name"} ref={register({required: true})} />
                    </div>
                </Col>
                <Col xs={24} sm={12}>
                    <div>
                        <Label>
                            Evt. CVR Nummer
                        </Label>
                        <input className={getInputClasses(errors.vatNumber)} name={"vatNumber"} ref={register({required: false, maxLength: 8})} />
                    </div>
                </Col>
            </Row>

            <hr/>

            <GroupLabel>Stamdata</GroupLabel>
            <Row gutter={[20, 20]}>
                <Col xs={24}>
                    <div>
                        <Label>
                            Navn / Att.
                        </Label>
                        <input className={getInputClasses(errors.phone)} name={"att"} ref={register({required: false})} />
                    </div>
                </Col>
                <Col xs={24} sm={8}>
                    <div>
                        <Label>
                            Telefon
                        </Label>
                        <input className={getInputClasses(errors.phone)} name={"phone"} ref={register({required: true, minLength: 8, maxLength: 8})} />
                    </div>
                </Col>
                <Col xs={24} sm={8}>
                    <div>
                        <Label>
                            Mailadresse
                        </Label>
                        <input className={getInputClasses(errors.email)} name={"email"} ref={register({required: true})} />
                    </div>
                </Col>
                <Col xs={24} sm={8}>
                    <div>
                        <Label>
                            EAN
                        </Label>
                        <input className={getInputClasses(errors.ean)} name={"ean"} ref={register({required: false, maxLength: 13})} />
                    </div>
                </Col>

                <Col xs={24} sm={8}>
                    <div>
                        <Label>
                            Adresse
                        </Label>
                        <Dropdown visible={addressSuggestions.length > 0} overlay={addressSuggestionsMenu}>
                            <input className={getInputClasses(errors.street)} onChange={e => searchAddress(e.target.value)} onBlur={() => setTimeout(() => clearAddressSuggestions(), 100)} name={"street"} ref={register({required: true})} />
                        </Dropdown>
                    </div>
                </Col>
                <Col xs={24} sm={8}>
                    <div>
                        <Label>
                            Postnummer
                        </Label>
                        <input className={getInputClasses(errors.zipCode)} name={"zipCode"} ref={register({required: true, minLength: 3, maxLength: 4})} />
                    </div>
                </Col>
                <Col xs={24} sm={8}>
                    <div>
                        <Label>
                            By
                        </Label>
                        <input className={getInputClasses(errors.city)} name={"city"} ref={register({required: true})} />
                    </div>
                </Col>
                <Col xs={24} sm={8}>
                    <div>
                        <Label>
                            Timepris excl. moms
                        </Label>
                        <input className={getInputClasses(errors.hourlyRate)} name={"hourlyRate"} ref={register({required: true, pattern: /^[0-9]*$/})} />
                    </div>
                </Col>
                <Col xs={24} sm={8}>
                    <div>
                        <Label>
                            Betalingsfrist
                        </Label>
                        <input placeholder={"8 dage"} className={getInputClasses(errors.paynentConditions)} name={"paymentConditions"} ref={register({required: false})}/>
                    </div>
                </Col>
            </Row>

            <FlexRow justify={"end"}>
                <StyledButton color={"primary"} disabled={isLoading} loading={isLoading} type={"submit"}>Gem</StyledButton>
            </FlexRow>

        </form>
    )
}

export default CustomerForm;
