import DefaultPageLayout from "Pages/Layouts/DefaultPageLayout";
import User from "Assets/Icons/User";
import React from "react";
import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import {useCreateUser} from "Apis/UserAdminApi";
import {hasErrors} from "Utils/CssUtils";
import {UserModel} from "Apis/Models/UserModel";
import UserForm from "Pages/Admin/Users/Forms/UserForm";

const CreateUserPage = () => {

    const history = useHistory();

    const {register, handleSubmit, trigger, errors} = useForm<UserModel>();

    const [createUserMutation, {isLoading: isSaving}] = useCreateUser();

    const onSubmitHandler = async (user: UserModel) => {
        await trigger();

        if (!hasErrors(errors)) {
            await createUserMutation(user)

            history.push('/admin/users');
        }
    }

    return (
        <DefaultPageLayout title={"Opret bruger"} icon={<User />}>
            <UserForm
                register={register}
                errors={errors}
                handleSubmit={handleSubmit(onSubmitHandler)}
                isLoading={isSaving}
            />
        </DefaultPageLayout>
    )
}

export default CreateUserPage;
