import React  from "react";
import styles from './QaField.module.scss';

interface Props {
    text?: string;
    value?: string;
    appendixNumber: number
}
const QaFieldCameraTemplate = ({text, value, appendixNumber}: Props) => {
    
    return (
        <div className={`${styles.answer} ${styles.answerRadio}`}>
            <span>{text}</span>
            <div>
                {value !== '' ? `Se billede i bilag ${appendixNumber}` : "Billede ikke taget"}
            </div>
        </div>
    )
}

export default QaFieldCameraTemplate
