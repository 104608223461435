import {useState} from "react";

export const useSelectEntries = (availableItems: number[]): [boolean, number[], Function, Function, Function] => {

    const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
    const [selectedItems, setSelectedItems] = useState<number[]>([]);

    const toggleSelected = (id: number) => {
        if (selectedItems.findIndex(x => x === id) > -1) {
            setSelectedItems(selectedItems.filter(x => x !== id));
        } else {
            setSelectedItems([id].concat(selectedItems))
        }
        setIsAllSelected(false)
    }

    const toggleAllSelected = () => {
        if (isAllSelected) {
            setIsAllSelected(false);
            setSelectedItems([]);
        } else {
            setIsAllSelected(true);
            setSelectedItems(availableItems);
        }
    }

    const isSelected = (number: number) => {
        return selectedItems.findIndex(x => x === number) > -1
    }

    return [isAllSelected, selectedItems, isSelected, toggleSelected, toggleAllSelected]
}
