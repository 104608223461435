import {useQuery} from "react-query";
import {request} from "Apis/BaseApi";
import {QaSchemeDefinitionModel} from "Apis/Models/QaSchemeDefinitionModel";

export const QaSchemeDefinitionCacheKey = 'QaSchemeDefinition';
export const useQaSchemeDefinition = (id: number, enabled: boolean) => {
    return useQuery([QaSchemeDefinitionCacheKey, id], async () => {
        return request<QaSchemeDefinitionModel>({
            url: `/qaschemedefinitions/${id}`,
            method: 'GET'
        })
    }, {
        enabled
    })
}

export const CurrentQaSchemeDefinitionsCacheKey = 'CurrentQaSchemeDefinitions';
export const useCurrentQaSchemeDefinitions = () => {
    return useQuery(CurrentQaSchemeDefinitionsCacheKey, async () => {
        return request<QaSchemeDefinitionModel[]>({
            url: `/qaschemedefinitions/current`,
            method: 'GET'
        })
    })
}


export const AllQaSchemeDefinitionsCacheKey = 'AllQaSchemeDefinitions';
export const useAllQaSchemeDefinitions = () => {
    return useQuery(AllQaSchemeDefinitionsCacheKey, async () => {
        return request<QaSchemeDefinitionModel[]>({
            url: `/qaschemedefinitions/all`,
            method: 'GET'
        })
    })
}
