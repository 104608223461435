import React, { useEffect, useRef, useState } from "react";
import Calendar from "Assets/Icons/Calendar";
import PageTitle from "Components/PageTitle";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import Box from "Components/Box";
import { Col, DatePicker, Modal, Row, Space } from "antd";
import moment from "moment";
import { CalendarTypeKey } from "Pages/Calendar/CalendarPage";
import { useDeleteTask, useSaveTask, useScheduleTask, useUserTasks } from "Apis/UserTasks/UserTaskApi";
import Label from "Components/Label";
import StyledButton from "Components/StyledButton";
import Add from "Assets/Icons/Add";
import FlexRow from "Components/FlexRow";
import { UserTaskModel } from "Apis/UserTasks/Models/UserTaskModel";
import { openSuccessNotification } from "Utils/NotificationUtils";
import { useForm, Controller } from "react-hook-form";
import { getInputClasses } from "Utils/CssUtils";
import Spinner from "Components/Spinner";
import { ScheduleUserTaskModel } from "Apis/UserTasks/Models/ScheduleUserTaskModel";
import styles from "./AdminCalenderPage.module.scss";

const AdminCalenderPage = () => {

    const [calendarType, setCalendarType] = useState<string>('timeGridWeek');
    const [from, setFrom] = useState<Date>(moment().startOf('month').toDate());
    const [to, setTo] = useState<Date>(moment().endOf('month').toDate());
    const ref = useRef<FullCalendar>(null);
    const [selectedTask, setSelectedTask] = useState<Partial<UserTaskModel> | null>()

    const [showModal, setShowModal] = useState<boolean>(false);
    const [taskList, setTaskList] = useState<any[]>([]);
    const { register, handleSubmit, errors, reset, control } = useForm();

    const [saveTaskMutation, {isLoading: isSavingTask}] = useSaveTask()
    const [deleteTaskMutation, {isLoading: isDeletetingTask}] = useDeleteTask();
    const { data: tasks, isLoading: isLoadingTasks } = useUserTasks()
    const [scheduleUserTaskMutation] = useScheduleTask();

    const changeCalendarView = () => {
        const currentView = ref.current?.getApi().view.type;

        const newView = currentView!.includes('timeGridWeek') ? 'dayGridMonth' : 'timeGridWeek';

        localStorage.setItem(CalendarTypeKey, newView);

        if (newView === "dayGridMonth") {
            setFrom(moment().startOf('month').toDate())
            setTo(moment().endOf('month').toDate())
        } else {
            setFrom(moment().startOf('isoWeek').toDate())
            setTo(moment().endOf('isoWeek').toDate())
        }

        ref.current?.getApi().changeView(newView);
    }

    const previousWeekCallback = () => {
        if (calendarType === 'timeGridWeek') {
            setFrom(moment(from).subtract(7, 'days').toDate())
            setTo(moment(to).subtract(7, 'days').toDate())
        } else {
            setFrom(moment(from).subtract(1, 'month').toDate())
            setTo(moment(to).subtract(1, 'month').toDate())
        }

        ref.current?.getApi().prev();
    }

    const nextWeekCallback = () => {
        if (calendarType === 'timeGridWeek') {
            setFrom(moment(from).add(7, 'days').toDate())
            setTo(moment(to).add(7, 'days').toDate())
        } else {
            setFrom(moment(from).add(1, 'month').toDate())
            setTo(moment(to).add(1, 'month').toDate())
        }

        ref.current?.getApi().next();
    }

    const saveTask = async (data: UserTaskModel) => {

        await saveTaskMutation(data)

        closeModal();

        openSuccessNotification('Sucess', 'Opgaven blev gemt')
    }

    useEffect(() => {
        const draggerbleTK = document.getElementById("taskList");

        new Draggable(draggerbleTK!, {
            itemSelector: ".show-tasks",
            eventData: function (taskEL) {
                let title = taskEL.getAttribute("title")
                let id = taskEL.getAttribute("data-task")
                return {
                    title: title,
                    taskId: id,
                };
            }
        })
    }, []);

    useEffect(() => {
        const calendarType = localStorage.getItem(CalendarTypeKey);

        if (calendarType) {
            setCalendarType(calendarType);

            if (calendarType === 'timeGridWeek') {
                setFrom(moment().startOf('isoWeek').toDate());
                setTo(moment().endOf('isoWeek').toDate())
            }

            ref.current?.getApi().changeView(calendarType);
        }
    }, [])

    useEffect(() => {
        setTasksInCalendar(tasks ?? [])
        ref.current?.getApi().removeAllEvents()
    }, [tasks])

    const setTasksInCalendar = (list: UserTaskModel[]) => {
        setTaskList(list?.map(task => {
            return {
                id: task.id,
                end: task.endDate,
                start: task.startDate,
                title: task.title
            }
        }) ?? [])
    }

    const taskResized = async ({ event }) => {
        const userTask: ScheduleUserTaskModel = {
            id: event.id,
            startDate: event.start,
            endDate: event.end,
        }

        await scheduleUserTaskMutation(userTask)

        openSuccessNotification("Success", "Opgaven blev gemt")
    }

    const taskDropped = async ({ event }) => {

        const task: ScheduleUserTaskModel = {
            id: event.extendedProps.taskId,
            startDate: event.start,
            endDate: moment(event.start).add(1, 'hour').toDate().toISOString()
        }

        await scheduleUserTaskMutation(task);
    }

    const newTask = async ({ start, end }) => {

        const newTask: Partial<UserTaskModel> = {
            startDate: start,
            endDate: end,
        }

        reset(newTask)
        setSelectedTask(newTask)

        setShowModal(true)
    }

    const eventClicked = ({ event }) => {

        const task = tasks?.find(x => x.id.toString() === event.id)

        reset(task);
        setSelectedTask(task);

        setShowModal(true);
    }

    const deleteTask = async () => {

        await deleteTaskMutation(selectedTask?.id)

        openSuccessNotification("Sucess", "Opgaven blev slettet.");

        closeModal();
    }

    const closeModal = () => {

        reset({})

        setShowModal(false)
        setSelectedTask(null);
    }

    const selectTask = (task) => {
        reset(task)

        setSelectedTask(task)
        setShowModal(true)
    }

    const getSortedTasks = () => {
        const getClosestDate = (task: UserTaskModel) => {

            if (task.dueDate && task.startDate) {
                return moment(task.startDate).diff(moment(task.dueDate)) > 0 ? task.startDate : task.dueDate;
            } else {
                return task.dueDate ? task.dueDate : task.startDate;
            }

        }

        return tasks?.slice().sort((a, b) => {

            const aDate = getClosestDate(a);
            const bDate = getClosestDate(b);

            return moment(aDate).diff(moment(bDate))
        })
    }

    return (
        <>
            <PageTitle title={"Kalender"} icon={<Calendar/>}/>
            <Row gutter={[20, 20]}>
                <Col span={18}>
                    <Box>
                        <FullCalendar
                            ref={ref}
                            events={taskList}
                            headerToolbar={{
                                end: 'asapenChangeView asapenPrev asapenNext'
                            }}
                            customButtons={{
                                asapenChangeView: {
                                    text: 'Skift visning',
                                    click: changeCalendarView
                                },
                                asapenPrev: {
                                    text: 'Forrige',
                                    click: previousWeekCallback
                                },
                                asapenNext: {
                                    text: 'Næste',
                                    click: nextWeekCallback
                                }
                            }}
                            plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin]}
                            initialView={calendarType}
                            locale={"da"}
                            buttonText={{
                                today: 'I dag',
                                month: 'Måned',
                                week: 'Uge',
                                day: 'Dag',
                                list: 'Liste',
                                prev: "Forrige",
                                next: "Næste"
                            }}
                            selectMinDistance={15}
                            firstDay={1}
                            dayHeaderClassNames={"asapen-day-cell"}
                            buttonIcons={{
                                prev: '',
                                next: '',
                            }}
                            allDaySlot={false}
                            eventBackgroundColor={'#F39324'}
                            eventBorderColor={'transparent'}

                            editable={true}
                            selectable={true}
                            selectMirror={true}
                            eventDurationEditable={true}
                            eventStartEditable={true}
                            droppable={true}
                            select={newTask}
                            eventResize={taskResized}
                            eventDrop={taskResized}
                            eventReceive={taskDropped}
                            eventClick={eventClicked}

                        />
                    </Box>
                </Col>
                <Col span={6}>
                    <Box>
                        <FlexRow justify={"space-between"}>
                            <b style={{ color: 'rgba(147, 147, 147, 0.5)' }}>
                                Opgaveliste
                            </b>
                            <div onClick={() => setShowModal(true)} className={styles.fab}>
                                <Add height={25} width={25}/>
                            </div>
                        </FlexRow>
                        <hr/>
                        <div id={"taskList"}>
                            {isLoadingTasks && <FlexRow justify={"center"} align={"center"}><Spinner/></FlexRow>}
                            {getSortedTasks()?.map(task => (
                                <div className={"fc-event asapen-order-event"} title={task.title} data-task={task.id} key={task.id!.toString()} onClick={() => selectTask(task)}>
                                    {task.title}
                                </div>
                            ))}
                        </div>
                    </Box>
                </Col>
            </Row>
            <Modal visible={showModal}
                   title={selectedTask?.id ? "Opdater opgave" : "Opret Opgave"}
                   onCancel={closeModal}
                   footer={false}
            >

                <form onSubmit={handleSubmit(saveTask)}>
                    <Space className={"w-100"} size={"large"} direction={"vertical"}>
                        <div>
                            <input style={{ display: "none" }} ref={register} name={"id"}/>
                            <Label>
                                Titel
                            </Label>
                            <input type={"text"} placeholder={"titel"} name={"title"} ref={register} className={getInputClasses(errors.title)}/>
                            <Label>
                                Beskrivelse
                            </Label>
                            <textarea placeholder={"Beskrivelse"} name={"description"} ref={register}
                                      className={getInputClasses(errors.description)}/>
                            <Label>Afslutningsdato</Label>
                            <Controller
                                name={"dueDate"}
                                control={control}
                                defaultValue={null}
                                render={props => (
                                    <DatePicker
                                        className={getInputClasses(errors.dueDate)}
                                        placeholder={"Afslutningsdato"}
                                        showTime
                                        minuteStep={15}
                                        format={"DD-MM-YYYY HH:mm"}
                                        value={props.value ? moment(props.value) : null}
                                        onChange={(e => {
                                            props.onChange(e)
                                        })}
                                    />
                                )}
                            />

                            <Label>
                                Startdato
                            </Label>
                            <Controller
                                name={"startDate"}
                                control={control}
                                defaultValue={null}
                                render={props => (
                                    <DatePicker
                                        className={getInputClasses(errors.startDate)}
                                        placeholder={"Startdato"}
                                        showTime
                                        minuteStep={15}
                                        format={"DD-MM-YYYY HH:mm"}
                                        value={props.value ? moment(props.value) : null}
                                        onChange={(e => {
                                            props.onChange(e)
                                        })}
                                    />
                                )}
                            />
                            <Label>
                                Slutdato
                            </Label>
                            <Controller
                                name={"endDate"}
                                control={control}
                                defaultValue={null}
                                render={props => (
                                    <DatePicker
                                        className={getInputClasses(errors.endDate)}
                                        placeholder={"Slutdato"}
                                        showTime
                                        minuteStep={15}
                                        format={"DD-MM-YYYY HH:mm"}
                                        value={props.value ? moment(props.value) : null}
                                        onChange={(e => {
                                            props.onChange(e)
                                        })}
                                    />
                                )}
                            />
                            <FlexRow justify={"space-between"}>
                                <div>
                                    {!!selectedTask?.id && (
                                        <StyledButton color={"danger"} onClick={deleteTask} loading={isDeletetingTask}>Slet</StyledButton>
                                    )}
                                </div>
                                    <StyledButton color={"primary"} type={"submit"} loading={isSavingTask}>Gem</StyledButton>
                            </FlexRow>
                        </div>
                    </Space>
                </form>
            </Modal>
        </>
    )
}

export default AdminCalenderPage
