import {queryCache, useQuery} from "react-query";
import {request} from "Apis/BaseApi";
import {PayoutModel} from "Apis/Models/PayoutModel";
import moment from "moment";
import { saveAs } from 'file-saver';
import {openSuccessNotification} from "Utils/NotificationUtils";

export const adminPayoutsCacheKey = "payouts";
export const useAdminPayouts = () => {
    return useQuery(adminPayoutsCacheKey, async () => {
        return request<PayoutModel[]>({
            url: '/admin/payouts',
            method: 'GET'
        })
    })
}

export const getAdminPayoutsFile = async (): Promise<any> => {
    const responseBlob = await request<Blob>({
        responseType: 'blob',
        url: `/admin/payouts/download`,
        method: 'GET',
    });

    const blob = new Blob([responseBlob], {type: 'text/csv' });
    const fileName = `Betalinger ${moment().format('DD.MM.YYYY')}.txt`;
    saveAs(blob, fileName);

    queryCache.invalidateQueries(adminPayoutsCacheKey);

    openSuccessNotification('Betalingsfilen blev hentet', '')
}

export const getAdminBankDataPayoutsFile = async (): Promise<any> => {
    const responseBlob = await request<Blob>({
        responseType: 'blob',
        url: `/admin/payouts/download/bankdata`,
        method: 'GET',
    });
    
    const blob = new Blob([responseBlob], {type: 'text/csv' });
    const fileName = `Betalinger ${moment().format('DD.MM.YYYY')}.txt`;
    saveAs(blob, fileName);
    
    queryCache.invalidateQueries(adminPayoutsCacheKey);
    
    openSuccessNotification('Betalingsfilen blev hentet', '')
}

export const getAdminBECPayoutsFile = async (): Promise<any> => {
    const responseBlob = await request<Blob>({
        responseType: 'blob',
        url: `/admin/payouts/download/erh`,
        method: 'GET',
    });

    const blob = new Blob([responseBlob], {type: 'text/csv' });
    const fileName = `Betalinger ${moment().format('DD.MM.YYYY')}.txt`;
    saveAs(blob, fileName);

    queryCache.invalidateQueries(adminPayoutsCacheKey);

    openSuccessNotification('Betalingsfilen blev hentet', '')
}
