import {Route, Switch, useRouteMatch} from "react-router-dom";
import React from "react";
import UsersOverviewPage from "Pages/Admin/Users/Overview/UsersOverviewPage";
import CreateUserPage from "Pages/Admin/Users/Create/CreateUserPage";
import EditUserPage from "Pages/Admin/Users/Edit/EditUserPage";

const UsersPage = () => {

    let {path} = useRouteMatch();

    return (
        <Switch>
            <Route path={`${path}`} exact>
                <UsersOverviewPage />
            </Route>
            <Route path={`${path}/create`}>
                <CreateUserPage />
            </Route>
            <Route path={`${path}/edit/:id`}>
                <EditUserPage />
            </Route>
        </Switch>
    )
}

export default UsersPage;
