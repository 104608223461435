import DocumentHeader from "Components/Document/DocumentHeader";
import React from "react";
import DocumentLayout from "Components/Document/DocumentLayout";
import { ReminderModel } from "Apis/Admin/Reminders/Models/ReminderModel";
import moment from "moment";

interface Props {
    reminder?: ReminderModel;
}

const ReminderDocument = ({reminder}: Props) => {
    
    if (!reminder) {
        return null;
    }
    
    return (
        <DocumentLayout>
            <DocumentHeader invoicingAddress={reminder.invoice.invoicingAddress} deliveryAddress={reminder.invoice.deliveryAddress} />
    
            <br/>
            <br/>
    
            <div>
                <p>
                    Dato: {moment().format('L')}
                </p>
                <h1>{reminder?.title}</h1>
        
                <hr />
        
                <p>
                    {reminder?.text}
                </p>
        
                <hr />
            </div>
            
        </DocumentLayout>
    )
}

export default ReminderDocument
