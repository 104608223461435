import {Link, useLocation, useHistory} from "react-router-dom";
import React, {useEffect, useState} from "react";
import { confirmPasswordReset, verifyPasswordReset } from "FirebaseAuth";
import {Space} from "antd";
import FlexRow from "Components/FlexRow";
import {getInputClasses} from "Utils/CssUtils";
import StyledButton from "Components/StyledButton";
import {useForm} from "react-hook-form";

const ActionsForm = () => {

    const location = useLocation();
    const history = useHistory();

    const query= new URLSearchParams(location.search);
    const mode = query.get('mode');
    const oobCode = query.get('oobCode') ?? '';

    const [codeValidated, setCodeValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const {register, handleSubmit, errors} = useForm<{newPassword}>();

    const onSubmitHandler = (data) => {

        setError('');
        setLoading(true);
    
        confirmPasswordReset(oobCode, data.newPassword).then(() => {
            setError('Dit kodeord blev opdateret, tryk på login neden for')
        }).catch((e) => {
            if (e.code === 'auth/weak-password') {
                setError('Du skal mindst have 6 tegn i dit kodeord');
            }
            if (e.code === 'auth/network-request-failed') {
                setError("Der skete en uventet fejl, prøv igen");
            }
        }).finally(() => setLoading(false))
    };

    useEffect(() => {

        if (mode === 'resetPassword' && !codeValidated) {
            setLoading(true);

            verifyPasswordReset(oobCode).then(() => {
                setCodeValidated(true);
                setLoading(false);
            }).catch((e) => {
                history.push('/auth/login')
            })
        }
    }, [mode, oobCode, codeValidated, history]);

    return (
        <form onSubmit={handleSubmit(onSubmitHandler)}>
            <Space direction={"vertical"} size={"middle"} className={"w-100"}>

                {error && (<FlexRow justify={'center'}>
                    <div>{error}</div>
                </FlexRow>)}

                <div>
                    <label>
                        Nyt kodeord
                    </label>
                    <input className={getInputClasses(errors.newPassword)} type="password" name={"newPassword"} ref={register({required: true, minLength: 6})} />
                </div>

                <FlexRow justify={'space-between'}>
                    <Link to={'/auth/login'}>Login</Link>
                    <StyledButton type={"submit"} loading={loading} color={"primary"}>Gem nyt kodeord</StyledButton>
                </FlexRow>

            </Space>
        </form>
    )
}

export default ActionsForm;
