import React, {useEffect, useState} from 'react';
import FlexRow from "Components/FlexRow";
import {Col, Space} from "antd";
import {
    LoginToWholesalerModel,
    UpdateVendorApplicationModel,
    WholesalerModel
} from "Apis/Models/KlarPrisIntegrationModel";
import {useForm} from "react-hook-form";
import {getInputClasses} from "Utils/CssUtils";
import StyledButton from "Components/StyledButton";
import {useLoginToWholesaler, useSelectWholesalerAccount, useUpdateVendorApplication} from "Apis/klarprisApi";
import Spinner from "Components/Spinner";
import Link from "Assets/Icons/Link";
import {openSuccessNotification} from "Utils/NotificationUtils";

interface Props  {
    wholesaler: WholesalerModel,
}
const WholeSalerItem = ({wholesaler}: Props) => {

    const [open, setOpen] = useState<boolean>(false);
    const {register, handleSubmit, errors} = useForm<LoginToWholesalerModel>();
    const {register: registerVendorApplicationForm, reset: resetVendorApplicationForm, handleSubmit: handleVendorApplicationSubmit, errors: vendorApplicationErrors} = useForm<UpdateVendorApplicationModel>();
    const [accounts, setAccounts] = useState<string[]>([]);

    const [loginToWholesalerMutation, {isLoading: loggingIn}] = useLoginToWholesaler();
    const [selectWholesalerAccount, {isLoading: selectingAccount}] = useSelectWholesalerAccount();
    const [updateVendorApplicationMutation, {isLoading: isUpdatingVendorApplication}] = useUpdateVendorApplication();

    useEffect(() => {
        if (wholesaler) {
            resetVendorApplicationForm(wholesaler)
        }
    }, [wholesaler, resetVendorApplicationForm])

    const onSubmit = async (data: LoginToWholesalerModel) => {
        data.wholesalerId = wholesaler.id;

        const response = await loginToWholesalerMutation(data);

        setAccounts(response!);
    }

    const updateVendorApplication = async (data: UpdateVendorApplicationModel) => {
        data.vendorApplicationId = wholesaler.vendorApplicationId;

        await updateVendorApplicationMutation(data);

        openSuccessNotification('Success', 'Oplysningerne blev opdateret')

        setOpen(false)
    }

    const selectAccount = async (account: string) => {
        await selectWholesalerAccount({
            accountNumber: account,
            wholesalerId: wholesaler.id
        })

        openSuccessNotification("Forbindelse oprettet", `Du kan nu søge efter produkter fra ${wholesaler.name} på konto ${account}`)

        setAccounts([])
        setOpen(false)
    }

    return (
        <Col sm={24}>
            <div className={"clickable"} style={{boxShadow: '0px 3px 6px rgba(0,0,0,.16)', borderRadius: 6, padding: '10px 20px', borderLeftWidth: 10, borderLeftStyle: 'solid', borderLeftColor: wholesaler.accountNumber ? '#F39324' : '#CE3939'}}>
                <div onClick={() => setOpen(!open)} >
                    <FlexRow style={{paddingBottom: 10}} justify={"space-between"}>
                        <span style={{fontSize: 14, color: '#9F9F9F'}}>
                            {wholesaler.accountNumber ? 'Forbindelse oprettet' : 'Ikke forbundet'}
                        </span>
                        <Link style={{fill: '#9F9F9F', height: 20, width: 20}} />
                    </FlexRow>

                    <img height={40} width={'auto'} src={wholesaler.imageUrl} alt={`${wholesaler.name} Logo`} />

                    {wholesaler.accountNumber && (
                        <div style={{padding: '10px 0'}}>
                            Forbundet til: {wholesaler.email} - {wholesaler.accountNumber}
                        </div>
                    )}

                    {wholesaler.invoiceErrorEmail ? (
                        <div style={{padding: '10px 0'}}>
                            Mail til din lokale sælger: {wholesaler.invoiceErrorEmail}
                        </div>
                    ) : (
                        <div>
                            {`Mail til din lokale sælger hos ${wholesaler.name} er ikke angivet`}
                        </div>
                    )}
                </div>

                {open && (
                    <div style={{marginTop: 20}}>
                        {wholesaler.accountNumber && (
                            <>
                                <hr/>
                                <form onSubmit={handleVendorApplicationSubmit(updateVendorApplication)}>
                                    <Space direction={"vertical"} className={"w-100"}>
                                        <input placeholder={`Mail til din lokale sælger hos ${wholesaler.name}`} type="text" name="invoiceErrorEmail" ref={registerVendorApplicationForm} className={getInputClasses(vendorApplicationErrors.invoiceErrorEmail)} />
                                        <FlexRow justify={"end"}>
                                            <StyledButton disabled={isUpdatingVendorApplication} loading={isUpdatingVendorApplication} type={"submit"}>Opdater oplysninger</StyledButton>
                                        </FlexRow>
                                    </Space>
                                </form>
                            </>
                        )}

                        <span>
                            {!wholesaler.accountNumber ? `Opret forbindelse til ${wholesaler.name}` : 'Opdater forbindelse'}
                        </span>
                        {accounts.length > 0 && (
                            <div>
                                {selectingAccount && (
                                    <Spinner />
                                )}
                                {!selectingAccount && accounts.map(x => (
                                    <div key={x} onClick={() => selectAccount(x)} className={"clickable"} style={{padding: '10px 0'}}>
                                        <FlexRow justify={"space-between"}>
                                            <span>
                                                {x}
                                            </span>
                                            Vælg
                                        </FlexRow>
                                    </div>
                                ))}
                            </div>
                        )}
                        {accounts.length === 0 && (
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Space direction={"vertical"} className={"w-100"}>
                                    <input placeholder={"Brugernavn"} type="text" name="username" ref={register} className={getInputClasses(errors.username)} />
                                    <input placeholder={"Kodeord"} type="password" name="password" ref={register} className={getInputClasses(errors.password)} />
                                    <FlexRow justify={"end"}>
                                        <StyledButton disabled={loggingIn} loading={loggingIn} type={"submit"}>Opret forbindelse</StyledButton>
                                    </FlexRow>
                                </Space>
                            </form>
                        )}
                    </div>
                )}
            </div>
        </Col>
    )
}

export default WholeSalerItem
