import {Col, Row, Space} from "antd";
import {getInputClasses} from "Utils/CssUtils";
import FlexRow from "Components/FlexRow";
import StyledButton from "Components/StyledButton";
import React, {useEffect} from "react";
import GroupLabel from "Components/GroupLabel";
import Label from "Components/Label";
import {useAdminUsers, useStandardUsers} from "Apis/UserAdminApi";
import {useForm} from "react-hook-form";
import OrganizationModel from "Apis/Models/OrganizationModel";
import {useInvoiceLayouts, useSaveOrganization} from "Apis/OrganizationAdminApi";
import {useHistory} from "react-router-dom";
import Spinner from "Components/Spinner";
import {openSuccessNotification} from "Utils/NotificationUtils";

interface Props {
    organization?: OrganizationModel
}
const OrganizationForm = ({organization}: Props) => {

    const history = useHistory();

    const {data: adminUsers} = useAdminUsers();
    const {data: standardUsers} = useStandardUsers();
    const { data: invoiceLayouts } = useInvoiceLayouts();

    const [saveOrganizationMutation, {isLoading}] = useSaveOrganization();

    const {register, handleSubmit, errors, reset, watch} = useForm<OrganizationModel>({mode: 'all'});

    useEffect(() => {
        reset(organization);
    }, [reset, organization])

    const onSubmitHandler = async (data: OrganizationModel) => {
        await saveOrganizationMutation(data);

        openSuccessNotification('Success', `Firmaet blev gemt`)

        history.push('/admin/organizations');
    }

    if (!adminUsers || !standardUsers || !invoiceLayouts) {
        return (
            <Spinner />
        )
    }

    return (
        <form onSubmit={handleSubmit(onSubmitHandler)}>

            <input type="hidden" name={"id"} ref={register}/>

            <GroupLabel>Firmaoplysninger</GroupLabel>
            <Row gutter={[20, 20]}>

                <Col xs={24} md={8}>
                    <div>
                        <Label>
                            Firma navn
                        </Label>
                        <input className={getInputClasses(errors.name)} type="text" name={'name'} ref={register({required: true})}/>
                    </div>
                </Col>

                <Col xs={24} md={8}>
                    <div>
                        <Label>
                            CVR
                        </Label>
                        <input className={getInputClasses(errors.vatNumber)} name={"vatNumber"} ref={register({required: true, minLength: 8, maxLength: 8, pattern: /^[0-9]*$/})}/>
                    </div>
                </Col>

                <Col xs={24} md={8}>
                    <div>
                        <Label>
                            Beskæftigelse
                        </Label>
                        <select className={getInputClasses(errors.occupation)} name={"occupation"} ref={register({required: true})}>
                            <option value={""}>Vælg</option>
                            <option value={"Electrician"}>Elektriker</option>
                            <option value={"Sanitarian"}>VVS</option>
                        </select>
                    </div>
                </Col>
            </Row>

            <Row gutter={[20, 20]}>
                <Col xs={24} md={8}>
                    <div>
                        <Label>
                            Navn
                        </Label>
                        <input className={getInputClasses(errors.contactName)} type="text" name={'contactName'} ref={register({required: true})}/>
                    </div>
                </Col>

                <Col xs={24} md={8}>
                    <div>
                        <Label>
                            Telefon
                        </Label>
                        <input className={getInputClasses(errors.contactPhone)} type="text" name={'contactPhone'} ref={register({required: true, minLength: 8, maxLength: 8, pattern: /^[0-9]*$/})}/>
                    </div>
                </Col>

                <Col xs={24} md={8}>
                    <div>
                        <Label>
                            E-Mail
                        </Label>
                        <input className={getInputClasses(errors.contactEmail)} type="text" name={'contactEmail'} ref={register({required: true})}/>
                    </div>
                </Col>
            </Row>

            <hr/>

            <GroupLabel>Bankoplysninger</GroupLabel>
            <Row gutter={[20, 20]}>
                <Col xs={24} sm={12}>
                    <div>
                        <Label>
                            Reg nr:
                        </Label>
                        <input className={getInputClasses(errors.bankRegistrationNumber)} type="text" name={'bankRegistrationNumber'} ref={register({maxLength: 4, pattern: /^[0-9]*$/})}/>
                    </div>
                </Col>

                <Col xs={24} sm={12}>
                    <div>
                        <Label>
                            Konto nr:
                        </Label>
                        <input className={getInputClasses(errors.bankAccountNumber)} type="text" name={'bankAccountNumber'} ref={register({maxLength: 12, pattern: /^[0-9]*$/})}/>
                    </div>
                </Col>
            </Row>

            <hr/>

            <GroupLabel>Fakturaindstillinger</GroupLabel>

            <Row gutter={[20, 20]}>
                <Col xs={24} sm={12}>
                    <div>
                        <Label>
                            Regnskabsprogram
                        </Label>
                        <select className={getInputClasses(errors.invoicingIntegrationType)} name={'invoicingIntegrationType'} ref={register({required: true})} disabled={!!organization?.id}>
                            <option value={""}>Vælg</option>
                            <option value={"Dinero"}>Dinero</option>
                            <option value={"Economic"}>e-conomic</option>
                        </select>
                    </div>
                </Col>

                {watch('invoicingIntegrationType') === 'Dinero' && (
                    <Col xs={24} sm={12}>
                        <div>
                            <Label>
                                Dinero Firma Id
                            </Label>
                            <input className={getInputClasses(errors.dineroOrganizationId)} type="text" name={'dineroOrganizationId'} ref={register({required: true, pattern: /^[0-9]*$/})}/>
                        </div>
                    </Col>
                )}

                {watch('invoicingIntegrationType') === 'Economic' && (
                    <Col xs={24} sm={12}>
                        <div>
                            <Label>
                                Konto til leverandørfakturaer
                            </Label>
                            <input className={getInputClasses(errors.supplierInvoiceAccountNumber)} type="text" name={'supplierInvoiceAccountNumber'} ref={register({required: true, pattern: /^[0-9]*$/})}/>
                        </div>
                    </Col>
                )}
            </Row>

            <Row gutter={[20, 20]}>
                <Col xs={24}>
                    <div>
                        <Label>
                            Fakturalayout
                        </Label>
                        <select className={getInputClasses(errors.invoiceLayout)} name={'invoiceLayout'} ref={register({required: true})}>
                            <option value={""}>Vælg</option>
                            {invoiceLayouts?.map((layout) => (
                                <option key={layout.layoutNumber} value={layout.layoutNumber}>{layout.name}</option>
                            ))}
                        </select>
                    </div>
                </Col>
            </Row>

            <hr/>

            <Row gutter={[20, 20]}>

                <Col xs={24} sm={24}>
                    <Label>Ansvarlig</Label>
                    <select className={getInputClasses(errors.responsibleId)} name={'responsibleId'} ref={register}>
                        <option value={""}>Vælg</option>
                        {adminUsers?.map((user) => {
                            return (
                                <option key={user.id} value={user.id}>{user.name}</option>
                            )
                        })}
                    </select>
                </Col>

                <Col xs={24}>
                    <Space>
                        <input type="checkbox" disabled={(!(watch('bankRegistrationNumber') && watch('bankAccountNumber')))} ref={register()} name={"payoutsEnabled"}/>
                        <Label>Integration har kontooplysninger og klar til at modtage udbetalinger</Label>
                    </Space>
                </Col>

            </Row>

            <FlexRow justify={"end"}>
                <StyledButton color={"primary"} type={"submit"} disabled={isLoading} loading={isLoading}>Gem</StyledButton>
            </FlexRow>

        </form>
    )
}

export default OrganizationForm;
