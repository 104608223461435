import saveAs from 'file-saver';

export function toBase64(file: File, includeData: boolean = false): Promise<any> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
            // @ts-ignore
            let payload = reader.result.split(',')[1];

            if (includeData) {
                payload = reader.result;
            }

            resolve(payload);
        }
        reader.onerror = error => reject(error);
    });
}

export const base64toFile = async (base64: string, fileName: string) => {
    const res: Response = await fetch(base64);
    const blob: Blob = await res.blob();
    saveAs(blob, fileName);
}
