import React from "react";
import {Route, Switch, useRouteMatch} from "react-router-dom";
import OfferApprovalPage from "Pages/Admin/Approval/Offers/OfferApprovalPage";
import OfferDetailsPage from "Pages/Admin/Approval/Offers/OfferDetailsPage";
import InvoiceApprovalPage from "Pages/Admin/Approval/Invoices/InvoiceApprovalPage";
import InvoiceDetailsPage from "Pages/Admin/Approval/Invoices/InvoiceDetailsPage";
import ReminderRoutes from "Pages/Admin/Approval/Reminders/ReminderRoutes";
import DeprecationOverviewPage from "Pages/Admin/Approval/Deprecation/DeprecationOverviewPage";
import DeprecationDetailsPage from "Pages/Admin/Approval/Deprecation/DeprecationDetailsPage";
const ApprovalPage = () => {

    let {path} = useRouteMatch();

    return (
        <Switch>
            <Route path={`${path}/offers`} exact>
                <OfferApprovalPage />
            </Route>
            <Route path={`${path}/offers/:id`}>
                <OfferDetailsPage />
            </Route>


            <Route path={`${path}/invoices`} exact>
                <InvoiceApprovalPage />
            </Route>
            <Route path={`${path}/invoices/:id`}>
                <InvoiceDetailsPage />
            </Route>

            <Route path={`${path}/creditnotes`}>
                <OfferApprovalPage />
            </Route>

            <Route path={`${path}/reminders`}>
                <ReminderRoutes />
            </Route>
    
            <Route path={`${path}/deprecation`} exact>
                <DeprecationOverviewPage />
            </Route>
    
            <Route path={`${path}/deprecation/:id`}>
                <DeprecationDetailsPage />
            </Route>
        </Switch>
    )

}

export default ApprovalPage;
