import React from "react";
import styles from './PageTitle.module.scss';
import FlexRow from "Components/FlexRow";

interface Props {
    title: string | undefined;
    icon: any;
    fab?: any;
}
const PageTitle = ({title, icon, fab}: Props) => {
    return (
        <div className={styles.container}>
            <FlexRow>
                <div className={styles.icon}>
                    {icon}
                </div>
                <h1 className={styles.title}>
                    {title}
                </h1>
            </FlexRow>
            {fab && fab}
        </div>
    )
}

export default PageTitle;
