import {queryCache, useMutation, useQuery} from "react-query";
import {request} from "Apis/BaseApi";
import OrganizationModel from "Apis/Models/OrganizationModel";
import {allowedOrganizationCacheKey} from "Apis/OrganizationApi";
import {BadRequestModel, DomainExceptionModel} from "Apis/Models/DomainExceptionModel";
import {openErrorNotification} from "Utils/NotificationUtils";
import {OrganizationUserModel} from "./Models/UserModel";
import {InvoiceLayoutModel} from "./Models/InvoiceLayoutModel";

export const useSaveOrganization = () => {
    return useMutation(async (organization: OrganizationModel) => {
        if (organization.id) {
            return await request<OrganizationModel>({
                url: `/admin/organizations/${organization.id}`,
                method: 'PUT',
                data: organization
            })
        } else {
            return await request<OrganizationModel>({
                url: `/admin/organizations`,
                method: 'POST',
                data: organization
            })
        }
    }, {
        onSuccess: (organization: OrganizationModel) => {
            queryCache.invalidateQueries(organizationsCacheKey)
            queryCache.invalidateQueries([organizationCacheKey, organization.id])
            queryCache.invalidateQueries(allowedOrganizationCacheKey)
        },
    })
}


export const organizationsCacheKey = 'organizations';
export const useOrganizations = (includeDeleted: boolean = false) => {
    return useQuery<OrganizationModel[]>(organizationsCacheKey, async () => {
        return await request<OrganizationModel[]>({
            url: `/admin/organizations?includeDeleted=${includeDeleted}`,
            method: 'GET'
        })
    })
}

export const organizationCacheKey = 'organization';
export const useOrganization = (id: number) => {
    return useQuery<OrganizationModel>([organizationsCacheKey, id], async () => {
        return await request<OrganizationModel>({
            url: `/admin/organizations/${id}`,
            method: 'GET'
        })
    })
}

export const useDeleteOrganization = () => {
    return useMutation(async (organization: OrganizationModel) => {
        return await request({
            url: `/admin/organizations/${organization.id}`,
            method: 'DELETE',
        })
    }, {
        onSuccess: () => {
            queryCache.invalidateQueries(organizationsCacheKey)
            queryCache.invalidateQueries(allowedOrganizationCacheKey)
        },
        onError: (error: BadRequestModel<DomainExceptionModel>) => {
            console.log(error)
            openErrorNotification('Der skete en fejl', error.data.message)
        }
    })
}

export const useAddOrganizationUser = () => {
    return useMutation(async (organizationUser: OrganizationUserModel) => {
        return await request({
            url: `/admin/organization/users/${organizationUser.organizationId}/${organizationUser.userId}?owner=${organizationUser.owner}`,
            method: 'POST',
        })
    }, {
        onSuccess: () => {
            queryCache.invalidateQueries(organizationsCacheKey)
            queryCache.invalidateQueries(organizationCacheKey)
            queryCache.invalidateQueries(allowedOrganizationCacheKey)
        },
    })
}

export const useRemoveOrganizationUser = () => {
    return useMutation(async (organizationUser: OrganizationUserModel) => {
        return await request({
            url: `/admin/organization/users/${organizationUser.organizationId}/${organizationUser.userId}`,
            method: 'DELETE',
        })
    }, {
        onSuccess: () => {
            queryCache.invalidateQueries(organizationsCacheKey)
            queryCache.invalidateQueries(organizationCacheKey)
            queryCache.invalidateQueries(allowedOrganizationCacheKey)
        },
    })
}

export const useInvoiceLayouts = () => {
    return useQuery<InvoiceLayoutModel[]>(['INVOICE_LAYOUTS_CACHE_KEY'], async () => {
        return await request<InvoiceLayoutModel[]>({
            url: `/admin/organizations/invoiceLayouts`,
            method: 'GET',
        })
    })
}