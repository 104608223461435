const DefaultStyles = {
    padding: '30px',
    colors: {
        boxShadow: 'rgba(0,0,0,.16)',
        red: '#CE3939',
        green: '#F39324',
        grey: '#989898',
        white: '#FFF',
        border: '#DBDBDB',
    },
}

export default DefaultStyles
