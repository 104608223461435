import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import QaSchemesPageAdmin from "./QaSchemesPageAdmin";

const QaSchemeRouteAdmin = () => {

    let { path } = useRouteMatch();

    return (<>
        <Switch>
            <Route path={`${path}`} exact>
                <QaSchemesPageAdmin />
            </Route>
        </Switch>
    </>)
}
export default QaSchemeRouteAdmin;