import React, { useContext } from "react";
import {Route, Redirect} from "react-router-dom";
import { UserContextProvider } from "Providers/UserProvider";
import Spinner from "./Spinner";

// @ts-ignore
function RedirectIfLoggedInRoute({ children, ...rest }) {

    const user = useContext(UserContextProvider);

    if (user.loading) {
        return (
            <div style={{width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Spinner size="large" />
            </div>
        )
    }

    return (
        <Route
            {...rest}
                render={({ location }) =>
                !user.firebaseUser ? (
                    children
                ) : (
                    <Redirect to={{pathname: "/", state: { from: location }}}
                />
            )
            }
        />
    );

}

export default RedirectIfLoggedInRoute;
