import React, {useState} from 'react';
import {Table} from "antd";
import {OrderMaterialModel} from "Apis/Models/OrderMaterialModel";
import {getNumberAsCurrency} from "Utils/NumberUtils";
import {
    useOrderMaterials,
    useSaveOrderMaterials
} from "Apis/orderMaterialApi";
import {OrderModel} from "Apis/Models/OrderModel";
import FlexRow from "Components/FlexRow";
import StyledButton from "Components/StyledButton";
import Modal from "antd/es/modal";
import {openSuccessNotification} from "Utils/NotificationUtils";
import MaterialForm from "Pages/Projects/Orders/Components/MaterialForm";


interface Props {
    order: OrderModel
}
const OrderMaterials = ({order}: Props) => {

    const {data: orderMaterials} = useOrderMaterials(order.id)

    const [showMaterialModal, showOrderMaterialModal] = useState<boolean>(false);

    const [saveOrderMaterialsMutation] = useSaveOrderMaterials();

    const saveMaterials = async (data: { materials: OrderMaterialModel[] }) => {
        await saveOrderMaterialsMutation({orderId: order.id, materials: data.materials ?? []});

        closeModal();
        openSuccessNotification('Success', 'Materialerne blev gemt')
    }

    const closeModal = () => {
        showOrderMaterialModal(false);
    }

    return (
        <>
            <FlexRow justify={"end"}>
                <StyledButton onClick={() => showOrderMaterialModal(true)}>Opret / Ændre materialer</StyledButton>
            </FlexRow>
            <Table
                dataSource={orderMaterials}
                pagination={false}
                rowKey={(record) => record.id}
            >
                <Table.Column title={"Tekst"} dataIndex={"text"} />
                <Table.Column title={"Antal"} dataIndex={"quantity"}  />
                <Table.Column title={"Indkøbspris pr styk"} dataIndex={"unitPriceExVat"} render={(value) => `${getNumberAsCurrency(value)} DKK`} />
                <Table.Column title={"Avance"} dataIndex={"margin"} render={(value) => `${value}%`} />
                <Table.Column title={"Salgspris pr styk"} dataIndex={"salesPriceExVat"} render={(value) => `${getNumberAsCurrency(value)} DKK`} />
                <Table.Column title={"Rabat"} dataIndex={"discount"} render={(value) => `${value}%`} />
            </Table>
            <Modal visible={showMaterialModal} title={"Materiale"}
                   onCancel={closeModal}
                   footer={false}
                   width={'80%'}
            >
                <MaterialForm orderId={order.id} materials={orderMaterials} onSave={saveMaterials} />
            </Modal>
        </>
    )
}

export default OrderMaterials
