import { useInvoicesReadyForDeprecation } from "Apis/InvoiceAdminApi";
import React from "react";
import Approve from "Assets/Icons/Approve";
import { Table } from "antd";
import Spinner from "Components/Spinner";
import DefaultPageLayout from "Pages/Layouts/DefaultPageLayout";
import { useHistory } from "react-router-dom";
import moment from "moment";

const DeprecationOverviewPage = () => {
    
    const history = useHistory();
    
    const { data, isLoading } = useInvoicesReadyForDeprecation();
    
    return (
        <DefaultPageLayout title={"Afskriv faktura til tab"} isLoading={isLoading} icon={<Approve />}>
            <Table
                size={"small"}
                rowKey={'invoiceSyncId'}
                dataSource={data}
                loading={{indicator: <Spinner />, spinning: isLoading}}
                pagination={false}
                onRow={(record) => {
                    return {
                        onClick: () => history.push(`/admin/approval/deprecation/${record.invoiceId}`),
                    };}
                }
                rowClassName={'clickable'}
            >
                <Table.Column title={"Faktura nr"} dataIndex={"invoiceNumber"} />
                <Table.Column title={"Dato"} dataIndex={"date"} render={text => moment(text).format('DD/MM/YYYY')} />
                <Table.Column title={"Forfaldsdato"} dataIndex={"dueDate"} render={text => moment(text).format('DD/MM/YYYY')} />
                <Table.Column title={"Kunde"} dataIndex={"customerName"} />
            </Table>
        </DefaultPageLayout>
    )
}

export default DeprecationOverviewPage
