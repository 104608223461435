import { useParams } from "react-router-dom";
import PaperLayout from "Components/PaperLayout";
import React, {useState} from "react";
import DefaultPageLayout from "Pages/Layouts/DefaultPageLayout";
import FlexRow from "Components/FlexRow";
import {Space} from "antd";
import StyledButton from "Components/StyledButton";
import {getDateWithTime} from "Utils/DateUtils";
import {useApproveOffer, useOffer, useRejectOffer} from "Apis/OfferAdminApi";
import Modal from "antd/es/modal";
import {getInputClasses} from "Utils/CssUtils";
import {useForm} from "react-hook-form";
import {getOfferTypeAsString} from "Utils/OfferUtils";
import {openSuccessNotification} from "Utils/NotificationUtils";
import Notice from "Components/Notice";
import OfferDetails from "Pages/Projects/Offers/Components/OfferDetails";
import Approve from "Assets/Icons/Approve";
import DocumentContainer from "../../../../Components/Document/DocumentContainer";

const OfferDetailsPage = () => {
    
    const { id } = useParams<{ id }>();

    const {data: offer, isLoading: isLoadingOffer} = useOffer(id);

    const [showRejectModal, setShowRejectModal] = useState<boolean>(false);

    const {register, errors, handleSubmit} = useForm<{reason}>();

    const [approveOfferMutation, {isLoading: isApprovingOffer}] = useApproveOffer();
    const [rejectOfferMutation, {isLoading: isRejectingOffer}] = useRejectOffer();

    const approveOffer = async () => {
        await approveOfferMutation(offer!.id);

        openSuccessNotification('Success', `${getOfferTypeAsString(offer?.offerType!)} blev godkendt`)
    }

    const rejectOffer = async (data: {reason}) => {
        await rejectOfferMutation({id: offer!.id, reason: data.reason});

        openSuccessNotification('Success', `${getOfferTypeAsString(offer?.offerType!)} blev afvist`)

        setShowRejectModal(false)
    }

    return (
        <DefaultPageLayout title={"Tilbud til godkendelse"} isLoading={isLoadingOffer} icon={<Approve />}>
            <DocumentContainer>
            <PaperLayout beforePaper={(
                <>
                <FlexRow justify={"end"} align={"end"} direction={"column"}>
                    {offer?.state === 'WaitingForApproval' && (<Space>
                        <StyledButton disabled={isRejectingOffer || isApprovingOffer} loading={isRejectingOffer} onClick={() => setShowRejectModal(true)} color={"danger"}>Afvis</StyledButton>
                        <StyledButton disabled={isRejectingOffer || isApprovingOffer} loading={isApprovingOffer} onClick={() => approveOffer()} color={"primary"}>Godkend</StyledButton>
                    </Space>)}
                </FlexRow>

                {offer?.approval && offer.approval.approvedAt && (
                    <Notice type={offer.approval.isApproved ? 'Success' : 'Danger'}>
                        <FlexRow direction={"column"} align={"start"}>
                            <b>
                                {offer.approval.isApproved ? 'Godkendt' : 'Afvist'} {getDateWithTime(offer.approval.approvedAt)}
                            </b>
                            <span>
                                {offer?.approval?.reason}
                            </span>
                        </FlexRow>
                    </Notice>
                )}
                </>
            )}>

            {offer && (
                <OfferDetails offer={offer} />
            )}

            </PaperLayout>
            </DocumentContainer>
            <Modal visible={showRejectModal} title={"Afvis tilbud"}
                   onCancel={() => setShowRejectModal(false)}
                footer={false}
            >
                <form onSubmit={handleSubmit(rejectOffer)}>
                    <textarea placeholder={"Indtast begundelse"} name="reason" rows={4} className={getInputClasses(errors.reason)} ref={register({required: true})} />
                    <FlexRow justify={"end"}>
                        <Space>
                            <StyledButton onClick={() => setShowRejectModal(false)}>Afbryd</StyledButton>
                            <StyledButton color={"danger"} type={"submit"}>Afvis</StyledButton>
                        </Space>
                    </FlexRow>
                </form>
            </Modal>
        </DefaultPageLayout>
    )

}

export default OfferDetailsPage;
