import React, { useState } from 'react';
import ShoppingList from "Assets/Icons/ShoppingList";
import DefaultPageLayout from "Pages/Layouts/DefaultPageLayout";
import { useNotifications, useSetNotificationSeen } from "Apis/NotificationsApi";
import Spinner from "Components/Spinner";
import { Table } from "antd";
import moment from "moment";
import Pill from "Components/Pill";
import FlexRow from "Components/FlexRow";
import { NotificationModel } from "Apis/Models/NotificationModel";
import { useHistory } from "react-router-dom";

const NotificationsPage = () => {

    const history = useHistory();

    const [page, setPage] = useState<number>(0);
    const { data: notifications, isLoading } = useNotifications(page);
    const [setNotificationSeenMutation] = useSetNotificationSeen();

    const onClick = async (record: NotificationModel) => {
        await setNotificationSeenMutation(record.id);

        switch (record.notificationType) {
            case "OfferRejected":
            case "OfferCustomerApproved":
            case "OfferCustomerRejected":
                history.push(`/projects/offers/${record.entityId}`);

                break;
            case "InvoicePaid":
            case "InternalInvoiceRejected":
            case "InvoiceRejected":
                history.push(`/economy/invoices/edit/${record.entityId}`);
                break;
            case "SupplierInvoiceReceived":
                history.push(`/purchases/supplierinvoices/${record.entityId}`);
                break;
            case "NewsReceived":
                history.push(`/news`);
                break;
        }
    }

    return (
        <DefaultPageLayout title={"Notifikationer"} isLoading={isLoading} icon={<ShoppingList/>}>
            <Table
                size={"small"}
                rowKey={'id'}
                dataSource={notifications?.results}
                loading={{ indicator: <Spinner/>, spinning: isLoading }}
                pagination={{
                    pageSize: 20,
                    current: page + 1,
                    total: notifications?.totalResults,
                    onChange: (newPage, pageSize) => {
                        setPage(newPage - 1)
                    }
                }}
                rowClassName={"clickable"}
                onRow={(record) => {
                    return {
                        onClick: () => onClick(record),
                    };
                }}
            >
                <Table.Column title={"Tidspunkt"} dataIndex={"createdAt"} render={(value, record: NotificationModel) => (
                    <FlexRow justify={"start"}>
                        <Pill color={record.seenAt ? 'Default' : 'Success'}/>
                        <FlexRow align={"start"} justify={"start"} direction={"column"}>
                            <span>
                                {moment(value).format('DD. MMM [kl] HH:II')}
                            </span>
                        </FlexRow>
                    </FlexRow>
                )}/>
                <Table.Column title={"Overskrift"} dataIndex={"title"}/>
                <Table.Column title={"Beskrivelse"} dataIndex={"text"} width={'60%'}/>
            </Table>
        </DefaultPageLayout>
    )
}

export default NotificationsPage
