import React from "react";
import Spinner from "./Spinner";

type Props = {
    block?: boolean
    loading?: boolean;
    color?: 'primary' | 'danger' | 'default' | 'warning';
    onClick?: any;
    type?: 'button' | 'submit';
    children?: any;
    disabled?: boolean;
}
export default function StyledButton(props: Props) {

    const classes = ['button'];

    if (props.color) {
        classes.push(props.color);
    } else {
        classes.push('default')
    }

    if (props.block) {
        classes.push('block');
    }

    const type = props.type ?? 'button';

    function onClick() {
        if (props.onClick) {
            props.onClick();
        }
    }

    return (
        <button type={type} disabled={props.disabled} className={classes.join(' ')} onClick={() => onClick()}>
            <div className={"flex justify-center align-center"}>
                {props.loading && (
                    <div style={{paddingRight: 10}}>
                        <Spinner style={{color: 'white'}} size="small" />
                    </div>
                )}
                {props.children}
            </div>
        </button>
    )
}
