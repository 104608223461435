import {useQuery} from "react-query";
import {request} from "Apis/BaseApi";
import {NewsModel} from "Apis/Models/NewsModel";

export const newsCacheKey = 'news'
export const useNews = () => {
    return useQuery(newsCacheKey, async () => {
        return request<NewsModel[]>({
            url: '/news',
            method: 'GET'
        })
    })
}
