import DocumentLayout from "Components/Document/DocumentLayout";
import styles from './OrderDocument.module.scss';
import React from "react";
import { OrderModel } from "Apis/Models/OrderModel";
import DocumentHeader from "Components/Document/DocumentHeader";

interface Props {
    order?: OrderModel;
}
const OrderDocument = ({order}: Props) => {
    
    if (!order) {
        return null;
    }
    
    return (
        <DocumentLayout>
            <DocumentHeader invoicingAddress={order.invoicingAddress} deliveryAddress={order.deliveryAddress} />
    
            <div>
                Ordrebeskrivelse
            </div>
    
            <hr className={styles.separator}/>
    
            <div style={{fontWeight: 600}}>
                {order.title}
            </div>
    
            <hr className={styles.separator}/>
    
            <div className={styles.descriptionContainer}>
                <span className={"display-linebreak"}>
                    {order.description}
                </span>
        
                <br/><br/>
        
        
                <br/>
                <div>
                    Via ELi A/S medlemskab af Tekniq arbejdsgiverne, er du som privatforbruger
                    omfattet af Tekniq’s garantiordning. Ligeledes gælder Tekniq’s standardforbehold for
                    el og vvs af 2007. <a href="https://www.tekniq.dk/forbruger">Klik her for at se
                    mere</a>
                </div>
            </div>
        </DocumentLayout>
    )
}

export default OrderDocument
