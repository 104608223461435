import {queryCache, useMutation, useQuery} from "react-query";
import {request} from "Apis/BaseApi";
import {BudgetModel} from "Apis/Organization/Models/BudgetModel";

export const BUDGET_CACHE_KEY = 'BUDGET_CACHE_KEY';
export const useBudget = () => {
    return useQuery<BudgetModel>(BUDGET_CACHE_KEY, async () => {
        return await request<BudgetModel>({
            url: `/budget`,
            method: 'GET'
        })
    })
}

export const useSaveBudget = () => {
    return useMutation(async (entry: BudgetModel): Promise<BudgetModel> => {
        return await request<BudgetModel>({
            url: `/budget`,
            method: 'PUT',
            data: entry
        })
    }, {
        onSuccess: (entry: BudgetModel) => {
            queryCache.invalidateQueries(BUDGET_CACHE_KEY)
        },
    })
}
