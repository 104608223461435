interface Config {
    baseUrl: string,
    firebaseConfig: FirebaseConfig
}

interface FirebaseConfig {
    apiKey: string,
    authDomain: string,
    projectId: string,
}

let config: Config

if (process.env.REACT_APP_ASAPEN_ENV === 'Production') {
    config = {
        baseUrl: 'https://asapen-z3xg3.ondigitalocean.app/api',
        firebaseConfig: {
            apiKey: 'AIzaSyAiAgJGPdtrZUbHeGBK30msFby06L1-5NQ',
            authDomain: 'asapen-production.firebaseapp.com',
            projectId: 'asapen-production'
        }
    }
} else if (process.env.REACT_APP_ASAPEN_ENV === 'Staging') {
    config = {
        baseUrl: 'http://localhost:3000/api',
        firebaseConfig: {
            apiKey: 'AIzaSyD6MkdpKK7pikGaiE_gvqTouijEF5DXxEc',
            authDomain: 'asapen-staging.firebaseapp.com',
            projectId: 'asapen-staging'
        }
    }
} else {
    config = {
        baseUrl: 'http://localhost:3000/api',
        firebaseConfig: {
            apiKey: 'AIzaSyD6MkdpKK7pikGaiE_gvqTouijEF5DXxEc',
            authDomain: 'asapen-staging.firebaseapp.com',
            projectId: 'asapen-staging'
        }
    }
}

export default config;
