import React from "react";
import {Spin} from "antd";
import {Loading3QuartersOutlined} from "@ant-design/icons/lib";

type Props = {
    size?: 'default' | 'large' | 'small',
    style?: any
}
function Spinner(props: Props) {
    const antIcon = <Loading3QuartersOutlined spin />;

    return (
        <Spin style={{color: '#F39324', ...props.style}} size={props.size} indicator={antIcon} />
    )
}

export default Spinner;
