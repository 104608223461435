import {useParams, Switch, Route, useRouteMatch, NavLink} from "react-router-dom";
import {useFinishOrder, useOrder} from "Apis/OrderApi";
import React from "react";
import {Col, Row} from "antd";
import PageTitle from "Components/PageTitle";
import Box from "Components/Box";
import FlexRow from "Components/FlexRow";
import Spinner from "Components/Spinner";
import ShoppingList from "Assets/Icons/ShoppingList";
import StyledButton from "Components/StyledButton";
import OrderDetails from "Pages/Projects/Orders/Components/OrderDetails";
import OrderTimeEntries from "Pages/Projects/Orders/Components/OrderTimeEntries";
import OrderMaterials from "Pages/Projects/Orders/Components/OrderMaterials";
import OrderDocumentation from "Pages/Projects/Orders/Components/OrderDocumentation";
import OrderAgreements from "Pages/Projects/Orders/Components/OrderAgreements";
import OrderDocuments from "Pages/Projects/Orders/Components/OrderDocuments";
import OrderQa from "Pages/Projects/Orders/Components/OrderQa";
import OrderFiles from "Pages/Projects/Orders/Components/OrderFiles";
import OrderNotes from "Pages/Projects/Orders/Components/OrderNotes";
import {openSuccessNotification} from "Utils/NotificationUtils";

const ShowOrderPage = () => {

    const { id } = useParams<{ id }>();
    const {path} = useRouteMatch();

    const {data: order, isLoading} = useOrder(id);
    const [finishOrderMutation, {isLoading: isFinishingOrder}] = useFinishOrder();

    const toggleOrderFinished = async () => {
        const response = await finishOrderMutation(order!.id);

        openSuccessNotification('Success', `Order blev ${response?.finishedAt ? 'afsluttet' : 'genåbnet'}`)
    }

    if (isLoading || !order) {
        return (
            <div>
                <Box>
                    <FlexRow justify={'center'}>
                        <Spinner size={"large"} />
                    </FlexRow>
                </Box>
            </div>
        )
    }

    return (
        <div>
            <PageTitle
                title={`#${order.orderNumber} - ${order.title}`}
                icon={<ShoppingList />}
                fab={
                    order?.finishedAt == null ? (
                        <StyledButton disabled={isFinishingOrder} loading={isFinishingOrder} onClick={toggleOrderFinished} >Afslut sag</StyledButton>
                    ) : (
                        <StyledButton disabled={isFinishingOrder} loading={isFinishingOrder} onClick={toggleOrderFinished} >Genåben sag</StyledButton>
                    )
                }
            />
            <Row gutter={[20, 20]}>
                <Col sm={24} md={16}>
                    <Box>
                        <Switch>
                            <Route path={`${path}`} exact>
                                <OrderDetails order={order} />
                            </Route>
                            <Route path={`${path}/notes`} exact>
                                <OrderNotes order={order} />
                            </Route>
                            <Route path={`${path}/time`} exact>
                                <OrderTimeEntries order={order} />
                            </Route>
                            <Route path={`${path}/ordermaterials`} exact>
                                <OrderMaterials order={order} />
                            </Route>
                            <Route path={`${path}/documentation`} exact>
                                <OrderDocumentation order={order} />
                            </Route>
                            <Route path={`${path}/agreements`} exact>
                                <OrderAgreements order={order} />
                            </Route>
                            <Route path={`${path}/documents`} exact>
                                <OrderDocuments order={order} />
                            </Route>
                            <Route path={`${path}/files`} exact>
                                <OrderFiles order={order} />
                            </Route>
                            <Route path={`${path}/qa`} exact>
                                <OrderQa order={order} />
                            </Route>
                        </Switch>
                    </Box>
                </Col>
                <Col sm={24} md={8}>
                    <Box>
                        <div style={{padding: '10px 0', borderBottom: '1px solid #eee'}}>
                            <NavLink exact activeStyle={{fontWeight: 'bold', color: '#F39324'}} style={{color: '#9F9F9F'}} to={`/projects/orders/show/${id}`}>Stamdata</NavLink>
                        </div>
                        <div style={{padding: '10px 0', borderBottom: '1px solid #eee'}}>
                            <NavLink exact activeStyle={{fontWeight: 'bold', color: '#F39324'}} style={{color: '#9F9F9F'}} to={`/projects/orders/show/${id}/notes`}>Noter</NavLink>
                        </div>
                        <div style={{padding: '10px 0', borderBottom: '1px solid #eee'}}>
                            <NavLink exact activeStyle={{fontWeight: 'bold', color: '#F39324'}} style={{color: '#9F9F9F'}} to={`/projects/orders/show/${id}/time`}>Timer</NavLink>
                        </div>
                        <div style={{padding: '10px 0', borderBottom: '1px solid #eee'}}>
                            <NavLink exact activeStyle={{fontWeight: 'bold', color: '#F39324'}} style={{color: '#9F9F9F'}} to={`/projects/orders/show/${id}/documentation`}>Fotodokumentation</NavLink>
                        </div>
                        <div style={{padding: '10px 0', borderBottom: '1px solid #eee'}}>
                            <NavLink exact activeStyle={{fontWeight: 'bold', color: '#F39324'}} style={{color: '#9F9F9F'}} to={`/projects/orders/show/${id}/qa`}>Kvalitetssikring</NavLink>
                        </div>
                        <div style={{padding: '10px 0', borderBottom: '1px solid #eee'}}>
                            <NavLink exact activeStyle={{fontWeight: 'bold', color: '#F39324'}} style={{color: '#9F9F9F'}} to={`/projects/orders/show/${id}/ordermaterials`}>Materialer</NavLink>
                        </div>
                        <div style={{padding: '10px 0', borderBottom: '1px solid #eee'}}>
                            <NavLink exact activeStyle={{fontWeight: 'bold', color: '#F39324'}} style={{color: '#9F9F9F'}} to={`/projects/orders/show/${id}/agreements`}>Aftalesedler</NavLink>
                        </div>
                        <div style={{padding: '10px 0', borderBottom: '1px solid #eee'}}>
                            <NavLink exact activeStyle={{fontWeight: 'bold', color: '#F39324'}} style={{color: '#9F9F9F'}} to={`/projects/orders/show/${id}/files`}>Filer</NavLink>
                        </div>
                        <div style={{padding: '10px 0', borderBottom: '1px solid #eee'}}>
                            <NavLink exact activeStyle={{fontWeight: 'bold', color: '#F39324'}} style={{color: '#9F9F9F'}} to={`/projects/orders/show/${id}/documents`}>Tilbud, Overslag, Faktura & Kreditnota</NavLink>
                        </div>
                    </Box>
                </Col>
            </Row>
        </div>
    )

}

export default ShowOrderPage;
