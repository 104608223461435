import React from 'react';
import {useWatch} from "react-hook-form";
import {getNumberAsCurrency, roundNumber} from "Utils/NumberUtils";

const PriceCalculatorLineTotal = ({control, index}) => {

    const values = useWatch<any>({
        control,
        name: `materials[${index}]`
    })

    const total = values ? values.quantity * (values.salesPriceExVat * (1 - (values.discount / 100))) : 0;

    return (
        <span style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{getNumberAsCurrency(roundNumber(total))}</span>
    )
}

export default PriceCalculatorLineTotal
