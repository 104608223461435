import React from 'react';
import {Route, Switch, useRouteMatch} from "react-router-dom";
import NewsOverviewPage from "Pages/Admin/News/NewsOverviewPage";

const NewsAdminRoutes = () => {

    const {path} = useRouteMatch();

    return (
        <Switch>

            <Route path={`${path}`} exact>
                <NewsOverviewPage />
            </Route>

        </Switch>
    )
}

export default NewsAdminRoutes
