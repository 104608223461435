import React, { useState } from "react";
import styles from "./DocumentFolderAdmin.module.scss";
import { DocumentReadModel } from "Apis/Documents/Models/DocumentReadModel";
import { Dropdown, Menu } from "antd";
import DocumentCrudModal from "Pages/Admin/Documents/Components/DocumentCrudModal";
import FlexRow from "Components/FlexRow";
import Link from "Assets/Icons/Link";

interface Props {
    file: DocumentReadModel,
}

const DocumentAdminLink = (props: Props) => {

    const [showModal, setShowModal] = useState<boolean>(false);

    const menu = (
        <Menu>
            <Menu.Item key={"1"} onClick={() => {
                setShowModal(true)
            }}>Opdater</Menu.Item>
        </Menu>
    )

    const openLink = async () => {
        window.open(props.file.link, "_blank");
    }

    return (
        <>
            <Dropdown overlay={menu} trigger={['contextMenu']}>
                <FlexRow justify={"space-between"} align={"center"} direction={"column"} className={styles.container} onClick={openLink}>
                    <FlexRow justify={"center"} align={"center"} className={"h-100"}>
                        <Link width={'20%'} fill="rgba(147, 147, 147, 0.5)"/>
                    </FlexRow>
                    <FlexRow align={"center"} justify={"center"}>
                        <div className={"w-100 text-ellipsis"}>
                            {props.file.name}
                        </div>
                    </FlexRow>
                </FlexRow>
            </Dropdown>

            <DocumentCrudModal parentId={props.file.parentId} document={props.file} visible={showModal} onCancel={() => {
                setShowModal(false)
            }}/>
        </>
    )
}

export default DocumentAdminLink;
