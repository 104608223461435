import {useHistory} from "react-router-dom";
import {Space, Table} from "antd";
import DefaultPageLayout from "Pages/Layouts/DefaultPageLayout";
import FlexRow from "Components/FlexRow";
import StyledButton from "Components/StyledButton";
import Spinner from "Components/Spinner";
import React, {useCallback, useState} from "react";
import ShoppingList from "Assets/Icons/ShoppingList";
import {useOffers} from "Apis/OfferApi";
import {OfferModel} from "Apis/Models/OfferModel";
import {getOfferTypeAsString, getOfferStateAsString, getOfferStateColor} from "Utils/OfferUtils";
import {useCustomers} from "Apis/CustomerApi";
import {getDateOnlyString} from "Utils/DateUtils";
import Pill from "Components/Pill";
import Box from "Components/Box";
import _ from 'lodash';
import {useUserProvider} from "../../../Providers/UserProvider";
import {OrderModel} from "../../../Apis/Models/OrderModel";

const OffersOverviewPage = () => {

    const history = useHistory();
    const { userContext } = useUserProvider();

    const [searchFilter, setSearchFilter] = useState<string>('')
    const [stateFilter, setStateFilter] = useState<string>('')
    const [offerType, setOfferType] = useState<string>('')
    const [userId, setUserId] = useState<string>('')

    const {data: offers, isLoading} = useOffers(searchFilter, stateFilter, offerType, userId);
    const {data: customers, isLoading: isLoadingCustomers} = useCustomers()
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const search = useCallback(_.debounce(async (searchFilter: string) => {
        setSearchFilter(searchFilter)
    }, 300), [])

    return (
        <DefaultPageLayout
            title={"Tilbud og overslag"}
            icon={<ShoppingList />}
            beforeBox={
                <Box style={{marginBottom: 15, padding: 10}}>
                    <FlexRow>
                        <input onChange={e => search(e.target.value)} type="text"
                               className={"input input-invisible full-width"} placeholder={"Søg...."}/>
                        <select placeholder={"Vælg type"} onChange={e => setOfferType((e.target.value))}
                                style={{paddingRight: 20, marginRight: 20}}>
                            <option value="">Vælg type</option>
                            <option value="Offer">Tilbud</option>
                            <option value="Estimate">Overslag</option>
                        </select>
                        <select placeholder={"Vælg medarbejder"} onChange={e => setUserId((e.target.value))} style={{paddingRight: 20, marginRight: 20}}>
                            <option value="">Alle medarbejdere</option>
                            {userContext!.users!.map((user) => (
                                <option value={user.id}>{user.name}</option>
                            ))}
                        </select>
                        <select placeholder={"Vælg status"} onChange={e => setStateFilter((e.target.value))}
                                style={{paddingRight: 20, marginRight: 20}}>
                            <option value="">Åbne</option>
                            <option value="Draft">Kladde</option>
                            <option value="WaitingForApproval">Afventer godkendelse</option>
                            <option value="Approved">Afventer kundegodkendelse</option>
                            <option value="Rejected">Afvist af installatør</option>
                            <option value="CustomerApproved">Godkendt af kunde</option>
                            <option value="CustomerRejected">Afvist af kunde</option>
                        </select>
                    </FlexRow>
                </Box>
            }
            fab={
                <div>
                    <Space>
                        <StyledButton onClick={() => history.push('/projects/offers/create/offer')}>Opret
                            tilbud</StyledButton>
                        <StyledButton onClick={() => history.push('/projects/offers/create/estimate')}>Opret
                            overslag</StyledButton>
                    </Space>
                </div>
            }>
            <Table
                size={"small"}
                rowKey={'id'}
                dataSource={offers}
                loading={{indicator: <Spinner />, spinning: isLoading || isLoadingCustomers}}
                pagination={false}
                onRow={(record) => {
                    return {
                        onClick: () => history.push(`/projects/offers/${record.id}`),
                    };}
                }
                rowClassName={'clickable'}
            >

                <Table.Column title={"Type"} dataIndex={"offerType"} render={(text, record: OfferModel) => (
                    <FlexRow justify={"start"}>
                        <Pill color={getOfferStateColor(record)} />
                        <FlexRow align={"start"} justify={"start"} direction={"column"}>
                            <span>
                                {getOfferTypeAsString(record.offerType)}
                            </span>
                            <span style={{fontSize: '.7em'}}>
                                {getOfferStateAsString(record)}
                            </span>
                        </FlexRow>
                    </FlexRow>
                )} />

                <Table.Column title={"Dato"} dataIndex={"date"} render={(text, record: OfferModel) => record.date ? getDateOnlyString(record.date) : '' } />
                <Table.Column title={"Overskrift"} dataIndex={"title"} />

                <Table.Column title={"Kunde"} dataIndex={"customerId"} render={(text, record: OfferModel) => (
                    <FlexRow direction={"column"} justify={"start"} align={"start"}>
                        <span style={{fontWeight: 600}}>
                            {customers?.find(x => x.id === record.customerId)!?.name}
                        </span>
                        <span style={{fontSize: '.8em'}}>
                            {record.deliveryAddress.street} - {record.deliveryAddress.zipCode} - {record.deliveryAddress.city}s
                        </span>
                    </FlexRow>
                ) } />

                <Table.Column title={"Ansvarlig"} dataIndex={"responsibleId"} render={(text, record: OrderModel) => (
                    <FlexRow direction={"column"} justify={"start"} align={"start"}>
                        {userContext!.users?.find(x => x.id === record.responsibleId)?.name}
                    </FlexRow>
                ) } />

            </Table>
        </DefaultPageLayout>
    )
}

export default OffersOverviewPage;
