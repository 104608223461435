import React, {useState} from 'react';
import {DatePicker, Table} from "antd";
import Graph from "Assets/Icons/Graph";
import FlexRow from "Components/FlexRow";
import Box from "Components/Box";
import {useDashboardTimeEntries} from "Apis/DashboardApi";
import moment from "moment";
import {useUserProvider} from "../../Providers/UserProvider";
import DefaultPageLayout from "../Layouts/DefaultPageLayout";
import {TimeEntryModel} from "../../Apis/Models/TimeEntryModel";
import {sumTimeEntries} from "../../Utils/TimeEntryUtils";

type FormattedData = {
    userId: number;
    timeEntries: TimeEntryModel[];
    dates: TimeEntriesByDate[];
}

type TimeEntriesByDate = {
    entries: TimeEntryModel[];
    date: string;
}

const TimeEntriesPage = () => {

    const { userContext } = useUserProvider();

    const [userId, setUserId] = useState<string | undefined>()

    const [displayMode, setDisplayMode] = useState<'ByDate' | 'ByEntry' | string>('ByDate')
    const [fromDate, setFromDate] = useState<Date>(moment().startOf('week').toDate());
    const [toDate, setToDate] = useState<Date>(moment().endOf('week').toDate());

    const { data } = useDashboardTimeEntries(userId, fromDate, toDate);

    const getFormattedData = () => {
        if (!data) {
            return [];
        }

        let formattedData: FormattedData[] = [];

        let userIds = new Set(data.map(x => x.userId));

        userIds.forEach((userId) => {
            let timeEntriesForUser = data.filter(x => x.userId === userId);
            let uniqueDates = new Set(timeEntriesForUser.map(x => moment(x.from).format('YYYY-MM-DD')));

            let dateTimeEntries: TimeEntriesByDate[] = [];

            uniqueDates.forEach(x => {
                dateTimeEntries.push({
                    entries: timeEntriesForUser.filter(y => moment(y.from).format('YYYY-MM-DD') === x),
                    date: moment(x).format('YYYY-MM-DD')
                })
            });

            formattedData.push({
                userId: userId,
                timeEntries: data.filter(x => x.userId === userId),
                dates: dateTimeEntries
            })
        })

        return formattedData;
    }

    const getTime = (time: number) => {
        return time < 10 ? '0' + time : time;
    }

    const getSecondsFromDuration = (duration: number) => {
        let unusedDuration = duration;

        const hours = Math.floor(unusedDuration / 3600);

        unusedDuration = unusedDuration % 3600;

        const minutes = Math.floor(unusedDuration / 60);

        return `${getTime(hours)}:${getTime(minutes)}`
    }

    const getContent = (data: FormattedData) => {
        switch (displayMode) {
            case 'ByDate':
                return contentByDate(data);
            case 'ByEntry':
                return contentByEntry(data);
        }
    }

    const contentByDate = (data: FormattedData) => {
        return (
            <Table dataSource={data.dates}
                   pagination={false}
                   rowClassName={"clickable"}
                   footer={() => (
                       <div style={{display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between'}}>
                           <div>I alt</div>
                           <div>
                               <b>
                                   {getSecondsFromDuration(sumTimeEntries(data.timeEntries))}
                               </b>
                           </div>
                       </div>
                   )}
            >
                <Table.Column title={"Dato"} dataIndex={"from"} render={(value, record: TimeEntriesByDate) => moment(record.date).format('DD MMMM YYYY')} />
                <Table.Column title={"Dato"} dataIndex={"from"} render={(value, record: TimeEntriesByDate) => getSecondsFromDuration(sumTimeEntries(record.entries))} />
            </Table>
        )
    }

    const contentByEntry = (data: FormattedData) => {
        return (
            <Table dataSource={data.timeEntries}
                   pagination={false}
                   rowClassName={"clickable"}
                   footer={() => (
                       <div style={{display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between'}}>
                           <div>I alt</div>
                           <div>
                               <b>
                                   {getSecondsFromDuration(sumTimeEntries(data.timeEntries))}
                               </b>
                           </div>
                       </div>
                   )}
            >
                <Table.Column title={"Dato"} dataIndex={"from"} render={(value, record: TimeEntryModel) => moment(record.from).format('DD MMMM YYYY')} />
                <Table.Column title={"Beskrivelse"} dataIndex={"description"}  />
                <Table.Column title={"Tidspunkt"} dataIndex={"from"} render={(value, record: TimeEntryModel) => (<span>{moment(record.from).format('HH:mm')} - {moment(record.to).format('HH:mm')}</span>)} />
                <Table.Column title={"Tid"} align={'right'} dataIndex={"from"} render={(value, record: TimeEntryModel) => (<b>{getSecondsFromDuration(moment(record.to).diff(moment(record.from), 'second'))}</b>)} />
            </Table>
        )
    }

    return (
        <DefaultPageLayout
            title={"Timeoverblik"}
            icon={<Graph />}
            beforeBox={
                <Box style={{marginBottom: 15, padding: 10}}>
                    <FlexRow>
                        <select placeholder={"Vælg medarbejder"} onChange={e => setUserId((e.target.value))} className={"input input-invisible full-width"} >
                            <option value="">Alle medarbejdere</option>
                            {userContext!.users!.map((user) => (
                                <option value={user.id}>{user.name}</option>
                            ))}
                        </select>

                        <select placeholder={"Vælg visning"} value={displayMode} onChange={e => setDisplayMode(e.target.value)} className={"input input-invisible full-width"}>
                            <option value={'ByEntry'}>Pr registrering</option>
                            <option value={'ByDate'}>Pr dato</option>
                        </select>

                        <div style={{display: "flex", gap: 10}}>
                            <DatePicker
                                placeholder={"Fra"}
                                style={{borderColor: 'transparent', minWidth: 140}}
                                format={"DD-MM-YYYY"}
                                value={moment(fromDate)}
                                onChange={(e => {
                                    setFromDate(e!.toDate())
                                })}
                            />

                            <DatePicker
                                placeholder={"Til"}
                                style={{borderColor: 'transparent', minWidth: 140}}
                                format={"DD-MM-YYYY"}
                                value={moment(toDate)}
                                onChange={(e => {
                                    setToDate(e!.toDate())
                                })}
                            />
                        </div>
                    </FlexRow>

                </Box>
        }>
            <div style={{display: 'flex', gap: 40, flexDirection: 'column'}}>
                {getFormattedData().map((x) => (
                    <div key={x.userId}>
                        <h2>
                            {userContext?.users.find(u => u.id === x.userId)?.name}
                        </h2>

                        {getContent(x)}
                    </div>
                ))}
            </div>
        </DefaultPageLayout>
    )
}

export default TimeEntriesPage
